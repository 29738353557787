import { mapActions, mapGetters, mapMutations } from "vuex";

import WithRender from "./Sections.html";

import { FormlyGenerateFunctions } from "../../../helpers/formly";
import { getFormFields } from "../../../assets/forms/forms";
import DetailFormly from "./DetailFormly";

export default WithRender({
  props: ["type"],
  components: {
    DetailFormly
  },
  data() {
    return {
      form: {},
      edit: false,
      previewMode: "preview",
      websiteUrl: "",
      qrCodeName: "",
      validation: []
    };
  },
  computed: {
    ...mapGetters(["getCurrentModel", "getCardType"]),
    model() {
      return this.getCurrentModel;
    },
    fields() {
      const fieldsF = getFormFields(this.type, 1);
      const fields = fieldsF(FormlyGenerateFunctions, this.translate);
      return fields;
    },
    isEditMode() {
      return false;
    }
  },
  mounted() {},
  methods: {
    ...mapActions([
      "createQrCode",
      "updateCurrentModel",
      "fetchQrDetails",
      "createSaveQrCode",
      "uploadFileToServerAsBlob",
      "uploadFileToServer"
    ]),
    translate(str) {
      return this.$t(str);
    },
    async onUploadFileAsBlob(name, data) {
      try {
        const { blob, ...rest } = data;
        const uploadFile = await this.uploadFileToServerAsBlob(blob);
        const onUpdateAttributes = {
          name,
          value: {
            base_path: uploadFile.base_path,
            ...rest
          }
        };
        this.onUpdate(onUpdateAttributes);
      } catch (error) {
        console.log(error);
      }
    },
    async onUploadFile(name, file) {
      try {
        const uploadFile = await this.uploadFileToServer(file);
        this.onUpdate({ name, value: uploadFile.base_path });
      } catch (error) {
        console.log(error);
      }
    },
    onUpdate(v) {
      const { name, value } = v;
      const payload = {
        key: name,
        value: value
      };
      this.updateCurrentModel(payload);
      let formElement = document.getElementById("formCard");
      if (formElement) {
        this.validation = [];
        const validateEvent = new Event("validate");
        let invalidFound = false;
        formElement.forEach(element => {
          if (element.hasAttribute("hasValidators")) {
            element.dispatchEvent(validateEvent);
          }
        });

        if (this.validation.length > 0) {
          this.validation.forEach(({ element }) => {
            if (element && !invalidFound) {
              // element.scrollIntoView({
              //   behavior: "smooth"
              // });
              element.focus();
              invalidFound = true;
            }
          });
        }

        if (!invalidFound) {
          this.createSaveQrCode(1);
        } else {
          //formElement.reportValidity();
        }
      }
    },
    onClicked(v) {
      switch (v.type) {
        case "SwapColor":
          const { swapFrom, swapTo } = v;
          const [newSwapTo, newSwapFrom] = [
            this.model[swapFrom],
            this.model[swapTo]
          ];
          this.updateCurrentModel({ key: swapFrom, value: newSwapFrom });
          this.updateCurrentModel({ key: swapTo, value: newSwapTo });
          break;
        case "uploadFile": {
          const { name, file } = v;
          this.onUploadFile(name, file);
          break;
        }
        case "uploadFileAsBlob": {
          const { name, data } = v;
          this.onUploadFileAsBlob(name, data);
          break;
        }
        case "uploadWelcomeFile": {
          const { name, file } = v;
          this.onUploadFile(name, file);
          break;
        }
        default:
          break;
      }
    },
    onValidate(v) {
      this.validation.push(v);
      this.$emit("validate", v);
    },
    onClicked(v) {
      switch (v.type) {
        case "SwapColor":
          const { swapFrom, swapTo } = v;
          const [newSwapTo, newSwapFrom] = [
            this.model[swapFrom],
            this.model[swapTo]
          ];
          this.updateCurrentModel({ key: swapFrom, value: newSwapFrom });
          this.updateCurrentModel({ key: swapTo, value: newSwapTo });
          break;
        case "uploadFile": {
          const { name, file } = v;
          this.onUploadFile(name, file);
          break;
        }
        case "uploadFileAsBlob": {
          const { name, data } = v;
          this.onUploadFileAsBlob(name, data);
          break;
        }
        case "uploadWelcomeFile": {
          const { name, file } = v;
          this.onUploadFile(name, file);
          break;
        }
        default:
          break;
      }
    },
    async onNextClicked() {
      try {
        const payload = await this.createSaveQrCode(1);
        if (!payload.save) {
          this.$router.push({ name: "home", query: { open: "true" } });
        } else {
          this.$router.push({ name: "home" });
        }
      } catch (error) {}
    }
  },
  watch: {
    model(newValue, oldValue) {
      if (this?.$refs?.detailFormly) {
        if (this?.$refs.detailFormly?.templatePreviewIframe?.contentWindow) {
          this.$refs.detailFormly.templatePreviewIframe.contentWindow.postMessage(
            {
              type: "landingChangeData",
              code: { ...newValue },
              templateType: null,
              demo: true
            },
            "*"
          );
        }
      }
    }
  }
});
