import baseField from "vue-formly-bootstrap/src/fields/baseField";

import WithRender from "./QRUploadRectangle.html";
import SwapIcon from "../icons/SwapIcon";
const avatarDefaults = {
  imageSize: 100,
  bgColor: "#ffffff",
  basePath: null
};
export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  components: {
    SwapIcon
  },
  data() {
    return {
      imageSize: avatarDefaults.imageSize,
      bgColor: avatarDefaults.bgColor,
      basePath: avatarDefaults.basePath
    };
  },
  mounted() {},

  computed: {
    name() {
      return this.field.key;
    },
    selectorId() {
      return this.field.templateOptions.selectorId;
    },
    aspectRatio() {
      return +this.field.templateOptions.aspectRatio || 0;
    },
    hideOptionId() {
      return this.field.templateOptions.hideOptionId || "";
    },
    defaultWelcomeImage() {
      return this.field?.templateOptions?.defaultWelcomeImage || null;
    },
    defaultImage() {
      return this?.field?.templateOptions?.defaultImage || null;
    },
    enableWelcomeImage() {
      return this.field?.templateOptions?.welcomeImage || false;
    },
    isEmptyOrDefaultImage() {
      return !this.basePath;
    }
  },
  watch: {
    model: {
      handler(newValue, oldValue) {
        if (oldValue[this.name] == newValue[this.name]) return;
        const value = newValue[this.name];
        this.imageSize = value?.imageSize || avatarDefaults.imageSize;
        this.bgColor = value?.bgColor || avatarDefaults.bgColor;
        this.basePath = value?.base_path || avatarDefaults.basePath;
      },
      deep: true
    }
  },
  methods: {
    onChangeFileName({ target: { files } }) {
      const file = files[0];
      const { selectorId, aspectRatio, name } = this;
      this.$root.$emit("avatarUpload", {
        selectorId,
        file,
        aspectRatio,
        name
      });
    },
    onRemoveClicked() {
      const { name, imageSize, bgColor } = this;
      this.$emit("update", {
        name,
        value: {
          imageSize,
          bgColor,
          base_path: null
        }
      });
    },
    onHideBarCode() {
      const name = this.hideOptionId;
      const value = !this.model[name];
      this.$emit("update", { name, value });
    }
  }
});
