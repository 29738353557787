<div class="flex my-2">
  <div class="relative w-full">
    <input type="text"
      class="bg-qr_color-gray_3 h-12 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent rounded-r-none"
      :value="value" :placeholder="placeholder" ref="customInput" @change="handleChange" v-on="inputListeners"
      :hasValidators="hasValidators" />

  </div>
  <button ref="dropdownBtn" data-dropdown-toggle="dropdown-search-city" type="button"
    class="flex-shrink-0 inline-flex items-center py-0 px-4 font-medium text-center text-qr_color-gray bg-qr_color-gray_3 rounded-r-qr_input_radius">
    <svg aria-hidden="true" class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd"
        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        clip-rule="evenodd"></path>
    </svg>
  </button>

  <div ref="dropdownMenu" class="hidden z-30 w-44 bg-white rounded divide-y divide-gray-100 shadow"
    data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="top" style="
      position: absolute;
      inset: auto auto 0px 0px;
      margin: 0px;
      transform: translate3d(897px, 5637px, 0px);
    ">
    <ul class="py-1 text-sm text-gray-700 dark:text-gray-200">
      <li v-for="item in items">
        <button type="button" class="inline-flex py-2 px-4 w-full text-sm text-gray-700 hover:bg-gray-100"
          role="menuitem" @click="onSelectItem(item)">
          <div class="inline-flex items-center">
            {{item}}
          </div>
        </button>
      </li>
    </ul>

    <div class="py-1">
      <div class="block py-2 px-4 text-sm text-cyan-600 hover:bg-gray-100 flex items-center hover:cursor-pointer"
        @click="onSelectCustom()">
        <PencilIcon class="fill-current w-3 h-3 mr-2"> </PencilIcon>
        <span>
          {{customLabel}}
        </span>

      </div>
    </div>
  </div>
  <div v-if="errorMessage && errorMessage !== ''" class="flex items-center pl-4 text-right col-span-2">
    <span class="text-qr_color-error whitespace-nowrap" style="white-space: nowrap;">{{errorMessage}}</span>
  </div>
</div>