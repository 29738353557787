import baseField from "vue-formly-bootstrap/src/fields/baseField";

import WithRender from "./FieldGroup.html";

export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  mounted() {
    if (this.collapseContainer) {
      const { fieldGroupBody } = this.$refs;
      const [triggerEle, targetEle] = fieldGroupBody;
      if (triggerEle && targetEle) {
        const options = {
          alwaysOpen: true,
          activeClasses: "text-gray-900 hover:cursor-pointer",
          inactiveClasses: "text-gray-500 hover:cursor-pointer collapsed"
        };
        const accordionItems = [];
        accordionItems.push({
          id: `${this.key}`,
          triggerEl: triggerEle,
          targetEl: targetEle,
          active: true
        });
        new window.Accordion(accordionItems, options);
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    update(data) {
      this.$emit("update", data);
    },
    validate(data) {
      this.$emit("validate", data);
    },
    click(data) {
      this.$emit("click", data);
    }
  },
  computed: {
    key() {
      return this.field.key;
    },
    subFields() {
      return [...this.field.fieldGroup];
    },
    className() {
      let className = "";
      if (this.field.className) {
        className = this.field.className;
      }
      return className;
    },
    templateOptions() {
      let templateOptions = {};
      if (this.field.templateOptions) {
        templateOptions = this.field.templateOptions;
      }
      return templateOptions;
    },
    hideExpression() {
      let hideExpression = null;
      if (this.field.hideExpression) {
        hideExpression = this.field.hideExpression;
      }
      return hideExpression;
    },
    collapseKey() {
      return this.field.collapseKey;
    },
    collapseContainer() {
      return this.field.collapseContainer;
    },
    collapseHead() {
      if (!this.collapseKey) {
        return null;
      }
      return `collapse_${this.collapseKey}-head`;
    },
    collapseBody() {
      if (!this.collapseKey) {
        return null;
      }
      return `collapse_${this.collapseKey}-body`;
    },
    dataAccordion() {
      if (!this.collapseContainer) {
        return null;
      }
      return "collapse";
    },
    isHide() {
      return (
        (typeof this.hideExpression === "function" &&
          this.hideExpression(this.model)) ||
        (typeof this.hideExpression == "boolean" && this.hideExpression)
      );
    }
  }
});
