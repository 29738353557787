<div class="w-full mt-4">
  <div class="">
    <div class="mb-6 flex w-full items-center" v-for="link in links">
      <label :for="`label-${link.id}`"
        class="w-20 flex-shrink-0 text-qr_color-blue_2 text-16 font-semibold inputTitle">{{link.type}}
      </label>

      <div class="flex-shrink-0 w-8 h-8 p-1 text-qr_color-yellow_icon mr-8">
        <component :is="link.type + 'Icon'" class="w-6 h-6 fill-current"></component>
      </div>
      <div class="flex flex-grow">
        <input type="text" :id="`label-${link.id}`" :name="`social_${link.id}`" :value="link.value"
          class="indent-8 bg-qr_color-gray_3 h-12 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent"
          @change="(event)=>onSocialUpdate(event)" :placeholder="link.label" v-on="inputListeners"
          :hasValidators="hasValidators" />
      </div>
      <span class="text-qr_color-error">{{getErrorMessage(link.type)}}</span>
      <div class="ml-2 w-12 p-1">
        <button type="button" class="text-qr_color-blue_2" @click="onRemoveSocial(link)">
          <CloseIcon class="w-4 h-4 fill-current"> </CloseIcon>
        </button>
      </div>
    </div>
  </div>
  <div v-if="required && !hasLink('AppleStore') && !hasLink('GooglePlay') && !hasLink('Amazon')">
    <span class="text-qr_color-error">{{getErrorMessage("Global")}}</span>
    <input type="text" class="border-0 w-0 h-0 focus:ring-0 focus:outline-none" value="" :hasValidators="required"
      v-on="inputListeners" />
  </div>
  <div class="w-full flex justify-between">
    <div class="text-qr_color-blue_2 text-16 font-semibold" v-if="!allLinksAdded()">
      <span>{{$t('qr_media_channels.add_more')}}:</span>
    </div>

    <div class="flex flex-row">
      <button v-if="!hasLink('AppleStore')" type="button"
        class="flex px-5 text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
        @click="addButton('AppleStore', 'https://apps.apple.com/us/app/')">
        <AppleStoreIcon class="mr-2 w-6 h-6 fill-current" />

        <span>{{$t("App Store")}}</span>
      </button>
      <button v-if="!hasLink('GooglePlay')" type="button"
        class="flex px-5 text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
        @click="addButton('GooglePlay', 'https://play.google.com/store/apps/')">
        <GooglePlayIcon class="mr-2 w-6 h-6 fill-current" />

        <span>
          {{$t("Google Play")}}
        </span>
      </button>
      <button v-if="!hasLink('Amazon')" type="button"
        class="flex px-5 text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
        @click="addButton('Amazon', 'https://www.amazon.com/')">
        <AmazonIcon class="mr-2 w-6 h-6 fill-current" />

        <span>{{$t("Amazon")}} </span>
      </button>
    </div>
  </div>
</div>