import { mapGetters, mapActions } from "vuex";

import WithRender from "./NextActionFooter.html";
import ArrowLeftIcon from "../../base/icons/ArrowLeftIcon";
import ArrowRightIcon from "../../base/icons/ArrowRightIcon";
import Spinner from "../../base/components/Spinner";

export default WithRender({
  props: {
    isBackShow: {
      type: Boolean,
      default: true
    },
    edit: {
      type: Boolean,
      default: false
    },
    popupMode: {
      type: Boolean,
      default: false
    },
    onCloseDialog: {
      type: Function,
      default: () => {}
    }
  },
  components: {
    ArrowLeftIcon,
    ArrowRightIcon,
    Spinner
  },
  computed: {
    ...mapGetters(["getEditorMode", "getQrCurrentDesign", "getQRActionLoading"])
  },
  methods: {
    ...mapActions(["changeCurrentStep", "fetchQrDetails"]),
    onNextClicked() {
      this.$emit("next");
    },
    onPreviousClicked() {
      if (this.getEditorMode) {
        if (this.popupMode) {
          this.fetchQrDetails({
            id: parseInt(this.getQrCurrentDesign.qr_code_id)
          });
          this.changeCurrentStep("create_new_detail");
          this.onCloseDialog();
        } else {
          this.changeCurrentStep("create_new");
        }
      } else {
        this.$emit("back");
      }
    }
  }
});
