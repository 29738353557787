<div class="w-full">
  <div class="w-full">
    <label
      :for="'color-picker-' + name"
      class="text-qr_color-blue_2 text-16 font-semiboldblock mb-2 text-sm font-medium text-gray-700 dark:text-gray-300"
      >{{title}}</label
    >
    <div class="flex py-2 relative">
      <input
        type="text"
        :id="'color-picker-' + name"
        class="bg-qr_color-gray_3 h-10 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent"
        v-model="color"
        @change="onChangedColor"
      />

      <button
        class="inline-flex items-center h-9 inline-flex items-center px-1.5 py-1.5 pt-2 rounded-qr_input_radius border-0 absolute right-0"
        type="button"
        @click="toggleColorPicker"
      >
        <span
          class="w-6 h-6 border-0 rounded-qr_color_radius border-0"
          :style="`background-color: ${color}`"
        >
        </span>
      </button>
    </div>
  </div>

  <div class="absolute z-10" v-if="colorPickerVisible">
    <QRCustomColorPicker
      v-model="color"
      @change="onChangedColor"
      @close="colorPickerVisible=false"
    />
  </div>
</div>
