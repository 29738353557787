import Vue from "vue";
import VueRouter from "vue-router";
import CreateNew from "./pages/CreateNew";
import CreateNewDetail from "./pages/CreateNewDetail";
import Error404Page from "./pages/Error404Page";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import LogoutPage from "./pages/LogoutPage";
import ProfilePage from "./pages/ProfilePage";
import ManagePage from "./pages/ManagePage";

const initRouter = () => {
  Vue.use(VueRouter);

  const routes = [
    { path: "/", redirect: "/home" },
    { path: "/home", component: HomePage, name: "home" },
    { path: "/login", component: LoginPage, name: "login" },
    { path: "/register", component: RegisterPage, name: "register" },
    { path: "/logout", component: LogoutPage, name: "logout" },
    { path: "/profile", component: ProfilePage, name: "profile" },
    { path: "/new", component: CreateNew, name: "new" },
    { path: "/manage/:id", component: ManagePage, name: "manage" },
    {
      path: "/new/create/:type",
      component: CreateNewDetail,
      name: "new-detail"
    },
    {
      path: "/edit/qr/:type/:id",
      component: CreateNewDetail,
      name: "edit-detail"
    },
    { path: "*", component: Error404Page }
  ];
  const liveBase = "/datacenter/qr-app/";
  const base = process.env.NODE_ENV === "development" ? "/" : liveBase;
  const AppRouter = new VueRouter({
    routes,
    base
  });
  return AppRouter;
};

export { initRouter };
