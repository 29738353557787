<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
  y="0px" viewBox="0 0 297 297" style="enable-background:new 0 0 297 297;" xml:space="preserve">
  <path d="M93.255,281h110.49l-0.173,1.629c-0.389,3.666,0.797,7.324,3.263,10.065c2.466,2.74,5.979,4.306,9.665,4.306h50
	c6.649,0,12.227-5.017,12.928-11.629l14-132c0.389-3.666-0.797-7.324-3.263-10.065c-2.466-2.74-5.979-4.306-9.665-4.306h-14.478
	c4.185-11.344,9.043-25.479,10.305-33.138c3.477-21.108-2.827-41.858-18.23-60.007C234.09,17.571,191.903,0,148,0
	C104.429,0,61.01,18.158,37.385,46.26c-15.229,18.116-21.347,38.769-17.691,59.724c1.339,7.68,6.447,21.7,10.879,33.017H16.5
	c-3.687,0-7.199,1.565-9.665,4.306c-2.466,2.741-3.651,6.399-3.263,10.065l14,132C18.273,291.983,23.851,297,30.5,297h50
	c0.008,0,0.016,0,0.02,0c7.18,0,13-5.82,13-13L93.255,281z M42.194,271L30.952,165h23.854l11.242,106H42.194z M90.498,255
	l-4.349-41.006c6.03-1.412,65.654-14.608,124.703-0.002L206.503,255H90.498z M217.572,150.629l-3.955,37.292
	c-58.3-13.48-114.782-3.275-130.229,0.044l-3.96-37.336C78.727,144.017,73.149,139,66.5,139h-7.918
	c-5.389-13.161-12.114-30.825-13.275-37.483c-2.898-16.616,4.915-30.122,11.98-38.525C76.178,40.52,111.785,26,148,26
	c35.927,0,71.361,14.397,90.274,36.681c7.188,8.469,15.175,22.103,12.398,38.956c-1.092,6.63-7.367,24.18-12.435,37.363H230.5
	C223.851,139,218.273,144.017,217.572,150.629z M254.806,271h-23.854l11.242-106h23.854L254.806,271z" />
</svg>