<svg
  xmlns="http://www.w3.org/2000/svg"
  width="21.029"
  height="21.029"
  viewBox="0 0 21.029 21.029"
>
  <g
    id="Icon_feather-upload"
    data-name="Icon feather-upload"
    transform="translate(-3 -3)"
  >
    <path
      id="Path_75"
      data-name="Path 75"
      d="M22.529,22.5v4.007a2,2,0,0,1-2,2H6.5a2,2,0,0,1-2-2V22.5"
      transform="translate(0 -5.98)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="3"
    />
    <path
      id="Path_76"
      data-name="Path 76"
      d="M20.516,9.508,15.508,4.5,10.5,9.508"
      transform="translate(-1.993)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="3"
    />
    <path
      id="Path_77"
      data-name="Path 77"
      d="M18,4.5V16.52"
      transform="translate(-4.485)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="3"
    />
  </g>
</svg>
