// couponsOption
export const createDynamicRatingFieldsWidget = formly => {
  return [
    formly.container(
      formly.header({
        index: 1,
        sectionName: "",
        headline: "",
        hideCollapse: true,
        icon: null
      }),
      formly.body({
        name: "section_basic_info",
        collapseKey: "section_basic_info",
        widget: 1,
        key: "code",
        content: [
          formly.inputRow({
            label: "Company",
            placeholder: "Name of company or shop",
            key: "company"
          }),
          formly.inputRowRequired({
            key: "headline",
            className: "form-input-row form-row-container flex flex-col",
            label: "Headline",
            placeholder: "e.g. Get 20% off your next order",
            condition: "true",
            errorMsgTitle: "Please provide a headline."
          }),
          formly.textAreaRow({
            label: "Description",
            placeholder: "Provide some more info about your offer",
            key: "description",
            maxChar: 180
          }),

          {
            className: "form-row-container flex flex-col",
            type: "field_group",
            fieldGroup: [
              {
                type: "qr_input_label",
                templateOptions: {
                  label: "Sale Badge",
                  tooltip:
                    "Use this optional element as a visual highlight on your page"
                }
              },
              {
                className: "col-sm-12 col-md-8 box-input",
                key: "sale",
                type: "qr_dropdown_box_with_custom",
                templateOptions: {
                  defaultValue: "30% OFF",
                  customLabel: "Custom",
                  items: ["10% OFF", "30% OFF", "50% OFF", "2 FOR 1", "SALE"]
                }
              },
              {
                className:
                  "form-input-row form-row-container relative flex flex-col",
                hideExpression: model => !!model["showBarcode"],
                type: "field_group",
                fieldGroup: [
                  {
                    type: "qr_input_label",
                    templateOptions: {
                      label: "Coupon Name",
                      required: true,
                      tooltip:
                        "Provide your coupon name. It's the one customers will need to enter during the online checkout or show to the cashier in store."
                    }
                  },
                  {
                    type: "qrinput",
                    key: "name",
                    templateOptions: {
                      placeHolder: "e.g. EXTRA20OFF",
                      errorMessage: "Please provide a coupon name.",
                      maxlength: 15,
                      showCharCount: true,
                      required: "true"
                    }
                  }
                ]
              },
              formly.avatarRectangular({
                key: "barcode",
                uploadId: "uploadBarcode",
                avatarExtraParam: "barcode_extra",
                hideExpression: model => !model["showBarcode"],
                className:
                  "form-file-row rectangular-image-container-no-margin sm:items-center flex-col sm:flex-row gap-x-8",
                label: "Barcode",
                tooltip:
                  "Upload a barcode for scanning at the point of sale checkout. Not recommended for online redeeming.",
                hideOptionId: "showBarcode",
                aspectRatio: "1.77"
              }),
              formly.timeInput({
                key: "date",
                className: " flex-row",
                keyRequired: true,
                label: "Valid until",
                labelRequired: true,
                allDay: false,
                allDayLabel: "",
                startDateOnly: false,
                endDateOnly: true,
                errorMsg: "Please enter a date.",
                condition: "true"
              }),
              formly.textAreaRow({
                label: "Terms & Conditions",
                placeholder:
                  "Provide the Terms & Conditions of your coupon offer",
                tooltip:
                  "Define if there's a minimum order value, if multiple uses per person are allowed, if your coupon can be combined with other promotional offers, etc.",
                key: "terms",
                maxChar: 2000
              }),
              {
                className: "form-row-container flex flex-col",
                type: "field_group",
                fieldGroup: [
                  {
                    type: "qr_input_label",
                    templateOptions: {
                      label: "Website",
                      tooltip:
                        "Link to your Online Shop where customers can redeem your coupon."
                    }
                  },
                  {
                    className: "col-sm-12 col-md-8 box-input",
                    key: "website.label",
                    type: "qr_dropdown_box_with_custom",
                    templateOptions: {
                      defaultValue: "Shop now",
                      customLabel: "Custom",
                      items: ["Shop now", "Redeem now"]
                    }
                  },
                  {
                    type: "qrinput",
                    key: "website.url",
                    templateOptions: {
                      placeHolder: "www.your-website.com",
                      errorMessage: "Please enter a valid website address",
                      required: "true"
                    }
                  }
                ]
              },
              // formly.autocompleteAddress({
              //   label: "Location",
              //   tooltip:
              //     "Provide your store location if your coupon is meant for use at point of sale.",
              //   placeholder: "Enter your address",
              //   key: "autocompleteVisible",
              //   // condition: model => !!model["autocompleteVisible"]
              //   condition: model => true
              // }),
              formly.autocompleteInputForm({
                label: "Location",
                wrappedInAddressKey: true,
                condition: model => false,
                // condition: model => !model["autocompleteVisible"],
                tooltip:
                  "Provide your store location if your coupon is meant for use at point of sale."
              })
            ]
          }
        ]
      })
    )
  ];
};

export const createDynamicRatingRequiredFieldsWidget = _ => {
  return ["email"];
};
