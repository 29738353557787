<div class="my-2 w-full grid grid-cols-2 gap-4">
  <div class="flex relative">
    <div class="relative w-full">
      <input
        type="text"
        class="bg-qr_color-gray_3 h-12 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent rounded-r-none"
        v-model="getSelectedValue"
        required="true"
        readonly="true"
      />
    </div>
    <button
      ref="dropdownOpenBtn"
      class="flex-shrink-0 inline-flex items-center py-0 px-4 font-medium text-center text-qr_color-gray bg-qr_color-gray_3 rounded-r-qr_input_radius"
      type="button"
    >
      <svg
        aria-hidden="true"
        class="w-4 h-4"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 9l-7 7-7-7"
        ></path>
      </svg>
    </button>

    <!-- Dropdown menu -->
    <div
      ref="dropdownItems"
      class="hidden font-medium z-10 w-44 bg-white rounded divide-y divide-gray-200 shadow"
    >
      <ul class="py-1 text-sm text-gray-700">
        <li v-for="item in items">
          <button
            type="button"
            class="inline-flex py-2 px-4 w-full text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
            role="menuitem"
          >
            <div class="inline-flex items-center" @click="onSelectItem(item)">
              {{item}}
            </div>
          </button>
        </li>
      </ul>
      <div class="py-1">
        <div
          class="block py-2 px-4 text-sm text-cyan-600 hover:bg-gray-100 flex items-center hover:cursor-pointer"
          @click="onSelectItem('')"
        >
          <PencilIcon class="fill-current w-3 h-3 mr-2"> </PencilIcon>
          <span>
            {{$t('qr_website_box.custom')}}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div>
    <input
      type="text"
      id="first_name"
      class="bg-qr_color-gray_3 h-12 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent"
      v-model="value.url"
      :placeholder="placeHolder"
      required
      @change="handleChange"
    />
  </div>
  <!-- <qr-action-button name="websiteLabel" url-name="websiteUrl" items="to.items" value="model[options.key]" editable="true" url-placeholder="{{ to.urlPlaceholder }}" default-value="to.defaultValue" error-messages="to.errorMessages" custom-label="{{ to.customLabel }}"></qr-action-button> -->
</div>
