<div class="w-full flex flex-col">
  <div class="bg-[#fbfbfb] text-qr_color-gray border-2 border-dashed border-[#d4d4d4] rounded-md float-left">
    <div v-if="!isPdfUploading" class="my-8 px-6 flex justify-between s_qr:justify-center items-center">
      <div>
        <p class="text-base font-semibold">
          {{$t('Upload a PDF file')}}
        </p>
        <span v-if="hasPdf">{{file.original_filename}}</span>
      </div>

      <div>
        <button v-if="!hasPdf" type="button"
          class="sm:flex px-16 text-white bg-qr_color-blue_1 p-2 rounded-qr_input_radius cursor-pointer"
          @click="$refs.file.click()">
          {{$t('Upload PDF')}}
        </button>
        <button v-if="hasPdf" type="button"
          class="sm:flex px-16 text-white bg-qr_color-blue_1 p-2 rounded-qr_input_radius cursor-pointer"
          @click="$refs.file.click()">
          {{$t('Change PDF')}}
        </button>
        <input ref="file" class="cursor-pointer absolute block opacity-0 pin-r pin-t" type="file" :name="name"
          @change="onChangeFileName" accept="application/pdf" />
      </div>
    </div>
    <div v-if="isPdfUploading" class="my-8 px-6 flex justify-center items-center">
      <Spinner v-if="isPdfUploading" :visible="true" />
    </div>
  </div>

  <div class="mt-2">
    <input type="checkbox" :value="linkToPDF" :checked="linkToPDF" @change="onLinkToPDF" />
    <label class="text-qr_color-gray text-16 pr-4 font-semibold">{{$t('Link directly to PDF')}}</label>
  </div>
</div>