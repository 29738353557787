// couponsOption
export const createDynamicImageFieldsWidget = (formly, t) => {
  return [
    formly.container(
      formly.header({
        index: 1,
        sectionName: "",
        headline: "",
        hideCollapse: true,
        icon: null
      }),
      formly.body({
        name: "section_basic_info",
        collapseKey: "section_basic_info",
        widget: 1,
        key: "code",
        content: [
          formly.uploadImagesFile({
            key: "files",
            useGridViewKey: "useGridView"
          }),
          {
            className: "m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          },
          formly.inputRow({
            label: t("Title"),
            key: "title",
            required: true,
            className: "form-input-row form-row-container flex flex-col",
            placeholder: t("Title for your gallery")
          }),
          formly.textAreaRow({
            key: "description",
            label: t("Description"),
            placeholder: t("More information about your gallery"),
            maxChar: 200
          }),
          formly.inputRow({
            label: t("Website"),
            key: "website",
            className: "flex-0",
            placeholder: t("www.yourwebsite.com"),
            filters: {
              makeUrl: function ({ value }) {
                if (value.trim() !== "" && !value.match(/:\/\//)) {
                  value = "http" + "://" + value;
                }
                return value;
              }
            },
            validators: {
              validUrl: function ({ messages, name, value }) {
                if (
                  value !== "" &&
                  !value.match(
                    /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                  )
                ) {
                  messages.push("Website is not a valid URL.");
                  return false;
                }
                return true;
              }
            }
          }),
          {
            className: "m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          },
          {
            type: "qr_input_label",
            templateOptions: {
              label: t("Button")
            }
          },
          {
            className: "col-sm-12 col-md-8 box-input",
            key: "callToAction",
            type: "qraction_button",
            templateOptions: {
              key: "callToAction.url",
              urlPlaceholder: t("http://"),
              defaultValue: {
                label: t("Shop online"),
                url: ""
              },
              items: [t("Shop online"), t("Learn More"), t("Apply now")],
              addLabel: t("Add button"),
              removeLabel: t("Remove button"),
              customLabel: t("Custom"),
              errorMessages: {
                url: t("Please enter a valid website address"),
                required: t("This field can not be empty")
              },
              filters: {
                makeUrl: function ({ value }) {
                  if (value.trim() !== "" && !value.match(/:\/\//)) {
                    value = "http" + "://" + value;
                  }
                  return value;
                }
              },
              validators: {
                required: function ({ messages, name, value }) {
                  if (value.trim() == "") {
                    messages.push(t("This field can not be empty"));
                    return false;
                  }

                  return true;
                },
                validUrl: function ({ messages, name, value }) {
                  if (
                    value !== "" &&
                    !value.match(
                      /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                    )
                  ) {
                    messages.push(t("Please enter a valid address."));
                    return false;
                  }
                  return true;
                }
              }
            }
          }
        ]
      })
    )
  ];
};

export const createDynamicImageRequiredFieldsWidget = _ => {
  return [];
};
