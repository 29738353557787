import { mapActions, mapGetters, mapMutations } from "vuex";
import WithRender from "./LogoutPage.html";

export default WithRender({
  methods: {
    ...mapActions(["logoutUser"]),
    onLogOut() {
      this.logoutUser();
    }
  },
  created() {
    this.logoutUser();
    this.$router.push({ name: "login" });
  }
});
