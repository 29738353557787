import { mapActions, mapGetters } from "vuex";

import WithRender from "./ProfilePage.html";

import MainLayout from "../components/ui/layouts/MainLayout";

export default WithRender({
  components: {
    MainLayout
  },
  data() {
    return {};
  },
  mounted() {
    this.fetchUserInfo();
  },
  computed: {
    ...mapGetters(["getUserInfo"])
  },
  methods: {
    ...mapActions(["fetchUserInfo"])
  }
});
