import Vue from "vue";
import VueX from "vuex";
import { Fragment } from "vue-fragment";
import "sweetalert2/dist/sweetalert2.min.css";
import VueSweetalert2 from "vue-sweetalert2";

window.__PRINTQR_FRONTEND_COMMIT_HASH__ = process.env.VUE_APP_VERSION;

import App from "./app";
import { initRouter } from "./router";
import makeStore from "./store/store";
import { makeApollo } from "./apollo-provider";
import { i18n, setLanguageAttributes } from "./i18n";
import printqGlobalPlugin from "./plugins/printqGlobalPlugin";

import "flowbite";
import "./styles/main.scss";
import "./styles/overwrite.css";
// form
import {
  VueFormly,
  VueFormlyBootstrap,
  registerFormlyComponent
} from "./formly";
const options = {
  confirmButtonColor: "var(--qr_color_blue_2,#112985)",
  cancelButtonColor: "var(--qr_color_blue_1,#68B9F4)"
};

Vue.use(Fragment);
Vue.use(VueX);
Vue.use(VueFormly);
Vue.use(VueFormlyBootstrap);
Vue.use(VueSweetalert2, options);
Vue.config.productionTip = false;

const requireComponent = require.context(
  // Look for files in the current directory
  "./components",
  // Do not look in subdirectories
  true
);

// For each matching file name...
requireComponent.keys().forEach(fileName => {
  // Get the component config
  const componentConfig = requireComponent(fileName);
  // Get the PascalCase version of the component name
  const componentName = fileName
    // Remove the "./_" from the beginning
    .replace(/^\.\/_/, "")
    // Remove the file extension from the end
    .replace(/\.\w+$/, "")
    // Split up kebabs
    .split("-")
    // Upper case
    .map(kebab => {
      const fName =
        kebab.lastIndexOf("/") != -1
          ? kebab.substring(kebab.lastIndexOf("/") + 1)
          : kebab;
      return (fName.charAt(0).toUpperCase() + fName.slice(1)).replace("./", "");
    })
    // Concatenated
    .join("");

  let newComponenent = componentConfig.default || componentConfig;

  const className =
    "matrix_" +
    (componentName.charAt(0).toLowerCase() + componentName.slice(1));
  newComponenent = {
    ...newComponenent,
    mixins: [
      {
        computed: {
          getClassName() {
            return className;
          },
          getClassNameContainer() {
            return className + "Container";
          },
          getClassNameItem() {
            return className + "Item";
          },
          getContainerType: function () {
            // for containers we might decide to not show the outer div if we might also include elements from other componenets
            if (this.hideContainer) {
              return "fragment";
            } else {
              return "div";
            }
          },
          shouldRenderConfig: function () {
            return this.$store.getters.getShouldRenderComponent(componentName);
          }
        }
      }
    ]
  };
  // Globally register the component
  Vue.component("app" + componentName, newComponenent);
});

// register fragment to be for all application
Vue.component("fragment", Fragment);
registerFormlyComponent(Vue);
class PrintqQrApp {
  vueInstance = null;

  init(args) {
    const { beforeInitCallback, selector, language, jsAppRoot, config } = args;
    console.log({ language });
    const { widgetMode } = config;
    Vue.use(printqGlobalPlugin, { ...config, locale: language.lang });
    setLanguageAttributes(language);
    console.log(Vue.$printqGlobalOptions);

    const seleSelector = selector || "#printq_app";

    if (jsAppRoot) __webpack_public_path__ = jsAppRoot;

    const portalElements = this.registerPortalComponents();
    if (beforeInitCallback && typeof beforeInitCallback == "function") {
      beforeInitCallback();
    }

    const { apolloProvider, apolloClient } = makeApollo(
      Vue.$printqGlobalOptions
    );

    const vueInits = {
      store: makeStore({
        apolloClient,
        ...Vue.$printqGlobalOptions
      }),
      apolloProvider,
      i18n,
      render: h =>
        h(App, {
          props: {
            ...args,
            portalElements,
            selector: seleSelector.replace("#", "")
          }
        })
    };
    if (!widgetMode) {
      const AppRouter = initRouter();
      vueInits.router = AppRouter;
    }

    this.vueInstance = new Vue(vueInits);
    return this.vueInstance.$mount(seleSelector);
  }

  getStore() {
    return this.vueInstance.$store;
  }

  registerPortalComponents() {
    const elements = document.getElementsByClassName("printq_portal");
    const portalElements = [];
    for (const el of elements) {
      if (el.getAttribute("data-portal") && el.getAttribute("id")) {
        const portal = JSON.parse(el.getAttribute("data-portal"));
        const portalId = el.getAttribute("id");
        const portalComponentname =
          "appPrintqPortal" + portal.component + "_" + portalId;
        this.registerComponent(
          portalId,
          "app" + portal.component,
          portalComponentname
        );

        portalElements.push(portalComponentname);
      }
    }

    return portalElements;
  }

  registerComponent(id, type, name) {
    Vue.component(name, {
      render: function (createElement) {
        return createElement(
          "MountingPortal",
          {
            attrs: {
              mountTo: "#" + id
            }
          },
          [createElement(type)]
        );
      }
    });
  }

  loadQrDesignWidget(widgetEditId) {
    this.resetQrWidget();
    if (widgetEditId) {
      const payloadFetchQrDetails = {
        id: widgetEditId,
        callBackFunction: response => {
          if (response.qrCode.qrType) {
            this.getStore().commit(
              "changeCardType",
              response.qrCode.qrType.code
            );
          }
          this.getStore().dispatch("openDesign", response);
        }
      };
      this.getStore().dispatch("fetchQrDetails", payloadFetchQrDetails);
    }
  }
  resetQrWidget() {
    this.getStore().dispatch("resetCurrentQr", {});
    this.getStore().dispatch("resetCurrentModel", {});
    this.getStore().dispatch("setQrCurrentDesignToDefault", {});
    this.getStore().dispatch("setSelectedCategory", "");
    this.getStore().dispatch("setQrCurrentSvg", "");
    this.getStore().commit("changeCardTypeToDefault");
    this.getStore().commit(
      "setSelectedCategory",
      this.getStore().getters.getCardType
    );
    const defaultModel = this.getStore().getters.getDefaultCategoryModel;
    this.getStore().commit("setCurrentModel", defaultModel);
  }
}

window.PrintqQr = PrintqQrApp;
