// couponsOption
export const createDynamicCouponFieldsWidget = (formly, t) => {
  return [
    formly.container(
      formly.header({
        index: 1,
        sectionName: "",
        headline: "",
        hideCollapse: true,
        icon: null
      }),
      formly.body({
        name: "section_basic_info",
        collapseKey: "section_basic_info",
        widget: 1,
        key: "code",
        content: [
          formly.avatarRectangular({
            tooltip: t(
              "Upload an image or logo from your computer. Images must be at least 640 x 360 px in .jpg or .png format."
            ),
            aspectRatio: 1.77
          }),
          {
            className: "m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          },
          formly.inputRow({
            label: t("Company"),
            placeholder: t("Name of company or shop"),
            key: "company"
          }),
          formly.inputRowRequired({
            key: "headline",
            className: "form-input-row form-row-container flex flex-col",
            label: t("Headline"),
            required: true,
            placeholder: t("e.g. Get 20% off your next order"),
            condition: "true",
            errorMsgTitle: t("Please provide a headline."),
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(t("Please provide a headline."));
                  return false;
                }

                return true;
              }
            }
          }),
          formly.textAreaRow({
            label: t("Description"),
            placeholder: t("Provide some more info about your offer"),
            key: "description",
            maxChar: 180
          }),

          {
            className: "form-row-container flex flex-col",
            type: "field_group",
            fieldGroup: [
              {
                type: "qr_input_label",
                templateOptions: {
                  label: t("Sale Badge*"),
                  tooltip: t(
                    "Use this optional element as a visual highlight on your page"
                  )
                }
              },
              {
                className: "col-sm-12 col-md-8 box-input",
                key: "sale",
                type: "qr_dropdown_box_with_custom",
                templateOptions: {
                  defaultValue: t("25% OFF"),
                  customLabel: t("Custom"),
                  items: [
                    t("10% OFF"),
                    t("30% OFF"),
                    t("50% OFF"),
                    t("2 FOR 1"),
                    t("SALE")
                  ],
                  validators: {
                    required: function ({ messages, name, value }) {
                      if (value.trim() == "") {
                        messages.push(t("Please provide a sale badge."));
                        return false;
                      }

                      return true;
                    }
                  }
                }
              },
              {
                className:
                  "form-input-row form-row-container relative flex flex-col",
                hideExpression: model => !!model["showBarcode"],
                type: "field_group",
                fieldGroup: [
                  {
                    type: "qr_input_label",
                    templateOptions: {
                      label: t("Coupon Name"),
                      required: true,
                      tooltip: t(
                        "Provide your coupon name. It's the one customers will need to enter during the online checkout or show to the cashier in store."
                      )
                    }
                  },
                  {
                    type: "qrinput",
                    key: "name",
                    templateOptions: {
                      placeHolder: t("e.g. EXTRA20OFF"),
                      errorMessage: t("Please provide a coupon name."),
                      maxlength: 15,
                      showCharCount: true,
                      required: "true",
                      filters: {},
                      validators: {
                        required: function ({ messages, name, value }) {
                          if (value.trim() == "") {
                            messages.push(t("Please provide a coupon name."));
                            return false;
                          }

                          return true;
                        }
                      }
                    }
                  },
                  {
                    type: "qr_toggle_button",
                    key: "showBarcode",
                    templateOptions: {
                      label: t("Use a barcode")
                    },
                    hideExpression: model => !!model["showBarcode"],
                    className: "absolute right-0 -bottom-4"
                  }
                ]
              },
              formly.avatarRectangular({
                key: "barcode",
                uploadId: "uploadBarcode",
                avatarExtraParam: "barcode_extra",
                hideExpression: model => !model["showBarcode"],
                className:
                  "form-file-row rectangular-image-container-no-margin sm:items-center flex-col sm:flex-row gap-x-8",
                label: t("Barcode"),
                tooltip: t(
                  "Upload a barcode for scanning at the point of sale checkout. Not recommended for online redeeming."
                ),
                hideOptionId: "showBarcode",
                aspectRatio: "1.77"
              }),
              formly.timeInput({
                key: "date",
                className: " flex-row",
                keyRequired: true,
                label: t("Valid until"),
                labelRequired: true,
                allDay: false,
                allDayLabel: "",
                startDateOnly: false,
                endDateOnly: true,
                errorMsg: t("Please enter a date."),
                condition: "true"
              }),
              formly.textAreaRow({
                label: t("Terms & Conditions"),
                placeholder: t(
                  "Provide the Terms & Conditions of your coupon offer"
                ),
                tooltip: t(
                  "Define if there's a minimum order value, if multiple uses per person are allowed, if your coupon can be combined with other promotional offers, etc."
                ),
                key: "terms",
                maxChar: 2000
              }),
              {
                className: "form-row-container flex flex-col",
                type: "field_group",
                fieldGroup: [
                  {
                    type: "qr_input_label",
                    templateOptions: {
                      label: t("Website"),
                      tooltip: t(
                        "Link to your Online Shop where customers can redeem your coupon."
                      )
                    }
                  },
                  {
                    className: "col-sm-12 col-md-8 box-input",
                    key: "website.label",
                    type: "qr_dropdown_box_with_custom",
                    templateOptions: {
                      defaultValue: t("Redeem now"),
                      customLabel: t("Custom"),
                      items: [t("Shop now"), t("Redeem now")]
                    }
                  },
                  {
                    type: "qrinput",
                    key: "website.url",
                    templateOptions: {
                      placeHolder: t("http://"),
                      errorMessage: t("Please enter a valid website address"),
                      required: "true",
                      filters: {
                        makeUrl: function ({ value }) {
                          if (value.trim() !== "" && !value.match(/:\/\//)) {
                            value = "http" + "://" + value;
                          }
                          return value;
                        }
                      },
                      validators: {
                        validUrl: function ({ messages, name, value }) {
                          if (
                            value !== "" &&
                            !value.match(
                              /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                            )
                          ) {
                            messages.push(
                              t("Please enter a valid website address")
                            );
                            return false;
                          }
                          return true;
                        }
                      }
                    }
                  }
                ]
              },
              formly.autocompleteInputForm({
                label: t("Location"),
                wrappedInAddressKey: false,
                condition: model => false,
                // condition: model => !model["autocompleteVisible"],
                tooltip: t(
                  "Provide your store location if your coupon is meant for use at point of sale."
                )
              })
            ]
          }
        ]
      })
    )
  ];
};

export const createDynamicCouponRequiredFieldsWidget = _ => {
  return [];
};
