import welcome from "./img/video-welcome.png";

export const createDynamicVideoFields = (formly, t) => {
  return [
    formly.labelTitle({
      titleIcon: "VideoIcon1",
      titleKey: "qrcode_video",
      titleTooltip: t(
        "Labeling QR Codes has been moved to this area. Names help you to stay organized and will only appear in your account and are not displayed to customers who scan your QR Codes."
      ),
      titlePlaceholder: t("My Video QR Code"),
      titleValue: null,
      buttonLabel: t("Got it")
    }),
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_design",
        fold: false,
        headline: t("Design & Customizations"),
        icon: "DesignIcon"
      }),
      formly.body({
        name: "section_design",
        collapseKey: "section_design",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Personalize your page by selecting some colors.")
          }),
          formly.colorsTemplate({
            customColors: [
              { color1: "#447fb6", color2: "#e91e63" },
              { color1: "#455a64", color2: "#e91e63" },
              { color1: "#d32f2f", color2: "#ef9a9a" },
              { color1: "#4caf50", color2: "#81c784" },
              { color1: "#795548", color2: "#ff8a65" }
            ]
          }),
          formly.colorsSwitch({ color1: "#447fb6", color2: "#e91e63" })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 1,
        sectionName: "section_information",
        fold: false,
        headline: t("Basic Information"),
        icon: "InfoIcon"
      }),
      formly.body({
        name: "section_information",
        collapseKey: "section_information",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t(
              "Add some context to your Video Page by providing your company name, a headline and a description."
            )
          }),
          formly.inputRow({
            label: t("Company"),
            key: "company",
            className: "flex-0",
            placeholder: t("Name of company, organization or channel"),
            errorHolder: "#errorLastname",
            errorMsg: t("Please provide a company.")
          }),
          formly.inputRowRequired({
            key: "headline",
            label: t("Headline"),
            className: "form-input-row form-row-container flex flex-col",
            required: true,
            placeholder: t("Add an engaging title to your page"),
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(t("This field can not be empty"));
                  return false;
                }

                return true;
              }
            }
          }),
          formly.textAreaRow({
            key: "description",
            label: t("Description"),
            placeholder: t(
              "Welcome users to your page or tell them a bit more about your video(s)."
            ),
            maxChar: 200
          }),
          {
            className: "form-row-container flex-col",
            type: "field_group",
            required: true,
            fieldGroup: [
              {
                type: "qr_input_label",
                templateOptions: {
                  label: t("Button")
                }
              },
              {
                className: "col-sm-12 col-md-8 box-input",
                key: "callToAction",
                type: "qraction_button",
                templateOptions: {
                  required: true,
                  key: "callToAction",
                  urlPlaceholder: t("http://"),
                  defaultValue: {
                    label: t("Get Yours"),
                    url: ""
                  },
                  items: [
                    t("Get Yours"),
                    t("Shop online"),
                    t("Learn More"),
                    t("Apply now")
                  ],
                  addLabel: t("Add button"),
                  removeLabel: t("Remove button"),
                  customLabel: t("Custom"),
                  errorMessages: {
                    url: t("Please enter a valid website address"),
                    required: t("This field can not be empty")
                  },
                  filters: {
                    makeUrl: function ({ value }) {
                      if (value.trim() !== "" && !value.match(/:\/\//)) {
                        value = "http" + "://" + value;
                      }
                      return value;
                    }
                  },
                  validators: {
                    required: function ({ messages, name, value }) {
                      if (value.trim() == "") {
                        messages.push(t("This field can not be empty"));
                        return false;
                      }

                      return true;
                    },
                    validUrl: function ({ messages, name, value }) {
                      if (
                        value !== "" &&
                        !value.match(
                          /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                        )
                      ) {
                        messages.push(t("Please enter a valid address."));
                        return false;
                      }
                      return true;
                    }
                  }
                }
              }
            ]
          }
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 2,
        sectionName: "section_information",
        fold: false,
        headline: t("Videos"),
        icon: "VideoIcon1"
      }),
      formly.body({
        name: "section_information",
        collapseKey: "section_information",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t(
              "Add one or more videos to your page.To do so, simply copy the URL of a YouTube video and paste it into the field below."
            )
          }),
          {
            className: "form-input-row form-row-container",
            type: "field_group",
            fieldGroup: [
              {
                type: "qryoutube_video_add",
                key: "videos",
                highlightVideoKey: "highlightVideo",
                templateOptions: {
                  requiredOneVideo: true,
                  requiredMessage: t("Please add at least one video."),
                  buttonLabel: t("Add Video"),
                  placeHolder: t("https://www.youtube.com/watch...")
                }
              }
            ]
          }
        ]
      })
    ),

    formly.container(
      formly.header({
        index: 4,
        sectionName: "section_social",
        fold: true,
        headline: t("Social Media"),
        icon: "SocialMedia1"
      }),
      formly.body({
        name: "section_social",
        collapseKey: "section_social",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Click on the icon to add a social media channel."),
            className: "mb-10"
          }),
          formly.socialChannels({
            key: "channels",
            requiredAllChannels: false,
            requiredMessage: t("At least one channel is required"),
            hasLabel: false,
            hasOrder: false
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 5,
        sectionName: "section_welcome_screen",
        headline: t("Welcome Screen"),
        icon: "SmartPhoneIcon"
      }),
      formly.body({
        name: "section_welcome_screen",
        collapseKey: "section_welcome_screen",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Display your logo while your page is loading.")
          }),
          formly.welcomeScreen({
            tooltip: t(
              "Upload an image or logo from your computer. Images must be in .jpg or .png format."
            ),
            key: "welcome_screen",
            previewWelcomeScreen: true,
            defaultWelcomeImage: welcome
          })
        ]
      })
    ),
    formly.container_advancedOptions({
      sharingKey: "sharing",
      sharingLabel: t("Add a share button to the page"),
      initValue: false
    })
  ];
};

export const createDynamicVideoRequiredFields = _ => {
  return ["headline"];
};
