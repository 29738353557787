<div class="relative w-auto sm:flex sm:justify-center sm:items-center mt-2 sm:mt-0 sm:pl-2 flex-col">
  <button type="button" class="sm:flex px-16 text-white bg-qr_color-blue_1 p-2 rounded-qr_input_radius"
    @click="$refs.file.click()">
    <span class="ml-auto"> {{$t('qr_upload_welcome.change')}}</span>
  </button>
  <input ref="file" class="cursor-pointer absolute block opacity-0 pin-r pin-t" type="file" :name="name"
    @change="onChangeFileName" />
  <button v-if="!isEmptyOrDefaultImage" type="button"
    class="mt-2 inline-flex px-1 py-1.5 text-sm text-qr_color_blue_1 underline" @click="onRemoveClicked">
    {{$t('qr_upload_welcome.remove_image')}}
  </button>
</div>