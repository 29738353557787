<span>
  <Component v-if="$options.components[name]" :is="name" />
  <svg
    v-else
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 27 27"
    class="w-full h-full fill-current"
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      class="cls-1"
      d="M13.52,0C6.06,0,0,6.06,0,13.52s5.91,13.36,13.24,13.51c.02,0,.05,0,.07,0,.01,0,.03,0,.04,0,.06,0,.11,0,.17,0,7.45,0,13.52-6.06,13.52-13.52S20.97,0,13.52,0Zm10.75,12.08h-3.15c-.16-3.04-.89-5.83-2-7.94,2.77,1.68,4.73,4.56,5.15,7.94ZM11.83,3.31V12.08h-3.22c.25-4.39,1.53-7.46,3.22-8.77Zm0,11.47v8.94c-1.69-1.3-2.96-4.55-3.22-8.94h3.22Zm2.7,9.23V14.79h3.88c-.28,4.73-2.2,8.18-3.88,9.23Zm0-11.93V3.02c1.69,1.05,3.61,4.33,3.89,9.06h-3.89ZM7.92,4.15c-1.11,2.1-1.83,4.9-2,7.94H2.77c.42-3.38,2.38-6.25,5.15-7.94ZM2.78,14.79h3.15c.17,3.04,.89,5.81,2,7.9-2.76-1.68-4.72-4.53-5.15-7.9Zm16.34,7.9c1.1-2.1,1.83-4.86,2-7.9h3.15c-.42,3.38-2.38,6.23-5.15,7.9Z"
    />
  </svg>
</span>
