import { Sketch as SketchPicker } from "vue-color";

import WithRender from "./QRCustomColorPicker.html";

export default WithRender({
  name: "QRCustomColorPicker",
  props: {
    value: String,
    swatches: {
      type: Array,
      default: () => []
    },
    hideSwatches: Boolean,
    showBackdrop: {
      type: Boolean,
      default: true
    }
  },
  components: {
    SketchPicker
  },
  methods: {
    onCloseDialog() {
      this.$emit("close");
    },
    onUpdateColor() {
      this.$emit("change", this.value);
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val.hex);
      }
    }
  }
});
