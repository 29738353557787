import { mapGetters, mapActions, mapMutations } from "vuex";

import WithRender from "./TypeCardDetails.html";
import Sections from "./card_details/Sections";
import Spinner from "../../components/base/components/Spinner";

export default WithRender({
  components: {
    Sections,
    Spinner
  },
  mounted() {},
  computed: {
    ...mapGetters(["getCardType", "getLoading"])
  },
  methods: {
    ...mapActions([]),
    onValidate(v) {
      this.$emit("validate", v);
    }
  }
});
