import { mapGetters, mapActions, mapMutations } from "vuex";

import WithRender from "./TypeCardItems.html";
import TypeCardItem from "./TypeCardItem";
import PointingLeft from "../../assets/icons/pointing-left.svg";
import Spinner from "../../components/base/components/Spinner";

export default WithRender({
  components: {
    TypeCardItem,
    Spinner
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    ...mapActions(["changeCardType"])
  },
  computed: {
    ...mapGetters([
      "getCategories",
      "getLoading",
      "getEditorMode",
      "getCardType"
    ]),
    selectType() {
      return this.getCardType;
    }
  }
});
