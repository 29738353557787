<div>
  <div v-html="getColors"></div>
  <div class="w-full min-h-[20rem] h-full flex items-center justify-center" v-if="getLoading">
    <Spinner :visible="getLoading" />
  </div>
  <div v-else class="flex flex-row md:p-4 w-full" ref="widget">
    <div class="flex flex-row w-full lg_qr:w-full lg_qr:flex lg_qr:flex-wrap lg_qr:flex-col-reverse">
      <div class="w-[60%] lg_qr:w-full lg_qr:flex lg_qr:w-full lg_qr:flex lg_qr:flex-col">
        <TypeCardItems></TypeCardItems>
        <TypeCardDetails @validate="onValidate" ref="widgetDetail"></TypeCardDetails>
      </div>
      <div class="flex mx-auto max-w-[460px] inline-block w-full">
        <PreviewQr :validationElements="validation" @clearValidation="onClearValidation"></PreviewQr>
      </div>
    </div>
  </div>
</div>