import { v4 as uuidv4 } from "uuid";
import { mapActions, mapGetters } from "vuex";
import baseField from "vue-formly-bootstrap/src/fields/baseField";
import WithRender from "./QrUploadImages.html";
import Spinner from "../../base/components/Spinner";
import CloseIcon from "../icons/CloseIcon";

import "cropperjs/dist/cropper.css";

const avatarDefaults = {
  imageSize: 100,
  bgColor: "#ffffff",
  basePath: null
};

export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  components: {
    Spinner,
    CloseIcon
  },
  created() {},
  data() {
    return {
      files: [],
      useGridView: false
    };
  },
  mounted() {
    this.files = this.getCurrentModel?.files
      ? [...this.getCurrentModel.files]
      : [];
    this.useGridView = this.getCurrentModel?.useGridView
      ? this.getCurrentModel.useGridView
      : false;
    this.$root.$on("ImagesUpload", ({ files }) => {
      for (const file of files) {
        this.onUploadFile(file);
      }
    });
  },
  computed: {
    ...mapGetters(["getCurrentModel", "getStorageBaseUrl"]),
    name() {
      return this.field.key;
    },
    useGridViewName() {
      return this.field.useGridViewKey;
    }
  },

  methods: {
    ...mapActions(["uploadFileToServer", "updateCurrentModel"]),
    onRemoveFile(file) {
      const { id } = file;
      this.files = this.files.filter(file => file.id !== id);
      this.$emit("update", {
        name: this.name,
        value: this.files
      });
    },

    onUploadFile(file) {
      try {
        const uuid = uuidv4();
        this.files.push({
          uuid,
          id: uuid,
          original_filename: file.name,
          loading: true
        });
        this.$emit("update", {
          name: this.name,
          value: this.files
        });
        this.uploadFileToServer(file)
          .then(uploadFile => {
            this.files = this.files.map(f => {
              if (f.uuid === uuid) {
                return {
                  ...uploadFile,
                  description: "",
                  loading: false
                };
              }
              return f;
            });
            this.$emit("update", {
              name: this.name,
              value: this.files
            });
          })
          .catch(error => {
            this.files = this.files.filter(f => f.uuid !== uuid);
            this.$emit("update", {
              name: this.name,
              value: this.files
            });
          });
      } catch (error) {
        console.log(error);
      }
    },

    onUpdateImageText(event, id) {
      const newText = event.target.value;
      this.files = this.files.map(file => {
        if (file.id === id) {
          file.description = newText;
        }
        return file;
      });
      this.$emit("update", {
        name: this.name,
        value: this.files
      });
    },

    onUpdate(v) {
      const { name, value } = v;
      const payload = {
        key: name,
        value: value
      };
      this.updateCurrentModel(payload);
    },
    onChangeFileName({ target: { files } }) {
      const { name } = this;
      const selectedFiles = Array.from(files);
      this.$root.$emit("ImagesUpload", {
        files: selectedFiles,
        name
      });
    },
    onUseGridView(event) {
      this.useGridView = event.target.checked;
      this.$emit("update", {
        name: this.useGridViewName,
        value: this.useGridView
      });
    },
    getFileUrl(file) {
      if (file.base_path) {
        return this.getStorageBaseUrl + file.base_path;
      }
      return null;
    }
  },
  watch: {
    model: {
      handler(newValue, oldValue) {
        if (oldValue[this.name] != newValue[this.name]) {
          this.files = [...newValue[this.name]];
          console.log({ files: this.files });
        }
        if (oldValue[this.useGridViewName] != newValue[this.useGridViewName])
          this.useGridView = newValue[this.useGridViewName];
      },
      deep: true
    }
  }
});
