import baseField from "vue-formly-bootstrap/src/fields/baseField";
import PencilIcon from "../icons/PencilIcon";
import { forEachObjIndexed } from "ramda";

import WithRender from "./QRDropdownBoxWithCustom.html";

export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  components: {
    PencilIcon
  },
  data() {
    return {
      value: "",
      dropdown: null,
      errorMessage: ""
    };
  },
  created() {
    this.value = this.model[this.name] ?? this.defaultValue;
  },
  mounted() {
    const targetEl = this.$refs.dropdownMenu;
    const triggerEl = this.$refs.dropdownBtn;
    const options = {
      placement: "bottom"
    };

    this.dropdown = new window.Dropdown(targetEl, triggerEl, options);
  },
  computed: {
    name() {
      return this.field.key;
    },
    defaultValue() {
      return this.field.templateOptions.defaultValue;
    },
    items() {
      return this.field.templateOptions.items;
    },
    customLabel() {
      return this.field.templateOptions.customLabel;
    },
    placeholder() {
      return this.field.templateOptions.placeholder ?? "";
    },
    required() {
      return this.field.templateOptions.required ?? false;
    },
    hasValidators() {
      const validators = this?.field?.templateOptions?.validators ?? {};
      return Object.keys(validators).length > 0;
    },
    inputListeners: function () {
      var vm = this;
      return Object.assign({}, this.$listeners, {
        validate: event => {
          if (!this.validate(event))
            this.$emit("validate", { element: event.target, event });
        }
      });
    }
  },
  methods: {
    onUpdateValue() {
      const { name, value } = this;
      this.validate();
      this.$emit("update", { name, value });
    },
    handleChange(event) {
      this.value = this.applyFilters(event.target.value);
      this.onUpdateValue();
    },
    onSelectItem(v) {
      this.value = this.applyFilters(v);
      const { name, value } = this;
      this.validate();
      this.$emit("update", { name, value });
      if (this.dropdown) {
        this.dropdown.hide();
      }
    },
    onSelectCustom() {
      if (this.dropdown) {
        this.dropdown.hide();
      }

      const customInput = this.$refs.customInput;
      customInput.select();
    },
    applyFilters(value) {
      let newValue = value;
      const { name } = this;
      const filters = this?.field?.templateOptions?.filters ?? {};
      forEachObjIndexed((filter, key) => {
        newValue = filter({ name, value: newValue });
      }, filters);
      return newValue;
    },
    validate() {
      let valid = true;
      const { name } = this;
      const validators = this?.field?.templateOptions?.validators ?? {};
      const messages = [];
      forEachObjIndexed((validator, key) => {
        valid = valid && validator({ messages, name, value: this.value });
      }, validators);
      this.errorMessage = "";
      if (!valid) {
        this.errorMessage = messages?.[0] ?? "";
      }
      return valid;
    }
  },
  watch: {
    model: {
      handler(newValue, oldValue) {
        if (oldValue[this.name] == newValue[this.name]) return;
        this.value = newValue[this.name];
      },
      deep: true
    }
  }
});
