import welcome from "./img/welcome_mp3.png";
// import avatar from "./img/coupons-avatar.jpg";
// import { remove } from "lodash";
// couponsOption

export const createDynamicMp3Fields = (formly, t) => {
  return [
    formly.labelTitle({
      titleIcon: "AudioIcon",
      titleKey: "qrcode_title",
      titleTooltip: t(
        "Labeling QR Codes has been moved to this area. Names help you to stay organized and will only appear in your account and are not displayed to customers who scan your QR Codes."
      ),
      titlePlaceholder: t("My MP3 Code"),
      titleValue: null,
      buttonLabel: t("Got it")
    }),
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_design",
        headline: t("Upload MP3"),
        icon: "AudioIcon"
      }),
      formly.body({
        name: "section_design",
        collapseKey: "section_design",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t(
              "Select and upload any MP3 file from your computer or add it via drag & drop."
            )
          }),
          formly.uploadMp3File({
            tooltip: t(
              "Upload an mp3 or audio file from your computer. Files must be in .mp3"
            ),
            key: "file",
            addDownloadOptionKey: "addDownloadOption"
            // previewWelcomeScreen: true
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_design",
        headline: t("Design & Customization"),
        icon: "DesignIcon"
      }),
      formly.body({
        name: "section_design",
        collapseKey: "section_design",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Personalize your page by selecting some colors.")
          }),
          formly.colorsTemplate({
            shape: "rect",
            customColors: [
              { color1: "#3c4245", color2: "#ff887e" },
              { color1: "#37427e", color2: "#5c9a86" },
              { color1: "#b1c7b2", color2: "#577458" },
              { color1: "#6c3d7d", color2: "#695cc4" },
              { color1: "#eec383", color2: "#8f3645" }
            ]
          }),
          formly.colorsSwitch({}),
          {
            className: "my-2",
            template: '<hr style="border-color: #eaeaea; margin: 30px 0">'
          },
          formly.avatarRectangular({
            smallLabel: t("180 x 180 px"),
            label: t("Logo"),
            aspectRatio: 1
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 1,
        sectionName: "section_basic_info",
        headline: t("Basic Information"),
        icon: "InfoIcon"
      }),
      formly.body({
        name: "section_basic_info",
        collapseKey: "section_basic_info",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t(
              "Provide more information about your MP3, such as a title and artist.Add an optional button to a website of your choice."
            )
          }),
          formly.inputRowRequired({
            key: "title",
            className: "form-input-row form-row-container flex flex-col",
            label: t("Title"),
            placeholder: t("Name of song or sound file"),
            required: true,
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(t("Please provide a title."));
                  return false;
                }

                return true;
              }
            }
          }),
          formly.inputRow({
            key: "info",
            className: "form-input-row form-row-container flex flex-col",
            label: t("More info"),
            placeholder: t("e.g. Name of artist and album")
          }),
          {
            className: "my-2",
            template: '<hr style="border-color: #eaeaea; margin: 10px 0">'
          },
          formly.inputRow({
            label: t("Website"),
            placeholder: t("www.your-website.com"),
            key: "website",
            filters: {
              makeUrl: function ({ value }) {
                if (value.trim() !== "" && !value.match(/:\/\//)) {
                  value = "http" + "://" + value;
                }
                return value;
              }
            },
            validators: {
              validUrl: function ({ messages, name, value }) {
                if (
                  value !== "" &&
                  !value.match(
                    /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                  )
                ) {
                  messages.push(t("Please enter a valid address."));
                  return false;
                }
                return true;
              }
            }
          }),
          {
            className: "form-row-container flex-col",
            type: "field_group",
            required: true,
            fieldGroup: [
              {
                type: "qr_input_label",
                templateOptions: {
                  label: t("Button")
                }
              },
              {
                className: "col-sm-12 col-md-8 box-input",
                key: "callToAction",
                type: "qraction_button",
                templateOptions: {
                  urlPlaceholder: t("http://"),
                  defaultValue: {
                    label: t("Visit us online"),
                    url: ""
                  },
                  items: [
                    t("Learn more"),
                    t("Buy now"),
                    t("View full album"),
                    t("Visit us online")
                  ],
                  addLabel: t("Add button"),
                  removeLabel: t("Remove button"),
                  customLabel: t("Custom"),
                  errorMessages: {
                    url: t("Please enter a valid website address"),
                    required: t("This field can not be empty")
                  },
                  filters: {
                    makeUrl: function ({ value }) {
                      if (value.trim() !== "" && !value.match(/:\/\//)) {
                        value = "http" + "://" + value;
                      }
                      return value;
                    }
                  },
                  validators: {
                    required: function ({ messages, name, value }) {
                      if (value.trim() == "") {
                        messages.push(t("This field can not be empty"));
                        return false;
                      }

                      return true;
                    },
                    validUrl: function ({ messages, name, value }) {
                      if (
                        value !== "" &&
                        !value.match(
                          /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                        )
                      ) {
                        messages.push(t("Please enter a valid address."));
                        return false;
                      }
                      return true;
                    }
                  }
                }
              }
            ]
          }
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 3,
        sectionName: "section_welcome_screen",
        headline: t("Welcome Screen"),
        icon: "SphereIcon"
      }),
      formly.body({
        name: "section_welcome_screen",
        collapseKey: "section_welcome_screen",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Display your logo while your page is loading.")
          }),
          formly.welcomeScreen({
            tooltip: t(
              "Upload an image or logo from your computer. Images must be in .jpg or .png format."
            ),
            key: "welcome_screen",
            previewWelcomeScreen: true,
            defaultWelcomeImage: welcome
          })
        ]
      })
    )
  ];
};

export const createDynamicMp3RequiredFields = _ => {
  return ["name"];
};
