export const createDynamicTextFields = (formly, t) => {
  return [
    formly.labelTitle({
      titleIcon: "TextIcon1",
      titleKey: "qrcode_title",
      titleTooltip: t(
        "Labeling QR Codes has been moved to this area. Names help you to stay organized and will only appear in your account and are not displayed to customers who scan your QR Codes."
      ),
      titlePlaceholder: t("My Text QR Code"),
      titleValue: null,
      buttonLabel: t("Got it")
    }),
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_design",
        headline: t("Design & Customization"),
        icon: "DesignIcon"
      }),
      formly.body({
        name: "section_design",
        collapseKey: "section_design",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Personalize your page by selecting some colors.")
          }),
          formly.colorsTemplate({
            shape: "rect",
            customColors: [
              { color1: "#5a6368", color2: "#3c4245" },
              { color1: "#ff8665", color2: "#ff5943" },
              { color1: "#2ddeff", color2: "#1e94fa" },
              { color1: "#e162de", color2: "#964194" },
              { color1: "#ffba71", color2: "#f87c4b" }
            ]
          }),
          formly.colorsSwitch({}),
          {
            className: "my-2",
            template:
              '<hr style="border-color: #eaeaea; margin: 10px 0 -10px;">'
          }
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_url",
        fold: false,
        headline: t("Enter your text"),
        icon: "DesignIcon"
      }),
      formly.body({
        name: "section_url",
        collapseKey: "section_url",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Type the text to link with your QR Code")
          }),
          formly.textAreaRow({
            key: "text",
            label: t("Text"),
            required: true,
            placeholder: t(
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue."
            ),
            maxChar: 300,
            filters: {},
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(t("This field can not be empty"));
                  return false;
                }

                return true;
              }
            }
          })
        ]
      })
    )
  ];
};

export const createDynamicTextRequiredFields = _ => {
  return ["text"];
};
