<form @submit="onSubmitForm" id="formCard">
  <div class="w-full">
    <div class="py-4 mx-auto">
      <div class="flex w-full containerDetails">
        <div class="flex-grow flex-shrink w-full">
          <qr-form :form="form" :model="model" :fields="fields" @update="onUpdate" @validate="onValidate"
            @click="onClicked"></qr-form>
          <button class="invisible" type="submit" ref="submitRef"></button>
        </div>
      </div>
    </div>
  </div>
</form>