import WithRender from "./WidgetLayout.html";
import Spinner from "../../base/components/Spinner";
import TypeCardItems from "../../../app/widget/TypeCardItems";
import TypeCardDetails from "../../../app/widget/TypeCardDetails";
import PreviewQr from "../../../app/widget/PreviewQr";
import { mapGetters } from "vuex";

export default WithRender({
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      validation: []
    };
  },
  computed: {
    ...mapGetters(["getWidgetMode", "getCurrentStep", "getLoading"]),
    getShowEditModal() {
      return this.getCurrentStep == "edit_modal";
    },
    getColors() {
      return this.$printGetColors();
    },
    getShowCreateNew() {
      return this.getCurrentStep == "create_new";
    },
    getShowCreateNewDetail() {
      return this.getCurrentStep == "create_new_detail";
    }
  },
  components: {
    TypeCardItems,
    TypeCardDetails,
    PreviewQr,
    Spinner
  },
  methods: {
    onValidate(v) {
      this.validation.push(v);
    },
    onClearValidation(v) {
      this.validation = [];
    }
  }
});
