import dayjs from "dayjs";
import { querySentences, mutationSentences } from "../gql";

const getModule = (queryAPI, mutationAPI) => {
  const state = {
    totalScans: 0,
    uniqueScans: 0,
    platforms: [],
    cities: [],
    countries: [],
    overtimes: [],
    data: {},
    loading: false,
    range: {
      startDate: null,
      endDate: null
    },
    unit: "day"
  };

  const getters = {
    getAnalysisData(state) {
      return state.data;
    },
    getTotalScans(state) {
      return state.totalScans;
    },
    getUniqueScans(state) {
      return state.uniqueScans;
    },
    getAnalysisLoading(state) {
      return state.loading;
    },
    getPlatforms(state) {
      return state.platforms;
    },
    getCities(state) {
      return state.cities;
    },
    getCountries(state) {
      return state.countries;
    },
    getOvertimes(state) {
      return state.overtimes;
    },
    getAnalysisUnit(state) {
      console.log(state.unit);
      return state.unit;
    },
    getAnalysisRange(state) {
      return state.range;
    }
  };

  const mutations = {
    setAnalysisData(state, data) {
      const {
        totalScans,
        uniqueScans,
        platforms,
        overtimes,
        cities,
        countries,
        ...rest
      } = data;
      state.data = rest;
      state.totalScans = totalScans;
      state.uniqueScans = uniqueScans;
      state.platforms = platforms;
      state.overtimes = overtimes;
      state.cities = cities;
      state.countries = countries;
    },
    setAnalysisLoading(state, loading) {
      state.loading = loading;
    },
    setAnalysisUnit(state, unit) {
      state.unit = unit;
    },
    setAnalysisRange(state, range) {
      state.range = range;
    },
    mutateResetQR(state, range) {}
  };

  const actions = {
    async fetchAnalysisData({ commit, getters }, payload) {
      try {
        commit("setAnalysisLoading", true);
        const qrCodeId = payload?.id || getters.getCurrentQrId;
        const unit = getters.getAnalysisUnit;
        const range = getters.getAnalysisRange;
        console.log("[fetchAnalysisData]", qrCodeId, unit, range);
        const options = {
          unit
        };
        if (range.startDate) {
          options.start = dayjs(range.startDate)
            .startOf(unit)
            .format(`YYYY-MM-DD HH:mm:ss`);
          options.end = dayjs(range.endDate)
            .endOf(unit)
            .format(`YYYY-MM-DD HH:mm:ss`);
        }
        const { qrStats: result } = await queryAPI({
          query: querySentences.qrStats,
          variables: {
            qrCodeId,
            options
          }
        });
        commit("setAnalysisData", result);
      } catch (error) {
        console.log(error);
      } finally {
        commit("setAnalysisLoading", false);
      }
    },
    updateAnalysisRange({ dispatch, commit }, payload) {
      commit("setAnalysisRange", payload);
      dispatch("fetchAnalysisData");
    },
    updateAnalysisUnit({ dispatch, commit }, payload) {
      commit("setAnalysisUnit", payload);
      dispatch("fetchAnalysisData");
    },
    async resetQrScans({ commit }, payload) {
      try {
        commit("setAnalysisLoading", true);
        const { id } = payload;
        if (!id) return false;
        console.log("[mutationSentences.resetQrCode id]", id);

        const result = await mutationAPI({
          mutation: mutationSentences.resetQrCode,
          variables: {
            ID: id
          }
        });
        console.log("[mutationSentences.resetQrCode result]", result);

        return result;
      } catch (error) {
      } finally {
        commit("setAnalysisLoading", false);
      }
    }
  };

  return {
    state,
    getters,
    mutations,
    actions
  };
};
export default getModule;
