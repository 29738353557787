<div class="pr-8 pt-4 text-gray-500">
  <div class="capitalize text-md font-bold text-gray-700">
    {{$t('scan_by_top_cities.title')}}
  </div>
  <div class="w-full mt-8">
    <div class="grid grid-cols-scan-os text-sm gap-4">
      <div>{{$t('scan_by_top_cities.city')}}</div>
      <div>{{$t('scan_by_top_cities.scans')}}</div>
      <div>%</div>
      <template v-for="row in data">
        <div class="font-semibold">{{row.key}}</div>
        <div>
          <HorizontalBar
            :value="row.ratio"
            :tooltip="`${row.num} scans in
          ${row.key}` "
          />
        </div>
        <div>{{row.ratio}}%</div>
      </template>
    </div>
  </div>
</div>
