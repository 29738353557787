const initExtString = () => {
  if (!String.prototype.rtrim) {
    String.prototype.rtrim = function (s) {
      if (s == undefined) s = "\\s";
      return this.replace(new RegExp("[" + s + "]*$"), "");
    };
  }

  if (!String.prototype.ltrim) {
    String.prototype.ltrim = function (s) {
      if (s == undefined) s = "\\s";
      return this.replace(new RegExp("^[" + s + "]*"), "");
    };
  }

  if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function (search, replacement) {
      const target = this;
      return target.split(search).join(replacement);
    };
  }
  if (!String.prototype.insert) {
    String.prototype.insert = function (index, string) {
      if (index > 0)
        return (
          this.substring(0, index) + string + this.substring(index, this.length)
        );
      else return string + this;
    };
  }
};

export { initExtString };
