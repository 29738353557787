import baseField from "vue-formly-bootstrap/src/fields/baseField";

import WithRender from "./QRToggleButton.html";

export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  created() {},

  computed: {
    label() {
      return this.field.templateOptions.label;
    },
    name() {
      return this.field.key;
    },
    className() {
      return this.field.className;
    },
    hide() {
      return this.field.hideExpression(this.model);
    }
  },
  methods: {
    onClick() {
      const name = this.name;
      const value = !this.model[name];
      this.$emit("update", { name, value });
    }
  }
});
