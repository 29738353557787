<footer class="p-4 bg-white rounded-lg shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800">
  <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2022 <a href="#"
      class="hover:underline">PrintQ</a>. {{$t('footer.all_rights_reserved')}}.
  </span>
  <ul class="flex flex-wrap items-center mt-3 text-sm text-gray-500 dark:text-gray-400 sm:mt-0">
    <li>
      <router-link to="/" class="mr-4 hover:underline md:mr-6 ">{{$t('footer.about')}}</router-link>
    </li>
    <li>
      <router-link to="/" class="mr-4 hover:underline md:mr-6 ">{{$t('footer.help')}}</router-link>
    </li>
  </ul>
</footer>