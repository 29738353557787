import { v4 as uuidv4 } from "uuid";
import { mapActions, mapGetters } from "vuex";
import baseField from "vue-formly-bootstrap/src/fields/baseField";
import WithRender from "./QrUploadPdf.html";
import Spinner from "../../base/components/Spinner";
import CloseIcon from "../icons/CloseIcon";

import "cropperjs/dist/cropper.css";

const defaultFile = {
  uuid: "",
  base_path: "",
  filename: "",
  height: 0,
  id: "",
  loading: false,
  original_filename: "",
  width: 0
};

export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  components: {
    Spinner,
    CloseIcon
  },
  mounted() {
    this.file = this.getCurrentModel?.file
      ? { ...this.getCurrentModel.file }
      : {};
    this.linkToPDF = this.getCurrentModel?.linkToPDF
      ? this.getCurrentModel.linkToPDF
      : false;
    this.$root.$on("PdfUpload", ({ file }) => {
      this.onUploadFile(file);
    });
  },
  data() {
    return {
      file: {
        ...defaultFile
      },
      linkToPDF: false,
      isPdfUploading: false
    };
  },
  computed: {
    ...mapGetters(["getCurrentModel", "getStorageBaseUrl"]),
    name() {
      return this.field.key;
    },
    linkToPDFName() {
      return this.field.linkToPDFKey;
    },
    hasPdf() {
      return this.file.original_filename && this.file.original_filename != "";
    }
  },

  methods: {
    ...mapActions(["uploadFileToServer", "updateCurrentModel"]),

    onRemoveFile(file) {
      const { id } = file;
      this.file = {
        ...defaultFile
      };
      this.$emit("update", {
        name: this.name,
        value: this.file
      });
    },

    onUploadFile(file) {
      try {
        const uuid = uuidv4();
        this.isPdfUploading = true;
        this.file = {
          ...defaultFile,
          uuid,
          id: uuid,
          original_filename: file.name,
          loading: true
        };
        this.$emit("update", {
          name: this.name,
          value: this.file
        });
        this.$emit("update", {
          name: "isPdfUploading",
          value: this.isPdfUploading
        });
        this.uploadFileToServer(file)
          .then(uploadFile => {
            this.file = {
              ...defaultFile,
              ...uploadFile,
              loading: false
            };
            this.$emit("update", {
              name: this.name,
              value: this.file
            });
            this.isPdfUploading = false;
            this.$emit("update", {
              name: "isPdfUploading",
              value: this.isPdfUploading
            });
          })
          .catch(error => {
            this.file = {
              ...defaultFile
            };
            this.$emit("update", {
              name: this.name,
              value: this.file
            });
            this.isPdfUploading = false;
            this.$emit("update", {
              name: "isPdfUploading",
              value: this.isPdfUploading
            });
          });
      } catch (error) {
        this.file = {
          ...defaultFile
        };
        this.$emit("update", {
          name: this.name,
          value: this.file
        });
        this.isPdfUploading = false;
        this.$emit("update", {
          name: "isPdfUploading",
          value: this.isPdfUploading
        });
      }
    },
    onUpdate(v) {
      const { name, value } = v;
      const payload = {
        key: name,
        value: value
      };
      this.updateCurrentModel(payload);
    },

    onChangeFileName({ target: { files } }) {
      const { name } = this;
      this.$root.$emit("PdfUpload", {
        file: files[0],
        name
      });
    },
    onLinkToPDF(event) {
      this.linkToPDF = event.target.checked;
      this.$emit("update", {
        name: this.linkToPDFName,
        value: this.linkToPDF
      });
    }
  },
  watch: {
    model: {
      handler(newValue, oldValue) {
        if (oldValue[this.name] != newValue[this.name]) {
          this.file = { ...defaultFile, ...newValue[this.name] };
        }
        if (oldValue[this.linkToPDFName] != newValue[this.linkToPDFName])
          this.linkToPDF = newValue[this.linkToPDFName];
      },
      deep: true
    }
  }
});
