import welcome from "./img/welcome_social_media.png";

export const createDynamicSocialMediaFieldsWidget = (formly, t) => {
  return [
    formly.container(
      formly.header({
        index: 1,
        sectionName: "",
        headline: "",
        hideCollapse: true,
        icon: null
      }),
      formly.body({
        name: "section_basic_info",
        collapseKey: "section_basic_info",
        widget: 1,
        key: "code",
        content: [
          formly.avatarRectangular({
            tooltip: t(
              "Upload an image or logo from your computer. Images must be at least 640 x 360 px in .jpg or .png format."
            ),
            aspectRatio: 1.77
          }),
          {
            className: "m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          },
          formly.inputRowRequired({
            key: "headline",
            label: t("Headline"),
            className: "form-input-row form-row-container flex flex-col",
            placeholder: t("Connect with us on social media"),
            condition: "true",
            errorMsgTitle: t("Please provide an engaging title to your page."),
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(
                    t("Please provide an engaging title to your page.")
                  );
                  return false;
                }

                return true;
              }
            }
          }),
          formly.textAreaRow({
            key: "description",
            label: t("About us"),
            placeholder: t(
              "Follow us and get updates delivered to your favorite social media channel."
            ),
            maxChar: 120
          }),
          {
            className: "m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          },
          formly.socialChannels({
            key: "channels",
            required: true,
            requiredAllChannels: false,
            sortable: false,
            showLabels: false,
            withTextInputs: true,
            helpMessage: false,
            validators: {
              required: function ({ messages, value, links, link }) {
                if (value.trim() == "") {
                  messages.push(t("The URL field can not be empty"));
                  return false;
                }
                return true;
              }
            },
            textValidators: {
              required: function ({ messages, value, links, link }) {
                if (value.trim() == "") {
                  messages.push(t("The Text field can not be empty"));
                  return false;
                }

                return true;
              }
            }
          })
        ]
      })
    )
  ];
};

export const createDynamicSocialMediaRequiredFieldsWidget = _ => {
  return ["headline"];
};
