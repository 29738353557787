<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
	y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
	<g>
		<g>
			<path d="M493.887,236.993h-70.475l-56.488-107.654c-3.129-5.963-9.305-9.697-16.039-9.697H305.34V92.249
			c0-10.003-8.11-18.113-18.113-18.113H185.798c-10.003,0-18.113,8.11-18.113,18.113v27.392H85.162
			c-8.57,0-15.966,6.006-17.727,14.393l-21.61,102.959H18.113C8.11,236.993,0,245.102,0,255.106v58.721v58.719v0.006
			c0,10.003,8.11,18.113,18.113,18.113c54.563,0,69.034-0.006,70.204-0.006c18.141,62.905,107.665,62.974,125.826,0h64.411
			c18.141,62.902,107.656,62.972,125.813,0h89.519c10.003,0,18.113-8.11,18.113-18.113c0-12.432,0-104.897,0-117.341v-0.099
			C512,245.102,503.89,236.993,493.887,236.993z M339.934,155.868l42.567,81.125H254.626v-81.125
			C264.292,155.868,330.907,155.868,339.934,155.868z M203.911,110.362h65.203v9.279h-65.203V110.362z M99.869,155.868
			c14.557,0,105.209,0,118.53,0v81.125H82.841L99.869,155.868z M58.725,354.433H36.226V331.94h22.499V354.433z M151.231,401.8
			c-16.13,0-29.255-13.124-29.255-29.254c0-16.126,13.124-29.243,29.255-29.243c16.132,0,29.255,13.118,29.255,29.243
			C180.486,388.677,167.362,401.8,151.231,401.8z M341.467,401.8c-16.13,0-29.255-13.124-29.255-29.254
			c0-16.126,13.124-29.243,29.255-29.243c16.124,0,29.242,13.118,29.242,29.243C370.709,388.677,357.591,401.8,341.467,401.8z
			 M475.774,354.433h-71.387v0c-18.36-63.684-107.934-62.101-125.853,0h-64.371c-15.963-55.39-89.991-64.209-119.211-15.303v-25.303
			c0-10.003-8.11-18.113-18.113-18.113H36.226V273.22c21.444,0,380.822,0,380.822,0v40.71c0,10.003,8.11,18.113,18.113,18.113
			h40.612V354.433z M475.774,295.816h-22.499v-22.498h22.499V295.816z" />
		</g>
	</g>
</svg>