<div v-if="!isHide" class="field-group" :class="className">
  <template v-for="(curField, idx) in subFields">
    <template v-if="!!curField.type">
      <div v-if="curField.type == 'field_group'" ref="fieldGroupBody">
        <formly_field_group :form="form" :model="model" :field="curField" @update="update" @validate="validate"
          @click="click">
        </formly_field_group>
      </div>
      <component v-else :is="'formly_' + curField.type" :form="form" :model="model" :field="curField" @update="update"
        @validate="validate" @click="click">
      </component>
    </template>
    <div v-else :class="curField.className" v-html="curField.template" @update="update"></div>
  </template>
</div>