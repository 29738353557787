<main-layout>
  <div class="max-w-screen-xl m-auto py-8">
    <div class="w-full flex overflow-hidden">
      <div class="flex-grow flex-shrink couponTypes">
        <div class="w-full flex justify-between mb-2 lg:mb-4 items-center px-3 text-qr_color-gray_2 text-24">
          {{$t('createNew.title')}}
        </div>
        <div class="w-full">
          <div class="w-full min-h-[20rem] flex items-center justify-center" v-if="getLoading">
            <Spinner :visible="getLoading" />
          </div>

          <ul v-else class="w-full mt-6 mb-12 relative couponTypesMainContainer flex w-full flex-row flex-wrap"
            role="tablist">
            <TypeCardItem v-for="qrtype in getCategories" :value="qrtype.name" :id="qrtype.id" :name="qrtype.name"
              :icon="qrtype.icon" :type="qrtype.code" :selected="selectType == qrtype.name"
              :description="qrtype.description"
              :class="selectType == qrtype.code? 'border-green-700': 'border-gray-200'">
              <div v-if="getSelectedCategory == qrtype.code"
                class="inline-flex absolute -bottom-0 -right-0 justify-center items-center w-8 h-8 text-normal font-bold text-white bg-green-700 rounded-full border-2 border-white dark:border-gray-900">

                ✓
              </div>
            </TypeCardItem>
            <li></li>
          </ul>
        </div>
      </div>
      <div class="min-w-96 w-1/3 flex-shrink-0 pl-4">
        <div :class="explanations.classlist">
          <div class="phonePlaceholder"></div>
          <img :src="explanations.image.icon" v-if="explanations.image.type == 'jpg'"
            :class="explanations.image.classlist" />
          <component :is="explanations.image.icon" v-if="explanations.image.type == 'svg'"
            :class="explanations.image.classlist" />

          <div v-if="explanations.description && 0" :class="explanations.description.classlist">

            {{explanations.description.content}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <NextActionFooter @next="onCreateNewQR" @back="onBackBtnClick" :isBackShow="true"></NextActionFooter>
</main-layout>