import welcome from "./img/welcome_rating.png";
// import avatar from "./img/coupons-avatar.jpg";
// import { remove } from "lodash";
// couponsOption

export const createDynamicRatingFields = formly => {
  return [
    formly.labelTitle({
      titleIcon: "RatingIcon",
      titleKey: "qrcode_title",
      titleTooltip:
        "Labeling QR Codes has been moved to this area. Names help you to stay organized and will only appear in your account and are not displayed to customers who scan your QR Codes.",
      titlePlaceholder: "My Rating QR Code",
      titleValue: null,
      buttonLabel: "Got it"
    }),
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_design",
        headline: "Design & Customization",
        icon: "DesignIcon"
      }),
      formly.body({
        name: "section_design",
        collapseKey: "section_design",
        key: "code",
        content: [
          formly.subHeadLine({
            text: "Select a color theme for your page."
          }),
          formly.colorsTemplate({
            shape: "rect",
            customColors: [
              { color1: "#0090e3", color2: "#e37b00" },
              { color1: "#45606f", color2: "#0288d1" },
              { color1: "#704b4b", color2: "#d72feb" },
              { color1: "#4caf50", color2: "#e04712" },
              { color1: "#e6c026", color2: "#12bce0" }
            ]
          }),
          formly.colorsSwitch({}),
          {
            className: "my-2",
            template: '<hr style="border-color: #eaeaea; margin: 30px 0;">'
          },
          formly.avatarRectangular({
            tooltip:
              "Upload an image or logo from your computer. Images must be at least 640 x 360 px in .jpg or .png format.",
            aspectRatio: 1.77
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 1,
        sectionName: "section_basic_info",
        headline: "Basic Information",
        icon: "InfoIcon"
      }),
      formly.body({
        name: "section_basic_info",
        collapseKey: "section_basic_info",
        key: "code",
        content: [
          formly.subHeadLine({
            text: "Enter your question below and choose a rating type."
          }),
          formly.inputRow({
            label: "Question",
            placeholder: "How was your rental experience?",
            key: "rating.question"
          }),
          {
            className: "my-2",
            template: '<hr style="border-color: #eaeaea; margin: 30px 0;">'
          }
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 1,
        sectionName: "section_basic_info",
        headline: "Basic Information",
        icon: "InfoIcon"
      }),
      formly.body({
        name: "section_basic_info",
        collapseKey: "section_basic_info",
        key: "code",
        content: [
          formly.subHeadLine({
            text:
              "Provide your company informations below.Whenever a costumer submits a rating, we'll send you a email"
          }),
          formly.inputRow({
            label: "Email*",
            key: "email",
            required: true,
            className: "flex-0",
            // errorHolder: "#errorLastname",
            // errorMsg: "Please provide an email.",
            placeholder: "dragosmatteod@gmail.com"
          }),

          formly.inputRow({
            label: "Company",
            placeholder: "Lake Cruisers",
            key: "company"
          }),
          formly.inputRow({
            key: "website",
            className: "form-input-row form-row-container flex flex-col",
            label: "Website",
            placeholder: "www.lakecruisers.com"
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 5,
        sectionName: "section_media_channels",
        headline: "Social Media Channels",
        icon: "EarthIcon"
      }),
      formly.body({
        name: "section_media_channels",
        collapseKey: "section_media_channels",
        key: "code",
        content: [
          formly.subHeadLine({
            text:
              "Add your username or links to social media profiles below.Social icons will appear on the Thank You page."
          }),
          formly.socialChannels({
            key: "channels",
            requiredAllChannels: false,
            sortable: false,
            showLabels: false,
            helpMessage: false
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 3,
        sectionName: "section_welcome_screen",
        headline: "Welcome Screen",
        icon: "SphereIcon"
      }),
      formly.body({
        name: "section_welcome_screen",
        collapseKey: "section_welcome_screen",
        key: "code",
        content: [
          formly.subHeadLine({
            text: "Display your logo while your page is loading."
          }),
          formly.welcomeScreen({
            tooltip:
              "Upload an image or logo from your computer. Images must be in .jpg or .png format.",
            key: "welcome_screen",
            previewWelcomeScreen: true,
            defaultWelcomeImage: welcome
          })
        ]
      })
    )
  ];
};

export const createDynamicRatingRequiredFields = _ => {
  return ["email"];
};
