<div>
  <div class="relative w-full">
    <input
      type="text"
      class="bg-qr_color-gray_3 h-12 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent"
      :value="inputValue"
      @blur="onBlurValue"
    />
    <button
      type="button"
      class="absolute top-2 right-0 px-2 py-1.5 text-sm font-medium text-qr_color-gray border-0 border-l-2 border-qr_color-gray_1 focus:ring-transparent focus:outline-none"
      ref="hourPickerBtn"
    >
      <svg
        aria-hidden="true"
        class="w-4 h-4"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>
  </div>
  <div
    ref="hourPicker"
    class="hidden z-10 w-36 right-0 bg-white opacity-90 divide-y divide-gray-100 shadow right-0"
  >
    <ul class="py-1 text-sm text-gray-700 max-h-[15rem] overflow-scroll">
      <li v-for="time in getCurrentTimes" @click="onUpdateTime(time.value)">
        <span href="#" class="block py-2 px-4 hover:bg-gray-200"
          >{{time.label}}</span
        >
      </li>
    </ul>
  </div>
</div>
