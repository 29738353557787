import welcome from "./img/welcome_feedback.png";

export const createDynamicFeedbackFieldsWidget = formly => {
  return [
    formly.labelTitle({
      titleIcon: "FeedbackIcon",
      titleKey: "qrcode_feedback",
      titleTooltip:
        "Labeling QR Codes has been moved to this area. Names help you to stay organized and will only appear in your account and are not displayed to customers who scan your QR Codes.",
      titlePlaceholder: "My Feedback QR Code",
      titleValue: null,
      buttonLabel: "Got it"
    }),
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_design",
        fold: false,
        headline: "Design & Customizations",
        icon: "DesignIcon"
      }),
      formly.body({
        name: "section_design",
        collapseKey: "section_design",
        key: "code",
        content: [
          formly.subHeadLine({
            text: "Choose a color scheme for your page."
          }),
          formly.colorsTemplate({
            customColors: [
              { color1: "#447fb6" },
              { color1: "#0288d1" },
              { color1: "#d32f2f" },
              { color1: "#4caf50" },
              { color1: "#795548" }
            ]
          }),
          formly.costumColor({})
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 1,
        sectionName: "section_information",
        fold: false,
        headline: "Basic Information",
        icon: "InfoIcon"
      }),
      formly.body({
        name: "section_information",
        collapseKey: "section_information",
        key: "code",
        content: [
          formly.subHeadLine({
            text:
              "Provide your company name and add a title to your Feedback Page."
          }),
          formly.inputRow({
            label: "Name*",
            required: true,
            className: "flex-0",
            errorHolder: "#errorLastname",
            errorMsg: "Please provide a name.",
            placeholder: "Company or product to be reviewed"
          }),
          formly.inputRow({
            label: "Title",
            className: "flex-0",
            placeholder: "e.g. Leave us your feedback"
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 2,
        sectionName: "section_categories",
        fold: false,
        headline: "Categories",
        icon: "BarsIcon"
      }),
      formly.body({
        name: "section_information",
        collapseKey: "section_information",
        key: "code",
        content: [
          formly.subHeadLine({
            text:
              "Start by creating new categories or choosing from pre-defined ones"
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 3,
        sectionName: "section_information",
        fold: false,
        headline: "Contact Information",
        icon: "ContactIcon"
      }),
      formly.body({
        name: "section_information",
        collapseKey: "section_information",
        key: "code",
        content: [
          formly.subHeadLine({
            text:
              "Provide your email address and website below. Whenever a customer gives feedback, we'll send you an email."
          }),
          formly.inputRow({
            label: "Email address*:",
            required: true,
            className: "flex-0",
            errorHolder: "#errorLastname",
            errorMsg: "Please provide an email.",
            placeholder: "your@email.com"
          }),
          formly.inputRow({
            label: "Website:",
            className: "flex-0",
            placeholder: "www.yourwebsite.com"
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 3,
        sectionName: "section_welcome_screen",
        headline: "Welcome Screen",
        icon: "SmartPhoneIcon"
      }),
      formly.body({
        name: "section_welcome_screen",
        collapseKey: "section_welcome_screen",
        key: "code",
        content: [
          formly.subHeadLine({
            text: "Display your logo while your page is loading."
          }),
          formly.welcomeScreen({
            tooltip:
              "Upload an image or logo from your computer. Images must be in .jpg or .png format.",
            key: "welcome_screen",
            previewWelcomeScreen: true,
            defaultWelcomeImage: welcome
          })
        ]
      })
    )
  ];
};

export const createDynamicFeedbackRequiredFieldsWidget = _ => {
  return ["company", "email"];
};
