import WithRender from "./QRIcon.html";

import ArrowRightIcon from "../icons/ArrowRightIcon";
import ClockIcon from "../icons/ClockIcon";
import DustBinIcon from "../icons/DustBinIcon";
import HammerIcon from "../icons/HammerIcon";
import ImageIcon from "../icons/ImageIcon";
import ImageIcon1 from "../icons/ImageIcon1";
import InfoIcon from "../icons/InfoIcon";
import LinkIcon from "../icons/LinkIcon";
import MoreIcon from "../icons/MoreIcon";
import NewsPaper from "../icons/NewsPaper";
import PaintIcon from "../icons/PaintIcon";
import PauseIcon from "../icons/PauseIcon";
import PencilIcon from "../icons/PencilIcon";
import QuestionIcon from "../icons/QuestionIcon";
import SearchIcon from "../icons/SearchIcon";
import ShareIcon from "../icons/ShareIcon";
import SphereIcon from "../icons/SphereIcon";
import SwapIcon from "../icons/SwapIcon";
import InstagramIcon from "../icons/InstagramIcon";
import WhatsappIcon from "../icons/WhatsappIcon";
import SpotifyIcon from "../icons/SpotifyIcon";
import TelegramIcon from "../icons/TelegramIcon";
import TwitterIcon from "../icons/TwitterIcon";
import YoutubeIcon from "../icons/YoutubeIcon";
import VimeoIcon from "../icons/VimeoIcon";
import FlickrIcon from "../icons/FlickrIcon";
import DribbbleIcon from "../icons/DribbbleIcon";
import GithubIcon from "../icons/GithubIcon";
import TumblrIcon from "../icons/TumblrIcon";
import RedditIcon from "../icons/RedditIcon";
import LinkedinIcon from "../icons/LinkedinIcon";
import PinterestIcon from "../icons/PinterestIcon";
import XingIcon from "../icons/XingIcon";
import YelpIcon from "../icons/YelpIcon";
import FacebookIcon from "../icons/FacebookIcon";
import EarthIcon from "../icons/EarthIcon";
import CogIcon from "../icons/CogIcon";
import SmartPhoneIcon from "../icons/SmartPhoneIcon";
import LibraryIcon from "../icons/LibraryIcon";
import LocationIcon from "../icons/LocationIcon";
import CouponIcon from "../icons/CouponIcon";
import BusinessIcon from "../icons/BusinessIcon";
import DesignIcon from "../icons/DesignIcon";
import CalendarIcon from "../icons/CalendarIcon";
import AddressIcon from "../icons/AddressIcon";
import PlayIcon from "../icons/PlayIcon";
import BarIcon from "../icons/BarIcon";
import BedIcon from "../icons/BedIcon";
import CafeIcon from "../icons/CafeIcon";
import ChairIcon from "../icons/ChairIcon";
import ParkingIcon from "../icons/ParkingIcon";
import PetIcon from "../icons/PetIcon";
import StrollerIcon from "../icons/StrollerIcon";
import TaxiIcon from "../icons/TaxiIcon";
import ToiletIcon from "../icons/ToiletIcon";
import TrainIcon from "../icons/TrainIcon";
import WifiIcon from "../icons/WifiIcon";
import VideoIcon from "../icons/VideoIcon";
import VideoIcon1 from "../icons/VideoIcon1";
import FeedbackIcon from "../icons/FeedbackIcon";
import FeedbackIcon1 from "../icons/FeedbackIcon1";
import ContactIcon from "../icons/ContactIcon";
import BarsIcon from "../icons/BarsIcon";
import SocialMedia from "../icons/SocialMedia";
import SocialMedia1 from "../icons/SocialMedia1";
import TextIcon from "../icons/TextIcon";
import TextIcon1 from "../icons/TextIcon1";
import PdfIcon from "../icons/PdfIcon";
import RatingIcon from "../icons/RatingIcon";
import AudioIcon from "../icons/AudioIcon";
import AppIcon from "../icons/AppIcon";
import UploadIcon from "../icons/UploadIcon";
import AmazonIcon from "../icons/AmazonIcon";
import GooglePlayIcon from "../icons/GooglePlayIcon";
import AppleStoreIcon from "../icons/AppleStoreIcon";

export default WithRender({
  props: ["name"],
  components: {
    ContactIcon,
    RatingIcon,
    TextIcon,
    BarsIcon,
    ArrowRightIcon,
    ClockIcon,
    DustBinIcon,
    HammerIcon,
    ImageIcon,
    InfoIcon,
    LinkIcon,
    MoreIcon,
    NewsPaper,
    PaintIcon,
    PauseIcon,
    PencilIcon,
    QuestionIcon,
    SearchIcon,
    ShareIcon,
    SphereIcon,
    SwapIcon,
    InstagramIcon,
    WhatsappIcon,
    SpotifyIcon,
    TelegramIcon,
    TwitterIcon,
    YoutubeIcon,
    VimeoIcon,
    FlickrIcon,
    DribbbleIcon,
    GithubIcon,
    TumblrIcon,
    RedditIcon,
    LinkedinIcon,
    PinterestIcon,
    XingIcon,
    YelpIcon,
    PdfIcon,
    FacebookIcon,
    EarthIcon,
    CogIcon,
    LibraryIcon,
    SmartPhoneIcon,
    LocationIcon,
    CouponIcon,
    AudioIcon,
    AppIcon,
    "icon-creation-code-url": EarthIcon,
    "icon-coupon": CouponIcon,
    BusinessIcon,
    "icon-manage-code-business-page": BusinessIcon,
    DesignIcon,
    CalendarIcon,
    "icon-creation-code-event": CalendarIcon,
    AddressIcon,
    "icon-creation-code-vcard": AddressIcon,
    "icon-creation-code-add-video": VideoIcon,
    "icon-creation-code-image": ImageIcon,
    "icon-creation-code-feedback": FeedbackIcon,
    "icon-creation-code-social-media": SocialMedia,
    "icon-creation-code-text": TextIcon,
    "icon-creation-code-instagram": InstagramIcon,
    "icon-creation-code-facebook": FacebookIcon,
    "icon-creation-code-pdf": PdfIcon,
    "icon-creation-code-rating": RatingIcon,
    "icon-creation-code-mp3": AudioIcon,
    "icon-creation-code-app": AppIcon,
    ImageIcon,
    PlayIcon,
    BarIcon,
    CafeIcon,
    ChairIcon,
    ParkingIcon,
    PetIcon,
    StrollerIcon,
    TaxiIcon,
    ToiletIcon,
    TrainIcon,
    WifiIcon,
    ImageIcon1,
    SocialMedia1,
    VideoIcon1,
    FeedbackIcon1,
    TextIcon1,
    UploadIcon,
    AmazonIcon,
    GooglePlayIcon,
    AppleStoreIcon
  },
  created() {
    // console.log("[qr icon]", this.name);
  }
});
