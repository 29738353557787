import { makeRNDStr } from "./helpers";
/**
 * Provides basic formly section handling functionality such as header, body and custom elements
 **/
export const FormlyGenerateFunctions = {
  /**
   * Solution title section
   *
   * @param options - information about title
   * @param options.titleKey - title key
   * @param options.titleIcon - icon of the solution
   * @param options.titlePlaceholder - title input placeholder
   * @param options.titleTooltip - title tooltip
   * @param options.titleValue - title value
   * @param options.isTooltip - tooltip true or false
   * @param options.buttonLabel - button label
   * */
  labelTitle: function (options) {
    return {
      type: "field_group",
      key: options.titleKey + "wrapper",
      fieldGroup: [
        {
          type: "qrtitle",
          key: options.titleKey,
          templateOptions: {
            icon: options.titleIcon,
            placeHolder: options.titlePlaceholder,
            tooltip: options.titleTooltip,
            value: options.titleValue,
            isTooltip: options.isTooltip,
            buttonLabel: options.buttonLabel
          }
        }
      ],
      className: "label-title"
    };
  },

  /**
   * Wrapper for header and body
   *
   * @param header - header of the section
   * @param body - body of the section
   * @param [hideExpression] hideExpression for the whole section.
   * */
  container: function (header, body, hideExpression) {
    return {
      className: "form-container pb-3 pt-3",
      type: "field_group",
      fieldGroup: [header, body],
      collapseContainer: true,
      hideExpression,
      key: "form-container-" + makeRNDStr(5)
    };
  },

  /**
   * Header of the section
   *
   * @param options - information about the header
   * @param options.index - index of the section
   * @param options.sectionName - name of the section
   * @param options.fold - section is fold or not
   * @param options.headline - headline of the section
   * @param options.icon - icon of the section
   * */
  header: function (options) {
    let className = "section-header relative";
    if (options.index !== undefined) {
      // className =
      //   "section-header relative {{model.form[" +
      //   options.index +
      //   "]['" +
      //   options.sectionName +
      //   "'].fold ? '' : 'section-open'}}";
      className = "section-header relative block";
    }
    return {
      className: className,
      type: "field_group",
      fieldGroup: [
        {
          className: "toggle-collapse text-gray-500 p-0 border-0",
          type: "field_group",
          fieldGroup: [
            {
              type: options.hideCollapse ? "" : "qr_collapse",
              templateOptions: {
                parent: "form",
                key: options.sectionName,
                fold: options.fold,
                index: options.index
              }
            }
          ]
        },
        {
          className:
            "form-title-row text-qr_color-yellow_icon flex items-center ",
          type: "field_group",
          fieldGroup: [
            {
              type: "qrinput_headline_icon",
              key: "empty",
              templateOptions: {
                icon: options.icon
              }
            },
            {
              type: "qrinput_headline",
              key: "empty",
              templateOptions: {
                headline: options.headline,
                hasHelp: true
              }
            }
          ]
        }
      ]
    };
  },

  /**
   * Body of the section
   *
   * @param options - information about the body
   * @param options.name - name of the section (it has to be like the header section name)
   * @param options.key - key of the section (after event solution, the new ones, have 'code' as a key)
   * @param options.content - array of elements
   * @param options.collapseKey - collapseKey of the section (it has to be like the header section name)
   * @param options.oldFormat - is old layout format
   * */
  body: function (options) {
    let className = options.oldFormat
      ? "section-body transition-all delay-100 duration-300 oldLayoutFormat text-qr_color-gray "
      : "section-body transition-all delay-100 duration-300 text-qr_color-qray bg-white ";
    let padding = " p-10";
    if (options.widget) {
      padding = "px-1";
    }
    className += padding;
    if (options.key)
      return {
        className: className,
        key: options.key,
        collapseKey: options.collapseKey,
        type: "field_group",
        fieldGroup: [
          {
            className: options.name,
            type: "field_group",
            fieldGroup: options.content
          }
        ]
      };
    else
      return {
        className: className,
        type: "field_group",
        fieldGroup: [
          {
            className: options.name,
            type: "field_group",
            fieldGroup: options.content
          }
        ]
      };
  },

  /**
   * Small headline. Appears underneath the header
   *
   * @param options - information about the subHeadLine
   * @param options.text - text to show
   * @param options.text2 - text2 to show
   * @param [options.hideExpression]
   * @param [options.centered]
   * */
  subHeadLine: function (options) {
    return {
      type: "qrinput_subheadline",
      templateOptions: {
        subheadline: options.text,
        subheadlineHtml: options.html,
        subheadline2: options.text2,
        subheadlineHtml2: options.html2,
        centered: options.centered
      },
      hideExpression: options.hideExpression || false
    };
  },

  /**
   * template type plugin
   *
   *@param options - information about the template type plugin
   *@param options.label - label
   *@param options.defaultTemplateId - default template ID
   *@param options.previewUrl - preview URL
   * */
  templateType: function (options) {
    return {
      type: "field_group",
      fieldGroup: [
        {
          type: "qr_input_label",
          templateOptions: {
            label: options.label
          }
        },
        {
          type: "qrinput_template_selector",
          templateOptions: {
            defaultTemplateId: options.defaultTemplateId,
            previewUrl: options.previewUrl,
            templates: [
              {
                id: 12,
                image: "/img/generator/dvcard/template-type-1.jpg"
              },
              {
                id: 13,
                image: "/img/generator/dvcard/template-type-1.jpg"
              },
              {
                id: 14,
                image: "/img/generator/dvcard/template-type-2.jpg"
              }
            ]
          }
        }
      ],
      hideExpression: true
    };
  },

  /**
   * Color gradient plugin
   *
   * @param options - information about the  color gradient plugin
   * @param options.key - key of the input
   * @param options.placeholder - input placeholder
   * @param options.label - label
   * @param options.tooltip - tooltip
   * */
  colorGradient: function (options) {
    return {
      type: "field_group",
      className: "!block",
      fieldGroup: [
        {
          type: "field_group",
          fieldGroup: [
            {
              className: "col-lg-offset-1 col-sm-12 col-md-3 box-label",
              type: "qr_input_label",
              templateOptions: {
                isEmpty: true
              }
            },
            {
              type: "qrinput_checkbox",
              key: options.key,
              templateOptions: {
                placeHolder: options.placeholder,
                label: options.label,
                tooltip: options.tooltip
              }
            }
          ]
        },
        {
          type: "field_group",
          fieldGroup: [
            {
              type: "qr_input_label",
              templateOptions: {
                isEmpty: true
              }
            },
            {
              type: "qrinput_colorpicker",
              key: "color1b",
              templateOptions: {
                defaultColor: "#ff0000",
                title: "Gradient"
              }
            }
          ],
          hideExpression: model => {
            return !model["show_gradient"];
          }
        }
      ]
    };
  },

  /**
   * Colors template
   *
   * @param options - information about the colors template
   * @param options.customColors - custom colors array
   * @param [options.oneColor] - hide the second color.
   * @param [options.shape] shape of the color2 input - "round" or "rect".
   * */
  colorsTemplate: function (options) {
    return {
      type: "field_group",
      className: "flex flex-col",
      fieldGroup: [
        {
          type: "qr_input_label",
          templateOptions: {
            tooltip: "Select a theme or choose your own colors below.",

            label: "Colors"
          }
        },
        {
          className: "col-sm-12 col-md-8 box-input",
          type: "qrinput_color_theming",
          key: "color1,color2",
          templateOptions: {
            oneColor: options && options.oneColor ? options.oneColor : false,
            shape: options && options.shape ? options.shape : "round",
            themes: [],
            colors: options.customColors
          },
          controller: function ($scope) {
            if (options && options.customColors)
              $scope.options.templateOptions.themes = options.customColors;
          }
        }
      ]
    };
  },

  /**
   * Colors switch between 2 inputs
   *
   * @param [options] - information about the colorsSwitch
   * @param [options.color1] - color 1 code
   * @param [options.color2] - color 2 code
   * @param [options.oneColor] - hide the second color.
   * @param [options.primaryLabel] label for primary color.
   * @param [options.secondLabel] label for secondary color.
   * */
  colorsSwitch: function (options) {
    var defaultColor1 = "#455A64",
      defaultColor2 = "#e91e63";
    if (options && options.color1) defaultColor1 = options.color1;
    if (options && options.color2) defaultColor2 = options.color2;
    return {
      type: "field_group",
      fieldGroup: [
        {
          type: "qr_input_label",
          templateOptions: {
            isEmpty: true
          }
        },
        {
          type: "qrinput_colorpicker",
          key: "color1",
          templateOptions: {
            defaultColor: defaultColor1,
            title:
              options && options.primaryLabel ? options.primaryLabel : "Primary"
          }
        },
        {
          type: "qrinput_colorswap",
          templateOptions: {
            swapFrom: "color1",
            swapTo: "color2"
          },
          hideExpression: options && options.oneColor ? true : false
        },
        {
          type: "qrinput_colorpicker",
          key: "color2",
          className:
            "col-sm-12 col-lg-3 md:w-1/2 box-input mt-0 mb-10 col-lg-offset-0 col-md-offset-3",
          templateOptions: {
            defaultColor: defaultColor2,
            title:
              options && options.secondLabel ? options.secondLabel : "Button"
          },
          hideExpression: options && options.oneColor ? true : false
        }
      ]
    };
  },

  costumColor: function (options) {
    var defaultColor1 = "#455A64";
    return {
      type: "field_group",
      fieldGroup: [
        {
          type: "qr_input_label",
          templateOptions: {
            isEmpty: true
          }
        },
        {
          type: "qrinput_colorpicker",
          key: "color1",
          templateOptions: {
            defaultColor: defaultColor1,
            title:
              options && options.primaryLabel ? options.primaryLabel : "Costum"
          }
        }
      ]
      //   {
      //     type: "qrinput_colorpicker",
      //     key: "color2",
      //     className:
      //       "col-sm-12 col-lg-3 md:w-1/2 box-input mt-0 mb-10 col-lg-offset-0 col-md-offset-3",
      //     templateOptions: {
      //       defaultColor: defaultColor2,
      //       title:
      //         options && options.secondLabel ? options.secondLabel : "Button"
      //     },
      //     hideExpression: options && options.oneColor ? true : false
      //   }
      // ]
    };
  },

  /**
   * Divider line
   *
   *@param options - information about the divider
   *@param options.style - css style for the divider
   *@param options.class - css class for the divider
   * */
  divider: function (options) {
    var defaultSpace = "margin:40px 0 30px!important;";
    if (options && options.style) defaultSpace = options.style;
    let className = "";
    if (options && options.class) className = options.class;
    return {
      template: ""
    };
  },

  /**
   * Upload avatar with rectangular crop
   *
   * @param options - information about the uploader
   * @param options.defaultImage - default image
   * @param options.tooltip - tooltip for the uploader
   * @param options.key - key for the uploader
   * @param options.previewWelcomeScreen - preview welcome screen
   * @param options.defaultWelcomeImage - default welcome screen
   * @param [options.hideExpression]
   * @param [options.label] a label for the input.
   * @param [options.uploadId] ID of the upload block. Should be unique if there's several upload buttons on the same page.
   * @param [options.avatarExtraParam] name of the "avatar_extra" parameter, if any.
   * @param [options.className] optional class name to redefine the default one.
   * @param [options.resultImageSize] optional size of the result image in format [width, height], e.g. [640, 360].
   * @param [options.aspectRatio] aspect ratio for the crop tool.
   * @param [options.smallLabel] smallLabel, size of the image.
   * @param [options.enableEditing] enable editing feature, default is true.
   * @param [options.borderRadius] border radius for the image, if any.
   * @param [options.gallery] gallery of header images
   * @param [options.galleryImages] images for gallery
   * */
  avatarRectangular: function (options) {
    if (options.key == undefined) options.key = "avatar";
    let defaultImg = undefined,
      templateOptions = {
        template_varname: options.key,
        uploadId: options.uploadId || "uploadRectangle",
        buttonIcon: "icon-app-addimage"
      };

    if (options.defaultImage) {
      templateOptions = {
        template_varname: options.key,
        uploadId: options.uploadId || "uploadRectangle",
        buttonIcon: "icon-app-addimage",
        hasDefault: true,
        defaultImage: options.defaultImage
      };
      defaultImg = options.defaultImage;
    }
    const defaultAvatarTo = {
      backgroundImageSize: "100%",
      defaultImage: defaultImg,
      selectorId: options.uploadId || "uploadRectangle"
    };
    if ("avatarExtraParam" in options) {
      defaultAvatarTo.avatarExtraParam = options.avatarExtraParam;
    }
    if ("enableEditing" in options) {
      defaultAvatarTo.enableEditing = options.enableEditing;
    }
    if ("borderRadius" in options) {
      defaultAvatarTo.borderRadius = options.borderRadius;
    }
    if ("resultImageSize" in options) {
      templateOptions.resultImageSize = options.resultImageSize;
    }
    if ("aspectRatio" in options) {
      templateOptions.aspectRatio = options.aspectRatio;
      defaultAvatarTo.aspectRatio = options.aspectRatio;
      if (options.aspectRatio === 1) {
        defaultAvatarTo.squareAvatar = true;
      }
    }
    if ("hideOptionId" in options) {
      templateOptions.hideOptionId = options.hideOptionId;
    }
    if ("gallery" in options) {
      templateOptions.browseImage = true;
    } else templateOptions.browseImage = false;
    const template = {
      className:
        "className" in options
          ? options.className
          : "form-file-row mt20 rectangular-image-container flex-col sm:flex-row gap-x-8 sm:items-center",
      hideExpression:
        "hideExpression" in options ? options.hideExpression : "false",
      type: "field_group",
      fieldGroup: [
        {
          type: "qr_input_label",
          templateOptions: {
            tooltip: options.tooltip,
            label: "label" in options ? options.label : "Image",
            smallLabel: options.previewWelcomeScreen
              ? ""
              : options.smallLabel || "640  x 360 px",
            smallLabelVisible: true
          }
        },
        {
          type: "qrinput_avatar",
          className: "col-xs-12 col-sm-12 col-md-5 box-img pl0",
          key: options.key,
          templateOptions: defaultAvatarTo
        },
        {
          key: options.key,
          type: "qrupload_rectangle",
          templateOptions: templateOptions
        }
      ]
    };
    if ("gallery" in options) {
      template.fieldGroup.splice(0, 0, {
        type: "qrheader_gallery",
        templateOptions: {
          gallery: "gallery" in options ? options.gallery : "",
          images: "galleryImages" in options ? options.galleryImages : ""
        }
      });
    }
    return template;
  },

  /**
   * Simple Upload Pdf
   *
   * @param options - information about simple checkbox
   * @param options.initVal
   * @param options.label
   * @param options.key
   * */

  uploadPdfFile: function (options) {
    return {
      className:
        "form-file-row mt20 rectangular-image-container rectangular-image flex-col sm:flex-row sm:flex-wrap mt-4",
      type: "field_group",
      fieldGroup: [
        {
          type: "qr_upload_pdf",
          className: "col-xs-12 col-sm-12 col-md-5 box-img pl0 ",
          key: options.key,
          linkToPDFKey: options.linkToPDFKey
        }
      ]
    };
  },

  /**
   * Simple Upload Pdf
   *
   * @param options - information about simple checkbox
   * @param options.initVal
   * @param options.label
   * @param options.key
   * */

  uploadImagesFile: function (options) {
    return {
      className:
        "form-file-row mt20 rectangular-image-container rectangular-image flex-col sm:flex-row sm:flex-wrap mt-4",
      type: "field_group",
      fieldGroup: [
        {
          type: "qr_upload_images",
          className: "col-xs-12 col-sm-12 col-md-5 box-img pl0 ",
          key: options.key,
          useGridViewKey: options.useGridViewKey
        }
      ]
    };
  },

  /**
   * Upload welcome screen with free crop
   *
   * @param options - information about the uploader
   * @param options.defaultImage - default image
   * @param options.tooltip - tooltip for the uploader
   * @param options.key - key for the uploader
   * @param options.previewWelcomeScreen - preview welcome screen
   * @param options.defaultWelcomeImage - default welcome screen
   * */
  welcomeScreen: function (options) {
    const defaultImg = undefined,
      templateOptions = {
        template_varname: options.key,
        buttonIcon: "icon-app-addimage"
      };

    if (options.defaultImage) {
      templateOptions = {
        template_varname: options.key,
        buttonIcon: "icon-app-addimage",
        hasDefault: true,
        defaultImage: options.defaultImage
      };
      defaultImg = options.defaultImage;
    }
    const defaultAvatarTo = {
      backgroundImageSize: "",
      defaultImage: defaultImg
    };
    if (options.previewWelcomeScreen) {
      templateOptions["previewWelcomeScreen"] = true;
      templateOptions["defaultWelcomeImage"] = options.defaultWelcomeImage;
      templateOptions["welcomeImage"] = true;
      templateOptions["selectorId"] = "uploadWelcome";

      defaultAvatarTo["defaultWelcomeImage"] = options.defaultWelcomeImage;
      defaultAvatarTo["welcomeImage"] = true;
      defaultAvatarTo["selectorId"] = "uploadWelcome";
    }
    return {
      className:
        "form-file-row mt20 rectangular-image-container rectangular-image flex-col sm:flex-row sm:flex-wrap",
      type: "field_group",
      fieldGroup: [
        {
          type: "qr_input_label",
          templateOptions: {
            tooltip: options.tooltip,
            label: "Image",
            smallLabel: options.previewWelcomeScreen ? "" : "640  x 360 px",
            smallLabelVisible: true
          }
        },
        {
          type: "qrinput_avatar",
          className: "col-xs-12 col-sm-12 col-md-5 box-img pl0",
          key: options.key,
          templateOptions: defaultAvatarTo
        },
        {
          key: options.key,
          type: "qrupload_welcome",
          templateOptions: templateOptions
        }
      ]
    };
  },

  /**
   * Upload welcome screen with free crop
   *
   * @param options - information about the uploader
   * @param options.defaultImage - default image
   * @param options.tooltip - tooltip for the uploader
   * @param options.key - key for the uploader
   * @param options.previewWelcomeScreen - preview welcome screen
   * @param options.defaultWelcomeImage - default welcome screen
   * */
  uploadMp3File: function (options) {
    return {
      className:
        "form-file-row mt20 rectangular-image-container rectangular-image flex-col sm:flex-row sm:flex-wrap mt-4",
      type: "field_group",
      fieldGroup: [
        {
          type: "qr_upload_mp3",
          className: "col-xs-12 col-sm-12 col-md-5 box-img pl0 ",
          key: options.key,
          addDownloadOptionKey: options.addDownloadOptionKey
        }
      ]
    };
  },

  /**
   * Upload avatar with circle crop
   *
   * @param options - information about the uploader
   * @param options.tooltip - tooltip for the uploader
   * @param options.key - key for the uploader
   * */
  avatarCircle: function (options) {
    return {
      className:
        "field-group form-file-row w-full flex items-center gap-x-8 py-4",
      type: "field_group",
      fieldGroup: [
        {
          type: "qr_input_label",
          templateOptions: {
            tooltip: options.tooltip,
            label: "Image"
          }
        },
        {
          type: "qrinput_avatar",
          key: "avatar",
          templateOptions: {
            welcomeImage: false,
            circleAvatar: true,
            selectorId: "uploadCircle",
            aspectRatio: 1
          }
        },
        {
          key: "avatar",
          type: "qrupload",
          templateOptions: {
            template_varname: options.key,
            selectorId: "uploadCircle",
            aspectRatio: 1
          }
        }
      ]
    };
  },

  /**
   * Input with label row
   *
   * @param options - information about the input row
   * @param options.key - key of the input
   * @param options.label - label of the input
   * @param options.placeholder - placeholder of the input
   * @param options.tooltip - tooltip
   * @param [options.showCharCount] show the number of chars in the input.
   * @param [options.hideExpression] hideExpression for this input.
   * @param [options.tooltip] optional tooltip.
   * */
  inputRow: function (options) {
    return {
      className: "form-row-container flex flex-col",
      type: "field_group",
      fieldGroup: [
        {
          type: "qr_input_label",
          templateOptions: {
            label: options.label,
            tooltip: options.tooltip || false
          }
        },
        {
          type: "qrinput",
          key: options.key,
          templateOptions: {
            placeHolder: options.placeholder,
            maxlength: options.maxlength || 500,
            showCharCount:
              "showCharCount" in options ? options.showCharCount : false,
            validators: options?.validators ?? false,
            filters: options?.filters ?? false
          }
        }
      ],
      hideExpression: options.hideExpression || null
    };
  },

  /**
   * Text area with label row
   *
   * @param options - information about the text area row
   * @param options.key - key of the input
   * @param options.label - label of the input
   * @param options.placeholder - placeholder of the input
   * @param options.maxChar - maximum characters
   * @param [options.tooltip] optional tooltip.
   * */
  textAreaRow: function (options) {
    return {
      className: "form-input-row form-row-container flex flex-col",
      type: "field_group",
      fieldGroup: [
        {
          type: "qr_input_label",
          templateOptions: {
            label: options.label,
            tooltip: options.tooltip || false
          }
        },
        {
          type: "qrinput_textarea",
          key: options.key,
          templateOptions: {
            maxChar: options.maxChar,
            placeHolder: options.placeholder,
            "@keydown.enter": "handleEnterKey",
            validators: options?.validators ?? false,
            filters: options?.filters ?? false
          }
        }
      ]
    };
  },

  /**
   * Input with label and required property row
   *
   * @param options - information about the input row
   * @param options.key - key of the input
   * @param options.className - class name of the row
   * @param options.label - label of the input
   * @param options.placeholder - placeholder of the input
   * @param options.condition - condition of the required
   * @param options.errorMsgTitle - error message
   * @param options.maxlength - max length
   * @param options.tooltip - tooltip
   * @param options.isFacebook - search if it is facebook url
   * */
  inputRowRequired: function (options) {
    var inputType = "qrinput";
    if (options.isFacebook) inputType = "qrfacebookinput";

    return {
      className: options.className,
      type: "field_group",
      fieldGroup: [
        {
          type: "qr_input_label",
          templateOptions: {
            label: options.label,
            required: true,
            tooltip: options.tooltip || false
          }
        },
        {
          type: inputType,
          key: options.key,
          templateOptions: {
            placeHolder: options?.placeholder ?? "",
            errorMessage: options?.errorMsgTitle ?? "",
            maxlength: options?.maxlength ?? 500,
            isFacebook: options?.isFacebook ?? false,
            validators: options?.validators ?? false,
            filters: options?.filters ?? false,
            required: true
          }
        }
      ]
    };
  },

  /**
   * 2 inputs in one row
   *
   * @param options - information about the row
   * @param options.label - label of th row
   * @param options.firstInput
   * @param options.secondInput
   * */
  rowWith2Inputs: function (options) {
    const obj = {
      className:
        "form-input-row form-row-container flex flex-col flex-wrap twoInputs gap-x-3",
      type: "field_group",
      fieldGroup: [
        {
          type: "qr_input_label",
          templateOptions: {}
        },
        options.firstInput,
        options.secondInput
      ]
    };
    if (options.label)
      obj["fieldGroup"][0]["templateOptions"]["label"] = options.label;
    else obj["fieldGroup"][0]["templateOptions"]["isEmpty"] = true;
    return obj;
  },

  /**
   * Input half width
   *
   * @param options - information about the input
   * @param options.key - key of the input
   * @param options.required - boolean
   * @param options.placeholder - input placeholder
   * @param options.errorHolder - error holder
   * @param options.errorMsg - error message
   * @param options.condition
   * */
  inputHalf: function (options) {
    const obj = {
      type: "qrinput_half",
      key: options.key,
      className: options.className,
      templateOptions: {
        placeHolder: options.placeholder,
        validators: options?.validators ?? false,
        filters: options?.filters ?? false
      }
    };
    if (options.required) obj["templateOptions"]["required"] = options.required;
    if (options.errorHolder)
      obj["templateOptions"]["errorHolder"] = options.errorHolder;
    if (options.errorMsg)
      obj["templateOptions"]["errorMessage"] = options.errorMsg;
    if (options.condition) {
      obj["expressionProperties"] = {};
      obj["expressionProperties"]["templateOptions.required"] =
        options.condition;
    }
    return obj;
  },

  /**
   * Button with CallToAction dropdown and url input
   *
   * @param options - information about the callToAction plugin
   * @param options.key - key of the input
   * @param options.errorMsgTargetUrl - error message for the url input
   * */
  callToAction: function (options) {
    return {
      className: "form-input-row form-row-container",
      type: "field_group",
      fieldGroup: [
        {
          type: "qrbutton_call_action",
          key: options.key,
          templateOptions: {
            tooltip:
              "Link to your registration form or ticketing website. This button will appear below your event summary.",
            placeHolder: "Third Party URL",
            errorMessage: options.errorMsgTargetUrl
          }
        }
      ]
    };
  },

  /**
   * Time plugin. This plugin has the option to select start date, start time, end date, end time and also an all day checkbox
   *
   * @param options - information about the time plugin
   * @param options.key - key of the plugin
   * @param options.keyRequired - boolean key required
   * @param options.label - label of the plugin
   * @param options.labelRequired - boolean label required
   * @param options.allDay - all day checkbox value
   * @param options.errorMsg - error message
   * @param options.condition - condition of the required
   * @param [options.startDateOnly] this option hides the second date.
   * @param [options.endDateOnly] this option hides the second date.
   * @param [options.allDayLabel] a label for the AllDay checkbox or an empty string to hide it.
   * */
  timeInput: function (options) {
    return {
      className: "form-row-container flex-col",
      type: "field_group",
      fieldGroup: [
        {
          type: "qr_input_label",
          templateOptions: {
            label: options.label,
            required: options.labelRequired
          }
        },
        {
          type: "qrdate_time",
          key: options.key,
          templateOptions: {
            required: options.keyRequired,
            allDay: options.allDay,
            label: "allDayLabel" in options ? options.allDayLabel : "All day",
            errorMessage: options.errorMsg,
            startDateOnly:
              "startDateOnly" in options ? options.startDateOnly : false,
            endDateOnly: "endDateOnly" in options ? options.endDateOnly : false
          },
          expressionProperties: {
            "templateOptions.required": options.condition
          }
        }
      ]
    };
  },

  /**
   * Autocomplete address input with Google API
   *
   * @param options - information about the autocomplete
   * @param options.key - key of the plugin
   * @param options.label - label of the plugin
   * @param options.placeholder - input placeholder
   * @param options.condition - condition of the hide expression
   * @param [options.tooltip] optional tooltip.
   * */
  autocompleteAddress: function (options) {
    return {
      className: "form-row-container relative",
      type: "field_group",
      fieldGroup: [
        {
          type: "qr_input_label",
          templateOptions: {
            label: options.label,
            tooltip: options.tooltip || false
          },
          hideExpression: options.condition
        },
        {
          type: "qr_toggle_button",
          key: options.key,
          templateOptions: {
            label: options.placeholder
          },
          hideExpression: options.condition,
          className: "absolute right-0 top-4"
        }
      ]
    };
  },

  /**
   * Autocomplete address detailed inputs
   *
   * @param options - information about the autocomplete full address
   * @param options.wrappedInAddressKey - boolean. If the parent object is address
   * @param [options.tooltip] optional tooltip.
   * @param [options.label] optional label.
   * @param [options.condition] optional condition.
   * */
  autocompleteInputForm: function (options) {
    const hideExpression = options.condition;
    const autocompleteInputForm = [
        {
          type: "qr_input_label",
          templateOptions: {
            label: options.label || "Address",
            tooltip: options.tooltip || false
          }
        },
        {
          className: "box-input flex-4",
          type: "qrinput",
          key: !options.wrappedInAddressKey ? "street" : "address.street",
          templateOptions: {
            placeHolder: "Street address",
            id: "route"
          }
        },
        {
          className: "box-input flex-2",
          type: "qrinput",
          key: !options.wrappedInAddressKey
            ? "street_number"
            : "address.street_number",
          templateOptions: {
            placeHolder: "Number",
            id: "street_number"
          }
        }
      ],
      autocompleteAddressForm = [
        {
          type: "qr_input_label",
          templateOptions: {
            isEmpty: true
          }
        },
        {
          className: "sm:w-full md:w-1/3 box-input",
          type: "qrinput",
          key: !options.wrappedInAddressKey ? "city" : "address.city",
          templateOptions: {
            placeHolder: "City",
            id: "locality"
          }
        },
        {
          className: "flex-3 box-input",
          type: "qrinput",
          key: !options.wrappedInAddressKey ? "state" : "address.state",
          templateOptions: {
            placeHolder: "State",
            id: "administrative_area_level_1"
          }
        },
        {
          className: "flex-3 box-input",
          type: "qrinput",
          key: !options.wrappedInAddressKey ? "zip" : "address.zip",
          templateOptions: {
            placeHolder: "ZIP",
            id: "postal_code"
          }
        },
        {
          type: "qr_input_label",
          templateOptions: {
            isEmpty: true
          }
        },
        {
          className: "flex-3 box-input",
          type: "qrinput",
          key: !options.wrappedInAddressKey ? "country" : "address.country",
          templateOptions: {
            placeHolder: "Country",
            id: "country"
          }
        }
        // {
        //   type: "qr_reset_address"
        // }
      ];
    return {
      className: !options.wrappedInAddressKey
        ? "fullAddress-container "
        : "fullAddress-container",
      type: "field_group",
      hideExpression,
      fieldGroup: [
        {
          className:
            "form-row-container form-input-row form-row-container flex flex-col flex-wrap twoInputs gap-x-3",
          type: "field_group",
          fieldGroup: autocompleteInputForm
        },
        {
          className:
            "form-row-container flex-wrap form-input-row form-row-container flex flex-col flex-wrap twoInputs gap-x-3 ",
          type: "field_group",
          fieldGroup: autocompleteAddressForm
        }
      ]
    };
  },

  /**
   * Show directions checkbox
   *
   * @param options - information about the show directions checkbox
   * @param options.key - key of the checkbox
   * @param options.initValue - initial value of the checkbox
   * @param options.placeholder
   * @param options.label
   * @param options.hideExpression
   * */
  directionsButton: function (options) {
    return {
      className: "form-row-container",
      type: "field_group",
      fieldGroup: [
        {
          className: "col-lg-offset-1 col-sm-12 col-md-3 box-label",
          type: "qr_input_label",
          templateOptions: {
            isEmpty: true
          }
        },
        {
          type: "qrinput_checkbox",
          key: options.key,
          templateOptions: {
            initValue: options.initValue,
            placeHolder: options.placeholder,
            trueValue: true,
            falseValue: false,
            label: options.label
          }
        }
      ],
      hideExpression: options.hideExpression
    };
  },

  /**
   * Features plugin.
   *
   * @param options - information about the features
   * @param options.key - key of the plugin
   * @param [options.label] label
   * @param [options.text] some help message to show
   * */
  features: function (options) {
    var featuresOptions = {};
    if (options.text) {
      featuresOptions.label = options.text;
    }
    return {
      className: "form-row-container flex-col",
      type: "field_group",
      fieldGroup: [
        {
          type: "qr_input_label",
          templateOptions: {
            isEmpty: !options.label,
            label: options.label || ""
          }
        },
        {
          type: "qrfeatures",
          key: options.key,
          templateOptions: featuresOptions
        }
      ]
    };
  },

  /**
   * Social media channels plugin
   *
   * @param options - information about the social media channels
   * @param options.key - key of media channels
   * @param options.requiredAllChannels - boolean if all channels are required
   * @param options.requiredMessage - required message
   * @param options.hasLabel - show/hide label input
   * @param options.hasOrder - show/hide order functions
   * @param [options.sortable] - true if the channels are sortable
   * @param [options.showLabels] - show the channels labels
   * @param [options.helpMessage] - set a help message or hide it with false/empty value
   * */
  socialChannels: function (options) {
    var templateOptions = {
      requiredAllChannels: options.requiredAllChannels,
      requiredMessage: options.requiredMessage,
      filters: options?.filters ?? false,
      textFilters: options?.textFilters ?? false,
      withTextInputs: options?.withTextInputs ?? false,
      validators: options?.validators ?? false,
      textValidators: options?.textValidators ?? false
    };
    if ("sortable" in options) {
      templateOptions.sortable = options.sortable;
      templateOptions.hasOrder = options.sortable;
    }
    if ("showLabels" in options) {
      templateOptions.showLabels = options.showLabels;
      templateOptions.hasLabel = options.showLabels;
    }
    if ("helpMessage" in options) {
      templateOptions.helpMessage = options.helpMessage;
    }
    if ("hasLabel" in options) {
      templateOptions.showLabels = options.hasLabel;
      templateOptions.hasLabel = options.hasLabel;
    }
    if ("hasOrder" in options) {
      templateOptions.sortable = options.hasOrder;
      templateOptions.hasOrder = options.hasOrder;
    }
    return {
      className: "form-input-row form-row-container",
      type: "field_group",
      fieldGroup: [
        {
          type: "qr_media_channels",
          key: options.key,
          templateOptions: templateOptions
        }
      ]
    };
    if ("gallery" in options) {
      template.fieldGroup.splice(0, 0, {
        type: "qrheader_gallery",
        templateOptions: {
          gallery: "gallery" in options ? options.gallery : "",
          images: "galleryImages" in options ? options.galleryImages : ""
        }
      });
    }
  },

  /**
   * App Store Links plugin
   *
   * @param options - information about the social media channels
   * @param options.key - key of media channels
   * @param options.requiredAllChannels - boolean if all channels are required
   * @param options.requiredMessage - required message
   * @param options.hasLabel - show/hide label input
   * @param options.hasOrder - show/hide order functions
   * @param [options.sortable] - true if the channels are sortable
   * @param [options.showLabels] - show the channels labels
   * @param [options.helpMessage] - set a help message or hide it with false/empty value
   * */
  appStoreLinks: function (options) {
    var templateOptions = {
      requiredAllStores: options.requiredAllStores,
      requiredMessage: options.requiredMessage,
      filters: options.filters,
      validators: options.validators
    };
    // if ("sortable" in options) {
    //   templateOptions.sortable = options.sortable;
    //   templateOptions.hasOrder = options.sortable;
    // }
    // if ("showLabels" in options) {
    //   templateOptions.showLabels = options.showLabels;
    //   templateOptions.hasLabel = options.showLabels;
    // }
    // if ("helpMessage" in options) {
    //   templateOptions.helpMessage = options.helpMessage;
    // }
    // if ("hasLabel" in options) {
    //   templateOptions.showLabels = options.hasLabel;
    //   templateOptions.hasLabel = options.hasLabel;
    // }
    // if ("hasOrder" in options) {
    //   templateOptions.sortable = options.hasOrder;
    //   templateOptions.hasOrder = options.hasOrder;
    // }
    return {
      className: "form-input-row form-row-container",
      type: "field_group",
      fieldGroup: [
        {
          type: "qr_app_store_links",
          key: options.key,
          templateOptions: templateOptions
        }
      ]
    };
    // if ("gallery" in options) {
    //   template.fieldGroup.splice(0, 0, {
    //     type: "qrheader_gallery",
    //     templateOptions: {
    //       gallery: "gallery" in options ? options.gallery : "",
    //       images: "galleryImages" in options ? options.galleryImages : ""
    //     }
    //   });
    // }
  },

  /**
   * Rating section
   *
   * @param options - information about rating section
   * @param options.key - rating key
   * @param options.tooltip - rating key
   * */
  rating: function (options) {
    return {
      className: "form-row-container",
      key: options.key,
      type: "field_group",
      fieldGroup: [
        {
          type: "qr_input_label",
          templateOptions: {
            tooltip: options.tooltip,
            label: "Question"
          }
        },
        {
          type: "qrinput",
          key: "question",
          templateOptions: {
            placeHolder: "e.g. How would you rate our service?",
            errorMessage: "Please provide a question.",
            maxlength: 500
          },
          expressionProperties: {
            "templateOptions.required": "!model.code.rating.question"
          }
        },
        {
          type: "qrrating",
          key: "type"
        }
      ]
    };
  },

  /**
   * Body advanced options section
   *
   * @param options - information about advanced options section
   * @param options.sharingKey - sharing key
   * @param options.sharingLabel - sharing input placeholder
   * @param options.labelKey - label key
   * @param options.labelPlaceholder - label placeholder
   * @param options.labelTooltip -label tooltip
   * @param options.labelValue - label value
   * @param options.initValue - init value of the checkbox
   * */
  bodyAdvancedOptions: function (options) {
    var fields = [];
    if (options.sharingKey) {
      fields.push({
        className: "col-lg-offset-1 col-sm-12 col-md-3 box-label",
        type: "qr_input_label",
        templateOptions: {
          label: "Sharing"
        }
      });
      fields.push({
        className: "col-sm-12 col-md-8 box-input mt15",
        type: "qrinput_checkbox",
        key: options.sharingKey,
        templateOptions: {
          placeHolder: options.labelPlaceholder,
          label: options.sharingLabel || "Add a share button to the page",
          trueValue: true,
          initValue: options.initValue
        }
      });
    }
    return {
      type: "field_group",
      fieldGroup: fields
    };
  },

  /**
   * Advanced options section
   *
   * @param options - information about advanced options section
   * @param options.sharingKey - sharing key
   * @param options.sharingLabel - sharing input placeholder
   * @param options.labelKey - label key
   * @param options.labelPlaceholder - label placeholder
   * @param options.labelTooltip -label tooltip
   * @param options.labelValue - label value
   * @param options.initValue - init value of the checkbox
   * */
  container_advancedOptions: function (options) {
    return this.container(
      this.header({
        sectionName: "section_advance_options",
        fold: true,
        headline: "Advanced Options",
        icon: "CogIcon"
      }),
      this.body({
        name: "section_advance_options",
        collapseKey: "section_advance_options",
        key: "code",
        content: [this.bodyAdvancedOptions(options)]
      })
    );
  },

  /**
   * Social media links
   *
   * @param options - information about social media links
   * @param options.key
   * */
  socialMediaLinks: function (options) {
    return {
      key: options.key,
      type: "field_group",
      fieldGroup: [
        {
          type: "advanced_socialmedia_icons"
        }
      ]
    };
  },

  /**
   * youtube verify channel name
   *
   * @param options - information about social media links
   * @param options.key
   * @param options.errorMsgTargetUrl
   * */
  youtubeSubscribeBtn: function (options) {
    return {
      className: "form-input-row form-row-container",
      type: "field_group",
      fieldGroup: [
        {
          type: "qryoutube_channel",
          key: options.key,
          templateOptions: {
            tooltip: "Add youtube ID to activate subscribe button",
            placeHolder: "Youtube channel url, user or ID",
            errorMessage: options.errorMsgTargetUrl
          }
        }
      ]
    };
  },

  /**
   * youtube add videos from youtube
   *
   * @param options - information about social media links
   * @param options.key
   * @param options.errorMessage
   * @param options.highlightedTooltip
   * @param options.addTooltip
   * @param options.requiredOneVideo
   * @param options.requiredMessage
   * */
  addYTVideo: function (options) {
    return {
      className: "form-input-row form-row-container",
      type: "field_group",
      fieldGroup: [
        {
          type: "qryoutube_video_add",
          key: options.key,
          highlightVideoKey: options.highlightVideoKey,
          templateOptions: {
            errorMessage: options.errorMessage,
            highlightedTooltip: options.highlightedTooltip,
            addTooltip: options.addTooltip,
            requiredOneVideo: options.requiredOneVideo,
            requiredMessage: options.requiredMessage,
            buttonLabel: options.buttonLabel
          }
        }
      ]
    };
  },

  /**
   * Simple checkbox
   *
   * @param options - information about simple checkbox
   * @param options.initVal
   * @param options.label
   * @param options.key
   * */
  checkbox: function (options) {
    return {
      className: "form-input-row form-row-container",
      type: "field_group",
      fieldGroup: [
        {
          type: "qr_simplecheckbox",
          key: options.key,
          templateOptions: {
            initValue: options.initVal,
            trueValue: true,
            falseValue: false,
            label: options.label
          }
        }
      ]
    };
  }
};
