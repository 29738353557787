import { forEachObjIndexed } from "ramda";
import WithRender from "./QRActionButton.html";
import { isValidHttpUrl } from "@/helpers/helpers";
import PencilIcon from "../icons/PencilIcon";

export default WithRender({
  props: ["form", "field", "model"],
  components: {
    PencilIcon
  },
  created() {
    this.value = this.model[this.name] ?? this.defaultValue;
  },
  mounted() {
    this.initializeDropdown();
  },

  data() {
    return {
      showSection: true,
      selectedValue:
        this?.field?.templateOptions?.defaultValue?.label ??
        this.$t("Learn More"),
      showAddButton: false,
      showRemoveButton: true,
      value: {
        label: "",
        defaultLabel: this.$t("Default Label"),
        url: ""
      },
      dropdown: null,
      errorMessage: ""
    };
  },
  methods: {
    initializeDropdown() {
      const targetEl = this.$refs.dropdownItems;
      const triggerEl = this.$refs.dropdownOpenBtn;
      const options = {
        placement: "bottom"
      };
      this.dropdown = new Dropdown(targetEl, triggerEl, options);
    },
    onUpdateValue() {
      const { name, value } = this;
      this.validate();
      this.$emit("update", { name, value });
    },
    onCustomInputChange(event) {
      this.selectedValue = event.target.value;
      this.value.label = this.selectedValue;
      this.onUpdateValue();
    },
    onOpenDropdown() {
      if (this.dropdown) {
        this.dropdown.show();
      }
    },
    onSelectItem(item) {
      this.selectedValue = item;
      this.value.label = item;
      this.dropdown?.hide();
      this.onUpdateValue();
    },
    onSelectCustom() {
      this.value.label = this.getSelectedValue();
      this.dropdown?.hide();

      const customInput = this.$refs.customInput;
      customInput.select();

      this.onUpdateValue();
    },
    handleChange(event) {
      this.value.url = this.applyFilters(event.target.value);
      this.onUpdateValue();
    },
    onRemoveClicked() {
      this.errorMessage = "";
      this.selectedValue = "";
      this.value.label = "";
      this.showRemoveButton = false;
      this.showAddButton = true;
      this.onUpdateValue();
      this.showSection = false;
    },
    onAddClicked() {
      if (this.items.length > 0) {
        this.errorMessage = "";
        this.selectedValue = this.items[0];
        this.value.label = this.items[0];
        this.showRemoveButton = true;
        this.showAddButton = false;
        this.onUpdateValue();
        this.onOpenDropdown();
      }
      this.showSection = true;
      this.$nextTick(() => {
        this.initializeDropdown();
      });
    },
    getSelectedValue() {
      // if (!this.value.label) {
      //   return "";
      // }
      for (const item of this.items) {
        if (item === this.value.label) return item;
      }
      // return "Custom";
    },
    applyFilters(value) {
      let newValue = value;
      const { name } = this;
      const filters = this?.field?.templateOptions?.filters ?? {};
      forEachObjIndexed((filter, key) => {
        newValue = filter({ name, value: newValue });
      }, filters);
      return newValue;
    },
    validate() {
      let valid = true;
      const { name } = this;
      const validators = this?.field?.templateOptions?.validators ?? {};
      const messages = [];
      forEachObjIndexed((validator, key) => {
        valid = valid && validator({ messages, name, value: this.value.url });
      }, validators);
      this.errorMessage = "";
      if (!valid) {
        this.errorMessage = messages?.[0] ?? "";
      }
      return valid;
    }
  },
  computed: {
    name() {
      return this.field.key;
    },
    required() {
      return this.field.templateOptions.required || false;
    },
    hasValidators() {
      const validators = this?.field?.templateOptions?.validators ?? {};
      return Object.keys(validators).length > 0;
    },
    inputListeners: function () {
      var vm = this;
      return Object.assign({}, this.$listeners, {
        validate: event => {
          if (!this.validate(event))
            this.$emit("validate", { element: event.target, event });
        }
      });
    },
    placeHolder() {
      return this.field.templateOptions.urlPlaceholder;
    },
    className() {
      return this.field.className;
    },
    defaultValue() {
      return this.field.templateOptions.defaultValue;
    },
    items() {
      return this.field.templateOptions.items;
    }
  },
  watch: {
    showSection(newValue, oldValue) {
      if (!newValue) {
        // Nu distrugeți dropdown-ul aici
        // this.dropdown.destroy();
        // this.dropdown = null;
      } else {
        // Afișați dropdown-ul existent dacă este nevoie
        if (this.dropdown) {
          this.dropdown.show();
        }
      }
    },
    model: {
      handler(newValue, oldValue) {
        if (oldValue[this.name] == newValue[this.name]) return;
        this.selectedValue = newValue?.[this.name]?.label;
        this.value.label = newValue?.[this.name]?.label;
        this.value.url = newValue?.[this.name]?.url;
      },
      deep: true
    }
  }
});
