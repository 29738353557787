import baseField from "vue-formly-bootstrap/src/fields/baseField";

import WithRender from "./QRInputHeadlineIcon.html";

export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  created() {},
  computed: {
    name() {
      return this.field.templateOptions.icon;
    }
  }
});
