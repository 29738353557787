import WithRender from "./TypeCardItem.html";
import QRIcon from "../../components/base/form/QRIcon";
import { mapActions, mapGetters } from "vuex";

export default WithRender({
  props: {
    name: {
      Type: String,
      default: ""
    },
    code: {
      Type: String,
      default: ""
    },
    id: {
      Type: BigInt,
      default: 1
    },
    description: {
      Type: String,
      default: ""
    },
    icon: {
      Type: String,
      default: ""
    },
    selected: {
      Type: Boolean,
      default: false
    },
    model: {
      Type: String
    },
    value: {
      Type: String,
      default: ""
    },
    type: {
      Type: String,
      default: ""
    }
  },
  components: {
    QRIcon
  },
  mounted() {
    const payload = {
      type: this.getCardType
    };
    this.changeCardType(payload);
  },
  computed: {
    ...mapGetters(["getCardType"]),
    isSelected() {
      return this.getCardType == this.code;
    }
  },
  methods: {
    ...mapActions(["changeCardType"]),
    onSelectCardType(event) {
      event.preventDefault();
      const payload = {
        type: this.type
      };
      this.changeCardType(payload);
    }
  }
});
