import { mutationSentences } from "../gql";
import { computeBackendEndpoint } from "../../helpers/envHelpers";
const USER_TOKEN_NAME = "user-token";

const getModule = (queryAPI, mutationAPI) => {
  const state = {};

  const getters = {};

  const mutations = {
    uploadLogoToServer(state, file) {
      const data = new FormData();
      data.append(
        "operations",
        `{
    
          "query": "mutation ($file: Upload!) { uploadLogo(file: $file){id url} }",
    
          "variables": {
            "file": null
          }
        }`
      );
      data.append("0", file);
      data.append("map", '{"0":["variables.file"]}');
      const token = localStorage.getItem("user-token");

      const url = computeBackendEndpoint(backendBaseUrl, backendGraphql);
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      };

      return axios.post(url, data, { headers }).then(({ data }) => data.data);
    }
  };

  const actions = {
    uploadFileToServerAsBlob(_, blob) {
      return mutationAPI({
        // Query
        mutation: mutationSentences.uploadFileBlob,
        // Parameters
        variables: {
          blob,
          name: "test.jpg"
        },
        debounce: 700
      }).then(data => data.uploadFileBlob);
    },
    uploadFileToServer(_, file) {
      return mutationAPI({
        // Query
        mutation: mutationSentences.uploadFile,
        // Parameters
        variables: {
          file
        },
        context: {
          hasUpload: true
        },
        debounce: 700
      }).then(data => data.uploadFile);
    },
    uploadLogoToServer(_, file) {
      return mutationAPI({
        // Query
        mutation: mutationSentences.uploadLogo,
        // Parameters
        variables: {
          file
        },
        context: {
          hasUpload: true
        },
        debounce: 700
      });
    }
  };

  return {
    state,
    getters,
    mutations,
    actions
  };
};
export default getModule;
