<div class="m-auto w-full">
  <div class="w-full flex overflow-hidden">
    <div class="flex-grow flex-shrink couponTypes">
      <div class="w-full">
        <ul
          class="w-full relative couponTypesMainContainer flex w-full flex-row flex-wrap gap-y-2 justify-evenly"
          role="tablist"
        >
          <TypeCardItem
            v-for="qrtype in getCategories"
            :value="qrtype.name"
            :id="qrtype.id"
            :code="qrtype.code"
            :type="qrtype.code"
            :name="qrtype.name"
            :selected="selectType == qrtype.name"
          >
          </TypeCardItem>
          <li></li>
        </ul>
      </div>
    </div>
  </div>
</div>
