import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";
import en from "../translation/en.json";
import de from "../translation/de.json";

var loadedDefaults = false;

export const setLanguageAttributes = language => {
  if (language.lang) {
    i18n.locale = language.lang;
  }
  if (language.fallback) {
    i18n.fallback = language.fallback;
  }

  if (language.translations) {
    if (loadedDefaults) {
      i18n.mergeLocaleMessage(i18n.locale, language.translations);
    } else {
      i18n.setLocaleMessage(i18n.locale, language.translations);
    }
  } else if (language.files) {
    var axiosRequests = [];
    var langIndex = {};
    var counter = 0;
    for (var lang in language.files) {
      axiosRequests.push(axios.get(language.files[lang]));
      langIndex[counter++] = lang;
    }

    axios
      .all(axiosRequests)
      .then(
        axios.spread((...responses) => {
          for (var response in responses) {
            if (langIndex[response] == i18n.locale && loadedDefaults) {
              i18n.mergeLocaleMessage(
                langIndex[response],
                responses[response].data
              );
            } else {
              i18n.setLocaleMessage(
                langIndex[response],
                responses[response].data
              );
            }
          }
        })
      )
      .catch(errors => {
        console.log("Can not get language file ");
        console.log(errors);
      });
  }
};

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  silentTranslationWarn: true,
  messages: { en, de }
});
