import { mapActions, mapGetters } from "vuex";

import WithRender from "./ManagePage.html";
import MainLayout from "../components/ui/layouts/MainLayout";
import WidgetLayout from "../components/ui/layouts/WidgetLayout";
import CardListItem from "../components/ui/cards/CodeListItem";
import PlusIcon from "../components/base/icons/PlusIcon";
import ArrowDownIcon from "../components/base/icons/ArrowDownIcon";
import ArrowLeftIcon from "../components/base/icons/ArrowLeftIcon";

import ManageOptions from "../components/base/dropdown/manage/Options";
import ManageTimeRange from "../components/base/dropdown/manage/TimeRange";
import ManageTimeUnit from "../components/base/dropdown/manage/TimeUnit";
import ScanOverTimeChart from "../components/ui/chart/ScanOverTime";
import ScanByOSChart from "../components/ui/chart/ScanByOS";
import ScanByTopCities from "../components/ui/chart/ScanByTopCities";
import ScanByTopCountries from "../components/ui/chart/ScanByTopCountries";
import SettingsModal from "../components/ui/modal/manage/SettingsModal";
import { exportToCSV } from "../services/exportToCSV";

export default WithRender({
  components: {
    MainLayout,
    CardListItem,
    WidgetLayout,
    ArrowDownIcon,
    ArrowLeftIcon,
    PlusIcon,
    ManageOptions,
    ManageTimeRange,
    ManageTimeUnit,
    ScanOverTimeChart,
    ScanByOSChart,
    SettingsModal,
    ScanByTopCities,
    ScanByTopCountries
  },
  data() {
    return {
      modal: null,
      optionsDropdown: null,
      showMore: false,
      showModal: false,
      settingsModal: {
        medium: "",
        print: "",
        start: "",
        end: ""
      }
    };
  },
  mounted() {
    this.routerActions({
      page: "management",
      params: { id: this.getCurrentId }
    });
  },
  computed: {
    ...mapGetters([
      "getWidgetMode",
      "getTotalScans",
      "getUniqueScans",
      "getPlatforms",
      "getCountries",
      "getCities",
      "getOvertimes",
      "getCurrentQr",
      "getAnalysisUnit",
      "getAnalysisRange"
    ]),
    getPlatformData() {
      const total = this.getPlatforms.reduce((s, c) => s + +c.num, 0);
      return this.getPlatforms.map(p => ({
        ...p,
        ratio: ((p.num * 100) / total).toFixed(2)
      }));
    },
    getCountriesData() {
      const total = this.getCountries.reduce((s, c) => s + +c.num, 0);
      return this.getCountries.map(p => ({
        ...p,
        ratio: ((p.num * 100) / total).toFixed(2)
      }));
    },
    getCitiesData() {
      const total = this.getCities.reduce((s, c) => s + +c.num, 0);
      return this.getCities.map(p => ({
        ...p,
        ratio: ((p.num * 100) / total).toFixed(2)
      }));
    },
    getCurrentId() {
      return this.$route.params.id;
    },
    getCurrentUpdatedTime() {
      return this.getCurrentQr.updated_at || this.getCurrentQr.created_at;
    },
    getCurrentCreatedTime() {
      return this.getCurrentQr.created_at || this.getCurrentQr.created_at;
    },
    datetype: {
      get() {
        return this.getAnalysisUnit;
      },
      set(v) {
        this.updateAnalysisUnit(v);
      }
    },
    dateRange: {
      get() {
        return this.getAnalysisRange;
      },
      set(v) {
        this.updateAnalysisRange(v);
      }
    }
  },
  methods: {
    ...mapActions([
      "routerActions",
      "updateAnalysisUnit",
      "resetQrScans",
      "updateAnalysisRange"
    ]),
    onShowMoreClick() {
      this.showMore = !this.showMore;
    },
    onShowModal() {
      this.showModal = true;
    },
    onPreviousClicked() {
      this.$router.push({ name: "home" });
    },
    async onAction(type) {
      console.log("[type]", type);
      switch (type) {
        case "downloadCSV": {
          console.log("[getOvertimes]", this.getOvertimes);
          exportToCSV(this.getOvertimes);
          break;
        }
        case "resetScan": {
          const response = await this.$swal({
            title: "Are you sure ?",
            text: "The QR State will be reset!",
            type: "inform",
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            closeOnConfirm: false,
            closeOnCancel: false
          });
          if (response.isConfirmed) {
            this.resetQrScans({ id: this.getCurrentId });
          }
          break;
        }
      }
    }
  }
});
