import DateRangePicker from "vue2-daterange-picker";
import dayjs from "dayjs";

import WithRender from "./TimeRange.html";
import DownloadIcon from "../../icons/DownloadIcon";
import SwapIcon from "../../icons/SwapIcon";
import CogIcon from "../../icons/CogIcon";

import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default WithRender({
  props: {
    value: {
      type: Object,
      default: {
        startDate: new Date(),
        endDate: new Date()
      }
    },
    start: {
      type: String,
      default: ""
    }
  },
  components: {
    DownloadIcon,
    SwapIcon,
    CogIcon,
    DateRangePicker
  },
  data() {
    return {
      optionsDropdown: null,
      dateRange: {}
    };
  },
  mounted() {
    const optionsTrig = this.$refs.optionsBtn;
    const optionsTgt = this.$refs.optionsMenu;
    this.optionsDropdown = new window.Dropdown(optionsTgt, optionsTrig, {
      placement: "bottom"
    });
    this.inputValue = {
      startDate: dayjs(this.start).toDate(),
      endDate: dayjs(this.start).add(30, "day").toDate()
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    onSelectRange(key) {
      const rangePicker = this.$refs.rangePicker;
      this.$refs.rangePicker.togglePicker(true);

      switch (key) {
        case "auto":
          this.inputValue = {
            startDate: dayjs(this.start).toDate(),
            endDate: dayjs(this.start).add(14, "day").toDate()
          };
          break;
        case "48hours":
          this.inputValue = {
            startDate: dayjs().subtract(48, "hour").toDate(),
            endDate: new Date()
          };
          break;
        case "30days":
          this.inputValue = {
            startDate: dayjs().subtract(30, "day").toDate(),
            endDate: new Date()
          };
          break;
        case "12months":
          this.inputValue = {
            startDate: dayjs().subtract(12, "month").toDate(),
            endDate: new Date()
          };
          break;
        case "custom":
          if (rangePicker) {
            console.log(rangePicker);
            rangePicker.togglePicker(true);
          }
        default:
          break;
      }
      this.optionsDropdown.hide();
    }
  },
  filters: {
    date(val) {
      if (!val) return "";

      return dayjs(val).format("MMM DD, YYYY");
    }
  }
});
