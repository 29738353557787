import WithRender from "./QROpeningHours.html";
import QRHourPicker from "../components/QRHourPicker";
import MinusInCircleIcon from "../icons/MinusInCircleIcon";
import PlusInCircleIcon from "../icons/PlusInCircleIcon";

export default WithRender({
  props: ["form", "field", "model"],
  components: {
    QRHourPicker,
    MinusInCircleIcon,
    PlusInCircleIcon
  },
  mounted() {
    this.value = this.model[this.name] || {
      0: {
        enabled: true,
        values: [
          {
            from: "08:00",
            to: "16:00"
          }
        ]
      },
      1: {
        enabled: true,
        values: [
          {
            from: "08:00",
            to: "16:00"
          }
        ]
      },
      2: {
        enabled: true,
        values: [
          {
            from: "08:00",
            to: "16:00"
          }
        ]
      },
      3: {
        enabled: true,
        values: [
          {
            from: "08:00",
            to: "16:00"
          }
        ]
      },
      4: {
        enabled: true,
        values: [
          {
            from: "08:00",
            to: "16:00"
          }
        ]
      },
      5: {
        enabled: true,
        values: [
          {
            from: "08:00",
            to: "16:00"
          }
        ]
      },
      6: {
        enabled: true,
        values: [
          {
            from: "08:00",
            to: "16:00"
          }
        ]
      },
      timeFormat: "12"
    };
  },
  computed: {
    name() {
      return `${this.field.key}`;
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.value = val;
        // const { name, value } = this;
        // this.$emit("update", { name, value });
      }
    },
    timeFormat: {
      get() {
        return this.value?.timeFormat;
      },
      set(val) {
        this.value.timeFormat = val;
      }
    }
  },
  data() {
    return {
      weekDays: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      value: null
    };
  },
  methods: {
    onAddTimeItem(event, day) {
      event.preventDefault();

      if (this.value[day].values.length > 1) return;
      this.value[day].values.push({ from: "16:00", to: "22:00" });
    },
    onRemoveTimeItem(event, day) {
      event.preventDefault();

      if (this.value[day].values.length == 1) return;
      this.value[day].values.pop();
    }
  },
  watch: {
    model: {
      handler(newValue, oldValue) {
        if (oldValue[this.name] == newValue[this.name]) return;
        this.value = newValue[this.name];
      },
      deep: true
    },
    value: {
      handler(newValue, oldValue) {
        const { name, value } = this;
        this.$emit("update", { name, value });
      },
      deep: true
    }
  }
});
