import baseField from "vue-formly-bootstrap/src/fields/baseField";

import WithRender from "./QRInputHalf.html";
import QRInput from "./QRInput";

export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  components: {
    QRInput
  },
  methods: {
    onUpdateValue(v) {
      this.$emit("update", v);
    },
    onValidate(v) {
      this.$emit("validate", v);
    }
  }
});
