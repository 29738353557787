<div>
  <button
    ref="dropdownBatchBtn"
    class="text-white flex ml-auto text-white bg-qr_color-blue_2 px-5 py-2.5 focus:outline-none rounded-qr_input_radius justify-center items-center gap-x-2"
    type="button"
  >
    {{$t('qr_action.actions')}}
    <svg
      class="ml-2 w-4 h-4"
      aria-hidden="true"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M19 9l-7 7-7-7"
      ></path>
    </svg>
  </button>

  <!-- Dropdown menu -->
  <div
    ref="dropdownBatchMenu"
    class="hidden z-10 w-32 bg-white rounded divide-y divide-gray-100 shadow"
  >
    <ul class="py-1 text-sm text-gray-700">
      <li
        class="flex px-4 items-center font-medium text-red-400 hover:text-red-500 hover:bg-gray-100"
        @click="onDeleteItems"
      >
        <DustBinIcon class="w-4 h-4 fill-current" />
        <button type="button" class="block py-2 px-4">
          {{$t('qr_action.delete')}}
        </button>
      </li>

      <li
        class="flex px-4 items-center font-medium text-blue-500 hover:text-qr_color-blue"
        @click="onActivateItems"
      >
        <PlayIcon class="w-4 h-4 fill-current" />
        <button type="button" class="block py-2 px-4">
          {{$t('qr_action.start')}}
        </button>
      </li>
      <li
        class="flex px-4 items-center font-medium text-gray-400 hover:text-qr_color-gray"
        @click="onDeactivateItems"
      >
        <PauseIcon class="w-4 h-4 fill-current" />
        <button type="button" class="block py-2 px-4">
          {{$t('qr_action.pause')}}
        </button>
      </li>
    </ul>
  </div>
</div>
