import { mapGetters, mapActions, mapMutations } from "vuex";

import WithRender from "./CreateNew.html";
import MainLayout from "../components/ui/layouts/MainLayout";
import SearchBar from "../components/ui/containers/SearchBar";
import TypeCardItem from "../components/ui/cards/TypeCardItem";
import PointingLeft from "../assets/icons/pointing-left.svg";
import PreviewWebSiteIcon from "../assets/images/preview-type-url-en.jpg";
import PreviewCouponsIcon from "../assets/images/preview-type-coupon-en.jpg";
import PreviewBusinessIcon from "../assets/images/preview-type-business-en.jpg";
import PreviewEventIcon from "../assets/images/preview-type-event-en.jpg";
import PreviewVideoIcon from "@/assets/images/preview-type-dvideo-en.jpg";
import PreviewImageIcon from "@/assets/images/preview-type-dgallery-en.jpg";
import PreviewFeedbackIcon from "@/assets/images/preview-type-feedback-en.png";
import PreviewSocialMediaIcon from "@/assets/images/preview-type-dsocial-en.png";
import PreviewTextIcon from "@/assets/images/text_preview.png";
import PreviewFacebookIcon from "@/assets/images/preview-type-facebook-en.jpg";
import PreviewInstagramIcon from "@/assets/images/preview-type-instagram-en.jpg";
import PreviewPdfIcon from "@/assets/images/preview-type-dpdf-en.jpg";
import PreviewRatingIcon from "@/assets/images/preview-type-drating-en.jpg";
import PreviewMp3Icon from "@/assets/images/preview-type-dmp3-en.jpg";
import PreviewAppIcon from "@/assets/images/preview-type-dapp-en.jpg";
import PreviewVCardIcon from "@/assets/images/preview-type-vcard-en.jpg";

import Spinner from "../components/base/components/Spinner";
import NextActionFooter from "../components/ui/containers/NextActionFooter";

export default WithRender({
  components: {
    MainLayout,
    SearchBar,
    TypeCardItem,
    Spinner,
    SearchBar,
    NextActionFooter
  },
  mounted() {
    this.routerActions({ page: "new" });
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["routerActions", "changeCurrentStep"]),
    ...mapMutations(["setCurrentQrProps", "setSelectedCategory"]),
    onCreateNewQR(e) {
      if (this.selectType) {
        if (this.getEditorMode) {
          this.changeCurrentStep("create_new_detail");
        } else {
          this.$router.push({ path: `/new/create/${this.selectType}` });
        }
      }
    },
    onBackBtnClick() {
      this.$router.push({ name: "home" });
    }
  },
  computed: {
    ...mapGetters([
      "getCategories",
      "getLoading",
      "getEditorMode",
      "getSelectedCategory"
    ]),
    selectType() {
      return this.getSelectedCategory;
    },
    explanations() {
      switch (this.selectType) {
        case "dynamic-url":
        case "Website": {
          return {
            classlist: "flex flex-col items-center flex-end",
            image: {
              icon: PreviewWebSiteIcon,
              type: "jpg",
              classlist: "w-full"
            }
          };
        }

        case "dynamic-coupon":
        case "Coupons": {
          return {
            classlist: "flex flex-col items-center flex-end",
            image: {
              icon: PreviewCouponsIcon,
              type: "jpg",
              classlist: "w-full"
            }
          };
        }

        case "dynamic-business":
        case "Business Page": {
          return {
            classlist: "flex flex-col items-center flex-end",
            image: {
              icon: PreviewBusinessIcon,
              type: "jpg",
              classlist: "w-full"
            }
          };
        }

        case "dynamic-vcard-plus":
        case "vCard Plus": {
          return {
            classlist: "flex flex-col items-center flex-end",
            image: {
              icon: PreviewVCardIcon,
              type: "jpg",
              classlist: "w-full"
            }
          };
        }

        case "dynamic-event":
        case "Event": {
          return {
            classlist: "flex flex-col items-center flex-end",
            image: {
              icon: PreviewEventIcon,
              type: "jpg",
              classlist: "w-full"
            }
          };
        }

        case "dynamic-video":
        case "Video": {
          return {
            classlist: "flex flex-col items-center flex-end",
            image: {
              icon: PreviewVideoIcon,
              type: "jpg",
              classlist: "w-full"
            }
          };
        }

        case "dynamic-image":
        case "Image": {
          return {
            classlist: "flex flex-col items-center flex-end",
            image: {
              icon: PreviewImageIcon,
              type: "jpg",
              classlist: "w-full"
            }
          };
        }

        case "dynamic-feedback":
        case "Feedback": {
          return {
            classlist: "flex flex-col items-center flex-end",
            image: {
              icon: PreviewFeedbackIcon,
              type: "jpg",
              classlist: "w-full"
            }
          };
        }

        case "dynamic-social-media":
        case "Social Media": {
          return {
            classlist: "flex flex-col items-center flex-end",
            image: {
              icon: PreviewSocialMediaIcon,
              type: "jpg",
              classlist: "w-full"
            }
          };
        }

        case "dynamic-text":
        case "Text": {
          return {
            classlist: "flex flex-col items-center flex-end",
            image: {
              icon: PreviewTextIcon,
              type: "jpg",
              classlist: "w-full"
            }
          };
        }

        // !! nou
        case "dynamic-instagram":
        case "Instagram": {
          return {
            classlist: "flex flex-col items-center flex-end",
            image: {
              icon: PreviewInstagramIcon,
              type: "jpg",
              classlist: "w-full"
            }
          };
        }

        case "dynamic-facebook":
        case "Feedback": {
          return {
            classlist: "flex flex-col items-center flex-end",
            image: {
              icon: PreviewFacebookIcon,
              type: "jpg",
              classlist: "w-full"
            }
          };
        }

        case "dynamic-pdf":
        case "Feedback": {
          return {
            classlist: "flex flex-col items-center flex-end",
            image: {
              icon: PreviewPdfIcon,
              type: "jpg",
              classlist: "w-full"
            }
          };
        }

        case "dynamic-rating":
        case "Rating": {
          return {
            classlist: "flex flex-col items-center flex-end",
            image: {
              icon: PreviewRatingIcon,
              type: "jpg",
              classlist: "w-full"
            }
          };
        }

        case "dynamic-mp3":
        case "Mp3": {
          return {
            classlist: "flex flex-col items-center flex-end",
            image: {
              icon: PreviewMp3Icon,
              type: "jpg",
              classlist: "w-full"
            }
          };
        }

        case "dynamic-app":
        case "App": {
          return {
            classlist: "flex flex-col items-center flex-end",
            image: {
              icon: PreviewAppIcon,
              type: "jpg",
              classlist: "w-full"
            }
          };
        }

        default:
          return {
            classlist: "flex flex-col items-center h-full justify-center ",
            image: {
              icon: PointingLeft,
              type: "svg",
              classlist: "w-1/2"
            },
            description: {
              content: "Please choose one to view a page preview",
              classlist: "text-xl font-bold text-center text-gray-500 w-5/12"
            }
          };
      }
    }
  }
});
