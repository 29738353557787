import { mutationSentences, querySentences } from "../gql";

const USER_TOKEN_NAME = "user-token";
const USER_OLD_TOKEN_NAME = "user-old-token";

const getModule = (queryAPI, mutationAPI) => {
  const state = {
    token: null,
    old_token: null,
    name: null,
    email: null,
    is_guest: 0,
    is_user_ready: false
  };

  const getters = {
    isAuthenticated(state) {
      return localStorage.getItem(USER_TOKEN_NAME, state.token);
    },
    isOldAuthenticated(state) {
      return localStorage.getItem(USER_OLD_TOKEN_NAME, state.old_token);
    },
    isGuest(state) {
      return state.is_guest;
    },
    isUserReady(state) {
      return state.user_ready;
    },
    getUserInfo(state) {
      return state.userInfo;
    }
  };

  const mutations = {
    setUserToken(state, { token }) {
      localStorage.setItem(USER_TOKEN_NAME, token);
      state.token = token;
    },
    setOldToken(state, { token }) {
      localStorage.setItem(USER_OLD_TOKEN_NAME, token);
      state.old_token = token;
    },
    setUserInfo(state, { name, email, is_guest }) {
      state.name = name ?? null;
      state.email = email ?? null;
      state.is_guest = is_guest ?? 0;
    },
    setUserReady(state, status) {
      state.is_user_ready = status | 0;
    },
    clearUserToken(state) {
      localStorage.clear();
      state.token = null;
    }
  };

  const actions = {
    setUserOldToken({ commit, getters }) {
      const oldToken = getters.isOldAuthenticated;
      commit("setOldToken", { token: oldToken });
    },
    async setUserToken({ commit, dispatch, getters }, { token }) {
      try {
        commit("setUserToken", { token });

        const {
          me: { name, email, is_guest }
        } = await queryAPI({
          query: querySentences.userInfo
        });
        if (!is_guest) {
          if (typeof Event === "function") {
            event = new Event("showLogin");
          } else {
            event = document.createEvent("Event");
            event.initEvent("showLogin", true, true);
          }

          window.dispatchEvent(event);
        } else {
          if (typeof Event === "function") {
            event = new Event("showGuest");
          } else {
            event = document.createEvent("Event");
            event.initEvent("showGuest", true, true);
          }

          window.dispatchEvent(event);
        }

        commit("setUserInfo", { name, email, is_guest });
        let payloadFetchBaseData = {
          locale: getters.getEditorLocale
        };
        if (getters.getWidgetMode) {
          payloadFetchBaseData = {
            ...payloadFetchBaseData,
            callBackFunction: () => {
              dispatch("resetCurrentQr", {});
              dispatch("resetCurrentModel", {});
              dispatch("setSelectedCategory", "");
              commit("setSelectedCategory", getters.getCardType);
              const defaultModel = getters.getDefaultCategoryModel;
              commit("setCurrentModel", defaultModel);
              const widgetEditId = getters.getWidgetEditId;
              if (widgetEditId) {
                const payloadFetchQrDetails = {
                  id: widgetEditId,
                  callBackFunction: response => {
                    if (response.qrCode.qrType) {
                      commit("changeCardType", response.qrCode.qrType.code);
                    }
                    dispatch("openDesign", response);
                  }
                };
                dispatch("fetchQrDetails", payloadFetchQrDetails);
              }
            }
          };
        }
        dispatch("fetchBaseData", payloadFetchBaseData);

        return { success: true, data: { name, email, is_guest } };
      } catch (error) {
        return { success: false, data: { error } };
      }
    },

    // graphQL info
    async fetchUserInfo({ commit }) {
      try {
        const data = await queryAPI({
          query: querySentences.userInfo
        });
        commit("setUserInfo", data);
      } catch (error) {
        throw error;
      }
    },

    // graphQL mutations
    async loginUser(
      { commit, dispatch, getters },
      { email, password, recaptcha }
    ) {
      try {
        const { login, error } = await mutationAPI({
          // Query
          mutation: mutationSentences.loginMutation,
          // Parameters
          variables: {
            email,
            password,
            recaptcha,
            device: "web",
            oldToken: getters.isOldAuthenticated
          }
        });
        if (login) {
          dispatch("setUserToken", { token: login });
          return { success: true };
        }
        return { success: false, data: { error: error } };
      } catch (error) {
        console.log("myerror", error);
        return { success: false, data: { error } };
      }
    },

    // graphQL mutations
    async registerUser(
      { commit, dispatch, getters },
      { email, name, password, confirm_password, recaptcha }
    ) {
      try {
        const { registerUser, error } = await mutationAPI({
          // Query
          mutation: mutationSentences.registerUserMutation,
          // Parameters
          variables: {
            email,
            name,
            password,
            confirm_password,
            device: "web",
            oldToken: getters.isOldAuthenticated,
            recaptcha
          }
        });
        if (registerUser) {
          dispatch("setUserToken", { token: registerUser });
          return { success: true };
        }
        return { success: false, data: { error: error } };
      } catch (error) {
        console.log("myerror", error);
        return { success: false, data: { error } };
      }
    },

    // graphQL mutations
    async CreateGuestUser({ commit, dispatch }) {
      try {
        const { createGuestUser, error } = await mutationAPI({
          // Query
          mutation: mutationSentences.createGuestUserMutation,
          // Parameters
          variables: {
            device: "web"
          }
        });

        if (createGuestUser) {
          return { success: true, data: { token: createGuestUser } };
        }

        return { success: false, data: { error: error } };
      } catch (error) {
        return { success: false, data: { error } };
      }
    },
    // graphQL mutations
    async logoutUser({ commit, dispatch, getters }) {
      try {
        const oldToken = getters.isAuthenticated;
        commit("clearUserToken");
        commit("setOldToken", { token: oldToken });
        commit("setUserInfo", { name: null, email: null, is_guest: 0 });
      } catch (error) {
        throw error;
      }
    }
  };

  return {
    state,
    getters,
    mutations,
    actions
  };
};
export default getModule;
