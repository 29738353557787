import welcome from "./img/welcome_facebook.png";
// import avatar from "./img/coupons-avatar.jpg";
// import { remove } from "lodash";
// couponsOption

export const createDynamicFacebookFields = (formly, t) => {
  return [
    formly.labelTitle({
      titleIcon: "FacebookIcon",
      titleKey: "qrcode_title",
      titleTooltip: t(
        "Labeling QR Codes has been moved to this area. Names help you to stay organized and will only appear in your account and are not displayed to customers who scan your QR Codes."
      ),
      titlePlaceholder: t("My Facebook QR Code"),
      titleValue: null,
      buttonLabel: t("Got it")
    }),
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_design",
        headline: t("Design & Customization"),
        icon: "DesignIcon"
      }),
      formly.body({
        name: "section_design",
        collapseKey: "section_design",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t(
              "Personalize your page by selecting some colors and uploading a logo."
            )
          }),
          formly.colorsTemplate({
            shape: "rect",
            customColors: [
              { color1: "#3766b8", color2: "#3766b8" },
              { color1: "#f14139", color2: "#3766b8" },
              { color1: "#0096e7", color2: "#45606f" },
              { color1: "#3f495a", color2: "#ff4467" },
              { color1: "#0cb2af", color2: "#4caf50" }
            ]
          }),
          formly.colorsSwitch({}),
          {
            className: "my-2",
            template:
              '<hr style="border-color: #eaeaea; margin: 10px 0 -10px;">'
          },
          formly.avatarRectangular({
            tooltip: t(
              "Upload an image or logo from your computer. Images must be at least 640 x 360 px in .jpg or .png format."
            ),
            aspectRatio: 1.77
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 1,
        sectionName: "section_basic_info",
        headline: t("Basic Information"),
        icon: "InfoIcon"
      }),
      formly.body({
        name: "section_basic_info",
        collapseKey: "section_basic_info",
        key: "code",
        content: [
          formly.subHeadLine({
            html: t(
              "Provide your business information below. <br />For the Like button to appear, enter a public Facebook Page."
            )
          }),
          formly.inputRowRequired({
            className: "form-input-row form-row-container flex flex-col",
            label: "Facebook URL",
            placeholder: "www.facebook/yourpage",
            key: "facebookUrl",
            required: true,
            errorMsgTitle: t("Please provide a facebook url."),
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(t("Please provide a facebook url."));
                  return false;
                }

                return true;
              }
            }
          }),

          {
            className: "form-input-row form-row-container my-2",
            type: "field_group",
            fieldGroup: [
              {
                type: "qrinput_checkbox",
                key: "code",
                templateOptions: {
                  label: t("Hide the number of Likes on the Like button"),
                  trueValue: true,
                  falseValue: false
                }
              }
            ]
          },

          formly.inputRow({
            key: "name",
            className: "form-input-row form-row-container flex flex-col",
            label: t("Name"),
            placeholder: t(
              "Company, brand or artist who runs the Facebook Page"
            )
          }),
          formly.inputRow({
            key: "title",
            className: "form-input-row form-row-container flex flex-col",
            label: t("Title"),
            placeholder: t("Click on the Like Button to follow us on Facebook")
          }),
          formly.inputRow({
            label: t("Website"),
            placeholder: t("www.your-website.com"),
            key: "website",
            filters: {
              makeUrl: function ({ value }) {
                if (value.trim() !== "" && !value.match(/:\/\//)) {
                  value = "http" + "://" + value;
                }
                return value;
              }
            },
            validators: {
              validUrl: function ({ messages, name, value }) {
                if (
                  value !== "" &&
                  !value.match(
                    /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                  )
                ) {
                  messages.push(t("Please enter a valid address."));
                  return false;
                }
                return true;
              }
            }
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 3,
        sectionName: "section_welcome_screen",
        headline: "Welcome Screen",
        icon: "SphereIcon"
      }),
      formly.body({
        name: "section_welcome_screen",
        collapseKey: "section_welcome_screen",
        key: "code",
        content: [
          formly.subHeadLine({
            text: "Display your logo while your page is loading."
          }),
          formly.welcomeScreen({
            tooltip:
              "Upload an image or logo from your computer. Images must be in .jpg or .png format.",
            key: "welcome_screen",
            previewWelcomeScreen: true,
            defaultWelcomeImage: welcome
          })
        ]
      })
    )
  ];
};

export const createDynamicFacebookRequiredFields = _ => {
  return ["facebookUrl"];
};
