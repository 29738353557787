// couponsOption
export const createDynamicMp3FieldsWidget = (formly, t) => {
  return [
    formly.container(
      formly.header({
        index: 1,
        sectionName: "",
        headline: "",
        hideCollapse: true,
        icon: null
      }),
      formly.body({
        name: "section_basic_info",
        collapseKey: "section_basic_info",
        widget: 1,
        key: "code",
        content: [
          formly.uploadMp3File({
            tooltip: t(
              "Upload an mp3 or audio file from your computer. Files must be in .mp3"
            ),
            key: "file",
            addDownloadOptionKey: "addDownloadOption"
            // previewWelcomeScreen: true
          }),
          {
            className: "m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          },
          formly.avatarRectangular({
            smallLabel: t("180 x 180 px"),
            label: t("Logo"),
            aspectRatio: 1
          }),
          {
            className: "m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          },
          formly.inputRowRequired({
            key: "title",
            className: "form-input-row form-row-container flex flex-col",
            label: t("Title"),
            placeholder: t("Name of song or sound file"),
            required: true,
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(t("Please provide a title."));
                  return false;
                }

                return true;
              }
            }
          }),
          formly.inputRow({
            key: "info",
            className: "form-input-row form-row-container flex flex-col",
            label: t("More info"),
            placeholder: t("e.g. Name of artist and album")
          }),
          {
            className: "m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          },
          formly.inputRow({
            label: t("Website"),
            placeholder: t("www.your-website.com"),
            key: "website",
            filters: {
              makeUrl: function ({ value }) {
                if (value.trim() !== "" && !value.match(/:\/\//)) {
                  value = "http" + "://" + value;
                }
                return value;
              }
            },
            validators: {
              validUrl: function ({ messages, name, value }) {
                if (
                  value !== "" &&
                  !value.match(
                    /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                  )
                ) {
                  messages.push(t("Please enter a valid address."));
                  return false;
                }
                return true;
              }
            }
          }),
          {
            className: "form-row-container flex-col",
            type: "field_group",
            required: true,
            fieldGroup: [
              {
                type: "qr_input_label",
                templateOptions: {
                  label: t("Button")
                }
              },
              {
                className: "col-sm-12 col-md-8 box-input",
                key: "callToAction",
                type: "qraction_button",
                templateOptions: {
                  urlPlaceholder: t("http://"),
                  defaultValue: {
                    label: t("Visit us online"),
                    url: ""
                  },
                  items: [
                    t("Learn more"),
                    t("Buy now"),
                    t("View full album"),
                    t("Visit us online")
                  ],
                  addLabel: t("Add button"),
                  removeLabel: t("Remove button"),
                  customLabel: t("Custom"),
                  errorMessages: {
                    url: t("Please enter a valid website address"),
                    required: t("This field can not be empty")
                  },
                  filters: {
                    makeUrl: function ({ value }) {
                      if (value.trim() !== "" && !value.match(/:\/\//)) {
                        value = "http" + "://" + value;
                      }
                      return value;
                    }
                  },
                  validators: {
                    required: function ({ messages, name, value }) {
                      if (value.trim() == "") {
                        messages.push(t("This field can not be empty"));
                        return false;
                      }

                      return true;
                    },
                    validUrl: function ({ messages, name, value }) {
                      if (
                        value !== "" &&
                        !value.match(
                          /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                        )
                      ) {
                        messages.push(t("Please enter a valid address."));
                        return false;
                      }
                      return true;
                    }
                  }
                }
              }
            ]
          }
        ]
      })
    )
  ];
};

export const createDynamicMp3RequiredFieldsWidget = _ => {
  return ["name"];
};
