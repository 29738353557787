import WithRender from "./MainLayout.html";
// import MainNavbar from "../containers/Navbar";
// import MainFooter from "../containers/Footer";
import Spinner from "../../base/components/Spinner";
import { mapGetters } from "vuex";

export default WithRender({
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(["getEditorMode"]),
    getColors() {
      return this.$printGetColors();
    }
  },
  components: {
    // MainNavbar,
    // MainFooter,
    Spinner
  }
});
