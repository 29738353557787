import WithRender from "./QRWebsiteBox.html";
import { isValidHttpUrl } from "@/helpers/helpers";
import PencilIcon from "../icons/PencilIcon";

export default WithRender({
  props: ["form", "field", "model"],
  components: {
    PencilIcon
  },
  created() {
    this.value = this.model[this.name] ?? this.defaultValue;
  },
  mounted() {
    //  set the dropdown menu element
    const targetEl = this.$refs.dropdownItems;
    const triggerEl = this.$refs.dropdownOpenBtn;

    // options with default values
    const options = {
      placement: "bottom"
    };
    this.dropdown = new Dropdown(targetEl, triggerEl, options);
  },

  data() {
    return {
      value: {
        label: "",
        url: ""
      },
      dropdown: null
    };
  },
  methods: {
    onUpdateValue() {
      const { name, value } = this;
      this.$emit("update", { name, value });
    },
    onOpenDropdown() {
      if (this.dropdown) {
        this.dropdown.show();
      }
    },
    onSelectItem(item) {
      this.value.label = item;
      this.dropdown?.hide();
      this.onUpdateValue();
    },
    handleChange() {
      let { url } = this.value;
      if (!isValidHttpUrl(url)) {
        url = "http://" + url;
      }
      this.value.url = url;
      this.onUpdateValue();
    }
  },
  computed: {
    name() {
      return this.field.key;
    },
    required() {
      return this.field.templateOptions.required || false;
    },
    placeHolder() {
      return this.field.templateOptions.urlPlaceholder;
    },
    className() {
      return this.field.className;
    },
    defaultValue() {
      return this.field.templateOptions.defaultValue;
    },
    items() {
      return this.field.templateOptions.items;
    },
    getSelectedValue: {
      get() {
        for (const item of this.items) {
          if (item == this.value.label) return item;
        }
        return "Custom";
      },
      set(v) {
        return (this.value = v);
      }
    }
  },
  watch: {
    model: {
      handler(newValue, oldValue) {
        if (oldValue[this.name] == newValue[this.name]) return;
        this.value = newValue[this.name];
      },
      deep: true
    }
  }
});
