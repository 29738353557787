<div class="overflow-hidden relative mt-4 mb-4 text-center">
  <button
    type="button"
    class="flex px-16 text-white rounded bg-qr_color-blue_1 p-2 rounded-qr_input_radius"
    @click="$refs.file.click()"
  >
    <span class="ml-auto"> {{$t('qr_upload_rectangle.change')}}</span>
  </button>
  <input
    ref="file"
    class="cursor-pointer absolute block opacity-0 invisible"
    type="file"
    name="vacancyImageFiles"
    @change="onChangeFileName"
  />
  <div class="mt-4" v-if="hideOptionId">
    <button
      type="button"
      class="mt-2 inline-flex px-1 py-1.5 text-sm text-qr_color-blue_1 underline"
      @click="onHideBarCode"
    >
      {{$t('qr_upload_rectangle.use_text_code')}}
    </button>
  </div>
  <button
    v-if="!isEmptyOrDefaultImage"
    type="button"
    class="mt-2 inline-flex px-1 py-1.5 text-sm text-qr_color-blue_1 underline"
    @click="onRemoveClicked"
  >
    {{$t('qr_upload_rectangle.remove_image')}}
  </button>
</div>
