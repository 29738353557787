import WithRender from "./QrAction.html";
import DustBinIcon from "../../base/icons/DustBinIcon";
import PauseIcon from "../../base/icons/PauseIcon";
import PlayIcon from "../../base/icons/PlayIcon";

export default WithRender({
  components: {
    DustBinIcon,
    PauseIcon,
    PlayIcon
  },
  data() {
    return {
      menuList: [
        { value: "UPDATED_AT", label: "Update Date" },
        { value: "CREATED_AT", label: "Create Date " },
        { value: "TITLE", label: "Name" }
      ]
    };
  },
  mounted() {
    const triggerEle = this.$refs.dropdownBatchBtn;
    const targetEle = this.$refs.dropdownBatchMenu;
    new window.Dropdown(targetEle, triggerEle, {
      placement: "bottom"
    });
  },
  methods: {
    onDeleteItems() {
      this.$emit("delete");
    },
    onActivateItems() {
      this.$emit("activate");
    },
    onDeactivateItems() {
      this.$emit("deactivate");
    }
  }
});
