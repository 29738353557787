import welcome from "./img/business-welcome.png";

export const createDynamicBusinessPageFields = (formly, t) => {
  return [
    formly.labelTitle({
      titleIcon: "BusinessIcon",
      titleKey: "qrcode_title",
      titleTooltip: t(
        "Labeling QR Codes has been moved to this area. Names help you to stay organized and will only appear in your account and are not displayed to customers who scan your QR Codes."
      ),
      titlePlaceholder: t("My Business Page QR Code"),
      titleValue: null,
      buttonLabel: t("Got it")
    }),
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_design",
        headline: t("Design & Customizations"),
        icon: "DesignIcon"
      }),
      formly.body({
        name: "section_design",
        collapseKey: "section_design",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t(
              "Personalize your page by selecting colors and uploading an image."
            )
          }),
          formly.colorsTemplate({
            customColors: [
              { color1: "#447fb6", color2: "#e91e63" },
              { color1: "#0288d1", color2: "#64b5f6" },
              { color1: "#d32f2f", color2: "#ef9a9a" },
              { color1: "#4caf50", color2: "#81c784" },
              { color1: "#795548", color2: "#ff8a65" }
            ]
          }),
          formly.colorsSwitch({}),
          {
            className: "w-full m-4",
            template:
              '<hr style="border-color: #eaeaea; margin: 10px 0 -10px;">'
          },
          formly.avatarRectangular({
            tooltip: t(
              t(
                "Upload an image or logo from your computer. Images must be at least 640 x 360 px in .jpg or .png format."
              )
            ),
            aspectRatio: 1.77
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 1,
        sectionName: "section_basic_info",
        headline: t("Basic Information"),
        icon: "InfoIcon"
      }),
      formly.body({
        name: "section_basic_info",
        key: "code",
        collapseKey: "section_basic_info",
        content: [
          formly.subHeadLine({
            text: t(
              "Introduce your business or organization in a few words. Optionally, add a button to a website of your choice. Fields marked with a * are mandatory."
            )
          }),
          formly.inputRow({
            label: t("Company"),
            placeholder: t("Name of company or organization"),
            key: "company"
          }),
          formly.inputRowRequired({
            key: "headline",
            label: t("Headline"),
            className: "form-input-row form-row-container flex flex-col",
            placeholder: t("Add an engaging title to your page"),
            condition: "true",
            errorMsgTitle: t("Please provide an engaging title to your page."),
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(
                    t("Please provide an engaging title to your page.")
                  );
                  return false;
                }

                return true;
              }
            }
          }),
          formly.textAreaRow({
            label: t("Summary"),
            placeholder: t(
              "Write a short summary about the type and purpose of your business"
            ),
            key: "teaser",
            maxChar: 200
          }),
          {
            className: "w-full m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          },
          {
            className: "form-row-container flex-col",
            type: "field_group",
            required: true,
            fieldGroup: [
              {
                type: "qr_input_label",
                templateOptions: {
                  label: t("Button")
                }
              },
              {
                className: "col-sm-12 col-md-8 box-input",
                key: "callToAction",
                type: "qraction_button",
                templateOptions: {
                  key: "callToAction.url",
                  urlPlaceholder: t("http://"),
                  defaultValue: {
                    label: t("View menu"),
                    url: ""
                  },
                  items: [
                    t("View menu"),
                    t("Shop online"),
                    t("Learn More"),
                    t("Apply now")
                  ],
                  addLabel: t("Add button"),
                  removeLabel: t("Remove button"),
                  customLabel: t("Custom"),
                  errorMessages: {
                    url: t("Please enter a valid website address"),
                    required: t("This field can not be empty")
                  },
                  filters: {
                    makeUrl: function ({ value }) {
                      if (value.trim() !== "" && !value.match(/:\/\//)) {
                        value = "http" + "://" + value;
                      }
                      return value;
                    }
                  },
                  validators: {
                    required: function ({ messages, name, value }) {
                      if (value.trim() == "") {
                        messages.push(t("This field can not be empty"));
                        return false;
                      }

                      return true;
                    },
                    validUrl: function ({ messages, name, value }) {
                      if (
                        value !== "" &&
                        !value.match(
                          /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                        )
                      ) {
                        messages.push(t("Please enter a valid address."));
                        return false;
                      }
                      return true;
                    }
                  }
                }
              }
            ]
          }
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 2,
        sectionName: "section_opening_hours",
        fold: true,
        headline: t("Opening Hours"),
        icon: "ClockIcon"
      }),
      formly.body({
        name: "section_opening_hours",
        key: "code",
        collapseKey: "section_opening_hours",
        content: [
          formly.subHeadLine({
            text: t("Provide your opening hours if applicable.")
          }),
          {
            type: "qr-opening-hours",
            key: "openingHours"
          }
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 3,
        sectionName: "section_location",
        headline: t("Address & Location"),
        icon: "LocationIcon"
      }),
      formly.body({
        name: "section_location",
        key: "code",
        collapseKey: "section_location",
        content: [
          formly.subHeadLine({
            text: t("Provide your address and location information.")
          }),

          formly.autocompleteInputForm({ wrappedInAddressKey: false }),
          {
            className: "w-full m-4",
            template: '<hr style="border-color: #eaeaea; margin: 10px 0 20px;">'
          },
          formly.features({
            key: "features",
            label: t("Features"),
            text: t(
              "Choose amenities available at your venue. Recommended for gastronomy and retail."
            )
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 4,
        sectionName: "section_about",
        headline: t("About & Contact Information"),
        icon: "CalendarIcon"
      }),
      formly.body({
        name: "section_about",
        collapseKey: "section_about",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t(
              "Add more detailed information about your business and provide contact data."
            )
          }),
          formly.textAreaRow({
            label: t("About"),
            placeholder: t(
              "Write more about your business, products or services"
            ),
            key: "about",
            maxChar: 120
          }),
          {
            className: "w-full m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 28px;">'
          },
          formly.inputRow({
            label: t("Name"),
            placeholder: t("Your Name"),
            key: "name"
          }),
          formly.inputRow({
            label: t("Phone"),
            placeholder: t("Phone Number"),
            key: "phone"
          }),
          formly.inputRow({
            label: t("Email"),
            placeholder: t("your@email.com"),
            key: "email"
          }),
          formly.inputRow({
            label: t("Website"),
            placeholder: t("www.your-website.com"),
            key: "website",
            filters: {
              makeUrl: function ({ value }) {
                if (value.trim() !== "" && !value.match(/:\/\//)) {
                  value = "http" + "://" + value;
                }
                return value;
              }
            },
            validators: {
              validUrl: function ({ messages, name, value }) {
                if (
                  value !== "" &&
                  !value.match(
                    /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                  )
                ) {
                  messages.push(t("Please enter a valid address."));
                  return false;
                }
                return true;
              }
            }
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 5,
        sectionName: "section_media_channels",
        headline: t("Social Media Channels"),
        icon: "EarthIcon"
      }),
      formly.body({
        name: "section_media_channels",
        collapseKey: "section_media_channels",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Click on the icon to add a social media profile.")
          }),
          formly.socialChannels({
            key: "channels",
            requiredAllChannels: false,
            sortable: false,
            showLabels: false,
            helpMessage: false,
            requiredMessage: t("At least one channel is required"),
            hasLabel: false,
            hasOrder: false,
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(t("This field can not be empty"));
                  return false;
                }

                return true;
              }
            },
            textValidators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(t("This field can not be empty"));
                  return false;
                }

                return true;
              }
            }
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 6,
        sectionName: "section_welcome_screen",
        headline: t("Welcome Screen"),
        icon: "SmartPhoneIcon"
      }),
      formly.body({
        name: "section_welcome_screen",
        collapseKey: "section_welcome_screen",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Display your logo while your page is loading.")
          }),
          formly.welcomeScreen({
            tooltip: t(
              "Upload an image or logo from your computer. Images must be in .jpg or .png format."
            ),
            key: "welcome_screen",
            previewWelcomeScreen: true,
            defaultWelcomeImage: welcome
          })
        ]
      })
    )
  ];
};

export const createDynamicBusinessRequiredFields = _ => {
  return ["headline"];
};
