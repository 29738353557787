const getMediaUrl = (path, baseUrl = "") => {
  if (path.match(/^(#|\/\/|https?:\/\/|(mailto|tel|sms):)/gm)) {
    return path;
  }
  return baseUrl.rtrim("/") + "/" + path.ltrim("/");
};

function isValidHttpUrl(string) {
  try {
    const url = new URL(string);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (e) {
    return false;
  }
}

function makeRNDStr(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
function blobToBase64(blob) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export { getMediaUrl, isValidHttpUrl, makeRNDStr, blobToBase64 };
