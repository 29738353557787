import baseField from "vue-formly-bootstrap/src/fields/baseField";

import WithRender from "./QRCollapse.html";

export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  created() {
    // console.log("[QR Collapse]", this.field);
  },
  computed: {
    name() {
      return `collapse_${this.field.templateOptions.key}`;
    },
    headId() {
      return `${this.name}-head`;
    },
    bodyId() {
      return `${this.name}-body`;
    },
    value() {
      return this.model[this.name] ?? true;
    }
  }
});
