import { mapGetters, mapActions } from "vuex";
import WithRender from "./Navbar.html";
import PrintQrLogo from "../../../assets/printQr.svg";

export default WithRender({
  components: { PrintQrLogo },
  computed: {
    ...mapGetters(["isAuthenticated"])
  },
  methods: {
    ...mapActions(["logoutUser"]),
    onLogOut() {
      this.logoutUser();
      this.$router.push({ name: "login" });
    }
  }
});
