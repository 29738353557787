<div class="flex w-full items-center">
  <div class="w-8 h-8 mr-3 text-qr_color-yellow_icon">
    <qr-icon :name="icon" class="w-8 h-8 fill-current" />
  </div>
  <div class="relative z-0 w-full group">
    <input
      type="text"
      :name="name"
      :value="getQrCurrentTitle"
      class="bg-qr_color-gray_3 h-12 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent"
      :placeholder="placeHolder"
      @change="updateTitle"
    />

    <div class="absolute right-5 top-4 z-20">
      <QuestionIcon
        v-if="0"
        :data-tooltip-target="'tooltip-' + name"
        data-tooltip-placement="left"
        class="w-4 h-4 inline-block text-gray-400 hover:text-gray-500 hover:cursor-pointer"
      />
      <div
        v-if="0"
        :id="'tooltip-' + name"
        role="tooltip"
        class="inline-block absolute invisible py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip w-56"
      >
        {{toolTip}}
        <div class="tooltip-arrow" data-popper-arrow></div>
      </div>
    </div>
  </div>
</div>
