import { ExportToCsv } from "export-to-csv";

export const exportToCSV = data => {
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: "Analysis",
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true
  };
  const csvExporter = new ExportToCsv(options);
  csvExporter.generateCsv(data);
};
