import baseField from "vue-formly-bootstrap/src/fields/baseField";

import WithRender from "./QRInputColorSwap.html";
import SwapIcon from "../icons/SwapIcon";
export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  created() {},
  components: {
    SwapIcon
  },
  computed: {
    swapFrom() {
      return this.field.templateOptions.swapFrom;
    },
    swapTo() {
      return this.field.templateOptions.swapTo;
    }
  },
  methods: {
    handleSwapColor() {
      const { swapFrom, swapTo } = this;
      this.$emit("click", {
        type: "SwapColor",
        swapFrom,
        swapTo
      });
    }
  }
});
