import { querySentences, mutationSentences } from "../gql";
import { DateTime, Settings } from "luxon";

const getModule = (queryAPI, mutationAPI) => {
  const state = {
    categories: [],
    curCategory: "",
    qrCodeData: {},
    qrCodeURL: "",
    categoryDefaultModels: {
      "dynamic-business": {
        company: "",
        headline: "",
        teaser: "",
        about: "",
        callToAction: {
          label: "View menu",
          url: ""
        },
        street: "",
        street_number: "",
        state: "",
        zip: "",
        city: "",
        country: "",
        features: { wifi: true, chair: true, disabled: true },
        name: "",
        phone: "",
        email: "",
        website: "",
        sharing: true,
        openingHours: {
          0: {
            enabled: true,
            values: [
              {
                from: "08:00",
                to: "20:00"
              }
            ]
          },
          1: {
            enabled: true,
            values: [
              {
                from: "08:00",
                to: "20:00"
              }
            ]
          },
          2: {
            enabled: true,
            values: [
              {
                from: "08:00",
                to: "20:00"
              }
            ]
          },
          3: {
            enabled: true,
            values: [
              {
                from: "08:00",
                to: "20:00"
              }
            ]
          },
          4: {
            enabled: true,
            values: [
              {
                from: "08:00",
                to: "20:00"
              }
            ]
          },
          5: {
            enabled: true,
            values: [
              {
                from: "08:00",
                to: "20:00"
              }
            ]
          },
          6: {
            enabled: true,
            values: [
              {
                from: "08:00",
                to: "20:00"
              }
            ]
          },
          timeFormat: "24"
        },
        channels: [],
        color1: "#447fb6",
        color2: "#e91e63",
        showAutocomplete: false,
        showPreview: 0
      },

      "dynamic-vcard-plus": {
        color1: "#455a64",
        color2: "#e91e63",
        show_gradient: false,
        show_directions: false,
        color1b: "#ff0000",
        sharing: false,
        company: "",
        firstname: "",
        lastname: "",
        numbers_mobile: "",
        numbers_phone: "",
        email: "",
        job: "",
        street_number: "",
        street: "",
        city: "",
        state: "",
        country: "",
        zip: ""
      },

      "dynamic-coupon": {
        company: "",
        headline: "",
        description: "",
        sale: "25% OFF",
        color1: "#447fb6",
        color2: "#e91e63",
        date: {
          startDate: DateTime.now().toFormat("dd LLL yyyy"),
          startTime: "00:00",
          endTime: "00:00",
          endDate: DateTime.now().plus({ days: 1 }).toFormat("dd LLL yyyy"),
          allDay: true,
          timeMode: "24",
          timezone: ""
        },
        "website.label": "Redeem now",
        "website.url": "",
        street: "",
        street_number: "",
        state: "",
        zip: "",
        city: "",
        country: "",
        barcode: "",
        showBarcode: false,
        showAutocomplete: true,
        terms: ""
      },

      "dynamic-video": {
        sharing: true,
        videos: [],
        videosArrange: 0,
        channels: [],
        color1: "#447fb6",
        color2: "#e91e63",
        callToAction: {
          label: "Get Yours",
          url: ""
        },
        company: "",
        headline: "",
        description: "",
        highlightVideo: true
      },

      "dynamic-social-media": {
        sharing: true,
        channels: [
          {
            type: "Website",
            label: "URL",
            id: "EjiOwiPkYY",
            value: "www.your-website.com",
            text: "Visit us online"
          },
          {
            type: "Facebook",
            label: "URL",
            id: "kZRgMVc9Os",
            value: "www.facebook.com",
            text: "Become a fan"
          },
          {
            type: "Youtube",
            label: "URL",
            id: "Qu5jU5uD21",
            value: "www.youtube.com",
            text: "Watch our videos"
          }
        ],
        color1: "#447fb6",
        color2: "#e91e63",
        headline: "",
        description: ""
      },

      "dynamic-image": {
        files: [],
        useGridView: false,
        color1: "#3c4245",
        color2: "#3c4245",
        callToAction: {
          label: "Shop Now",
          url: ""
        },
        title: "",
        website: "",
        description: "",
        showPreview: 0
      },

      "dynamic-text": {
        showPreview: 0,
        color1: "#447fb6",
        text: ""
      },

      "dynamic-url": {
        url: ""
      },

      "dynamic-instagram": {
        username: ""
      },

      "dynamic-event": {
        features: { wifi: true, chair: true, disabled: true },
        color1: "#447fb6",
        color2: "#e91e63",
        date: {
          startDate: DateTime.now().toFormat("dd LLL yyyy"),
          startTime: "00:00",
          endTime: "00:00",
          endDate: DateTime.now().plus({ days: 1 }).toFormat("dd LLL yyyy"),
          allDay: true,
          timeMode: "24",
          timezone: ""
        },
        sharing: true,
        callToAction: {
          label: "Get tickets",
          url: ""
        },
        organizer: "",
        title: "",
        teaser: "",
        about: "",
        venue: "",
        street: "",
        street_number: "",
        city: "",
        country: "",
        zip: "",
        state: "",
        contact: "",
        phone: "",
        email: "",
        website: ""
      },

      "dynamic-facebook": {
        title: "",
        color1: "#3766b8",
        color2: "#3766b8",
        hideFacebookLikeNumber: false,
        facebookUrl: "",
        facebookId: "",
        name: "",
        website: "",
        showPreview: 0
      },

      "dynamic-feedback": {
        categories: [
          {
            id: 1,
            name: "Room",
            icon: "bed",
            sub: [
              { id: 1, name: "Subcategory 1.1" },
              { id: 2, name: "Subcategory 1.2" },
              { id: 3, name: "Subcategory 1.3" },
              { id: 4, name: "Subcategory 1.4" }
            ]
          },
          {
            id: 2,
            name: "Restaurant",
            icon: "utensils",
            sub: [
              { id: 5, name: "Subcategory 2.1" },
              { id: 6, name: "Subcategory 2.2" }
            ]
          },
          {
            id: 3,
            name: "Selection",
            icon: "cart-shopping",
            sub: [{ id: 7, name: "Subcategory 3.1" }]
          },
          {
            id: 4,
            name: "Service",
            icon: "spinner",
            sub: []
          }
        ],
        page: {
          backIndex: 0,
          index: 1,
          categoryId: 1,
          feedback: {
            1: {
              1: {
                rating: 5,
                message: "Experiență excelentă!",
                image: ""
              },
              2: {
                rating: 4,
                message: "Mâncare delicioasă!",
                image: ""
              },
              3: {
                rating: 1,
                message: "",
                image: ""
              }
            },
            2: {
              5: {
                rating: 3,
                message: "Serviciu mediu.",
                image: ""
              },
              6: {
                rating: 2,
                message: "Prețuri ridicate pentru calitatea oferită.",
                image: ""
              }
            },
            3: {
              7: {
                rating: 1,
                message: "Prețuri mici.",
                image: ""
              }
            }
          }
        },
        showPreview: 0,
        color1: "#447fb6",
        color2: "#e91e63",
        welcome_extra: {
          zoom: 50,
          background: "#ffffff"
        },
        callToAction: {
          label: "Shop online",
          url: "https://www.youtube.com/"
        },
        company: "Hotel",
        title: "Give us a feedback",
        description: "Please select to review a category.",
        email: "dragosmatteod@gmail.com",
        website: "https://google.com",
        message: "Go to our website",
        welcome_image:
          "https://s3.eu-central-1.amazonaws.com/qrcgappcdn/video-solution/welcome.png",
        gotItText:
          "Name this optional button as you want and link to any website of your choice."
      },

      "dynamic-rating": {
        rating: {
          question: "How was your rental experience?",
          type: "smiley",
          value: 0
        },
        channels: [
          {
            input_prefix_label: "URL",
            link: "https://www.myfitnesspal.com/",
            label: "",
            icon: "internet-explorer",
            name: "Website"
          },
          {
            input_prefix_label: "FACEBOOK",
            link: "https://www.facebook.com/",
            label: "",
            icon: "facebook-f",
            name: "Facebook"
          },
          {
            input_prefix_label: "YouTube",
            link: "https://www.youtube.com/",
            label: "",
            icon: "youtube",
            name: "YouTube"
          }
        ],
        color1: "#0090e3",
        color2: "#e37b00",
        avatar_extra: {
          zoom: 100,
          background: "#ffffff"
        },
        welcome_extra: {
          zoom: 50,
          background: "#ffffff"
        },
        avatar:
          "https://s3.eu-central-1.amazonaws.com/qrcgappcdn/rating-solution/header.jpg",
        company: "Lake Cruisers",
        website: "www.lakecruisers.com",
        email: "dragosmatteod@gmail.com",
        showPreview: 0,
        welcome_screen:
          "https://s3.eu-central-1.amazonaws.com/qrcgappcdn/rating-solution/welcome.png"
      },

      "dynamic-pdf": {
        file: {},
        closePreview: true,
        isPdfUploading: false,
        linkToPDF: false,
        color1: "#da5167",
        color2: "#464154",
        company: "",
        title: "",
        description: "",
        website: ""
      },

      "dynamic-mp3": {
        color1: "#3c4245",
        color2: "#ff887e",
        file: {},
        title: "",
        info: "",
        website: "",
        callToAction: {
          label: "Visit us online",
          url: ""
        },
        isMp3Uploading: false,
        addDownloadOption: false
      },

      "dynamic-app": {
        directLinking: 0,
        openWindow: false,
        avatar: "",
        color1: "#ff9071",
        color2: "#ff7e64",
        fallbackUrl: "",
        stores: [],
        button: {
          label: "Learn more",
          url: ""
        },
        name: "",
        company: "",
        title: "",
        description: "",
        website: "",
        showPreview: 0,
        welcome_screen: {}
      }
    }
  };

  const getters = {
    getCategories(state) {
      return state.categories;
    },
    getSelectedCategory(state) {
      return state.curCategory;
    },
    getQrCodeId(state) {
      return state.qrCodeData?.id || "";
    },
    getQrCodeURL(state) {
      return state.qrCodeURL || "";
    },
    getDefaultCategoryModel(state) {
      return state.categoryDefaultModels[state.curCategory] || {};
    }
  };

  const mutations = {
    setCategories(state, data) {
      state.categories = data;
    },
    setSelectedCategory(state, data) {
      state.curCategory = data;
    },
    setQRCodeData(state, data) {
      state.qrCodeData = data;
    },
    setImageURL(state, url) {
      state.qrCodeURL = url;
    }
  };

  const actions = {
    // normal actions

    // graphQL queries
    async fetchQrCategories({ commit, dispatch }) {
      try {
        commit("setLoading", true);
        const { qrTypesByAction } = await queryAPI({
          query: querySentences.fetchQRTypes,
          variables: {
            action: "dynamic",
            withAction: true
          }
        });
        commit("setCategories", qrTypesByAction);
        dispatch("updateCurrentQrType");
      } catch (error) {
        console.log(error);
      } finally {
        commit("setLoading", false);
      }
    },
    requestQRCodeImgUrl({ commit }, id) {
      commit("setLoading", true);
      queryAPI({ query: querySentences.fetchQrCodeImageUrl, variables: { id } })
        .then(data => {
          commit("setImageURL", data.qrCodeUrlById);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
    // graphQL mutations
    async updateQRCodeByCategory({ commit, dispatch, getters }, input) {
      try {
        const codeId = getters.getQrCodeId;
        const { updateQrCode } = await mutationAPI({
          // Query
          mutation: mutationSentences.updateQrCode,
          // Parameters
          variables: {
            input: { ...input, id: codeId }
          }
        });
        commit("setQRCodeData", updateQrCode);
        dispatch("requestQRCodeImgUrl", updateQrCode.id);
        return updateQrCode;
      } catch (error) {
        throw error;
      }
    },

    updateSelectedCategory({ commit, dispatch }, category) {
      // set current commit
      commit("setSelectedCategory", category);
      dispatch("updateCurrentQrType");
    }
  };
  return {
    state,
    getters,
    mutations,
    actions
  };
};

export default getModule;
