import WithRender from "./SettingsModal.html";
import VueDatePick from "vue-date-pick";

export default WithRender({
  props: {
    visibility: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: {
        medium: "",
        print: "",
        start: "",
        end: ""
      }
    }
  },
  components: {
    VueDatePick
  },
  mounted() {
    // set the modal menu element
    const thisModalRef = this.$refs.optionsModal;

    // options with default values
    const thisModalOptions = {
      placement: "top-center",
      backdrop: true,
      backdropClasses: "bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed z-40"
    };
    this.modal = new window.Modal(thisModalRef, thisModalOptions);

    //
    // set the dropdown menu element
    const mediumMenu = this.$refs.mediumMenu;

    // set the element that trigger the dropdown menu on click
    const mediumBtn = this.$refs.mediumBtn;

    // options with default values
    const mediumOptions = {
      placement: "bottom"
    };
    this.mediumDropdown = new window.Dropdown(
      mediumMenu,
      mediumBtn,
      mediumOptions
    );
  },
  data() {
    return {
      modal: null,
      mediumDropdown: null,
      inputValueStart: "",
      mediumItems: [
        { value: "", label: "No material" },
        { value: "banners", label: "Banners" },
        { value: "books", label: "Books" },
        { value: "bottle & cans", label: "Bottle & Cans" },
        { value: "brochures", label: "Brochures" },
        { value: "business cards", label: "Business Cards" },
        { value: "clothing", label: "Clothing" },
        { value: "displays", label: "Displays" },
        { value: "flyers", label: "Flyers" },
        { value: "food packaging", label: "Food Packaging" },
        { value: "labels & stickers", label: "Labels & Stickers" },
        { value: "newspapers & magazi", label: "Newspapers & Magazi" },
        { value: "posters", label: "Posters" },
        { value: "product packaging", label: "Product Packaging" },
        { value: "stationery", label: "Stationery" },
        { value: "table tents", label: "Table Tents" },
        { value: "tickets", label: "Tickets" },
        { value: "vehicles", label: "Vehicles" },
        { value: "websites", label: "Websites" },
        { value: "windows", label: "Windows" },
        { value: "art", label: "Art" },
        { value: "badges", label: "Badges" },
        { value: "billboards", label: "Billboards" },
        { value: "catalogs", label: "Catalogs" },
        { value: "cinema advertising", label: "Cinema Advertising" },
        { value: "digital signage", label: "Digital Signage" },
        { value: "ebooks & ezines", label: "Ebooks & Ezines" },
        { value: "emails", label: "Emails" },
        { value: "giveaways", label: "Giveaways" },
        { value: "infographics", label: "Infographics" },
        { value: "menus", label: "Menus" },
        { value: "street signs", label: "Street Signs" },
        { value: "video games", label: "Video Games" },
        { value: "web banners", label: "Web Banners" },
        { value: "social media", label: "Social Media" },
        { value: "tv commercials", label: "TV Commercials" }
      ]
    };
  },
  methods: {
    onHide() {
      this.$emit("hide", false);
    },
    onSaveAction() {
      this.$emit("save", this.inputValue);
    },
    onMediumClick(item) {
      this.inputValue.medium = item;
      this.mediumDropdown.hide();
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  },
  watch: {
    visibility(newV) {
      if (this.modal) {
        if (newV) {
          this.modal.show();
        } else {
          this.modal.hide();
        }
      }
    }
  }
});
