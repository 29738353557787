const getModule = () => {
  const state = {
    editorMode: true,
    edit_mode: 0,
    editorLocale: "en",
    current_step: "create_new"
  };

  const getters = {
    getEditorMode: state => {
      return state.editorMode;
    },
    getEditorLocale: state => {
      return state.editorLocale;
    },
    getCurrentStep: state => {
      return state.current_step;
    },
    getEditMode: state => {
      return state.edit_mode;
    }
  };

  const mutations = {
    changeCurrentStep(state, current_step) {
      state.current_step = current_step;
    },
    changeEditorMode(state, payload) {
      state.editorMode = payload;
    },
    changeEditorLocale(state, payload) {
      state.editorLocale = payload;
    },
    setEditMode(state, payload) {
      state.edit_mode = payload;
    }
  };
  const actions = {
    changeCurrentStep: function ({ commit, dispatch }, payload) {
      commit("changeCurrentStep", payload);
      if (payload == "create_new") {
        dispatch("setQrCurrentDesignToDefault");
        dispatch("setEditMode", 0);
        dispatch("setCurrentQrProps", {
          id: 0,
          type_id: 5,
          url: ""
        });
      }
    },
    changeEditorMode: function ({ commit, dispatch }, payload) {
      commit("changeEditorMode", payload);
    },
    changeEditorLocale: function ({ commit, dispatch }, payload) {
      commit("changeEditorLocale", payload);
    },
    setEditMode: function ({ commit, dispatch }, payload) {
      commit("setEditMode", payload);
    }
  };

  return {
    state,
    getters,
    mutations,
    actions
  };
};
export default getModule;
