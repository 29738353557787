import baseField from "vue-formly-bootstrap/src/fields/baseField";

import WithRender from "./QRInputCheckbox.html";
import QuestionIcon from "../icons/QuestionIcon";

export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  components: { QuestionIcon },
  created() {
    this.value = this.model[this.name] ?? "";
  },
  data() {
    return {
      value: ""
    };
  },
  computed: {
    name() {
      return this.field.key;
    },
    label() {
      return this.field.templateOptions.label;
    },
    placeHolder() {
      return this.field.templateOptions.placeHolder;
    },
    toolTip() {
      return this.field.templateOptions.tooltip;
    }
  },
  methods: {
    onUpdateValue() {
      const { name, value } = this;
      this.$emit("update", { name, value });
    }
  },
  watch: {
    model: {
      handler(newValue, oldValue) {
        if (oldValue[this.name] == newValue[this.name]) return;
        this.value = newValue[this.name];
      },
      deep: true
    }
  }
});
