// couponsOption
export const createDynamicFacebookFieldsWidget = (formly, t) => {
  return [
    formly.container(
      formly.header({
        index: 1,
        sectionName: "",
        headline: "",
        hideCollapse: true,
        icon: null
      }),
      formly.body({
        name: "section_basic_info",
        collapseKey: "section_basic_info",
        widget: 1,
        key: "code",
        content: [
          formly.avatarRectangular({
            tooltip: t(
              "Upload an event photo from your computer. Images must be at least 640x360 px in .jpg or .png format."
            ),
            previewWelcomeScreen: false,
            key: "avatar",
            aspectRatio: 1.77
          }),
          {
            className: "m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          },
          formly.inputRowRequired({
            className: "form-input-row form-row-container flex flex-col",
            label: "Facebook URL",
            placeholder: "www.facebook/yourpage",
            key: "facebookUrl",
            required: true,
            errorMsgTitle: t("Please provide a facebook url."),
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(t("Please provide a facebook url."));
                  return false;
                }

                return true;
              }
            }
          }),
          {
            className: "form-input-row form-row-container my-2",
            type: "field_group",
            fieldGroup: [
              {
                type: "qrinput_checkbox",
                key: "code",
                templateOptions: {
                  label: t("Hide the number of Likes on the Like button"),
                  trueValue: true,
                  falseValue: false
                }
              }
            ]
          },

          formly.inputRow({
            key: "name",
            className: "form-input-row form-row-container flex flex-col",
            label: t("Name"),
            placeholder: t(
              "Company, brand or artist who runs the Facebook Page"
            )
          }),
          formly.inputRow({
            key: "title",
            className: "form-input-row form-row-container flex flex-col",
            label: t("Title"),
            placeholder: t("Click on the Like Button to follow us on Facebook")
          }),
          formly.inputRow({
            label: t("Website"),
            placeholder: t("www.your-website.com"),
            key: "website",
            filters: {
              makeUrl: function ({ value }) {
                if (value.trim() !== "" && !value.match(/:\/\//)) {
                  value = "http" + "://" + value;
                }
                return value;
              }
            },
            validators: {
              validUrl: function ({ messages, name, value }) {
                if (
                  value !== "" &&
                  !value.match(
                    /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                  )
                ) {
                  messages.push(t("Please enter a valid address."));
                  return false;
                }
                return true;
              }
            }
          })
        ]
      })
    )
  ];
};

export const createDynamicFacebookRequiredFieldsWidget = _ => {
  return ["facebookUrl"];
};
