<div
  class="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
>
  <div class="w-full max-w-md space-y-8">
    <div>
      <PrintQrLogo class="mx-auto h-12 w-auto" alt="PrintQr" />
      <h2
        class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-700"
      >
        {{$t('register.title')}}
      </h2>
    </div>
    <p v-if="error" class="mt-2 text-sm text-red-600 dark:text-red-500">
      <span class="font-medium">{{$t('register.error')}}</span> {{error}}
    </p>
    <form class="mt-8 space-y-6">
      <input type="hidden" name="remember" value="true" />
      <div class="-space-y-px rounded-md shadow-sm">
        <div>
          <label for="email-address" class="sr-only"
            >{{$t('register.email')}}</label
          >
          <input
            id="email-address"
            name="email"
            type="email"
            autocomplete="email"
            required
            class="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-700 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            :placeholder="$t('register.email')"
            v-model="userEmail"
          />
        </div>
        <div>
          <label for="name" class="sr-only">{{$t('register.name')}}</label>
          <input
            id="name"
            name="email"
            type="name"
            autocomplete="name"
            required
            class="relative block w-full appearance-none rounded-none border border-gray-300 px-3 py-2 text-gray-700 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            :placeholder="$t('register.name')"
            v-model="userName"
          />
        </div>
        <div>
          <label for="password" class="sr-only"
            >{{$t('register.password')}}</label
          >
          <input
            id="password"
            name="password"
            type="password"
            autocomplete="current-password"
            required
            class="relative block w-full appearance-none rounded-none border border-gray-300 px-3 py-2 text-gray-700 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            v-model="userPwd"
            :placeholder="$t('register.password')"
          />
        </div>
        <div>
          <label for="confirm_password" class="sr-only"
            >{{$t('register.confirm_password')}}</label
          >
          <input
            id="confirm_password"
            name="confirm_password"
            type="password"
            required
            class="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-700 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            v-model="userCPwd"
            :placeholder="$t('register.confirm_password')"
          />
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div class="flex items-center"></div>

        <div class="text-sm">
          <a
            href="#login"
            @click="(event) => goToLogin(event)"
            class="font-medium text-indigo-600 hover:text-indigo-500"
            >{{$t('register.login')}}</a
          >
        </div>
      </div>

      <vue-recaptcha
        ref="recaptcha"
        @verify="verifyRecaptcha"
        @expired="expiredRecaptcha"
        @render="renderRecaptcha"
        @error="errorRecaptcha"
        :sitekey="recaptchaKey"
      ></vue-recaptcha>

      <div>
        <button
          type="button"
          @click="handleRegister"
          class="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span class="absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
              class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
          {{$t('register.signup')}}
        </button>
      </div>
    </form>
  </div>
</div>
