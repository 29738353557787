import WithRender from "./Options.html";
import DownloadIcon from "../../icons/DownloadIcon";
import SwapIcon from "../../icons/SwapIcon";
import CogIcon from "../../icons/CogIcon";

export default WithRender({
  components: {
    DownloadIcon,
    SwapIcon,
    CogIcon
  },
  data() {
    return {
      optionsDropdown: null
    };
  },
  mounted() {
    const optionsTrig = this.$refs.optionsBtn;
    const optionsTgt = this.$refs.optionsMenu;
    this.optionsDropdown = new window.Dropdown(optionsTgt, optionsTrig, {
      placement: "bottom"
    });
  },
  methods: {
    onDownloadCSV() {
      this.$emit("menuClicked", "downloadCSV");
    },
    onResetScan() {
      this.$emit("menuClicked", "resetScan");
    }
  }
});
