import baseField from "vue-formly-bootstrap/src/fields/baseField";

import WithRender from "./QRInputLabel.html";
import QuestionIcon from "../icons/QuestionIcon";
export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  components: {
    QuestionIcon
  },
  computed: {
    label() {
      return this.field.templateOptions.label;
    },
    smallLabel() {
      return this.field.templateOptions.smallLabel;
    },
    toolTip() {
      return this.field.templateOptions.tooltip;
    },
    hideExpression() {
      return this.field.hideExpression;
    },
    isHide() {
      return (
        typeof this.hideExpression === "function" &&
        this.hideExpression(this.model)
      );
    },
    required() {
      return !!this.field.templateOptions.required;
    }
  },
  created() {}
});
