<div class="w-full text-sm">
  <div class="">
    <div class="mb-6 flex w-full items-center" v-for="link in links">
      <label :for="`label-${link.id}`"
        class="w-20 flex-shrink-0 text-qr_color-blue_2 text-16 font-semibold inputTitle">{{link.type}}
      </label>

      <div class="flex-shrink-0 w-8 h-8 p-1 text-qr_color-yellow_icon mr-8">
        <component :is="link.type + 'Icon'" class="w-6 h-6 fill-current"></component>
      </div>
      <div class="flex flex-grow flex-col">
        <div class="flex flex-grow">
          <span
            class="inline-flex items-center px-5 bg-qr_color-gray_3 h-12 rounded-l-qr_input_radius text-qr_color-gray">
            {{link.label}}
          </span>
          <input type="text" :id="`label-${link.id}`" :name="`social_${link.id}`" :value="link.value"
            class="indent-8 bg-qr_color-gray_3 h-12 px-2.5 py-2 text-qr_color-gray peer rounded-r-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent"
            @change="(event)=>onSocialUpdate(event)" :placeholder="link.label" v-on="inputListeners"
            :hasValidators="hasValidators" />
        </div>
        <div v-if="withTextInputs" class="flex flex-grow mt-2">
          <span
            class="inline-flex items-center px-5 bg-qr_color-gray_3 h-12 rounded-l-qr_input_radius text-qr_color-gray">
            {{$t('Text')}}
          </span>
          <input type="text" :id="`labeltext-${link.id}`" :name="`socialtext_${link.id}`" :value="link.text"
            class="indent-8 bg-qr_color-gray_3 h-12 px-2.5 py-2 text-qr_color-gray peer rounded-r-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent"
            @change="(event)=>{onSocialTextUpdate(event)}" v-on="inputListeners" :hasValidators="hasTextValidators" />
        </div>
      </div>
      <span class="text-qr_color-error">{{getErrorMessage(link.type)}}</span>
      <div class="ml-2 w-12 p-1">
        <button type="button" class="text-qr_color-blue_2" @click="onRemoveSocial(link)">
          <CloseIcon class="w-4 h-4 fill-current"> </CloseIcon>
        </button>
      </div>
    </div>
  </div>
  <div v-if="required && !hasLink('Website') &&  !hasLink('Instagram') &&
  !hasLink('Whatsapp') &&
  !hasLink('Spotify') &&
  !hasLink('Telegram') &&
  !hasLink('Twitter') &&
  !hasLink('Youtube') &&
  !hasLink('Vimeo') &&
  !hasLink('Flickr') &&
  !hasLink('Dribbble') &&
  !hasLink('Github') &&
  !hasLink('Tumblr') &&
  !hasLink('Reddit') &&
  !hasLink('Linkedin') &&
  !hasLink('Pinterest') &&
  !hasLink('Xing') &&
  !hasLink('Yelp') &&
  !hasLink('Facebook')">
    <span class="text-qr_color-error">{{getErrorMessage("Global")}}</span>
    <input type="text" class="border-0 w-0 h-0 focus:ring-0 focus:outline-none" value="" :hasValidators="required"
      v-on="inputListeners" />
  </div>
  <div class="w-full flex flex-col">
    <div class="w-full text-16 text-qr_color-blue_2 font-semibold flex-row flex">
      <div class="flex">{{$t('qr_media_channels.add_more')}}:</div>
      <div class="text-qr_color-gray flex pl-16">
        {{$t('qr_media_channels.click_icon')}}.
      </div>
    </div>
    <div class="flex-grow">
      <div class="grid grid-cols-7 gap-x-4 gap-y-4 mt-5">
        <button type="button" class="text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
          @click="addButton('Website', 'URL')">
          <WebsiteIcon class="w-6 h-6 fill-current"></WebsiteIcon>
        </button>

        <button type="button" class="text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
          @click="addButton('Instagram', 'URL')">
          <InstagramIcon class="w-6 h-6 fill-current" />
        </button>
        <button type="button" class="text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
          @click="addButton('Whatsapp', 'Phone')">
          <WhatsappIcon class="w-6 h-6 fill-current" />
        </button>
        <button type="button" class="text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
          @click="addButton('Spotify', 'URL')">
          <SpotifyIcon class="w-6 h-6 fill-current" />
        </button>
        <button type="button" class="text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
          @click="addButton('Telegram', 'URL')">
          <TelegramIcon class="w-6 h-6 fill-current" />
        </button>
        <button type="button" class="text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
          @click="addButton('Twitter', '@')">
          <TwitterIcon class="w-6 h-6 fill-current" />
        </button>
        <button type="button" class="text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
          @click="addButton('Youtube', 'URL')">
          <YoutubeIcon class="w-6 h-6 fill-current" />
        </button>
        <button type="button" class="text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
          @click="addButton('Vimeo', 'URL')">
          <VimeoIcon class="w-6 h-6 fill-current" />
        </button>
        <button type="button" class="text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
          @click="addButton('Flickr', 'URL')">
          <FlickrIcon class="w-6 h-6 fill-current" />
        </button>
        <button type="button" class="text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
          @click="addButton('Dribbble', 'URL')">
          <DribbbleIcon class="w-6 h-6 fill-current" />
        </button>
        <button type="button" class="text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
          @click="addButton('Github', 'URL')">
          <GithubIcon class="w-6 h-6 fill-current" />
        </button>
        <button type="button" class="text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
          @click="addButton('Tumblr', 'URL')">
          <TumblrIcon class="w-6 h-6 fill-current" />
        </button>
        <button type="button" class="text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
          @click="addButton('Reddit', 'URL')">
          <RedditIcon class="w-6 h-6 fill-current" />
        </button>
        <button type="button" class="text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
          @click="addButton('Linkedin', 'URL')">
          <LinkedinIcon class="w-6 h-6 fill-current" />
        </button>
        <button type="button" class="text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
          @click="addButton('Pinterest', 'URL')">
          <PinterestIcon class="w-6 h-6 fill-current" />
        </button>
        <button type="button" class="text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
          @click="addButton('Xing', 'URL')">
          <XingIcon class="w-6 h-6 fill-current" />
        </button>
        <button type="button" class="text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
          @click="addButton('Yelp', 'URL')">
          <YelpIcon class="w-6 h-6 fill-current" />
        </button>
        <button type="button" class="text-qr_color-gray hover:text-qr_color-gray_2 hover:cursor-pointer"
          @click="addButton('Facebook', 'URL')">
          <FacebookIcon class="w-6 h-6 fill-current" />
        </button>
      </div>
    </div>
  </div>
</div>