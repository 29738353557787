import WithRender from "./QrDirection.html";

export default WithRender({
  props: {
    value: {
      type: String,
      default: "all"
    }
  },
  data() {
    return {
      menuList: [
        { value: "ASC", label: this.$t("qr_direction.ASC") },
        { value: "DESC", label: this.$t("qr_direction.DESC") }
      ]
    };
  },
  mounted() {
    const triggerEle = this.$refs.qrDirectionBtn;
    const targetEle = this.$refs.qrDirectionMenu;
    new window.Dropdown(targetEle, triggerEle, {
      placement: "bottom"
    });
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  }
});
