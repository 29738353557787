import { mapGetters } from "vuex";
import WithRender from "./DetailFormly.html";
import { computeBackendEndpoint } from "../../../helpers/envHelpers";

export default WithRender({
  props: ["fields", "model", "edit"],
  data() {
    const {
      backendBaseUrl,
      endpoints: { backendTemplatePreview, backendTemplate }
    } = this.$printqGlobalOptions;
    return {
      form: {},
      previewMode: "preview",
      websiteUrl: "",
      qrCodeName: "",
      type: "website",
      validation: [],
      template_preview_endpoint: computeBackendEndpoint(
        backendBaseUrl,
        backendTemplatePreview
      ),
      template_endpoint: computeBackendEndpoint(backendBaseUrl, backendTemplate)
    };
  },
  components: {},
  computed: {
    ...mapGetters([
      "getQrUrlLandingPage",
      "getCurrentQrId",
      "getCurrentQr",
      "getEditorMode",
      "getEditMode",
      "getCurrentModel"
    ]),
    getQrBoxStyle() {
      return {
        backgroundImage: "url(" + this.getQrUrlLandingPage + ")"
      };
    },
    getTemplatePreviewUrl() {
      if (this.getCurrentQrId) {
        return this.template_preview_endpoint.replace(
          "{qrCodeId}",
          this.getCurrentQrId ?? ""
        );
      } else {
        console.log(this.template_endpoint);
        return this.template_endpoint.replace(
          "{qrTypeId}",
          this.getCurrentQr.type_id ?? ""
        );
      }
    }
  },
  mounted() {
    const handleQrLandingEvent = e => {
      if (this?.$refs?.templatePreviewIframe?.contentWindow) {
        this.$refs.templatePreviewIframe.contentWindow.postMessage(
          {
            type: "landingChangeData",
            code: { ...this.getCurrentModel },
            templateType: null,
            demo: true
          },
          "*"
        );
      }
    };

    // window.document.addEventListener(
    //   "qrLandingLoaded",
    //   handleQrLandingEvent,
    //   false
    // );

    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent =
      eventMethod === "attachEvent" ? "onmessage" : "message";
    eventer(messageEvent, e => {
      if (e.data === "qrLandingLoaded" || e.message === "qrLandingLoaded") {
        handleQrLandingEvent();
      }
    });
  },
  methods: {
    onPreviewModeUpdate(mode) {
      debugger;
      if (mode == "qrcode") {
        this.previewMode = mode;
      } else {
        this.previewMode = mode;
      }
    },
    onUpdate(v) {
      this.$emit("update", v);
    },
    onValidate(v) {
      this.validation.push(v);
      this.$emit("validate", v);
    },
    onClicked(v) {
      this.$emit("click", v);
    },
    onSubmitForm(e) {
      e.preventDefault();
      this.$emit("next");
    }
  }
});
