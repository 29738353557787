<div>
  <button
    ref="qrStatusBtn"
    type="button"
    class="flex ml-auto bg-qr_color-gray_3 h-12 px-5 py-2.5 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent flex items-center w-auto"
  >
    {{$t('qr_type_status.status')}}
    <svg
      class="ml-2 w-4 h-4"
      aria-hidden="true"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M19 9l-7 7-7-7"
      ></path>
    </svg>
  </button>

  <div ref="qrStatusMenu" class="hidden z-10 w-40 bg-white rounded shadow">
    <ul class="p-3 space-y-1 text-sm">
      <li v-for="menu in menuList" :key="menu.value">
        <div class="flex items-center rounded hover:bg-qr_color-blue_3">
          <input
            type="radio"
            :id="`radio-item-${menu.value}`"
            name="radio-qr-status"
            v-model="inputValue"
            class="sr-only peer"
            :value="menu.value"
          />
          <label
            :for="`radio-item-${menu.value}`"
            class="p-2 w-full text-sm font-medium text-gray-900 rounded peer-checked:bg-qr_color-blue_3 text-qr_color-gray_2 cursor-pointer"
            >{{menu.label}}</label
          >
        </div>
      </li>
    </ul>
  </div>
</div>
