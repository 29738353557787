<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
	y="0px" viewBox="0 0 488.8 488.8" xml:space="preserve">
	<g>
		<path
			d="M431.65,328.7V65.5c0-36.4-29.1-65.5-65.5-65.5h-243.5c-36.4,0-65.5,29.1-65.5,65.5v264.2c0,36.4,29.1,65.5,65.5,65.5h3.3
		l-55.3,59.3c-7.3,8.3-7.3,21.8,1,29.1c3.1,3.1,15,10.4,29.1-1l13.6-14.6h261l13.6,14.6c11.7,11.4,25,4.2,29.1,1
		c8.3-8.3,8.3-20.8,1-29.1l-55.3-59.3h2.3C402.45,395.2,431.65,365.1,431.65,328.7z M97.65,201.7h293.4v30.7l-146.7,46.3
		l-146.7-47.3V201.7z M391.05,65.5v94.7h-125.9V40.5h100.9C379.55,40.5,391.05,51.9,391.05,65.5z M122.65,40.5h100.9v119.6H97.65
		V65.5C97.65,51.9,109.15,40.5,122.65,40.5z M122.65,353.6c-13.5,0-25-11.4-25-25v-53.9c0,0,140.4,45.5,146.7,45.5
		c9.8,0,146.7-44.5,146.7-44.5v52.8c0,13.5-11.4,25-25,25h-243.4V353.6z M336.55,426.5h-183.3l29.1-31.2h125L336.55,426.5z" />
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
	<g>
	</g>
</svg>