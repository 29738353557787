export const createDynamicURLFields = (formly, t) => {
  return [
    formly.labelTitle({
      titleIcon: "EarthIcon",
      titleKey: "qrcode_title",
      titleTooltip: t(
        "Labeling QR Codes has been moved to this area. Names help you to stay organized and will only appear in your account and are not displayed to customers who scan your QR Codes."
      ),
      titlePlaceholder: t("My Website QR Code"),
      titleValue: null,
      buttonLabel: t("Got it")
    }),
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_url",
        fold: false,
        headline: t("Enter your website address"),
        icon: "DesignIcon"
      }),
      formly.body({
        name: "section_url",
        collapseKey: "section_url",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Type in the website to link with your QR Code")
          }),
          formly.inputRowRequired({
            key: "url",
            className: "form-input-row form-row-container flex-col",
            label: t("Website"),
            required: true,
            placeholder: t("https://www.my-website.com"),
            errorMsgTitle: t("Please provide an website."),
            filters: {
              makeUrl: function ({ value }) {
                if (value.trim() !== "" && !value.match(/:\/\//)) {
                  value = "http" + "://" + value;
                }
                return value;
              }
            },
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(
                    t("Please enter a website address and click on next.")
                  );
                  return false;
                }

                return true;
              },
              validUrl: function ({ messages, name, value }) {
                if (
                  value !== "" &&
                  !value.match(
                    /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                  )
                ) {
                  messages.push("Website is not a valid URL.");
                  return false;
                }
                return true;
              }
            }
          })
        ]
      })
    )
  ];
};

export const createDynamicURLRequiredFields = _ => {
  return ["url"];
};
