<div class="block lg:flex justify-between items-center">
  <div id="qr-type-dropdown" class="z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow"
    data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="top">
    <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-button">
      <li>
        <button type="button"
          class="inline-flex py-2 px-4 w-full hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
          {{$t('search_bar.mockups')}}
        </button>
      </li>
      <li>
        <button type="button"
          class="inline-flex py-2 px-4 w-full hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
          {{$t('search_bar.templates')}}
        </button>
      </li>
      <li>
        <button type="button"
          class="inline-flex py-2 px-4 w-full hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
          {{$t('search_bar.design')}}
        </button>
      </li>
      <li>
        <button type="button"
          class="inline-flex py-2 px-4 w-full hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
          {{$t('search_bar.logos')}}
        </button>
      </li>
    </ul>
  </div>
</div>