import { mutationSentences, querySentences } from "../gql";

import { forEach } from "ramda";

const getModule = (queryAPI, mutationAPI) => {
  const state = {
    qr_patterns: [],
    qr_logos: [],
    qr_logo_templates: [],
    qr_current_design: {},
    qr_current_svg: ""
  };
  const default_qr_design = {
    id: 0,
    logo_id: null,
    logo_template_id: null,
    frame_template_id: null,
    background_color: "#FFFFFF",
    logo_type: "template",
    foreground_color: "#000000",
    color: "#000000",
    text_color: "#000000",
    text_font: "",
    text_font_url: "",
    qr_code_id: null,
    pattern_id: 1,
    inner_corner_color: "#000000",
    text: "Scan me",
    svg_text: "",
    color: "#000000",
    short_code: "",
    shortCodeLink: "",
    qr_url: "",
    outer_corner_color: "#000000"
  };

  const getters = {
    getQrPatterns(state) {
      return state.qr_patterns;
    },
    getQrLogos(state) {
      return state.qr_logos;
    },
    getQrLogoTemplates(state) {
      return state.qr_logo_templates;
    },
    getQrCurrentDesign(state) {
      return state.qr_current_design;
    },
    getQrCurrentSvg(state) {
      return state.qr_current_svg;
    }
  };

  const mutations = {
    setQrPatterns(state, qr_patterns) {
      state.qr_patterns = qr_patterns;
    },
    setQrCurrentDesignProps(state, { key, value }) {
      state.qr_current_design[key] = value;
    },
    setQrLogoTemplates(state, qr_logo_templates) {
      const defautTemplate = {
        id: null,
        url: null
      };
      state.qr_logo_templates = [defautTemplate, ...qr_logo_templates];
    },
    addQrLogo(state, logo) {
      state.qr_logos.push(logo);
    },
    updateLoadingLogo(state, { uploadLogo, loadingId }) {
      state.qr_logos = [
        ...state.qr_logos.map(qr_logo =>
          qr_logo.isLoading && qr_logo.loading_id === loadingId
            ? { ...uploadLogo }
            : qr_logo
        )
      ];
    },
    removeQrLoadingLogo(state, loadingId) {
      state.qr_logos = [
        ...state.qr_logos.filter(
          qr_logo => !(qr_logo.isLoading && qr_logo.loading_id === loadingId)
        )
      ];
    },
    deleteQrLogo(state, id) {
      state.qr_logos = [
        ...state.qr_logos.filter(qr_logo => !(qr_logo.id === id))
      ];
    },

    addQrLoadingLogo(state, loading_id) {
      state.qr_logos.push({ loading_id, isLoading: true, id: 0, url: null });
    },
    setQrLogos(state, qr_logos) {
      state.qr_logos = qr_logos;
    },

    setQrCurrentDesign(state, qr_current_design) {
      state.qr_current_design = qr_current_design;
    },
    setQrCurrentSvg(state, qr_current_svg) {
      state.qr_current_svg = qr_current_svg;
    }
  };
  const actions = {
    // graphQL info
    async fetchQrPatterns({ commit }) {
      const { qrPatterns } = await queryAPI({
        query: querySentences.qrPatterns
      });
      commit("setQrPatterns", qrPatterns);
    },
    async fetchQrLogoTemplates({ commit }) {
      const { qrLogoTemplates } = await queryAPI({
        query: querySentences.qrLogoTemplates
      });
      commit("setQrLogoTemplates", qrLogoTemplates);
    },
    async fetchQrLogos({ commit }) {
      const { qrLogos } = await queryAPI({
        query: querySentences.qrLogos
      });

      commit("setQrLogos", qrLogos);
    },
    openDesign({ dispatch }, payload) {
      dispatch("fetchDesignDetails", payload);
    },
    addQrLogo({ commit }, payload) {
      const { uploadLogo, loadingId } = payload;
      if (!loadingId) commit("addQrLogo", uploadLogo);
      else commit("updateLoadingLogo", { uploadLogo, loadingId });
    },
    addQrLoadingLogo({ commit }, payload) {
      commit("addQrLoadingLogo", payload);
    },
    removeQrLoadingLogo({ commit }, payload) {
      commit("removeQrLoadingLogo", payload);
    },
    deleteQrLogo({ commit }, payload) {
      mutationAPI({
        // Query
        mutation: mutationSentences.deleteUploadLogo,
        // Parameters
        variables: {
          ID: payload
        },
        debounce: 700
      })
        .then(({ deleteUploadLogo: logo }) => {
          if (logo) console.log({ deletedLogo: logo });
        })
        .catch(error => {
          console.log({ error });
        });

      commit("deleteQrLogo", payload);
    },
    setQrCurrentSvg({ commit, dispatch }, payload) {
      commit("setQrCurrentSvg", payload);
    },
    setQrCurrentDesignToDefault({ commit, dispatch }, payload) {
      commit("setQrCurrentDesign", { ...default_qr_design });
    },
    async createDesign({ commit, dispatch, getters }, payload) {
      commit("setLoadingQrModal", true);
      const response = await mutationAPI({
        // Query
        mutation: mutationSentences.createDesign,
        // Parameters
        variables: {
          ...getters.getQrCurrentDesign
        },
        debounce: 700
      });
      if (response.createDesign) {
        commit("setQrCurrentSvg", response.createDesign.svgContent);
        commit("setQrCurrentDesignProps", {
          qr_url: response.createDesign.qrCode.url
        });
        if (getters.getWidgetMode) {
          commit("setQrCurrentDesignProps", {
            key: "id",
            value: response.createDesign.qrCode.qrDesign.id
          });
        }
        commit("updateQrFromList", response.createDesign.qrCode);
      }
      commit("setLoadingQrModal", false);
    },

    previewChanges({ commit, dispatch }, payload) {
      commit("setQrCurrentDesignProps", payload);
      dispatch("createDesign");
    },
    previewChangesMultiple({ commit, dispatch }, payload) {
      let ommitCreate = false;
      forEach(item => {
        if (item.key != "stop") commit("setQrCurrentDesignProps", item);
        else ommitCreate = true;
      }, payload);
      if (!ommitCreate) {
        dispatch("createDesign");
      }
    },
    addNewOneToQrLogos({ commit }, newLogo) {
      commit("appendQrLogosAndTemplates", newLogo);
    }
  };

  return {
    state,
    getters,
    mutations,
    actions
  };
};
export default getModule;
