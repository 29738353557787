import baseField from "vue-formly-bootstrap/src/fields/baseField";
import { forEachObjIndexed, is } from "ramda";

import WithRender from "./QRMediaChannels.html";
import WebsiteIcon from "../icons/WebsiteIcon";
import InstagramIcon from "../icons/InstagramIcon";
import WhatsappIcon from "../icons/WhatsappIcon";
import SpotifyIcon from "../icons/SpotifyIcon";
import TelegramIcon from "../icons/TelegramIcon";
import TwitterIcon from "../icons/TwitterIcon";
import YoutubeIcon from "../icons/YoutubeIcon";
import VimeoIcon from "../icons/VimeoIcon";
import FlickrIcon from "../icons/FlickrIcon";
import DribbbleIcon from "../icons/DribbbleIcon";
import GithubIcon from "../icons/GithubIcon";
import TumblrIcon from "../icons/TumblrIcon";
import RedditIcon from "../icons/RedditIcon";
import LinkedinIcon from "../icons/LinkedinIcon";
import PinterestIcon from "../icons/PinterestIcon";
import XingIcon from "../icons/XingIcon";
import YelpIcon from "../icons/YelpIcon";
import CloseIcon from "../icons/CloseIcon";
import FacebookIcon from "../icons/FacebookIcon";
import { makeRNDStr } from "../../../helpers/helpers";
import { mapGetters } from "vuex";
export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  components: {
    WebsiteIcon,
    InstagramIcon,
    WhatsappIcon,
    SpotifyIcon,
    TelegramIcon,
    TwitterIcon,
    YoutubeIcon,
    VimeoIcon,
    FlickrIcon,
    DribbbleIcon,
    GithubIcon,
    TumblrIcon,
    RedditIcon,
    LinkedinIcon,
    PinterestIcon,
    XingIcon,
    YelpIcon,
    FacebookIcon,
    CloseIcon
  },
  created() {
    this.model[this.name] ?? [];
  },
  mounted() {
    this.links = this.getCurrentModel?.channels
      ? this.getCurrentModel.channels
      : [];
  },
  data() {
    return {
      links: [],
      errorMessage: {
        Website: "",
        Instagram: "",
        Whatsapp: "",
        Spotify: "",
        Telegram: "",
        Twitter: "",
        Youtube: "",
        Vimeo: "",
        Flickr: "",
        Dribbble: "",
        Github: "",
        Tumblr: "",
        Reddit: "",
        Linkedin: "",
        Pinterest: "",
        Xing: "",
        Yelp: "",
        Facebook: "",
        Global: ""
      }
    };
  },
  computed: {
    ...mapGetters(["getCurrentModel"]),
    name() {
      return this.field.key;
    },
    required() {
      return this.field?.templateOptions?.requiredAllChannels ?? false;
    },
    withTextInputs() {
      return this.field?.templateOptions?.withTextInputs ?? false;
    },
    hasValidators() {
      const validators = this?.field?.templateOptions?.validators ?? {};
      return Object.keys(validators).length > 0;
    },
    hasTextValidators() {
      const validators = this?.field?.templateOptions?.textValidators ?? {};
      return Object.keys(validators).length > 0;
    },
    inputListeners: function () {
      var vm = this;
      return Object.assign({}, this.$listeners, {
        validate: event => {
          if (!this.validate(event))
            this.$emit("validate", { element: event.target, event });
        }
      });
    },
    getErrorMessage() {
      return id => this.errorMessage[id] ?? "";
    }
  },
  methods: {
    addButton(iconName, label) {
      this.links.push({
        type: iconName,
        label: label,
        id: makeRNDStr(10),
        value: ""
      });
    },
    onSocialUpdate(e) {
      const { value, id } = e.target;
      const socialId = id.split("-")[1];
      this.links = this.links.map(link => {
        const newValue = this.applyFilters(value);
        if (link.id == socialId) {
          return { ...link, value: newValue };
        }
        return link;
      });
      if (this.validate(null, "link")) {
        this.errorMessage["Global"] = "";
      }
      this.$emit("update", {
        name: this.name,
        value: this.links
      });
    },
    onSocialTextUpdate(e) {
      const { value, id } = e.target;
      const socialId = id.split("-")[1];
      this.links = this.links.map(link => {
        const newValue = this.applyTextFilters(value);
        if (link.id == socialId) {
          return { ...link, text: newValue };
        }
        return link;
      });
      if (this.validate(null, "text")) {
        this.errorMessage["Global"] = "";
      }
      this.$emit("update", {
        name: this.name,
        value: this.links
      });
    },
    onRemoveSocial(link) {
      const { id } = link;
      this.errorMessage[link.type] = "";
      this.errorMessage["Global"] = "";
      this.links = this.links.filter(link => link.id != id);
      console.log("[onSocialUpdate]", this.links);
      this.$emit("update", {
        name: this.name,
        value: this.links
      });
    },
    applyFilters(value) {
      let newValue = value;
      const { name } = this;
      const filters = this?.field?.templateOptions?.filters ?? {};
      forEachObjIndexed((filter, key) => {
        newValue = filter({ name, value: newValue });
      }, filters);
      return newValue;
    },
    applyTextFilters(value) {
      let newValue = value;
      const { name } = this;
      const filters = this?.field?.templateOptions?.textFilters ?? {};
      forEachObjIndexed((filter, key) => {
        newValue = filter({ name, value: newValue });
      }, filters);
      return newValue;
    },
    hasLink(iconName) {
      return this.links.some(link => link.type === iconName);
    },
    validate(event, type = "all") {
      let isValid = true;

      const { name } = this;
      if (
        this.required &&
        !this.hasLink("Website") &&
        !this.hasLink("Instagram") &&
        !this.hasLink("Whatsapp") &&
        !this.hasLink("Spotify") &&
        !this.hasLink("Telegram") &&
        !this.hasLink("Twitter") &&
        !this.hasLink("Youtube") &&
        !this.hasLink("Vimeo") &&
        !this.hasLink("Flickr") &&
        !this.hasLink("Dribbble") &&
        !this.hasLink("Github") &&
        !this.hasLink("Tumblr") &&
        !this.hasLink("Reddit") &&
        !this.hasLink("Linkedin") &&
        !this.hasLink("Pinterest") &&
        !this.hasLink("Xing") &&
        !this.hasLink("Yelp") &&
        !this.hasLink("Facebook")
      ) {
        this.errorMessage["Global"] =
          this?.field?.templateOptions?.requiredMessage ??
          this.$t("At least one channel is required");
        isValid = false;
      }
      const validators = this?.field?.templateOptions?.validators ?? {};
      const textValidators = this?.field?.templateOptions?.textValidators ?? {};

      this.links.forEach(link => {
        let valid = true;
        const messages = [];
        if (type === "all" || type === "link") {
          forEachObjIndexed((validator, key) => {
            valid =
              valid &&
              validator({
                messages,
                name,
                value: link.value,
                links: this.links,
                link: link
              });
          }, validators);
        }
        if (type === "all" || type === "text") {
          forEachObjIndexed((validator, key) => {
            valid =
              valid &&
              validator({
                messages,
                name,
                value: link.text,
                links: this.links,
                link: link
              });
          }, textValidators);
        }
        this.errorMessage[link.type] = "";
        if (!valid) {
          this.errorMessage[link.type] = messages?.[0] ?? "";
        }
        isValid = isValid && valid;
      });

      return isValid;
    }
  },
  watch: {
    model: {
      handler(newValue, oldValue) {
        if (oldValue[this.name] == newValue[this.name]) return;
        this.links = newValue[this.name];
      },
      deep: true
    }
  }
});
