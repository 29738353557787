import welcome from "./img/business-welcome.png";

export const createDynamicBusinessPageFieldsWidget = (formly, t) => {
  return [
    formly.container(
      formly.header({
        index: 1,
        sectionName: "section_basic_info",
        headline: "",
        hideCollapse: true,
        icon: null
      }),
      formly.body({
        name: "section_basic_info",
        key: "code",
        widget: 1,
        collapseKey: "section_basic_info",
        content: [
          formly.avatarRectangular({
            tooltip: t(
              t(
                "Upload an image or logo from your computer. Images must be at least 640 x 360 px in .jpg or .png format."
              )
            ),
            aspectRatio: 1.77
          }),
          {
            className: "m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          },
          formly.inputRow({
            label: t("Company"),
            placeholder: t("Name of company or organization"),
            key: "company"
          }),
          formly.inputRowRequired({
            key: "headline",
            label: t("Headline"),
            className: "form-input-row form-row-container flex flex-col",
            placeholder: t("Add an engaging title to your page"),
            condition: "true",
            errorMsgTitle: t("Please provide an engaging title to your page."),
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(
                    t("Please provide an engaging title to your page.")
                  );
                  return false;
                }

                return true;
              }
            }
          }),
          formly.textAreaRow({
            label: t("Summary"),
            placeholder: t(
              "Write a short summary about the type and purpose of your business"
            ),
            key: "teaser",
            maxChar: 200
          }),
          {
            className: "m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          },
          {
            className: "form-row-container flex-col",
            type: "field_group",
            required: true,
            fieldGroup: [
              {
                type: "qr_input_label",
                templateOptions: {
                  label: t("Button")
                }
              },
              {
                className: "col-sm-12 col-md-8 box-input",
                key: "callToAction",
                type: "qraction_button",
                templateOptions: {
                  key: "callToAction.url",
                  urlPlaceholder: t("http://"),
                  defaultValue: {
                    label: t("View menu"),
                    url: ""
                  },
                  items: [
                    t("View menu"),
                    t("Shop online"),
                    t("Learn More"),
                    t("Apply now")
                  ],
                  addLabel: t("Add button"),
                  removeLabel: t("Remove button"),
                  customLabel: t("Custom"),
                  errorMessages: {
                    url: t("Please enter a valid website address"),
                    required: t("This field can not be empty")
                  },
                  filters: {
                    makeUrl: function ({ value }) {
                      if (value.trim() !== "" && !value.match(/:\/\//)) {
                        value = "http" + "://" + value;
                      }
                      return value;
                    }
                  },
                  validators: {
                    required: function ({ messages, name, value }) {
                      if (value.trim() == "") {
                        messages.push(t("This field can not be empty"));
                        return false;
                      }

                      return true;
                    },
                    validUrl: function ({ messages, name, value }) {
                      if (
                        value !== "" &&
                        !value.match(
                          /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                        )
                      ) {
                        messages.push(t("Please enter a valid address."));
                        return false;
                      }
                      return true;
                    }
                  }
                }
              }
            ]
          },
          {
            className: "m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          },

          {
            className: "form-row-container flex-col",
            type: "field_group",
            fieldGroup: [
              {
                type: "qr_input_label",
                templateOptions: {
                  label: t("Opening Hours")
                }
              },
              {
                type: "qr-opening-hours",
                key: "openingHours"
              }
            ]
          },

          {
            className: "m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          },
          {
            className: "form-row-container flex-col",
            type: "field_group",
            fieldGroup: [
              formly.autocompleteInputForm({
                wrappedInAddressKey: false
              })
            ]
          },
          {
            className: "m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          },
          {
            className: "form-row-container flex-col",
            type: "field_group",
            fieldGroup: [
              formly.features({
                key: "features",
                label: t("Features"),
                text: t(
                  "Choose amenities available at your venue. Recommended for gastronomy and retail."
                )
              })
            ]
          },

          {
            className: "m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          },
          {
            className: "form-row-container flex-col",
            type: "field_group",
            fieldGroup: [
              formly.inputRow({
                label: t("Name"),
                placeholder: t("Your Name"),
                key: "name"
              }),
              formly.inputRow({
                label: t("Phone"),
                placeholder: t("Phone Number"),
                key: "phone"
              }),
              formly.inputRow({
                label: t("Email"),
                placeholder: t("your@email.com"),
                key: "email"
              }),
              formly.inputRow({
                label: t("Website"),
                placeholder: t("www.your-website.com"),
                key: "website",
                filters: {
                  makeUrl: function ({ value }) {
                    if (value.trim() !== "" && !value.match(/:\/\//)) {
                      value = "http" + "://" + value;
                    }
                    return value;
                  }
                },
                validators: {
                  validUrl: function ({ messages, name, value }) {
                    if (
                      value !== "" &&
                      !value.match(
                        /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                      )
                    ) {
                      messages.push(t("Please enter a valid address."));
                      return false;
                    }
                    return true;
                  }
                }
              })
            ]
          },
          {
            className: "m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          },
          {
            className: "form-row-container flex-col",
            type: "field_group",
            fieldGroup: [
              formly.socialChannels({
                key: "channels",
                requiredAllChannels: false,
                sortable: false,
                showLabels: false,
                helpMessage: false,
                requiredMessage: t("At least one channel is required"),
                hasLabel: false,
                hasOrder: false,
                validators: {
                  required: function ({ messages, name, value }) {
                    if (value.trim() == "") {
                      messages.push(t("This field can not be empty"));
                      return false;
                    }
                    return true;
                  }
                },
                textValidators: {
                  required: function ({ messages, name, value }) {
                    if (value.trim() == "") {
                      messages.push(t("This field can not be empty"));
                      return false;
                    }
                    return true;
                  }
                }
              })
            ]
          }
        ]
      })
    )
  ];
};

export const createDynamicBusinessRequiredFieldsWidget = _ => {
  return ["headline"];
};
