import WithRender from "./HorizontalBar.html";

export default WithRender({
  props: {
    color: {
      type: String,
      default: "#62c641"
    },
    value: {
      type: Number | String,
      default: 0
    },
    tooltip: {
      type: String,
      default: ""
    }
  },
  mounted() {
    const targetEl = this.$refs.tooltipContent;
    const triggerEl = this.$refs.tooltipBtn;
    // options with default values
    const options = {
      placement: "bottom",
      triggerType: "hover"
    };
    new Tooltip(targetEl, triggerEl, options);
  }
});
