<svg
  xmlns="http://www.w3.org/2000/svg"
  width="31px"
  height="31px"
  viewBox="0 0 40.5 31.5"
>
  <path
    class="fill-current"
    id="Icon_awesome-images"
    data-name="Icon awesome-images"
    d="M33.75,29.25v1.125a3.375,3.375,0,0,1-3.375,3.375h-27A3.375,3.375,0,0,1,0,30.375v-18A3.375,3.375,0,0,1,3.375,9H4.5V23.625a5.631,5.631,0,0,0,5.625,5.625Zm6.75-5.625v-18A3.375,3.375,0,0,0,37.125,2.25h-27A3.375,3.375,0,0,0,6.75,5.625v18A3.375,3.375,0,0,0,10.125,27h27A3.375,3.375,0,0,0,40.5,23.625ZM18,9a3.375,3.375,0,1,1-3.375-3.375A3.375,3.375,0,0,1,18,9ZM11.25,19.125l3.9-3.9a.844.844,0,0,1,1.193,0L19.125,18l9.528-9.528a.844.844,0,0,1,1.193,0L36,14.625V22.5H11.25Z"
    transform="translate(0 -2.25)"
  ></path>
</svg>
