import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "apollo-link-error";
import { ApolloLink, Observable } from "apollo-link";
import VueApollo from "vue-apollo";
import { computeBackendEndpoint } from "./helpers/envHelpers";

const makeApollo = ({ backendBaseUrl, endpoints: { backendGraphql } }) => {
  const cache = new InMemoryCache();

  const request = async operation => {
    const token = localStorage.getItem("user-token");
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ""
      }
    });
  };

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle;
        Promise.resolve(operation)
          .then(oper => request(oper))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer)
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) handle.unsubscribe();
        };
      })
  );

  const httpOptions = {
    uri: computeBackendEndpoint(backendBaseUrl, backendGraphql)
  };

  const httpLink = ApolloLink.split(
    operation => operation.getContext().hasUpload,
    createUploadLink(httpOptions),
    new HttpLink(httpOptions)
  );

  const apolloClient = new ApolloClient({
    // You should use an absolute URL here
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        console.log(graphQLErrors, networkError);
        // if (graphQLErrors) {
        //   sendToLoggingService(graphQLErrors);
        // }
        // if (networkError) {
        //   logoutUser();
        // }
      }),
      requestLink,
      new createUploadLink({
        uri: computeBackendEndpoint(backendBaseUrl, backendGraphql)
      })
    ]),
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore"
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all"
      }
    },

    request: operation => {
      const token = localStorage.getItem("user-token");
      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : ""
        }
      });
    }
  });

  const apolloProvider = new VueApollo({
    defaultClient: apolloClient
  });

  return { apolloProvider, apolloClient };
};

export { makeApollo };
