<div class="relative">
  <div
    class="flex ml-auto bg-qr_color-gray_3 h-12 px-5 py-2.5 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent flex items-center w-auto"
  >
    <DateRangePicker
      ref="rangePicker"
      v-model="inputValue"
      :timePicker="false"
      control-container-class="text-gray-500 w-auto  hover:border-gray-300 focus:ring-0 focus:outline-none focus:ring-gray-300 font-medium text-sm px-2 py-1.5 text-center inline-flex items-center capitalize relative"
      class="border-0"
    >
      <template v-slot:input="optionsBtn">
        {{ optionsBtn.startDate | date }} - {{ optionsBtn.endDate | date }}
      </template>
    </DateRangePicker>
    <button ref="optionsBtn" type="button" class="ml-2 w-4 h-4">
      <svg
        class="w-4 h-4"
        :class="optionsDropdown && optionsDropdown._visible? 'rotate-180' : ''"
        aria-hidden="true"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 9l-7 7-7-7"
        ></path>
      </svg>
    </button>
  </div>

  <div ref="optionsMenu" class="z-10 w-40 bg-white rounded shadow block hidden">
    <ul class="p-3 space-y-1 text-sm">
      <li class="flex items-center rounded hover:bg-qr_color-blue_3">
        <button
          type="button"
          class="p-2 w-full text-sm font-medium text-gray-900 rounded peer-checked:bg-qr_color-blue_3 text-qr_color-gray_2 cursor-pointer text-left"
          @click="onSelectRange('auto')"
        >
          {{$t('time_range.auto-range')}}
        </button>
      </li>
      <li class="flex items-center rounded hover:bg-qr_color-blue_3">
        <button
          type="button"
          class="p-2 w-full text-sm font-medium text-gray-900 rounded peer-checked:bg-qr_color-blue_3 text-qr_color-gray_2 cursor-pointer text-left"
          @click="onSelectRange('48hours')"
        >
          {{$t('time_range.last_48_hours')}}
        </button>
      </li>
      <li class="flex items-center rounded hover:bg-qr_color-blue_3">
        <button
          type="button"
          class="p-2 w-full text-sm font-medium text-gray-900 rounded peer-checked:bg-qr_color-blue_3 text-qr_color-gray_2 cursor-pointer text-left"
          @click="onSelectRange('30days')"
        >
          {{$t('time_range.last_30_days')}}
        </button>
      </li>
      <li class="flex items-center rounded hover:bg-qr_color-blue_3">
        <button
          type="button"
          class="p-2 w-full text-sm font-medium text-gray-900 rounded peer-checked:bg-qr_color-blue_3 text-qr_color-gray_2 cursor-pointer text-left"
          @click="onSelectRange('12months')"
        >
          {{$t('time_range.last_12_months')}}
        </button>
      </li>
      <li v-if="0" class="flex items-center rounded hover:bg-qr_color-blue_3">
        <button
          type="button"
          class="p-2 w-full text-sm font-medium text-gray-900 rounded peer-checked:bg-qr_color-blue_3 text-qr_color-gray_2 cursor-pointer text-left"
          @click="onSelectRange('custom')"
        >
          {{$t('time_range.custom')}}
        </button>
      </li>
    </ul>
  </div>
</div>
