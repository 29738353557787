import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import VueHead from "vue-head";

import HomePage from "../pages/HomePage";

import WithRender from "./index.html";
import { getLayoutName, getMyAccountLayoutName } from "../layouts.js";
import { initExtString } from "../helpers/extString";

Vue.use(VueHead);

export default WithRender({
  name: "App",
  props: ["config"],
  components: {
    HomePage
  },
  data: function () {
    return {
      userReady: false,
      vers: process.env.VUE_APP_VERSION,
      isMobileClass: window.innerWidth <= 990 ? "qr_layoutMainMobile" : ""
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "isGuest", "isUserReady"]),
    getPortalElements: function () {
      return this.portalElements;
    },
    getLayout: function () {
      if (this.getIsMyAccount) {
        return getMyAccountLayoutName();
      }
      return getLayoutName(this.getLayoutType);
    }
  },
  methods: {
    ...mapActions([
      "changeEditorMode",
      "changeEditorLocale",
      "changeWidgetMode",
      "changeWidgetEditId",
      "changeWidgetEditorMode",
      "changeWidgetEditorPlaceCallback",
      "CreateGuestUser",
      "setUserToken",
      "logoutUser"
    ]),
    checkUser(tokenData) {
      const { success, data } = tokenData;
      if (!this.$printqGlobalOptions?.widgetMode) {
        const currentRoute = this.$router.currentRoute.name;
        if (currentRoute === "register") {
        } else {
          if (success) {
            const is_guest = data?.is_guest || false;

            if (is_guest) {
              this.logoutUser();

              if (currentRoute !== "login") {
                this.$router.push({ name: "login" });
              }
            }
          } else {
            this.logoutUser();
            if (currentRoute !== "login") {
              this.$router.push({ name: "login" });
            }
          }
        }
      }
      this.userReady = true;
    }
  },
  created() {
    window.printqQrVersion = process.env.VUE_APP_VERSION;
    window.globalStore = this.$store;
    this.userReady = false;
    let token = this.isAuthenticated;

    if (!token && this.$printqGlobalOptions?.widgetMode) {
      this.CreateGuestUser().then(tokenData => {
        const { success, data } = tokenData;
        if (success) {
          const { token } = data;
          this.setUserToken({ token }).then(this.checkUser);
        } else {
          this.logoutUser();
          //this.$router.push({ name: "login" });
        }
      });
    } else {
      this.setUserToken({ token }).then(this.checkUser);
    }

    if (!this.$printqGlobalOptions?.widgetMode) {
      this.$router.beforeEach((to, from, next) => {
        if (!this.$printqGlobalOptions?.widgetMode) {
          if (to.name == "register") {
            next();
          } else {
            const token = (this.isAuthenticated && !this.isGuest) | 0;
            if (to.name == "login" && token) {
              next({
                path: "/home",
                replace: true
              });
            } else if (to.name !== "login" && !token) {
              next({
                path: "/login",
                replace: true
              });
            } else {
              next();
            }
          }
        } else {
          next();
        }
      });
    }

    this.changeEditorMode(this.$printqGlobalOptions.editorMode);
    this.changeEditorLocale(this.$printqGlobalOptions?.locale || "en");
    this.changeWidgetMode(this.$printqGlobalOptions?.widgetMode || false);
    this.changeWidgetEditorMode(
      this.$printqGlobalOptions?.widgetEditorMode || false
    );
    this.changeWidgetEditId(this.$printqGlobalOptions?.widgetEditId || 0);
    this.changeWidgetEditorPlaceCallback(
      this.$printqGlobalOptions?.editor_place_callback || false
    );
    initExtString();
  }
});
