<div class="flex w-1/4 p-3 xl_qr:w-1/3 l_qr:w-1/2">
  <div
    class="py-4 px-6 bg-white flex w-full justify-between items-center relative flex bg-qr_color-blue_3 rounded-qr_input_radius flex-col"
  >
    <div class="w-4 absolute top-1 right-2">
      <input
        type="checkbox"
        class="w-4 h-4 text-qr_color-blue-2 hover:cursor-pointer hover:text-qr_color-blue_2 hover:bg-qr-color-blue_2 border-2 border-qr_color-blue_2 rounded-qr_checkbox_radius"
        v-model="selected"
      />
    </div>
    <div class="flex flex-col items-center w-full">
      <div class="text-xl flex items-center gap-x-4 flex-col w-full">
        <div class="relative flex" v-if="0">
          <span class="text-sm -top-4 left-0 text-qr_color-gray">
            {{qrTypeName}}
          </span>
        </div>
        <div
          class="h-6 text-qr_color-blue_2 flex flex-row w-full justify-center gap-x-2 items-center"
        >
          <QRIcon :name="qrTypeIcon" class="fill-current w-6 h6"></QRIcon>
          <span class="text-19 flex qr_color-gray">
            {{title}}
          </span>
        </div>
      </div>
      <div class="gap-y-2 text-12 pt-1">
        <div class="flex items-center m-auto text-qr_color-gray">
          <ClockIcon class="w-4 h-4 fill-current inline-block mr-3"></ClockIcon>
          <span class="hover:cursor-pointer">{{createdAt}} </span>
        </div>
        <div></div>
      </div>
    </div>
    <div class="flex gap-x-2 flex-col w-full">
      <div class="flex items-center justify-center py-1">
        <img :src="link" alt="QWr-code" class="w-40" />
      </div>
      <div class="flex text-qr_color-gray items-center">
        <LinkIcon class="w-4 h-4 fill-current inline-block min-w-40"></LinkIcon>
        <a
          target="_blank"
          class="text-qr_color-gray hover:cursor-pointer break-all text-left"
          :href="shortCodeLink"
          >{{shortCodeLink}}
        </a>
      </div>
      <div class="w-full flex flex-row justify-center text-center pb-2 pt-4">
        <div class="font-semibold text-12 text-qr_color-gray_2">
          <span class="mr-4"
            >{{uniqueScans}}/{{totalScans}} {{$t('code_list_item.scans')}}</span
          >
        </div>
        <router-link
          :to="`/manage/${id}`"
          class="transition delay-100 group relative inline-block text-blue-400 font-semibold hover:text-blue-500 hover:cursor-pointer"
        >
          <span class="pr-8">{{$t('code_list_item.details')}}</span>
          <arrow-right-icon
            class="absolute translate-x-4 group-hover:translate-x-6 right-6 top-1 w-4 h-4 inline-block fill-current"
          >
          </arrow-right-icon>
        </router-link>
      </div>

      <div
        class="flex items-center justify-center relative group flex flex-col"
      >
        <button
          @click="onOpenDesign"
          class="px-8 py-2.5 overflow-hidden text-sm font-medium text-white bg-qr_color-blue_1 hover:cursor-pointer rounded-qr_input_radius"
        >
          {{$t('code_list_item.download')}}
        </button>
        <div
          class="w-full px-3 bottom-1 group-hover:visible text-qr_color-gray"
        >
          <div class="flex justify-center items-center pt-4 gap-x-6">
            <div class="relative">
              <button
                type="button"
                class="w-5 h-5"
                ref="contentTooltipBtnRef"
                @click="editQrCodeHandler(qrId)"
              >
                <PencilIcon
                  class="fill-current hover:transition-all hover:delay-100 hover:text-gray-500 hover:cursor-pointer"
                >
                </PencilIcon>
              </button>
              <div
                role="tooltip"
                ref="contentTooltipRef"
                class="-translate-x-1/2 top-6 left-1/2 invisible w-max inline-block absolute z-10 py-1.5 px-3 font-medium text-[10px] text-white bg-gray-900 rounded-md shadow-sm transition-opacity duration-300 tooltip"
              >
                {{$t('code_list_item.edit_content')}}
              </div>
            </div>

            <div class="relative">
              <button
                type="button"
                class="w-5 h-5"
                ref="designTooltipBtnRef"
                @click="onOpenDesign"
              >
                <PaintIcon
                  class="fill-current hover:transition-all hover:delay-100 hover:text-gray-500 hover:cursor-pointer"
                >
                </PaintIcon>
              </button>
              <div
                role="tooltip"
                ref="designTooltipRef"
                class="-translate-x-1/2 top-6 left-1/2 invisible w-max inline-block absolute z-10 py-1.5 px-3 font-medium text-[10px] text-white bg-gray-900 rounded-md shadow-sm transition-opacity duration-300 tooltip"
              >
                {{$t('code_list_item.design_qr_code')}}
              </div>
            </div>

            <div class="relative" v-if="status">
              <button
                type="button"
                class="w-5 h-5"
                ref="designTooltipBtnRef"
                @click="onDeactivateItem"
              >
                <PauseIcon
                  class="fill-current hover:transition-all hover:delay-100 hover:text-gray-500 hover:cursor-pointer"
                >
                </PauseIcon>
              </button>
              <div
                role="tooltip"
                ref="designTooltipRef"
                class="-translate-x-1/2 top-6 left-1/2 invisible w-max inline-block absolute z-10 py-1.5 px-3 font-medium text-[10px] text-white bg-gray-900 rounded-md shadow-sm transition-opacity duration-300 tooltip"
              >
                {{$t('code_list_item.pause')}}
              </div>
            </div>
            <div class="relative" v-else>
              <button
                type="button"
                class="w-5 h-5"
                ref="designTooltipBtnRef"
                @click="onActivateItem"
              >
                <PlayIcon
                  class="fill-current hover:transition-all hover:delay-100 hover:text-gray-500 hover:cursor-pointer"
                >
                </PlayIcon>
              </button>
              <div
                role="tooltip"
                ref="designTooltipRef"
                class="-translate-x-1/2 top-6 left-1/2 invisible w-max inline-block absolute z-10 py-1.5 px-3 font-medium text-[10px] text-white bg-gray-900 rounded-md shadow-sm transition-opacity duration-300 tooltip"
              >
                {{$t('code_list_item.start')}}
              </div>
            </div>
            <div class="relative">
              <button
                type="button"
                class="w-5 h-5"
                ref="designTooltipBtnRef"
                @click="deleteQrCodeHandler($event,qrId)"
              >
                <DustBinIcon
                  class="fill-current hover:transition-all hover:delay-100 hover:text-gray-500 hover:cursor-pointer"
                >
                </DustBinIcon>
              </button>
              <div
                role="tooltip"
                ref="designTooltipRef"
                class="-translate-x-1/2 top-6 left-1/2 invisible w-max inline-block absolute z-10 py-1.5 px-3 font-medium text-[10px] text-white bg-gray-900 rounded-md shadow-sm transition-opacity duration-300 tooltip"
              >
                {{$t('code_list_item.delete')}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
