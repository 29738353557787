<div class="pr-8 pb-4 text-gray-500">
  <div class="capitalize text-md font-bold text-gray-700">
    {{$t('scan_over_time.title')}}
  </div>
  <div class="w-full mt-8">
    <Bar
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </div>
</div>
