import VueFormly from "vue-formly";
import VueFormlyBootstrap from "vue-formly-bootstrap";

import QRTitle from "./components/base/form/QRTitle";
import QRCollapse from "./components/base/form/QRCollapse";
import QRInputHeadlineIcon from "./components/base/form/QRInputHeadlineIcon";
import QRInputHeadline from "./components/base/form/QRInputHeadline";
import QRInputSubHeadline from "./components/base/form/QRInputSubHeadline";
import QRInputLabel from "./components/base/form/QRInputLabel";
import QRInputCheckbox from "./components/base/form/QRInputCheckbox";
import QRInputColorPicker from "./components/base/form/QRInputColorPicker";
import QRInputColorTheming from "./components/base/form/QRInputColorTheming";
import QRInputColorSwap from "./components/base/form/QRInputColorSwap";
import QRInputAvatar from "./components/base/form/QRInputAvatar";
import QRUploadRectangle from "./components/base/form/QRUploadRectangle";
import QRUploadWelcome from "./components/base/form/QRUploadWelcome";
import QRUpload from "./components/base/form/QRUpload";
import QRInput from "./components/base/form/QRInput";
import QRInputTextarea from "./components/base/form/QRInputTextarea";
import QRInputHalf from "./components/base/form/QRInputHalf";
import QRDateTime from "./components/base/form/QRDateTime";
import QRAutocompleteAddress from "./components/base/form/QRAutocompleteAddress";
import QRResetAddress from "./components/base/form/QRResetAddress";
import QRFeatures from "./components/base/form/QRFeatures";
import Repeater from "./components/base/form/Repeater";
import FieldGroup from "./components/base/form/FieldGroup";
import QRIcon from "./components/base/form/QRIcon";
import QRColorPicker from "./components/base/form/QRColorPicker";
import QRDropDown from "./components/base/form/QRDropDown";
import QRForm from "./components/base/form/QRForm";
import QRToggleButton from "./components/base/form/QRToggleButton";
import QROpeningHours from "./components/base/form/QROpeningHours";
import QRWebsiteBox from "./components/base/form/QRWebsiteBox";
import QRDropdownBoxWithCustom from "./components/base/form/QRDropdownBoxWithCustom";
import QRActionButton from "./components/base/form/QRActionButton";
import QRYoutubeVideoAdd from "./components/base/form/QRYoutubeVideoAdd";
import QrUploadMp3 from "./components/base/form/QrUploadMp3";
import QrUploadPdf from "./components/base/form/QrUploadPdf";
import QrUploadImages from "./components/base/form/QrUploadImages";
import QRMediaChannels from "./components/base/form/QRMediaChannels";
import QRAppStoreLinks from "./components/base/form/QRAppStoreLinks";

const registerFormlyComponent = Vue => {
  VueFormly.addType("qrtitle", QRTitle);
  VueFormly.addType("qr_collapse", QRCollapse);
  VueFormly.addType("qrinput_headline_icon", QRInputHeadlineIcon);
  VueFormly.addType("qrinput_headline", QRInputHeadline);
  VueFormly.addType("qrinput_subheadline", QRInputSubHeadline);
  VueFormly.addType("qr_input_label", QRInputLabel);
  VueFormly.addType("qrinput_checkbox", QRInputCheckbox);
  VueFormly.addType("qrinput_colorpicker", QRInputColorPicker);
  VueFormly.addType("qrinput_color_theming", QRInputColorTheming);
  VueFormly.addType("qrinput_colorswap", QRInputColorSwap);
  VueFormly.addType("qrupload_rectangle", QRUploadRectangle);
  VueFormly.addType("qrupload", QRUpload);
  VueFormly.addType("qrinput", QRInput);
  VueFormly.addType("qrinput_textarea", QRInputTextarea);
  VueFormly.addType("qrinput_half", QRInputHalf);
  VueFormly.addType("qrdate_time", QRDateTime);
  VueFormly.addType("qrautocomplete_address", QRAutocompleteAddress);
  VueFormly.addType("qr_reset_address", QRResetAddress);
  VueFormly.addType("qrfeatures", QRFeatures);
  VueFormly.addType("qr_media_channels", QRMediaChannels);
  VueFormly.addType("qr_app_store_links", QRAppStoreLinks);
  VueFormly.addType("repeater", Repeater);
  VueFormly.addType("field_group", FieldGroup);
  VueFormly.addType("qrupload_welcome", QRUploadWelcome);
  VueFormly.addType("qrinput_avatar", QRInputAvatar);
  VueFormly.addType("qr_upload_mp3", QrUploadMp3);
  VueFormly.addType("qr_upload_pdf", QrUploadPdf);
  VueFormly.addType("qr_upload_images", QrUploadImages);

  //
  Vue.component("qr-icon", QRIcon);
  Vue.component("formly_qrtitle", QRTitle);
  Vue.component("formly_qr_collapse", QRCollapse);
  Vue.component("formly_qrinput_headline_icon", QRInputHeadlineIcon);
  Vue.component("formly_qrinput_headline", QRInputHeadline);
  Vue.component("formly_qrinput_subheadline", QRInputSubHeadline);
  Vue.component("formly_qr_input_label", QRInputLabel);
  //Vue.component("formly_qrinput_template_selector", QRInputTemplateSelector);
  Vue.component("formly_qrinput_checkbox", QRInputCheckbox);
  Vue.component("formly_qrinput_colorpicker", QRInputColorPicker);
  Vue.component("formly_qrinput_color_theming", QRInputColorTheming);
  Vue.component("formly_qrinput_colorswap", QRInputColorSwap);
  Vue.component("formly_qrinput_avatar", QRInputAvatar);
  Vue.component("formly_qrupload_rectangle", QRUploadRectangle);
  // Vue.component("formly_qrheader_gallery", QRHeaderGallery);
  Vue.component("formly_qrupload_welcome", QRUploadWelcome);
  Vue.component("formly_qrupload", QRUpload);
  Vue.component("formly_qrinput", QRInput);
  Vue.component("formly_qrinput_textarea", QRInputTextarea);
  Vue.component("formly_qrinput_half", QRInputHalf);
  //Vue.component("formly_qrbutton_call_action", QRButtonCallAction);
  Vue.component("formly_qrdate_time", QRDateTime);
  Vue.component("formly_qrautocomplete_address", QRAutocompleteAddress);
  Vue.component("formly_qr_reset_address", QRResetAddress);
  Vue.component("formly_qrfeatures", QRFeatures);
  Vue.component("formly_qr_media_channels", QRMediaChannels);
  Vue.component("formly_qr_app_store_links", QRAppStoreLinks);
  //Vue.component("formly_advanced_socialmedia_icon", AdvancedSocialMediaIcon);
  // Vue.component("formly_qryoutube_channel", QRYoutubeChannel);
  Vue.component("formly_qryoutube_video_add", QRYoutubeVideoAdd);
  Vue.component("formly_field_group", FieldGroup);
  Vue.component("formly_qr_dropdown", QRDropDown);
  Vue.component("formly_qr_toggle_button", QRToggleButton);
  Vue.component("formly_qr_website_box", QRWebsiteBox);
  Vue.component("formly_qr_dropdown_box_with_custom", QRDropdownBoxWithCustom);
  Vue.component("formly_qr-opening-hours", QROpeningHours);
  Vue.component("formly_qraction_button", QRActionButton);
  Vue.component("qraction_button", QRActionButton);
  Vue.component("qr-color-picker", QRColorPicker);
  Vue.component("formly_qr_upload_mp3", QrUploadMp3);
  Vue.component("qr-form", QRForm);
  Vue.component("formly_qr_upload_pdf", QrUploadPdf);
  Vue.component("formly_qr_upload_images", QrUploadImages);
};
export { VueFormly, VueFormlyBootstrap, registerFormlyComponent };
