import { mapActions, mapGetters, mapMutations } from "vuex";

import WithRender from "./Sections.html";

import { FormlyGenerateFunctions } from "../../../helpers/formly";
import { getFormFields } from "../../../assets/forms/forms";
import DetailFormly from "./DetailFormly";

export default WithRender({
  props: ["type"],
  components: {
    DetailFormly
  },
  data() {
    return {
      form: {},
      edit: false,
      fields: [],
      previewMode: "preview",
      websiteUrl: "",
      qrCodeName: "",
      validation: {}
    };
  },
  computed: {
    ...mapGetters(["getCurrentModel"]),
    model() {
      return this.getCurrentModel;
    },
    getAvatarStyle: function () {
      return {
        backgroundImage: "url(" + this.model.avatar + ")"
      };
    },
    isEditMode() {
      return this.$route.params && !!this.$route.params.id;
    }
  },
  mounted() {
    const fieldsF = getFormFields(this.type, 0);
    this.fields = fieldsF(FormlyGenerateFunctions, this.translate);
  },
  methods: {
    ...mapActions([
      "updateCurrentModel",
      "createSaveQrCode",
      "uploadFileToServerAsBlob",
      "uploadFileToServer"
    ]),
    translate(str) {
      return this.$t(str);
    },
    async onUploadFileAsBlob(name, data) {
      try {
        const { blob, ...rest } = data;
        const uploadFile = await this.uploadFileToServerAsBlob(blob);
        const onUpdateAttributes = {
          name,
          value: {
            base_path: uploadFile.base_path,
            ...rest
          }
        };
        this.onUpdate(onUpdateAttributes);
      } catch (error) {
        console.log(error);
      }
    },
    async onUploadFile(name, file) {
      try {
        const uploadFile = await this.uploadFileToServer(file);
        this.onUpdate({ name, value: uploadFile.base_path });
      } catch (error) {
        console.log(error);
      }
    },
    onUpdate(v) {
      const { name, value } = v;
      const payload = {
        key: name,
        value: value
      };
      this.updateCurrentModel(payload);
    },
    onClicked(v) {
      switch (v.type) {
        case "SwapColor":
          const { swapFrom, swapTo } = v;
          const [newSwapTo, newSwapFrom] = [
            this.model[swapFrom],
            this.model[swapTo]
          ];
          this.updateCurrentModel({ key: swapFrom, value: newSwapFrom });
          this.updateCurrentModel({ key: swapTo, value: newSwapTo });
          break;
        case "uploadFile": {
          const { name, file } = v;
          this.onUploadFile(name, file);
          break;
        }
        case "uploadFileAsBlob": {
          const { name, data } = v;
          this.onUploadFileAsBlob(name, data);
          break;
        }
        case "uploadWelcomeFile": {
          const { name, file } = v;
          this.onUploadFile(name, file);
          break;
        }
        default:
          break;
      }
    },
    async onNextClicked() {
      try {
        const payload = await this.createSaveQrCode(1);
        if (!payload.save) {
          this.$router.push({
            name: "home",
            query: { open: "true", id: payload.createQrCode.id }
          });
        } else {
          this.$router.push({ name: "home" });
        }
      } catch (error) {}
    }
  },
  watch: {
    model(newValue, oldValue) {
      if (this?.$refs?.detailFormly) {
        // if (
        //   this?.$refs?.detailFormly?.$refs?.templatePreviewIframe?.contentWindow
        //     ?.qrChangeData
        // ) {
        //   this.$refs.detailFormly.$refs.templatePreviewIframe.contentWindow.qrChangeData(
        //     { ...newValue },
        //     null,
        //     true
        //   );
        // }

        if (
          this?.$refs?.detailFormly?.$refs?.templatePreviewIframe?.contentWindow
        ) {
          this?.$refs?.detailFormly?.$refs.templatePreviewIframe.contentWindow.postMessage(
            {
              type: "landingChangeData",
              code: { ...newValue },
              templateType: null,
              demo: true
            },
            "*"
          );
        }
      }
    }
  }
});
