import WithRender from "./ScanOverTime.html";

import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  TimeScale
} from "chart.js";
import "chartjs-adapter-moment";
import dayjs from "dayjs";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  TimeScale
);

export default WithRender({
  props: {
    chartId: {
      type: String,
      default: "bar-chart"
    },
    datasetIdKey: {
      type: String,
      default: "label"
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: "",
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: []
    },
    unit: {
      type: String,
      default: "day"
    },
    range: {
      type: Object,
      default: {
        startDate: new Date(),
        endDate: new Date()
      }
    },
    totalScans: {
      type: Number,
      default: 0
    }
  },
  components: {
    Bar
  },

  computed: {
    chartOptions() {
      const { unit, range, totalScans } = this;

      const startDate = dayjs(range.startDate).startOf(unit).toDate();
      const endDate = dayjs(range.endDate).endOf(unit).toDate();

      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {}
        },
        scales: {
          x: {
            grid: {
              display: false
            },
            type: "time",
            time: {
              unit
            },
            min: startDate,
            max: endDate
          },
          y: {
            min: 0,
            max: totalScans,
            ticks: {
              stepSize: 1
            }
          }
        },
        beginAtZero: true
      };
    },
    chartData() {
      const { data } = this;
      const labels = data.map(r => r.visitTime);
      const datasets = [
        {
          label: "Total",
          backgroundColor: "#62c641",
          data: data.map(r => r.totalCounts)
        },
        {
          label: "Unique",
          backgroundColor: "#47BDEF",
          data: data.map(r => r.uniqueCounts)
        }
      ];
      return {
        labels,
        datasets
      };
    }
  }
});
