import baseField from "vue-formly-bootstrap/src/fields/baseField";

import WithRender from "./QRTitle.html";
import QuestionIcon from "../icons/QuestionIcon";
import { mapGetters, mapActions } from "vuex";

export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model", "options"],
  components: {
    QuestionIcon
  },
  data() {
    return {
      title: ""
    };
  },
  created() {},
  mounted() {
    this.title = this.model[this.name];
  },
  computed: {
    ...mapGetters(["getQrCurrentTitle"]),
    name() {
      return this.field.key;
    },
    buttonLabel() {
      return this.field.templateOptions.buttonLabel;
    },
    icon() {
      return this.field.templateOptions.icon;
    },
    placeHolder() {
      return this.field.templateOptions.placeHolder;
    },
    toolTip() {
      return this.field.templateOptions.tooltip;
    }
  },
  methods: {
    ...mapActions(["setCurrentQrProps"]),
    updateTitle(event) {
      this.setCurrentQrProps({ title: event.target.value });
    }
  }
});
