<div>
  <div
    class="fixed left-0 right-0 top-0 bottom-0"
    v-if="showBackdrop"
    @click="onCloseDialog"
  ></div>
  <SketchPicker
    v-model="inputValue"
    :presetColors="[]"
    @input="onUpdateColor"
    :disableAlpha="true"
  />
</div>
