<div class="text-sm text-qr_color-gray font-medium my-2 ml-0 sm:ml-16">
  <div class="w-full mb-4">
    <select
      class="block ml-auto bg-qr_color-gray_3 h-12 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent w-1/3 block"
      v-model="timeFormat"
    >
      <option value="12">AM/PM</option>
      <option value="24">24h</option>
    </select>
  </div>
  <div
    class="grid sm:grid-cols-opening-hours gap-x-8 gap-y-2 items-center"
    v-if="!!inputValue"
  >
    <template v-for="(day, dayIdx) in weekDays">
      <div
        class="pt-1.5"
        :style="`grid-row: span ${inputValue[dayIdx].values.length} / span ${inputValue[dayIdx].values.length}`"
      >
        <input
          type="checkbox"
          class="w-4 h-4 text-qr_color-blue-2 hover:cursor-pointer hover:text-qr_color-blue_2 hover:bg-qr-color-blue_2 border-2 border-qr_color-blue_2 rounded-qr_checkbox_radius"
          v-model="inputValue[dayIdx].enabled"
        />
        <span class="ml-2">{{day}}</span>
      </div>
      <template v-for="(item, itemIdx) in inputValue[dayIdx].values">
        <QRHourPicker :mode="timeFormat" v-model="item.from"></QRHourPicker>
        <QRHourPicker :mode="timeFormat" v-model="item.to"></QRHourPicker>
        <div class="mt-1.5">
          <button
            v-if="!itemIdx"
            class="text-qr_color-yellow_icon"
            @click="onAddTimeItem($event,dayIdx)"
          >
            <PlusInCircleIcon class="w-5 fill-current" />
          </button>
          <button
            v-else
            class="text-qr_color-yellow_icon"
            @click="onRemoveTimeItem($event,dayIdx)"
          >
            <MinusInCircleIcon class="w-5 fill-current" />
          </button>
        </div>
      </template>
    </template>
  </div>
</div>
