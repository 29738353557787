import WithRender from "./QRInputColorPicker.html";
import QRCustomColorPicker from "./QRCustomColorPicker";

export default WithRender({
  props: ["form", "field", "model"],
  components: {
    QRCustomColorPicker
  },
  data() {
    return {
      color: this.field?.templateOptions?.defaultColor || "#FFFFFF",
      swatches: [],
      colorPickerVisible: false
    };
  },
  created() {
    console.log(this.field, this.name, this.model);
    if (this.model && this.model[this.name]) {
      this.color = this.model[this.name];
    }
  },
  methods: {
    toggleColorPicker() {
      this.colorPickerVisible = !this.colorPickerVisible;
    },
    onChangedColor() {
      const name = this.name;
      const value = this.color;
      this.$emit("update", { name, value });
    }
  },
  computed: {
    name() {
      return this.field.key;
    },
    title() {
      return this.field.templateOptions.title;
    },
    defaultColor() {
      return this.field.templateOptions.defaultColor;
    }
  },
  watch: {
    model: {
      handler(newValue, oldValue) {
        if (oldValue[this.name] == newValue[this.name]) return;
        this.color = newValue[this.name];
      },
      deep: true
    }
  }
});
