import WithRender from "./QrStatus.html";

export default WithRender({
  props: {
    value: {
      type: String,
      default: "all"
    }
  },
  data() {
    return {
      menuList: [
        { value: "all", label: this.$t("qr_type_status.all") },
        { value: "active", label: this.$t("qr_type_status.active") },
        { value: "inactive", label: this.$t("qr_type_status.inactive") }
      ]
    };
  },
  mounted() {
    const triggerEle = this.$refs.qrStatusBtn;
    const targetEle = this.$refs.qrStatusMenu;
    new window.Dropdown(targetEle, triggerEle, {
      placement: "bottom"
    });
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  }
});
