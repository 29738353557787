export const createDynamicURLFieldsWidget = (formly, t) => {
  return [
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_url",
        fold: false,
        headline: "",
        hideCollapse: true,
        icon: null
      }),
      formly.body({
        name: "section_url",
        collapseKey: "section_url",
        widget: 1,
        key: "code",
        content: [
          formly.inputRowRequired({
            key: "url",
            className: "form-input-row form-row-container flex-col",
            label: t("Website"),
            required: true,
            placeholder: t("https://www.my-website.com"),
            errorMsgTitle: t("Please provide an website."),
            filters: {
              makeUrl: function ({ value }) {
                if (value.trim() !== "" && !value.match(/:\/\//)) {
                  value = "http" + "://" + value;
                }
                return value;
              }
            },
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(
                    t("Please enter a website address and click on next.")
                  );
                  return false;
                }

                return true;
              },
              validUrl: function ({ messages, name, value }) {
                if (
                  value !== "" &&
                  !value.match(
                    /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                  )
                ) {
                  messages.push("Website is not a valid URL.");
                  return false;
                }
                return true;
              }
            }
          })
        ]
      })
    )
  ];
};

export const createDynamicURLRequiredFieldsWidget = _ => {
  return ["url"];
};
