import baseField from "vue-formly-bootstrap/src/fields/baseField";

import WithRender from "./QRFeatures.html";
import WifiIcon from "../icons/WifiIcon";
import ChairIcon from "../icons/ChairIcon";
import DisabledIcon from "../icons/DisabledIcon";
import ToiletIcon from "../icons/ToiletIcon";
import StrollerIcon from "../icons/StrollerIcon";
import ParkingIcon from "../icons/ParkingIcon";
import PetIcon from "../icons/PetIcon";
import TrainIcon from "../icons/TrainIcon";
import TaxiIcon from "../icons/TaxiIcon";
import BedIcon from "../icons/BedIcon";
import CafeIcon from "../icons/CafeIcon";
import BarIcon from "../icons/BarIcon";
import RestaurantIcon from "../icons/RestaurantIcon";

export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  mounted() {
    console.log(this.field, this.model);
    this.value = {
      ...this.value,
      ...this.model[this.name]
    };
  },
  data() {
    return {
      value: {
        wifi: false,
        chair: false,
        disabled: false,
        toilet: false,
        stroller: false,
        pet: false,
        park: false,
        train: false,
        taxi: false,
        bed: false,
        cafe: false,
        bar: false,
        restaurant: false
      }
    };
  },

  components: {
    WifiIcon,
    ChairIcon,
    DisabledIcon,
    ToiletIcon,
    StrollerIcon,
    PetIcon,
    ParkingIcon,
    TrainIcon,
    TaxiIcon,
    BedIcon,
    CafeIcon,
    BarIcon,
    RestaurantIcon
  },
  computed: {
    name() {
      return this.field.key;
    },
    description() {
      return this.field.templateOptions.label;
    }
  },
  methods: {
    onChange(e) {
      const { name, value } = this;

      this.$emit("update", {
        name,
        value
      });
    }
  },
  watch: {
    model: {
      handler(newValue, oldValue) {
        if (oldValue[this.name] == newValue[this.name]) return;
        this.value = newValue[this.name];
      },
      deep: true
    }
  }
});
