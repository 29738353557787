import WithRender from "./ScanByTopCountries.html";
import HorizontalBar from "../../base/chart/HorizontalBar";

export default WithRender({
  props: {
    data: {
      type: Array,
      default: []
    }
  },
  components: {
    HorizontalBar
  },
  mounted() {},
  data() {
    return {
      size: 50,
      count: 2
    };
  }
});
