const handleError = e => {
  if (e.graphQLErrors) {
    const { message } = e;
    const [errorType, errorContent] = message.split(":");
    switch (errorContent.trim()) {
      case "Unauthenticated.":
        localStorage.removeItem("user-token");
        window.location.href = "#/login";
    }
    console.log(errorContent.trim());
    return errorContent.trim();
  } else if (e.networkError) {
    console.log("[handleError]", errorType, errorContent);
    console.log(e.networkError.toString());
    console.log(e.networkStatus);
    console.log(e.networkError.networkStatus);
    return errorContent.trim();
  }
};
const mutationAPI = apolloClient => ({
  mutation,
  variables = {},
  context = {},
  refetchQueries = () => [],
  ...rest
}) => {
  return apolloClient
    .mutate({
      // Query
      mutation,
      // Parameters
      variables,
      // context
      context,
      // fetch
      refetchQueries,
      ...rest
    })
    .then(data => {
      return data.data;
    })
    .catch(e => {
      const error = handleError(e);
      return { error };
    });
};

const queryAPI = apolloClient => ({ query, variables = {} }) => {
  return apolloClient
    .query({
      // Query
      query,
      // Parameters
      variables
    })
    .then(data => data.data)
    .catch(e => {
      const error = handleError(e);
      return { error };
    });
};

export { mutationAPI, queryAPI };
