<div>
  <button
    ref="qrTypeSelectButton"
    type="button"
    class="flex ml-auto bg-qr_color-gray_3 h-12 px-5 py-2.5 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent flex items-center w-auto"
  >
    {{$t('qr_type_select.qr_code_types')}}
    <svg
      class="ml-2 w-4 h-4"
      aria-hidden="true"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M19 9l-7 7-7-7"
      ></path>
    </svg>
  </button>

  <div ref="qrTypeSelect" class="hidden z-10 w-40 bg-white rounded shadow">
    <ul class="p-3 space-y-1 text-sm">
      <li v-for="menu in menuList" :key="menu.value">
        <div class="flex items-center p-2 rounded hover:bg-qr_color-blue_3">
          <input
            :id="`checkbox-item-${menu.value}`"
            type="checkbox"
            v-model="inputValue[menu.value]"
            @change="onUpdateInput"
            class="w-4 h-4 text-qr_color-blue-2 hover:cursor-pointer hover:text-qr_color-blue_2 hover:bg-qr-color-blue_2 border-2 border-qr_color-blue_2 rounded-qr_checkbox_radius"
          />
          <label
            :for="`checkbox-item-${menu.value}`"
            class="ml-2 w-full text-sm font-medium rounded peer-checked:bg-qr_color-blue_3 text-qr_color-gray_2 cursor-pointer"
            >{{menu.label}}</label
          >
        </div>
      </li>
    </ul>
  </div>
</div>
