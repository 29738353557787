import welcome from "./img/welcome_images.png";

export const createDynamicImageFields = (formly, t) => {
  return [
    formly.labelTitle({
      titleIcon: "ImageIcon1",
      titleKey: "qrcode_image",
      titleTooltip: t(
        "Labeling QR Codes has been moved to this area. Names help you to stay organized and will only appear in your account and are not displayed to customers who scan your QR Codes."
      ),
      titlePlaceholder: t("My Gallery QR Code"),
      titleValue: null,
      buttonLabel: t("Got it")
    }),
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_design",
        fold: false,
        headline: t("Design & Customizations"),
        icon: "DesignIcon"
      }),
      formly.body({
        name: "section_design",
        collapseKey: "section_design",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Personalize your page by selecting some colors.")
          }),
          formly.colorsTemplate({
            customColors: [
              { color1: "#3c4245" },
              { color1: "#37427e" },
              { color1: "#a0b3a1" },
              { color1: "#6c3d7d" },
              { color1: "#f6a35d" }
            ]
          }),
          formly.costumColor({})
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 1,
        sectionName: "section_information",
        fold: false,
        headline: t("Images"),
        icon: "ImageIcon1"
      }),
      formly.body({
        name: "section_information",
        collapseKey: "section_information",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t(
              "Upload or drag and drop images to include in your gallery. You can arrange them once they appear."
            )
          }),
          formly.uploadImagesFile({
            key: "files",
            useGridViewKey: "useGridView"
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 2,
        sectionName: "section_information",
        fold: false,
        headline: t("Basic Information"),
        icon: "InfoIcon"
      }),
      formly.body({
        name: "section_information",
        collapseKey: "section_information",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t(
              "Add some context to your gallery. Optionally add a button to link to a website of your choice."
            )
          }),
          formly.inputRow({
            label: t("Title"),
            key: "title",
            required: true,
            className: "form-input-row form-row-container flex flex-col",
            placeholder: t("Title for your gallery")
          }),
          formly.textAreaRow({
            key: "description",
            label: t("Description"),
            placeholder: t("More information about your gallery"),
            maxChar: 200
          }),
          formly.inputRow({
            label: t("Website"),
            key: "website",
            className: "flex-0",
            placeholder: t("www.yourwebsite.com"),
            filters: {
              makeUrl: function ({ value }) {
                if (value.trim() !== "" && !value.match(/:\/\//)) {
                  value = "http" + "://" + value;
                }
                return value;
              }
            },
            validators: {
              validUrl: function ({ messages, name, value }) {
                if (
                  value !== "" &&
                  !value.match(
                    /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                  )
                ) {
                  messages.push("Website is not a valid URL.");
                  return false;
                }
                return true;
              }
            }
          }),
          {
            className: "py-4",
            template:
              '<hr style="border-color: #eaeaea; margin: 10px 0 -10px;">'
          },
          {
            className: "form-row-container flex-col",
            type: "field_group",
            required: true,
            fieldGroup: [
              {
                type: "qr_input_label",
                templateOptions: {
                  label: t("Button")
                }
              },
              {
                className: "col-sm-12 col-md-8 box-input",
                key: "callToAction",
                type: "qraction_button",
                templateOptions: {
                  key: "callToAction.url",
                  urlPlaceholder: t("http://"),
                  defaultValue: {
                    label: t("Shop online"),
                    url: ""
                  },
                  items: [t("Shop online"), t("Learn More"), t("Apply now")],
                  addLabel: t("Add button"),
                  removeLabel: t("Remove button"),
                  customLabel: t("Custom"),
                  errorMessages: {
                    url: t("Please enter a valid website address"),
                    required: t("This field can not be empty")
                  },
                  filters: {
                    makeUrl: function ({ value }) {
                      if (value.trim() !== "" && !value.match(/:\/\//)) {
                        value = "http" + "://" + value;
                      }
                      return value;
                    }
                  },
                  validators: {
                    required: function ({ messages, name, value }) {
                      if (value.trim() == "") {
                        messages.push(t("This field can not be empty"));
                        return false;
                      }

                      return true;
                    },
                    validUrl: function ({ messages, name, value }) {
                      if (
                        value !== "" &&
                        !value.match(
                          /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                        )
                      ) {
                        messages.push(t("Please enter a valid address."));
                        return false;
                      }
                      return true;
                    }
                  }
                }
              }
            ]
          }
        ]
      })
    ),

    formly.container(
      formly.header({
        index: 3,
        sectionName: "section_welcome_screen",
        headline: t("Welcome Screen"),
        icon: "SmartPhoneIcon"
      }),
      formly.body({
        name: "section_welcome_screen",
        collapseKey: "section_welcome_screen",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Display your logo while your page is loading.")
          }),
          formly.welcomeScreen({
            tooltip: t(
              "Upload an image or logo from your computer. Images must be in .jpg or .png format."
            ),
            key: "welcome_screen",
            previewWelcomeScreen: true,
            defaultWelcomeImage: welcome
          })
        ]
      })
    )
  ];
};

export const createDynamicImageRequiredFields = _ => {
  return [];
};
