import baseField from "vue-formly-bootstrap/src/fields/baseField";
import VueCropper from "vue-cropperjs";
import ImageIcon from "../icons/ImageIcon";
import WithRender from "./QRInputAvatar.html";
import { mapGetters } from "vuex";
import { blobToBase64 } from "../../../helpers/helpers";
import QRCustomColorPicker from "./QRCustomColorPicker";
import Spinner from "../../base/components/Spinner";

import "cropperjs/dist/cropper.css";

const avatarDefaults = {
  imageSize: 100,
  bgColor: "#ffffff",
  basePath: null
};

export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  components: {
    ImageIcon,
    VueCropper,
    Spinner,
    QRCustomColorPicker
  },
  created() {
    const value = this.model[this.name];
    this.imageSize = value?.imageSize || this.imageSize;
    this.bgColor = value?.bgColor || this.bgColor;
    this.basePath = value?.base_path || this.basePath;
  },
  mounted() {
    const cropperModalOptions = {
      placement: "center",
      backdropClasses:
        "bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
    };
    const cropperModalRef = this.$refs.cropperModal;
    if (cropperModalRef) {
      this.modal = new window.Modal(cropperModalRef, cropperModalOptions);
      if (this.aspectRatio) {
        this.$refs.cropper.setAspectRatio(this.aspectRatio);
      }
    }
    this.$root.$on(
      "avatarUpload",
      ({ selectorId, file, aspectRatio, name }) => {
        if (name == this.name) {
          if (aspectRatio) this.$refs.cropper.setAspectRatio(aspectRatio);
          this.handleFile(file);
          this.initImageParams();
        }
      }
    );
  },
  data() {
    return {
      editable: false,
      imageSize: avatarDefaults.imageSize,
      bgColor: avatarDefaults.bgColor,
      swatches: [],
      colorPickerVisible: false,
      peerCheckedEnable: true,
      enableImage: true,
      basePath: avatarDefaults.basePath,
      mimeType: "",
      cropedImage: "",
      autoCrop: false,
      selectedFile: "",
      image: "",
      dialog: false,
      showLoading: false,
      files: ""
    };
  },
  computed: {
    ...mapGetters(["getStorageBaseUrl"]),
    name() {
      return this.field.key;
    },
    imageUrl() {
      if (!this.basePath) {
        return this.defaultImage || this.defaultWelcomeImage;
      } else {
        return this.getStorageBaseUrl + this.basePath;
      }
    },
    defaultImage() {
      return this?.field?.templateOptions?.defaultImage || null;
    },
    defaultWelcomeImage() {
      return this.field?.templateOptions?.defaultWelcomeImage || null;
    },
    enableWelcomeImage() {
      return this.field?.templateOptions?.welcomeImage || false;
    },
    circleAvatar() {
      return this.field?.templateOptions?.circleAvatar || 0;
    },
    imageWidth() {
      return this.field?.templateOptions?.backgroundImageSize;
    },
    selectorId() {
      return this.field?.templateOptions?.selectorId;
    },
    aspectRatio() {
      return +this.field?.templateOptions?.aspectRatio || 0;
    },
    imageScale() {
      return +this.imageSize / 100;
    },
    isEmptyOrDefaultImage() {
      return !this.basePath || this.defaultImage || this.defaultWelcomeImage;
    }
  },
  watch: {
    model: {
      handler(newValue, oldValue) {
        if (oldValue[this.name] == newValue[this.name]) return;
        const value = newValue[this.name];
        this.imageSize = value?.imageSize || avatarDefaults.imageSize;
        this.bgColor = value?.bgColor || avatarDefaults.bgColor;
        const basePath = value?.base_path || avatarDefaults.basePath;
        if (basePath != this.basePath) {
          this.showLoading = false;
        }
        this.basePath = basePath;
      },
      deep: true
    }
  },
  methods: {
    initImageParams() {
      this.bgColor = avatarDefaults.bgColor;
      this.imageSize = avatarDefaults.imageSize;
    },
    updateImageParams() {
      const { name, imageSize, bgColor } = this;
      this.$emit("update", {
        name,
        value: {
          imageSize,
          bgColor,
          base_path: this.model[this.name]?.base_path || null
        }
      });
    },
    onSelectColor(c) {
      this.bgColor = c;
      this.peerCheckedEnable = true;
      this.updateImageParams();
    },
    getCropperImage() {
      return new Promise(resolve => {
        this.$refs.cropper.getCroppedCanvas().toBlob(async blob => {
          const base64Data = await blobToBase64(blob);
          resolve(base64Data);
        }, this.mimeType);
      });
    },

    async onSaveImage() {
      try {
        this.showLoading = true;
        const blob = await this.getCropperImage();
        const { imageSize, bgColor } = this;
        this.$emit("click", {
          type: "uploadFileAsBlob",
          name: this.name,
          data: {
            blob,
            imageSize,
            bgColor
          }
        });
      } catch (error) {
        console.log(error);
        this.showLoading = false;
      } finally {
        this.onCloseModal();
      }
    },
    onCloseModal() {
      this.modal.hide();
    },
    handleFile(file) {
      this.mimeType = file.type;
      if (typeof FileReader === "function") {
        this.modal.show();
        const reader = new FileReader();
        reader.onload = event => {
          this.selectedFile = event.target.result;
          this.$refs.cropper.replace(this.selectedFile);
        };
        reader.readAsDataURL(file);
      }
    },
    onChangeFileName({ target: { files } }) {
      const file = files[0];
      this.handleFile(file);
    },
    toggleColorPicker() {
      this.colorPickerVisible = !this.colorPickerVisible;
      this.peerCheckedEnable = false;
    },
    onChangedColor() {
      this.updateImageParams();
    },
    onRemoveClicked() {
      const { name, imageSize, bgColor } = this;
      this.$emit("update", {
        name,
        value: {
          imageSize,
          bgColor,
          base_path: null
        }
      });
    },
    onChangeImageSize() {
      this.updateImageParams();
    }
  }
});
