<svg
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 27 27"
>
  <path
    class="cls-1"
    d="M24.71,26.14H2.33c-.74,0-1.34-.6-1.34-1.34V4.39c0-.74,.6-1.34,1.34-1.34H24.71c.74,0,1.34,.6,1.34,1.34V24.8c0,.74-.6,1.34-1.34,1.34ZM3.67,23.46H23.37V5.73H3.67V23.46Z"
  />
  <g>
    <path
      class="cls-1"
      d="M5.88,8.95c-.74,0-1.35-.6-1.35-1.34V2.24c0-.74,.61-1.34,1.35-1.34s1.35,.6,1.35,1.34V7.61c0,.74-.61,1.34-1.35,1.34Z"
    />
    <path
      class="cls-1"
      d="M10.95,8.95c-.74,0-1.35-.6-1.35-1.34V2.24c0-.74,.61-1.34,1.35-1.34s1.35,.6,1.35,1.34V7.61c0,.74-.61,1.34-1.35,1.34Z"
    />
    <path
      class="cls-1"
      d="M15.68,8.95c-.74,0-1.35-.6-1.35-1.34V2.24c0-.74,.61-1.34,1.35-1.34s1.35,.6,1.35,1.34V7.61c0,.74-.61,1.34-1.35,1.34Z"
    />
    <path
      class="cls-1"
      d="M20.41,8.95c-.74,0-1.35-.6-1.35-1.34V2.24c0-.74,.61-1.34,1.35-1.34s1.35,.6,1.35,1.34V7.61c0,.74-.61,1.34-1.35,1.34Z"
    />
  </g>
  <path
    class="cls-1"
    d="M19.96,16.21H7.07c-.74,0-1.34-.44-1.34-1.18s.6-1.18,1.34-1.18h12.89c.74,0,1.34,.44,1.34,1.18s-.6,1.18-1.34,1.18Z"
  />
  <path
    class="cls-1"
    d="M19.96,21.28H7.07c-.74,0-1.34-.61-1.34-1.35s.6-1.35,1.34-1.35h12.89c.74,0,1.34,.61,1.34,1.35s-.6,1.35-1.34,1.35Z"
  />
</svg>
