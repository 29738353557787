import combineQuery from "graphql-combine-query";
import { querySentences } from "../gql";

const getModule = queryAPI => {
  const state = {
    config: {
      storageBaseUrl: "",
      storageBasePath: ""
    }
  };

  const getters = {
    getStorageBaseUrl: state => {
      return state.config.storageBaseUrl;
    },
    getStorageBasePath: state => {
      return state.config.storageBasePath;
    }
  };

  const mutations = {
    setQrConfig(state, qr_config) {
      state.config = qr_config;
    }
  };

  const actions = {
    async fetchBaseData({ commit, dispatch }, payload) {
      try {
        const locale = payload?.locale ?? "en";
        commit("setLoading", true);
        const {
          qrPatterns: qrPatternsSentence,
          qrFrameTemplates: qrFrameTemplatesSentence,
          qrLogos: qrLogosSentence,
          qrLogoTemplatesByLocale: qrLogoTemplatesSentence,
          fetchQRTypes: fetchQRTypesSentence,
          qrConfig: qrConfigSentence
        } = querySentences;

        const { document, variables } = combineQuery("initQuery")
          .add(qrConfigSentence)
          .add(qrPatternsSentence)
          .add(qrFrameTemplatesSentence)
          .add(qrLogosSentence)
          .add(qrLogoTemplatesSentence, { locale })
          .add(fetchQRTypesSentence, {
            action: "dynamic",
            withAction: true
          });

        const {
          qrConfig,
          qrPatterns,
          qrFrameTemplates,
          qrLogos,
          qrLogoTemplatesByLocale,
          qrTypesByAction
        } = await queryAPI({
          query: document,
          variables
        });

        commit("setQrConfig", qrConfig);
        commit("setQrPatterns", qrPatterns);
        commit("setFrameTemplates", qrFrameTemplates);
        commit("setQrLogos", qrLogos);
        commit("setQrLogoTemplates", qrLogoTemplatesByLocale);
        commit("setCategories", qrTypesByAction);
        dispatch("updateCurrentQrType");
      } catch (error) {
        console.log(error);
      } finally {
        if (payload) {
          if (payload.callBackFunction) {
            payload.callBackFunction();
          } else {
            commit("setLoading", false);
          }
        } else {
          commit("setLoading", false);
        }
      }
    },

    async createGuestUser({ commit, dispatch }) {
      try {
        const {
          qrPatterns: qrPatternsSentence,
          qrFrameTemplates: qrFrameTemplatesSentence,
          qrLogos: qrLogosSentence,
          qrLogoTemplates: qrLogoTemplatesSentence,
          fetchQRTypes: fetchQRTypesSentence,
          qrConfig: qrConfigSentence
        } = querySentences;

        const { document, variables } = combineQuery("initQuery")
          .add(qrConfigSentence)
          .add(qrPatternsSentence)
          .add(qrFrameTemplatesSentence)
          .add(qrLogosSentence)
          .add(qrLogoTemplatesSentence)
          .add(fetchQRTypesSentence, {
            action: "dynamic",
            withAction: true
          });

        const {
          qrConfig,
          qrPatterns,
          qrFrameTemplates,
          qrLogos,
          qrLogoTemplates,
          qrTypesByAction
        } = await queryAPI({
          query: document,
          variables
        });
        commit("setQrConfig", qrConfig);
        commit("setQrPatterns", qrPatterns);
        commit("setFrameTemplates", qrFrameTemplates);
        commit("setQrLogos", qrLogos);
        commit("setQrLogoTemplates", qrLogoTemplates);
        commit("setCategories", qrTypesByAction);
        dispatch("updateCurrentQrType");
      } catch (error) {
        console.log(error);
      } finally {
        commit("setLoading", false);
      }
    }
  };
  return {
    state,
    getters,
    mutations,
    actions
  };
};

export default getModule;
