import { mapActions, mapGetters, mapMutations } from "vuex";

import WithRender from "./HomePage.html";
import MainLayout from "../components/ui/layouts/MainLayout";
import WidgetLayout from "../components/ui/layouts/WidgetLayout";
import CardListItem from "../components/ui/cards/CodeListItem";
import EditQrDesignModal from "../components/ui/modal/EditQrDesignModal";
import Spinner from "../components/base/components/Spinner";
import QrTypeSelect from "../components/base/dropdown/QrTypeSelect";
import QrOrderby from "../components/base/dropdown/QrOrderby";
import QrStatus from "../components/base/dropdown/QrStatus";
import QrAction from "../components/base/dropdown/QrAction";
import QrDirection from "../components/base/dropdown/QrDirection";
import Pagination from "../components/base/components/Pagination";

export default WithRender({
  components: {
    MainLayout,
    CardListItem,
    WidgetLayout,
    EditQrDesignModal,
    Spinner,
    Pagination,
    QrTypeSelect,
    QrOrderby,
    QrStatus,
    QrDirection,
    QrAction
  },
  data() {
    return {
      modal: null
    };
  },
  mounted() {
    this.routerActions({ page: "homePage" });
    if (!this.$printqGlobalOptions?.widgetMode) {
      if (this.$route.query.open) {
        if (!this.getQrCurrentDesign.id) {
          const qrId = this.$route.query.id;
          if (qrId) {
            const payloadFetchQrDetails = {
              id: qrId,
              callBackFunction: response => {
                if (response.qrCode.qrType) {
                  this.changeCardType(response.qrCode.qrType.code);
                }
                this.fetchDesignDetails(response);
              }
            };
            this.fetchQrDetails(payloadFetchQrDetails);
          }
        }
        this.onOpenModal();
      }
    }
  },
  computed: {
    ...mapGetters([
      "getQRCodes",
      "getWidgetMode",
      "getQrCurrentDesign",
      "getQRFilters",
      "getLoading",
      "getBatchItemCount",
      "getQRCodesRowsCount",
      "getQRCodesCount",
      "getQRCodesTotal",
      "getQRCodesCurPage",
      "getQRCodesPageCount",
      "getQRSortBy",
      "getQrDirection"
    ]),
    pageCount() {
      return this.getQRCodesPageCount;
    },
    getRageQRCodes() {
      return this.getQRCodes;
    },

    curPage: {
      get() {
        return this.getQRCodesCurPage;
      },
      set(v) {
        this.setQRCodesCurPage(v);
      }
    },
    rowsCount: {
      get() {
        return this.getQRCodesRowsCount;
      },
      set(v) {
        this.setQRCodesRowsCount(+v);
      }
    },
    getQrType: {
      get() {
        return this.getQRFilters.qrType;
      },
      set(qrType) {
        this.updateFilter({
          qrType
        });
      }
    },
    getQrStatus: {
      get() {
        return this.getQRFilters.qrStatus;
      },
      set(v) {
        this.updateFilter({
          qrStatus: v
        });
      }
    },
    getQrSortByKey: {
      get() {
        return this.getQRSortBy;
      },
      set(v) {
        this.updateSortBy(v);
      }
    },
    getDirectionByKey: {
      get() {
        return this.getQrDirection;
      },
      set(v) {
        this.updateQrDirection(v);
      }
    }
  },
  methods: {
    ...mapActions([
      "changeCurrentStep",
      "onBachUpdateActions",
      "applyQRBatchItemUpdate",
      "batchQrCodesActions",
      "routerActions",
      "fetchQRCodes",
      "updateFilter",
      "updateSortBy",
      "updateQrDirection",
      "changeCardType",
      "fetchQrDetails",
      "fetchDesignDetails"
    ]),
    ...mapMutations(["setQRCodesCurPage", "setQRCodesRowsCount"]),
    onOpenModal() {
      console.log("[onOpenModal]");
      const modalRef = this.$refs.modalRef;
      if (modalRef.getModal()) {
        modalRef.getModal().show();
      }
    },
    onChangeFilter() {
      updateFilter();
    },
    onBachUpdateActions(e) {
      const { value } = e.target;
      if (value == "deleted") {
      }
    },
    onSelectAll({ target }) {
      const { checked } = target;
      for (const code of this.getQRCodes) {
        this.applyQRBatchItemUpdate({ key: code.id, value: checked });
      }
    },
    onPagination(page) {
      console.log("[onPagination]", page);
      this.setQRCodesCurPage(page);
      this.fetchQRCodes();
    },
    onUpdateRowCount(v) {
      this.rowsCount = v;
      this.setQRCodesCurPage(1);
      this.fetchQRCodes();
    },
    async onDeleteItems() {
      try {
        const { isConfirmed } = await this.$swal({
          title: "Are you sure ?",
          text: "The selected Qr(s) will be deleted!",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          closeOnConfirm: false,
          closeOnCancel: false
        });
        if (isConfirmed) {
          // this.deleteQrCode({ id: id, swal: this.$swal });
          console.log("[onDeleteItems]");
          this.batchQrCodesActions("delete");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onDeactivateItems() {
      try {
        const { isConfirmed } = await this.$swal({
          title: "Are you sure ?",
          text: "The selected Qr(s) will be deactivated!",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          closeOnConfirm: false,
          closeOnCancel: false
        });
        if (isConfirmed) {
          // this.deleteQrCode({ id: id, swal: this.$swal });
          this.batchQrCodesActions("deactivate");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onActivateItems() {
      try {
        const { isConfirmed } = await this.$swal({
          title: "Are you sure ?",
          text: "The selected Qr(s) will be activated!",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          closeOnConfirm: false,
          closeOnCancel: false
        });
        if (isConfirmed) {
          // this.deleteQrCode({ id: id, swal: this.$swal });
          console.log("[onActivateItems]");
          this.batchQrCodesActions("activate");
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
});
