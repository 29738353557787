<div
  :class="{modalEditor:getEditorMode}"
  ref="designModalRef"
  tabindex="-1"
  data-modal-show="true"
  class="edit_popup hidden overflow-y-auto overflow-x-hidden fixed top-2 right-0 left-0 z-50 lg:inset-0 h-modal lg:h-full"
>
  <div class="relative p-4 pt-0 w-full max-w-6xl h-[545px]">
    <!-- Modal content -->
    <div
      class="shadow lg:shadow-lg xl:shadow-xl 2xl:shadow-2xl rounded-qr_input_radius flex h-[565px] xl:h-auto"
    >
      <!-- left side panel -->
      <!-- close button -->
      <button
        type="button"
        class="absolute top-[25px] right-[25px] block p-1 text-qr_color-blue_1 z-10"
        @click="onCloseDialog"
        v-if="!getEditorMode"
      >
        <CloseIcon class="w-4 h-4 fill-current"> </CloseIcon>
      </button>
      <!-- right side panel -->
      <div
        class="w-[calc(100%-24rem)] bg-white border-0 py-4 relative rounded-l-qr_input_radius pl-8"
      >
        <!-- main panel -->
        <div v-if="page == 'main'" class="w-full">
          <!-- frame -->
          <div class="flex">
            <div class="w-3/5 mb-4">
              <div class="flex justify-between items-center pb-2">
                <span class="text-qr_color-blue_2 text-16 font-bold"
                  >{{$t('edit_qr_design_modal.frame')}}:</span
                >
              </div>

              <div class="w-full">
                <ul class="flex gap-x-6 my-1 justify-start">
                  <li
                    class="w-16 h-16 relative"
                    v-for="frame in getQRFrameTemplates"
                  >
                    <input
                      class="sr-only peer"
                      type="radio"
                      :value="frame.id"
                      name="qr_frame_type"
                      :id="`qr_frame_${frame.id}`"
                      :checked="(getQrCurrentDesign.frame_template_id == frame.id) || (frame.code =='default' && !getQrCurrentDesign.frame_template_id )"
                      @change="previewChangesHandler($event,'frame_template_id',frame)"
                    />
                    <label
                      class="flex flex-col p-2 w-full h-full ring ring-6 rounded-qr_input_radius cursor-pointer ring-qr_color-blue_ring peer-checked:ring-qr_color-blue_2 peer-checked:ring-6"
                      :for="`qr_frame_${frame.id}`"
                    >
                      <img
                        v-if="frame.url && frame.code!='default'"
                        :src="frame.url"
                        alt="frame"
                        class="w-full h-full object-contain"
                      />
                      <div v-else class="m-auto">
                        <div
                          class="w-8 h-8 rounded-full border-2 border-gray-700 relative before:absolute before:content-[' '] before:origin-center before:rotate-45 before:w-8 before:h-0.5 before:bg-gray-700 before:block before:top-[13px] before:left-[-2px]"
                        ></div>
                      </div>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="w-2/5 pt-qr_title pl-4"
              v-if="!(getQrCurrentDesign.frame_template_id ==getDefaultFrameId ||  !getQrCurrentDesign.frame_template_id) "
            >
              <div class="w-full relative text-gray-500 inline-block">
                <span
                  class="text-qr_color-blue_2 text-16 float-left pb-2 font-bold"
                >
                  {{$t('edit_qr_design_modal.frame_color')}}
                </span>
                <input
                  type="text"
                  class="bg-qr_color-gray_3 h-10 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent"
                  name="qr_frame_color"
                  v-model="frameColor"
                />

                <button
                  class="absolute right-0.5 top-8 h-9 inline-flex items-center px-1.5 py-1.5 rounded-qr_input-radius border-0"
                  type="button"
                  @click="frameColorPickerVisible=!frameColorPickerVisible"
                >
                  <span
                    class="w-6 h-6 border-0 rounded-qr_color_radius border-0"
                    :style="`background-color: ${frameColor}`"
                  >
                  </span>
                </button>
              </div>

              <div class="absolute z-40" v-if="frameColorPickerVisible">
                <QRCustomColorPicker
                  v-model="frameColor"
                  :swatches="[]"
                  class="flex justify-between items-center pb-2"
                  :hide-swatches="true"
                  @change="onFrameColorUpdated"
                  @close="frameColorPickerVisible=false"
                />
              </div>
              <div class="w-full relative text-gray-500 inline-block">
                <span
                  class="text-qr_color-blue_2 text-16 float-left py-2 font-bold"
                >
                  {{$t('edit_qr_design_modal.frame_text')}}
                </span>
                <input
                  type="text"
                  class="bg-qr_color-gray_3 h-10 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent"
                  name="qr_frame_text"
                  :value="getQrCurrentDesign.text"
                  @change="onChangeFrameText($event)"
                />
              </div>
            </div>
          </div>
          <!-- qr code -->
          <div class="flex">
            <div class="w-3/5 mb-4">
              <div class="flex justify-between items-center pb-2">
                <span class="text-qr_color-blue_2 text-16 font-bold"
                  >{{$t('edit_qr_design_modal.qr_code')}}:</span
                >
              </div>

              <div class="w-full">
                <ul class="flex gap-x-6 my-1 justify-start">
                  <li
                    class="w-16 h-16 relative"
                    v-for="format in getQrPatterns"
                  >
                    <input
                      class="sr-only peer"
                      type="radio"
                      :value="format.id"
                      name="qr_code_type"
                      :id="`qr_format_${format.id}`"
                      :checked="getQrCurrentDesign.pattern_id==format.id"
                      @change="previewChangesHandler($event,'pattern_id')"
                    />

                    <label
                      class="flex flex-col p-2 w-full h-full ring ring-6 rounded-qr_input_radius cursor-pointer ring-qr_color-blue_ring peer-checked:ring-qr_color-blue_2 peer-checked:ring-6"
                      :for="`qr_format_${format.id}`"
                    >
                      <img
                        v-if="format.url"
                        :src="format.url"
                        alt="format"
                        class="w-full h-full object-contain"
                      />
                      <div v-else class="m-auto">
                        <div
                          class="w-8 h-8 rounded-full border-2 border-gray-700 relative before:absolute before:content-[' '] before:origin-center before:rotate-45 before:w-8 before:h-0.5 before:bg-gray-700 before:block before:top-[13px] before:left-[-2px]"
                        ></div>
                      </div>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div class="w-2/5 pt-qr_title pl-4">
              <div class="w-full relative text-gray-500 inline-block mr-4">
                <span
                  class="text-qr_color-blue_2 text-16 float-left pb-2 font-bold"
                >
                  {{$t('edit_qr_design_modal.code_color')}}
                </span>
                <input
                  type="text"
                  class="bg-qr_color-gray_3 h-10 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent"
                  name="qr_code_color"
                  v-model="codeColor"
                />

                <button
                  class="absolute right-0.5 top-8 h-9 inline-flex items-center px-1.5 py-1.5 rounded-qr_input-radius border-0"
                  type="button"
                  @click="codeColorPickerVisible=!codeColorPickerVisible"
                >
                  <span
                    class="w-6 h-6 border-0 rounded-qr_color_radius border-0"
                    :style="`background-color: ${codeColor}`"
                  >
                  </span>
                </button>
                <button
                  type="button"
                  class="p-1 text-qr_color-blue_1 items-center rounded-sm flex"
                  @click="page='qr_code_detail'"
                >
                  <span class="mr-1 text-qr_color-blue_1 text-[12px]">
                    {{$t('edit_qr_design_modal.more_options')}}
                  </span>
                  <ArrowRightIcon class="w-4 h-4 fill-current">
                  </ArrowRightIcon>
                </button>
              </div>

              <div class="absolute z-40" v-if="codeColorPickerVisible">
                <QRCustomColorPicker
                  v-model="codeColor"
                  :swatches="[]"
                  :hide-swatches="true"
                  @change="onCodeColorUpdated"
                  @close="codeColorPickerVisible=false"
                />
              </div>
            </div>
          </div>
          <!-- Logo  -->
          <div class="flex">
            <div class="w-3/5 mb-4">
              <div class="flex justify-between items-center pb-2">
                <span class="text-qr_color-blue_2 text-16 font-bold"
                  >{{$t('edit_qr_design_modal.logo')}}:</span
                >

                <input
                  ref="logoFile"
                  class="cursor-pointer absolute block opacity-0 invisible"
                  type="file"
                  name="logo"
                  @change="onChangeLogoFile"
                />
              </div>
              <ul
                class="flex gap-x-6 justify-start overflow-x-auto logoContainer pb-3"
              >
                <li
                  class="w-20 h-20 relative min-w-[64px] p-1"
                  v-for="logo in getQrLogoTemplates"
                >
                  <div class="w-full h-full object-contain">
                    <input
                      class="sr-only peer"
                      type="radio"
                      :value="logo.id"
                      name="qr_logo_type"
                      :id="`qr_template_logo_${logo.id}`"
                      :checked="(getQrCurrentDesign.logo_type == 'template') && (logo.id == getQrCurrentDesign.logo_template_id||( !logo.id  && getQrCurrentDesign.logo_template_id))"
                      @change="changeColorLogoHandler($event,'template')"
                    />
                    <label
                      class="flex flex-col p-2 w-full h-full ring ring-6 rounded-qr_input_radius cursor-pointer ring-qr_color-blue_ring peer-checked:ring-qr_color-blue_2 peer-checked:ring-6"
                      :for="`qr_template_logo_${logo.id}`"
                    >
                      <img
                        :src="logo.url"
                        v-if="logo.url"
                        alt="logo"
                        class="w-full h-full object-contain"
                      />
                      <div v-else class="m-auto">
                        <div
                          class="w-8 h-8 rounded-full border-2 border-gray-700 relative before:absolute before:content-[' '] before:origin-center before:rotate-45 before:w-8 before:h-0.5 before:bg-gray-700 before:block before:top-[13px] before:left-[-2px]"
                        ></div>
                      </div>
                    </label>
                  </div>
                </li>
                <li
                  class="w-20 h-20 relative min-w-[64px] p-1"
                  v-for="logo in getQrLogos"
                >
                  <div
                    :key="logo.id"
                    class="w-full h-full"
                    v-if="logo.isLoading"
                  >
                    <label
                      class="flex p-2 bg-gray-50 border-1 border-gray-300 w-full h-full rounded-md items-center justify-center"
                    >
                      <Spinner />
                    </label>
                  </div>
                  <div
                    :key="logo.id"
                    class="w-full h-full object-contain"
                    v-else
                  >
                    <input
                      class="sr-only peer"
                      type="radio"
                      :value="logo.id"
                      name="qr_logo_type"
                      :id="`qr_logo_${logo.id}`"
                      :checked="(getQrCurrentDesign.logo_type == 'defined') && (logo.id == getQrCurrentDesign.logo_id)"
                      @change="changeColorLogoHandler($event,'defined')"
                    />
                    <label
                      class="flex flex-col p-2 w-full h-full ring ring-6 rounded-qr_input_radius cursor-pointer ring-qr_color-blue_ring peer-checked:ring-qr_color-blue_2 peer-checked:ring-6"
                      :for="`qr_logo_${logo.id}`"
                    >
                      <img
                        :src="logo.url"
                        v-if="logo.url"
                        alt="logo"
                        class="w-full h-full object-contain"
                      />
                    </label>
                  </div>
                </li>
              </ul>
            </div>
            <div
              class="w-2/5 pt-qr_title items-center flex justify-center pl-4"
            >
              <button
                type="button"
                @click="$refs.logoFile.click()"
                class="px-8 py-2.5 overflow-hidden text-sm font-medium text-white bg-qr_color-blue_1 hover:cursor-pointer rounded-qr_input_radius items-center h-auto flex"
              >
                <CloudUploadIcon class="w-4 h-4 fill-current">
                </CloudUploadIcon>
                <span class="ml-1 text-white text-sm font-medium text-[12px]">
                  {{$t('edit_qr_design_modal.upload')}}
                </span>
              </button>
            </div>
          </div>
          <!-- Short URL -->
          <div class="">
            <div class="flex items-center">
              <span class="text-qr_color-blue_2 text-16 py-2 font-bold"
                >{{$t('edit_qr_design_modal.short_url')}}:</span
              >
              <button
                type="button"
                class="p-1 text-cyan-400 flex items-center rounded-sm"
              ></button>
            </div>
            <div class="w-full">
              <div
                v-if="shortURLEditable"
                class="w-full relative text-gray-500 inline-block button"
              >
                <div class="inline-block w-50">
                  <input
                    type="text"
                    readonly="true"
                    class="bg-qr_color-gray_3 h-10 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent"
                    name="qr_short_url"
                    v-model="qrShortBaseUrl"
                  />
                </div>
                <div class="inline-block w-50 mr-8">
                  <input
                    type="text"
                    id="qr_short_url"
                    class="bg-qr_color-gray_3 h-10 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent"
                    name="qr_short_url"
                    :value="qrShortUrlId"
                  />
                </div>
                <button
                  type="button"
                  class="px-8 py-2.5 overflow-hidden text-sm font-medium text-white bg-qr_color-blue_1 hover:cursor-pointer rounded-qr_input_radius"
                  @click="onSaveShortURL($event)"
                >
                  {{$t('edit_qr_design_modal.save')}}
                </button>
                <button
                  type="button"
                  class="px-8 py-2.5 overflow-hidden text-sm font-medium text-qr_color-blue_1 bg-transparent hover:cursor-pointer rounded-qr_input_radius border-qr_color-blue_1 border-1"
                  @click="onCancelShortURL"
                >
                  {{$t('edit_qr_design_modal.cancel')}}
                </button>
              </div>
              <div
                v-else
                class="w-full relative text-gray-500 inline-block buttonS"
              >
                <div class="w-full group">
                  <input
                    type="text"
                    class="bg-qr_color-gray_3 h-10 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent"
                    name="qr_short_url"
                    :value="qrFullShortURL"
                    :disabled="true"
                    :readonly="true"
                  />
                  <div
                    class="invisible delay-100 group-hover:visible tran absolute top-1 bottom-1 left-1 px-2 py-1 bg-gray-900 rounded-lg text-white flex items-center"
                  >
                    <span class="text-sm overflow-hidden text-ellipsis"
                      >{{qrFullShortURL}}</span
                    >
                    <button
                      class="text-[12px] font-medium text-blue-400 hover:text-blue-300"
                      @click="onCopyURLToClipboard"
                    >
                      {{$t('edit_qr_design_modal.copy')}}
                    </button>
                  </div>
                </div>
                <button
                  type="button"
                  class="absolute right-0.5 top-0.5 h-9 inline-flex items-center px-2.5 py-1.5 text-sm text-qr_color_gray rounded-sm hover:text-qr_color-blue_1"
                  @click="changeShortUrlHandler"
                >
                  <PencilIcon class="w-4 h-4 fill-current" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- qr code detail settings -->
        <div v-if="page == 'qr_code_detail'" class="w-full">
          <!-- Back button -->
          <button
            type="button"
            class="absolute top-2 left-0 block px-2 rounded-full text-qr_color-blue_1 pl-8"
            @click="page='main'"
          >
            <ArrowLeftIcon class="w-4 h-4 fill-current inline-block">
            </ArrowLeftIcon>
            <span class="text-qr_color-blue_1 text-sm h-4">
              {{$t('edit_qr_design_modal.back')}}
            </span>
          </button>
          <!-- shape color -->
          <div class="mb-8">
            <div class="flex justify-between items-center mt-8">
              <span class="text-qr_color-blue_2 text-16 font-bold"
                >{{$t('edit_qr_design_modal.shape_color')}}:</span
              >
            </div>
            <div class="w-full">
              <!-- code fore color -->
              <div class="inline-block relative">
                <div class="w-full relative text-gray-500 inline-block">
                  <span
                    class="text-qr_color-blue_2 text-16 float-left py-2 font-bold"
                  >
                    {{$t("edit_qr_design_modal.code_color")}}
                  </span>

                  <input
                    type="text"
                    class="bg-qr_color-gray_3 h-10 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent"
                    name="qr_code_color"
                    v-model="codeColor"
                  />

                  <button
                    class="absolute right-0.5 top-10 h-9 inline-flex items-center px-1.5 py-1.5 rounded-qr_input-radius border-0"
                    type="button"
                    @click="codeColorPickerVisible=!codeColorPickerVisible"
                  >
                    <span
                      class="w-6 h-6 border-0 rounded-qr_color_radius border-0"
                      :style="`background-color: ${codeColor}`"
                    >
                    </span>
                  </button>
                </div>

                <div class="absolute z-40" v-if="codeColorPickerVisible">
                  <QRCustomColorPicker
                    v-model="codeColor"
                    :swatches="[]"
                    :hide-swatches="true"
                    @change="onCodeColorUpdated"
                    @close="codeColorPickerVisible=false"
                  />
                </div>
              </div>

              <!-- code background -->
              <div class="inline-block relative">
                <div class="w-full relative text-gray-500 inline-block">
                  <span
                    class="text-qr_color-blue_2 text-16 float-left py-2 font-bold"
                  >
                    {{$t('edit_qr_design_modal.background_color')}}
                  </span>

                  <input
                    type="text"
                    class="bg-qr_color-gray_3 h-10 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent"
                    name="qr_code_color"
                    v-model="codeBgColor"
                  />

                  <button
                    class="absolute right-0.5 top-10 h-9 inline-flex items-center px-1.5 py-1.5 rounded-qr_input-radius border-0"
                    type="button"
                    @click="codeBackColorPickerVisible=!codeBackColorPickerVisible"
                  >
                    <span
                      class="w-6 h-6 border-0 rounded-qr_color_radius border-0"
                      :style="`background-color: ${codeBgColor}`"
                    >
                    </span>
                  </button>
                </div>
                <div class="absolute z-40" v-if="codeBackColorPickerVisible">
                  <QRCustomColorPicker
                    v-model="codeBgColor"
                    :swatches="[]"
                    :hide-swatches="true"
                    @change="onBackgroundColorUpdated"
                    @close="codeBackColorPickerVisible=false"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- shape style begin -->
          <div class="mb-8">
            <div class="flex justify-between items-center">
              <span class="text-qr_color-blue_2 text-16 mt-2 font-bold"
                >{{$t('edit_qr_design_modal.shape_style')}}</span
              >
            </div>
            <div class="w-full">
              <ul class="flex gap-x-6 my-1 justify-start">
                <li class="w-16 h-16 relative" v-for="format in getQrPatterns">
                  <input
                    class="sr-only peer"
                    type="radio"
                    :value="format.id"
                    name="qr_format_type"
                    :checked="getQrCurrentDesign.pattern_id==format.id"
                    @change="previewChangesHandler($event,'pattern_id')"
                    :id="`qr_format_${format.id}`"
                  />
                  <label
                    class="flex flex-col p-2 w-full h-full ring ring-6 rounded-qr_input_radius cursor-pointer ring-qr_color-blue_ring peer-checked:ring-qr_color-blue_2 peer-checked:ring-6"
                    :for="`qr_format_${format.id}`"
                  >
                    <img
                      v-if="format.url"
                      :src="format.url"
                      alt="format"
                      class="w-full h-full object-contain"
                    />
                    <div v-else class="m-auto">
                      <div
                        class="w-8 h-8 rounded-full border-2 border-gray-700 relative before:absolute before:content-[' '] before:origin-center before:rotate-45 before:w-8 before:h-0.5 before:bg-gray-700 before:block before:top-[13px] before:left-[-2px]"
                      ></div>
                    </div>
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <!-- shape style end -->

          <!-- edges begin-->
          <div class="">
            <div class="flex justify-between items-center">
              <span class="text-qr_color-blue_2 text-16 mt-2 font-bold"
                >{{$t('edit_qr_design_modal.edges')}}:</span
              >
            </div>
            <div class="w-full">
              <!-- Edge outer Color color -->
              <div class="inline-block - relative">
                <div class="w-full relative text-gray-500 inline-block">
                  <span
                    class="text-qr_color-blue_2 text-16 float-left py-2 font-bold"
                  >
                    {{$t('edit_qr_design_modal.outer_color')}}
                  </span>
                  <input
                    type="text"
                    class="bg-qr_color-gray_3 h-10 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent"
                    name="qr_code_color"
                    v-model="codeOuterColor"
                  />

                  <button
                    class="absolute right-0.5 top-10 h-9 inline-flex items-center px-1.5 py-1.5 rounded-qr_input-radius border-0"
                    type="button"
                    @click="codeEdgeOuterPickerVisible=!codeEdgeOuterPickerVisible"
                  >
                    <span
                      class="w-6 h-6 border-0 rounded-qr_color_radius border-0"
                      :style="`background-color: ${codeOuterColor}`"
                    >
                    </span>
                  </button>
                </div>

                <div class="absolute z-40" v-if="codeEdgeOuterPickerVisible">
                  <QRCustomColorPicker
                    v-model="codeOuterColor"
                    :swatches="[]"
                    :hide-swatches="true"
                    @change="onOuterColorUpdated"
                    @close="codeEdgeOuterPickerVisible=false"
                  />
                </div>
              </div>

              <!-- Edge inner Color visible -->
              <div class="inline-block relative">
                <div class="w-full relative text-gray-500 inline-block">
                  <span
                    class="text-qr_color-blue_2 text-16 float-left py-2 font-bold"
                  >
                    {{$t('edit_qr_design_modal.inner_color')}}
                  </span>
                  <input
                    type="text"
                    class="bg-qr_color-gray_3 h-10 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent"
                    name="qr_code_color"
                    v-model="codeInnerColor"
                  />

                  <button
                    class="absolute right-0.5 top-10 h-9 inline-flex items-center px-1.5 py-1.5 rounded-qr_input-radius border-0"
                    type="button"
                    @click="codeEdgeInnerPickerVisible=!codeEdgeInnerPickerVisible"
                  >
                    <span
                      class="w-6 h-6 border-0 rounded-qr_color_radius border-0"
                      :style="`background-color: ${codeInnerColor}`"
                    >
                    </span>
                  </button>
                </div>

                <div class="absolute z-40" v-if="codeEdgeInnerPickerVisible">
                  <QRCustomColorPicker
                    v-model="codeInnerColor"
                    :swatches="[]"
                    :hide-swatches="true"
                    @change="onInnerColorUpdated"
                    @close="codeEdgeInnerPickerVisible=false"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- edges end-->
        </div>
      </div>
      <div
        class="relative flex-shrink-0 w-full md:w-96 border-0 border-transparent py-8 pr-8 bg-white rounded-r-qr_input_radius"
      >
        <div
          class="h-full flex flex-col justify-center transition-all ease-linear duration-200 gap-y-14"
          :class="drawerOpen? 'h-5/6' : 'h-full'"
        >
          <!-- left-header -->
          <div class="h-20 w-full" v-if="0">
            <div class="flex justify-between items-center">
              <button
                v-if="0"
                type="button"
                class="p-1 text-cyan-400 hover:bg-cyan-100"
              >
                <PrinterIcon class="w-4 h-4 fill-current"> </PrinterIcon>
              </button>
              <button
                v-if="0"
                type="button"
                class="p-1 text-cyan-400 hover:bg-cyan-100 flex items-center"
              >
                <FolderUploadIcon class="w-4 h-4 fill-current">
                </FolderUploadIcon>
                <span
                  class="ml-2 text-blue-600 text-sm font-medium text-[12px]"
                >
                  {{$t('saved_design')}}
                </span>
              </button>
            </div>
          </div>
          <!-- left-main content -->
          <div class="w-full flex items-center justify-center relative pl-8">
            <div class="spinnerContainer absolute w-full">
              <Spinner :visible="getLoadingQrModal" />
            </div>
            <div class="designBox" :style="getStyleDesignBox"></div>
          </div>

          <!-- left-footer -->
          <div class="h-32 w-full downloadButtonCnt">
            <div class="flex justify-center items-center mb-2">
              <button
                v-if="getEditorMode"
                type="button"
                class="newBtn text-white bg-cyan-400 hover:bg-cyan-500 focus:ring-4 focus:ring-cyan-300 font-medium rounded-full text-base mr-2 focus:outline-none flex items-center"
                @click="placeQrInEditorHandler($event)"
              >
                <span class="uppercase"
                  >{{$t('edit_qr_design_modal.place_qr_in_editor')}}</span
                >
              </button>
              <button
                v-if="!getEditorMode"
                type="button"
                class="overflow-hidden text-sm font-medium text-white bg-qr_color-blue_1 hover:cursor-pointer rounded-qr_input_radius"
              >
                <a
                  :href="getDownloadSvgUrl"
                  class="hover:text-white no-underline w-full h-full px-8 py-2.5 flex"
                  ><span>{{$t('edit_qr_design_modal.download')}}</span></a
                >
              </button>
              <button
                v-if="!getEditorMode"
                type="button"
                class="text-qr_color-blue_1"
                @click="showDownloadOptions"
              >
                <MoreIcon
                  class="w-6 h-6 border-0 rounded-qr_color_radius border-0 fill-current"
                >
                </MoreIcon>
              </button>
            </div>
          </div>
        </div>
        <!-- drawer component -->
        <div>
          <!-- overlay -->
          <div
            class="absolute z-10 left-0 right-0 bottom-0 top-0 bg-black border-0 border-transparent rounded-qr-r-input_radius transition-all ease-linear delay-50 duration-500"
            :class="drawerOpen? 'visible opacity-50' : 'invisible opacity-0'"
          ></div>
          <!-- main content -->
          <div
            class="absolute z-20 h-64 left-2 right-2 bottom-2 bg-white border-0 rounded-qr_input_radius transition-all ease-linear delay-50 duration-500"
            :class="drawerOpen? 'visible opacity-100' : 'invisible opacity-0'"
          >
            <div class="relative w-full h-full px-8 py-6">
              <button
                type="button"
                class="absolute top-2 right-2 block p-1 text-qr_color-blue_1"
                @click="drawerOpen = false"
              >
                <CloseIcon class="w-4 h-4 fill-current"> </CloseIcon>
              </button>
              <div class="font-bold text-qr_color-gray pb-1">
                {{$t('edit_qr_design_modal.select_your_format')}}
              </div>
              <div class="w-full w-full flex flex-col gap-y-3">
                <button
                  type="button"
                  class="w-full overflow-hidden text-sm font-medium text-qr_color-blue_1 bg-transparent hover:cursor-pointer rounded-qr_input_radius border-qr_color-blue_1 border-1"
                >
                  <a
                    :href="getDownloadPngUrl"
                    class="hover:text-blue_1 inline-block text-center no-underline w-full h-full px-8 py-2.5"
                    target="_blank"
                  >
                    {{$t('edit_qr_design_modal.download_png')}}
                  </a>
                </button>
                <button
                  type="button"
                  class="w-full overflow-hidden text-sm font-medium text-qr_color-blue_1 bg-transparent hover:cursor-pointer rounded-qr_input_radius border-qr_color-blue_1 border-1"
                >
                  <a
                    :href="getDownloadJpgUrl"
                    class="hover:text-blue_1 inline-block text-center no-underline w-full h-full px-8 py-2.5"
                    target="_blank"
                    >{{$t('edit_qr_design_modal.download_jpg')}}</a
                  >
                </button>
                <button
                  type="button"
                  class="w-full overflow-hidden text-sm font-medium text-qr_color-blue_1 bg-transparent hover:cursor-pointer rounded-qr_input_radius border-qr_color-blue_1 border-1"
                >
                  <a
                    :href="getDownloadSvgUrl"
                    class="hover:text-blue_1 inline-block text-center no-underline w-full h-full px-8 py-2.5"
                    target="_blank"
                    >{{$t('edit_qr_design_modal.download_svg')}}</a
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NextActionFooter
      v-if="getEditorMode"
      :popupMode="true"
      :onCloseDialog="onCloseDialog"
      @back="backHandlerEditorMode"
    ></NextActionFooter>
  </div>
</div>
