export const createDynamicInstagramFields = (formly, t) => {
  return [
    formly.labelTitle({
      titleIcon: "InstagramIcon",
      titleKey: "qrcode_title",
      titleTooltip: t(
        "Labeling QR Codes has been moved to this area. Names help you to stay organized and will only appear in your account and are not displayed to customers who scan your QR Codes."
      ),
      titlePlaceholder: t("My Instagram QR Code"),
      titleValue: null,
      buttonLabel: t("Got it")
    }),
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_instagram",
        fold: false,
        headline: t("Basic information"),
        icon: "DesignIcon"
      }),
      formly.body({
        name: "section_instagram",
        collapseKey: "section_instagram",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t(
              "Type in the Instagram username of your business to link with the QR Code."
            )
          }),
          formly.inputRowRequired({
            key: "username",
            className: "form-input-row form-row-container flex-col",
            label: t("Username"),
            required: true,
            placeholder: "@",
            errorMsgTitle: t("Please provide an username."),
            filters: {
              // makeUsername: function ({ value }) {
              //   if (
              //     value &&
              //     value.trim() !== "" &&
              //     !(value.substring(0, 1) === "@")
              //   ) {
              //     value = "@" + value;
              //   }
              //   return value;
              // }
            },
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(t("Please provide an username."));
                  return false;
                }

                return true;
              }
            }
          })
        ]
      })
    )
  ];
};

export const createDynamicInstagramRequiredFields = _ => {
  return ["username"];
};
