const getModule = () => {
  const DEFAULT_CARD_TYPE = "dynamic-vcard-plus";
  const state = {
    widget_mode: false,
    editor_mode: false,
    edit_id: 0,
    card_type: DEFAULT_CARD_TYPE,
    card_types: {},
    editor_place_callback: () => {}
  };

  const getters = {
    getWidgetMode: state => {
      return state.widget_mode;
    },
    getCardType: state => {
      return state.card_type;
    },
    getCardTypes: state => {
      return state.card_types;
    },
    getWidgetEditorMode: state => {
      return state.editor_mode;
    },
    getWidgetEditId: state => {
      return state.edit_id;
    },

    getWidgetEditorPlaceCallback: state => {
      return state.editor_place_callback;
    }
  };

  const mutations = {
    changeWidgetMode(state, status) {
      state.widget_mode = status;
    },
    changeCardType(state, type) {
      state.card_type = type;
    },
    changeCardTypes(state, { type, model }) {
      state.card_types[type] = { ...model };
    },
    changeWidgetEditorMode(state, status) {
      state.editor_mode = status;
    },
    changeWidgetEditId(state, id) {
      state.edit_id = id;
    },
    changeWidgetEditorPlaceCallback(state, payload) {
      state.editor_place_callback = payload;
    },
    changeCardTypeToDefault(state) {
      state.card_type = DEFAULT_CARD_TYPE;
    }
  };
  const actions = {
    changeWidgetMode: function ({ commit, dispatch }, payload) {
      commit("changeWidgetMode", payload);
    },
    changeWidgetEditId: function ({ commit, dispatch }, payload) {
      commit("changeWidgetEditId", payload);
    },
    changeWidgetEditorMode: function ({ commit, dispatch }, payload) {
      commit("changeWidgetEditorMode", payload);
    },
    changeWidgetEditorPlaceCallback: function ({ commit, dispatch }, payload) {
      if (!payload) {
        commit("changeWidgetEditorPlaceCallback", () => {});
      } else {
        commit("changeWidgetEditorPlaceCallback", payload);
      }
    },
    changeCardType: function ({ commit, dispatch, getters }, { type }) {
      const currentModel = getters.getCurrentModel;
      const currentType = getters.getCardType;

      const payload = {
        type: currentType,
        model: currentModel
      };
      commit("changeCardTypes", payload);

      dispatch("resetCurrentModel", {});
      commit("setSelectedCategory", type);
      dispatch("updateCurrentQrType");
      const card_types = getters.getCardTypes;
      let defaultModel = getters.getDefaultCategoryModel;
      if (card_types.hasOwnProperty(type)) {
        defaultModel = { ...card_types[type] };
      }
      commit("setCurrentModel", defaultModel);
      commit("changeCardType", type);
      /*let formElement = document.getElementById("formCard");
      if (formElement) {
        if (formElement.checkValidity()) { 
          dispatch("createSaveQrCode", 1);
        } else {
          formElement.reportValidity();
        }
      } */
    }
  };

  return {
    state,
    getters,
    mutations,
    actions
  };
};
export default getModule;
