<div
  class="left-0 bg-qr_color-blue_2 h-20 fixed bottom-0 w-full flex items-center justify-around md:justify-center nextArea"
>
  <div class="w-full flex flex-row justify-between max-w-7xl px-3">
    <div class="relative left-0 flex">
      <button
        type="button"
        v-if="isBackShow"
        class="text-white font-bold backButton"
        @click="onPreviousClicked"
      >
        <ArrowLeftIcon class="w-4 h-4 fill-current inline-block">
        </ArrowLeftIcon>
        <span>{{$t('next_action_footer.back')}}</span>
      </button>
    </div>

    <button
      type="button"
      v-if="!popupMode"
      class="relative flex items-center px-10 text-white rounded bg-qr_color-blue_1 p-2 rounded-qr_input_radius"
      :disabled="getQRActionLoading"
      @click="onNextClicked"
    >
      <span class="mr-2"
        >{{ edit? $t('next_action_footer.save') :
        $t('next_action_footer.next')}}</span
      >
      <Spinner
        v-if="getQRActionLoading"
        :size="6"
        :visible="true"
        class="absolute right-5"
      />
    </button>
  </div>
</div>
