<form @submit="onSubmitForm" id="formCard" ref="formCard">
  <div class="w-full">
    <div class="py-4 lg:py-8 mx-auto max-w-screen-2xl pb-16">
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-0 lg:gap-6 xl:gap-16 containerDetails relative">
        <div class="lg:col-span-2">
          <qr-form :form="form" :model="model" :fields="fields" @update="onUpdate" @validate="onValidate"
            @click="onClicked"></qr-form>
          <button class="invisible" type="submit" ref="submitRef"></button>
        </div>
        <div class="relative flex justify-center">
          <aside class="">
            <PreviewCode :mode="previewMode" :enabled="true" @update="onPreviewModeUpdate">
              <div class="w-full">
                <div v-show="previewMode == 'preview'" class="w-full">
                  <div
                    class="w-[320px] h-[523px] relative flex m-auto text-qr_color-blue_1 bg-qr_color-blue_1 border-[15px] border-qr_color-blue_1 rounded-qr_input_radius box-content">
                    <div class="w-[320px] h-[523px]">
                      <div class="placeholder ratchet overflow-hidden w-full h-full rounded-qr_input_radius">
                        <iframe class="w-full h-full" ref="templatePreviewIframe" :src="getTemplatePreviewUrl"></iframe>
                      </div>
                      <PhoneIcon class="absolute top-0 left-0 pointer-events-none" />
                    </div>
                  </div>
                </div>
                <div v-show="previewMode !== 'preview'" class="w-full">
                  <div class="qrBox" :style="getQrBoxStyle"></div>
                </div>
              </div>
            </PreviewCode>
          </aside>
        </div>
      </div>
    </div>
    <NextActionFooter @next="onNextClicked" @back="onBackClicked" :edit="edit"
      :isBackShow="!(getEditorMode && getEditMode)"></NextActionFooter>
  </div>
</form>