import WithRender from "./QrTypeSelect.html";

export default WithRender({
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      menuList: [
        {
          value: "dynamic-vcard-plus",
          label: this.$t("qr_type_select.dynamic_vcard_plus")
        },
        {
          value: "dynamic-coupon",
          label: this.$t("qr_type_select.dynamic_coupon")
        },
        {
          value: "dynamic-business",
          label: this.$t("qr_type_select.dynamic_business")
        },
        {
          value: "dynamic-event",
          label: this.$t("qr_type_select.dynamic_event")
        },
        {
          value: "dynamic-video",
          label: this.$t("qr_type_select.dynamic_video")
        },
        {
          value: "dynamic-image",
          label: this.$t("qr_type_select.dynamic_image")
        },
        { value: "dynamic-url", label: this.$t("qr_type_select.dynamic_url") },
        // {
        //   value: "dynamic-feedback",
        //   label: this.$t("qr_type_select.dynamic_feedback")
        // },
        {
          value: "dynamic-social-media",
          label: this.$t("qr_type_select.dynamic_social_media")
        },
        {
          value: "dynamic-text",
          label: this.$t("qr_type_select.dynamic_text")
        },
        {
          value: "dynamic-instagram",
          label: this.$t("qr_type_select.dynamic_instagram")
        },
        {
          value: "dynamic-facebook",
          label: this.$t("qr_type_select.dynamic_facebook")
        },
        {
          value: "dynamic-pdf",
          label: this.$t("qr_type_select.dynamic_pdf")
        },
        // {
        //   value: "dynamic-rating",
        //   label: this.$t("qr_type_select.dynamic_rating")
        // },
        {
          value: "dynamic-mp3",
          label: this.$t("qr_type_select.dynamic_mp3")
        },
        {
          value: "dynamic-app",
          label: this.$t("qr_type_select.dynamic_app")
        }
      ]
    };
  },
  mounted() {
    const triggerEle = this.$refs.qrTypeSelectButton;
    const targetEle = this.$refs.qrTypeSelect;
    new window.Dropdown(targetEle, triggerEle, {
      placement: "bottom"
    });
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(v) {}
    }
  },
  methods: {
    onUpdateInput() {
      this.$emit("input", this.inputValue);
    }
  }
});
