<div>
  <button ref="optionsBtn" type="button"
    class="text-gray-500 border border-gray-200 hover:border-gray-300 focus:ring-0 focus:outline-none focus:ring-gray-300 font-medium text-sm px-2 py-1.5 text-center inline-flex items-center">
    <CogIcon class="mr-2 w-4 h-4 fill-current"
      :class="optionsDropdown && optionsDropdown._visible? 'text-blue-400' : ''" />
    {{$t('options.options')}}
    <svg class="ml-2 w-4 h-4" :class="optionsDropdown && optionsDropdown._visible? 'rotate-180' : ''" aria-hidden="true"
      fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
    </svg>
  </button>
  <div ref="optionsMenu" class="hidden z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700">
    <ul class="py-1 text-sm text-gray-700 dark:text-gray-200">
      <li class="hover:bg-gray-100" @click="onDownloadCSV">
        <button type="button" class="block py-2 px-4 hover:bg-gray-100 flex items-center">
          <DownloadIcon class="w-4 h-4 mr-2 fill-current inline-block" />
          <span class="inline-block">
            {{$t('options.download_as_csv')}}
          </span>
        </button>
      </li>
      <li class="hover:bg-gray-100" @click="onResetScan">
        <button type="button" class="block py-2 px-4 hover:bg-gray-100 flex items-center">
          <SwapIcon class="w-4 h-4 mr-2 fill-current inline-block" />
          <span class="inline-block">
            {{$t('options.reset_scans')}}
          </span>
        </button>
      </li>
    </ul>
  </div>
</div>