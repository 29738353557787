export const createDynamicInstagramFieldsWidget = (formly, t) => {
  return [
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_instagram",
        fold: false,
        headline: "",
        hideCollapse: true,
        icon: null
      }),
      formly.body({
        name: "section_instagram",
        collapseKey: "section_instagram",
        widget: 1,
        key: "code",
        content: [
          formly.inputRowRequired({
            key: "username",
            className: "form-input-row form-row-container flex-col",
            label: t("Username"),
            required: true,
            placeholder: "@",
            errorMsgTitle: t("Please provide an username."),
            filters: {
              // makeUsername: function ({ value }) {
              //   if (
              //     value &&
              //     value.trim() !== "" &&
              //     !(value.substring(0, 1) === "@")
              //   ) {
              //     value = "@" + value;
              //   }
              //   return value;
              // }
            },
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(t("Please provide an username."));
                  return false;
                }

                return true;
              }
            }
          })
        ]
      })
    )
  ];
};

export const createDynamicInstagramRequiredFieldsWidget = _ => {
  return ["username"];
};
