import baseField from "vue-formly-bootstrap/src/fields/baseField";
import { forEachObjIndexed } from "ramda";

import WithRender from "./QRYoutubeVideoAdd.html";
import { makeRNDStr } from "../../../helpers/helpers";
import { mapGetters, mapActions } from "vuex";
import CloseIcon from "../icons/CloseIcon";
import axios from "axios";

export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model", "options"],
  components: {
    CloseIcon
  },
  data() {
    return {
      videoUrl: "",
      videos: [],
      highlightVideo: true,
      errorMessage: ""
    };
  },

  created() {
    this.model[this.name] ?? [];
    this.model[this.highlightVideoName] ?? true;
  },
  mounted() {
    this.videos = this.getCurrentModel?.videos
      ? [...this.getCurrentModel.videos]
      : [];
    this.highlightVideo = this.getCurrentModel?.highlightVideo
      ? this.getCurrentModel.highlightVideo
      : true;
  },
  computed: {
    ...mapGetters(["getCurrentModel"]),
    name() {
      return this.field.key;
    },
    highlightVideoName() {
      return this.field.highlightVideoKey;
    },
    apiKey: function () {
      return this.$printqGlobalOptions?.youtubeKey ?? "";
    },
    buttonLabel() {
      return this.field.templateOptions.buttonLabel;
    },
    placeHolder() {
      return this.field.templateOptions.placeHolder;
    },
    required() {
      return this.field?.templateOptions?.requiredOneVideo ?? false;
    },
    hasValidators() {
      const validators = this?.field?.templateOptions?.validators ?? {};
      return Object.keys(validators).length > 0;
    },
    inputListeners: function () {
      return Object.assign({}, this.$listeners, {
        validate: event => {
          if (!this.validate(event))
            this.$emit("validate", { element: event.target, event });
        }
      });
    }
  },
  methods: {
    onRemoveVideo(video) {
      const { id } = video;
      this.videos = this.videos.filter(video => video.id !== id);
      this.$emit("update", {
        name: this.name,
        value: this.videos
      });
    },
    changeVideoUrl(event) {
      this.videoUrl = event.target.value;
    },
    onUpdateVideoText(event, id) {
      const newText = event.target.value;
      this.videos = this.videos.map(video => {
        if (video.id === id) {
          video.title = newText;
        }
        return video;
      });
      this.$emit("update", {
        name: this.name,
        value: this.videos
      });
    },
    onHighlightVideo(event) {
      this.highlightVideo = event.target.checked;
      this.$emit("update", {
        name: this.highlightVideoName,
        value: this.highlightVideo
      });
    },
    getVideoInfo() {
      const videoUrl = this.videoUrl.trim();
      const videoId = this.getVideoId(videoUrl);
      if (!videoId) {
        this.errorMessage = this.$t("Please enter a valid youtube video");
        return;
      }

      this.videoUrl = "";

      const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${this.apiKey}&part=snippet`;

      axios
        .get(apiUrl)
        .then(response => {
          const videoData = response.data;
          if (videoData.items && videoData.items.length > 0) {
            const snippet = videoData.items[0].snippet;
            this.videos.push({
              id: videoId,
              videoUrl: videoUrl,
              videoImgUrl:
                snippet?.thumbnails?.medium?.url ??
                snippet?.thumbnails?.default?.url ??
                "",
              title: snippet?.title ?? ""
            });

            this.$emit("update", {
              name: this.name,
              value: this.videos
            });

            this.errorMessage = "";
          } else {
            this.errorMessage = this.$t(
              "Cannot fetch video information from YouTube."
            );
            this.videoInfo = null;
          }
        })
        .catch(() => {
          this.errorMessage = this.$t(
            "Cannot fetch video information from YouTube."
          );
          this.videoInfo = null;
        });
    },
    getVideoId(url) {
      const regex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?(?=.*v=\w+)(?:\S+)?|v\/|embed\/|user\/\S+|playlist\?list=\S+)|youtu\.be\/|(?=.*embed\/))([\w-]{11})(?=\S+|$)/;
      const match = url.match(regex);
      return match ? match[1] : null;
    },
    applyFilters(value) {
      let newValue = value;
      const { name } = this;
      const filters = this?.field?.templateOptions?.filters ?? {};
      forEachObjIndexed((filter, key) => {
        newValue = filter({ name, value: newValue });
      }, filters);
      return newValue;
    },
    validate() {
      let isValid = true;

      if (this.required && this.videos.length === 0) {
        this.errorMessage =
          this?.field?.templateOptions?.requiredMessage ??
          this.$t("At least one video is required");
        isValid = false;
      }

      return isValid;
    }
  },
  watch: {
    model: {
      handler(newValue, oldValue) {
        if (oldValue[this.name] !== newValue[this.name])
          this.videos = [...newValue[this.name]];
        if (
          oldValue[this.highlightVideoName] !==
          newValue[this.highlightVideoName]
        )
          this.highlightVideo = newValue[this.highlightVideoName];
        return;
      },

      deep: true
    }
  }
});
