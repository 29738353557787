import { mapActions, mapGetters, mapMutations } from "vuex";

import opentype from "opentype.js";
import WithRender from "./PreviewQr.html";
import ArrowDownIcon from "../../components/base/icons/ArrowDownIcon";
import CloudUploadIcon from "../../components/base/icons/CloudUploadIcon";
import PrintQrLogoPartial from "../../../src/assets/printQrPartial.svg";
import ArrowUpIcon from "../../components/base/icons/ArrowUpIcon";
import QRCustomColorPicker from "../../../src/components/base/form/QRCustomColorPicker";
import PreviewCode from "../../../src/components/base//sidebar/PreviewCode";
import { computeBackendEndpoint } from "../../../src/helpers/envHelpers";
import Spinner from "../../../src/components/base/components/Spinner";
import PhoneIcon from "../../../src/components/base/icons/PhoneIcon";
import { SVG } from "@svgdotjs/svg.js";

import "@svgdotjs/svg.filter.js";

import _, { set } from "lodash";
export default WithRender({
  expose: ["designModalRef"],
  components: {
    QRCustomColorPicker,
    ArrowDownIcon,
    ArrowUpIcon,
    CloudUploadIcon,
    PrintQrLogoPartial,
    PreviewCode,
    PhoneIcon,
    Spinner
  },
  props: ["validationElements"],
  data() {
    const {
      backendBaseUrl,
      endpoints: { backendTemplatePreview, backendTemplate }
    } = this.$printqGlobalOptions;
    return {
      validation: this.validationElements,
      accordion: {
        frame_open: true,
        shape_open: false,
        color_open: false,
        logo_open: false
      },
      previewMode: "preview",
      designModalRef: null,
      modal: null,
      frameColor: "#000000",
      frameText: "SCAN ME",
      frameColorPickerVisible: false,
      codeColor: "#000000",
      codeBgColor: "#FFFFFF",
      codeOuterColor: "#000000",
      codeInnerColor: "#000000",
      codeColorPickerVisible: false,
      codeBackColorPickerVisible: false,
      codeEdgeOuterPickerVisible: false,
      codeEdgeInnerPickerVisible: false,
      page: "main",
      drawerOpen: false,
      live_preview: false,
      fontObject: null,
      shortURLEditable: false,
      template_preview_endpoint: computeBackendEndpoint(
        backendBaseUrl,
        backendTemplatePreview
      ),
      template_endpoint: computeBackendEndpoint(backendBaseUrl, backendTemplate)
    };
  },
  created() {
    this.updateColor = _.debounce(this.updateColor, 100);
  },
  mounted() {
    const handleQrLandingEvent = e => {
      this.passChangesToIframe({ ...this.getCurrentModel });
    };
    // window.document.addEventListener(
    //   "qrLandingLoaded",
    //   handleQrLandingEvent,
    //   false
    // );

    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent =
      eventMethod === "attachEvent" ? "onmessage" : "message";
    eventer(messageEvent, e => {
      if (e.data === "qrLandingLoaded" || e.message === "qrLandingLoaded") {
        handleQrLandingEvent();
      }
    });
  },
  computed: {
    ...mapGetters([
      "getQRFrameTemplates",
      "getQrPatterns",
      "getQrLogos",
      "getQrLogoTemplates",
      "getEditorMode",
      "getEditMode",
      "getQrCurrentSvg",
      "getDefaultFrameId",
      "getQRActionLoading",
      "isGuest",
      "getLoadingQrModal",
      "getQrCurrentDesign",
      "getWidgetEditorPlaceCallback",
      "getCurrentQrId",
      "getCurrentQr",
      "getCurrentModel",
      "getWidgetEditorMode",
      "getCategories",
      "getCardType"
    ]),
    getTemplatePreviewUrl() {
      return this.template_endpoint.replace("{qrTypeId}", this.typeId ?? "");
    },
    isLivePreviewModeEnabled() {
      return this.live_preview;
    },
    model() {
      return this.getCurrentModel;
    },
    typeId() {
      const categories = this.getCategories;
      const catCode = this.getCardType;
      // console.log("[updateCurrentQrType]", categories, catCode);
      if (categories && categories.length) {
        const category = categories.find(c => c.code == catCode);
        if (category && category.id) {
          return category.id;
        }
      }
      return 0;
    },
    getStyleDesignBox() {
      return {
        backgroundImage:
          "url(data:image/svg+xml;base64," + this.getQrCurrentSvg + ")"
      };
    },
    qrFullShortURL() {
      return computeBackendEndpoint(
        this.$printqGlobalOptions.backendBaseUrl,
        "q/" + this.getQrCurrentDesign.short_code
      );
    },
    getDownloadSvgUrl() {
      if (this.isGuest) {
        return computeBackendEndpoint(
          this.$printqGlobalOptions.backendBaseUrl,
          "app"
        );
      }
      return computeBackendEndpoint(
        this.$printqGlobalOptions.backendBaseUrl,
        "download/downloadDesign/?id=" +
          this.getQrCurrentDesign.id +
          "&type=svg"
      );
    },
    getDownloadPngUrl() {
      return computeBackendEndpoint(
        this.$printqGlobalOptions.backendBaseUrl,
        "download/downloadDesign/?id=" +
          this.getQrCurrentDesign.id +
          "&type=png"
      );
    },
    qrShortUrlId() {
      return this.getQrCurrentDesign.short_code;
    },
    qrShortBaseUrl() {
      return this.$printqGlobalOptions.backendBaseUrl;
    },
    getDownloadJpgUrl() {
      return computeBackendEndpoint(
        this.$printqGlobalOptions.backendBaseUrl,
        "download/downloadDesign/?id=" +
          this.getQrCurrentDesign.id +
          "&type=jpg"
      );
    }
  },
  methods: {
    ...mapActions([
      "fetchQrLogos",
      "previewChanges",
      "addQrLogo",
      "addQrLoadingLogo",
      "removeQrLoadingLogo",
      "deleteQrLogo",
      "previewChangesMultiple",
      "changeCurrentStep",
      "fetchQrDetails",
      "setEditMode",
      "changeShortCode",
      "uploadLogoToServer"
    ]),
    onUpdateMode() {
      this.live_preview = !this.live_preview;
    },
    toggleAccordion(event, type) {
      this.accordion[type] = !this.accordion[type];
    },
    backHandlerEditorMode() {
      if (this.getEditorMode) {
        this.fetchQrDetails({
          id: parseInt(this.getQrCurrentDesign.qr_code_id)
        });
        this.changeCurrentStep("create_new_detail");
      }
    },
    getModal() {
      return this.modal;
    },
    getFormValid(event) {
      let formElement = document.getElementById("formCard");
      const validateEvent = new Event("validate");
      let invalidFound = false;

      if (formElement) {
        formElement.forEach(element => {
          if (element.hasAttribute("hasValidators")) {
            element.dispatchEvent(validateEvent);
          }
        });
        if (this.validationElements.length > 0) {
          this.validationElements.forEach(({ element }) => {
            if (element && !invalidFound) {
              // element.scrollIntoView({
              //   behavior: "smooth"
              // });
              element.focus();
              invalidFound = true;
            }
          });
        }
        this.$emit("clearValidation");

        if (!invalidFound) {
          return true;
        } else {
          event.preventDefault();
        }
      }
      this.$emit("clearValidation");
      return false;
    },
    preventOpenColorPicker(event, colorPicker) {
      if (this.getFormValid(event)) {
        this[colorPicker] = !this[colorPicker];
      }
    },
    showDownloadOptions() {
      this.drawerOpen = true;
    },
    placeQrInEditorHandler(event) {
      event.stopPropagation();
      event.preventDefault();
      if (this.getWidgetEditorPlaceCallback) {
        var post_data = {
          url: this.getQrCurrentDesign.qr_url,
          qr_id: this.getQrCurrentDesign.qr_code_id,
          type: "qr_add",
          svg_content: this.getQrCurrentSvg
        };
        this.getWidgetEditorPlaceCallback(post_data);
      }
    },
    changeColorLogoHandler(event, type, value) {
      if (this.getFormValid(event)) {
        let key_logo = "logo_template_id";
        if (type == "defined") {
          key_logo = "logo_id";
        }
        if (value) {
          value = parseInt(value);
        }
        this.previewChangesMultiple([
          {
            key: "logo_type",
            value: type
          },
          {
            key: key_logo,
            value: value
          }
        ]);
      }
    },
    previewChangesHandler(event, key, frame) {
      if (this.getFormValid(event)) {
        let value = frame;
        if (!frame) {
          value = event.target.value;
        }
        if (key == "frame_template_id") {
          this.previewChangesMultiple([
            {
              key: key,
              value: parseInt(frame.id)
            },
            {
              key: "stop",
              value: parseInt(frame.id)
            },
            {
              key: "text_color",
              value: frame.text_color
            },
            {
              key: "text_font",
              value: frame.text_font
            },
            {
              key: "text_font_url",
              value: frame.text_font_url
            }
          ]);
          let callBackFunction = font => {
            this.changeText(font, this.getQrCurrentDesign.text);
          };
          var params = {
            fontName: this.getQrCurrentDesign.text_font,
            fontUrl: this.getQrCurrentDesign.text_font_url,
            callBackFunction: callBackFunction
          };
          this.$printqTextToPath(params);
        } else {
          this.previewChanges({
            key: key,
            value: parseInt(value)
          });
        }
      }
    },
    onCloseDialog() {
      if (this.modal) {
        this.modal.hide();
      }
    },
    onFrameColorUpdated(color) {
      var params = {
        key: "color",
        value: this.frameColor
      };
      this.updateColor(params);
    },
    onCodeColorUpdated(color) {
      var params = {
        key: "foreground_color",
        value: this.codeColor
      };
      this.updateColor(params);
    },
    onChangeLogoFile({ target: { validity, files } }) {
      if (validity.valid && files.length > 0) {
        const file = files[0];
        const loadingId = 243;
        this.addQrLoadingLogo(loadingId);
        this.uploadLogoToServer(file)
          .then(data => {
            const { uploadLogo, error } = data;
            if (error) {
              this.removeQrLoadingLogo(loadingId);
            } else {
              this.addQrLogo({ uploadLogo, loadingId });
            }
          })
          .catch(e => {
            this.removeQrLoadingLogo(loadingId);
          });
      }
    },
    onBackgroundColorUpdated(color) {
      var params = {
        key: "background_color",
        value: this.codeBgColor
      };
      this.updateColor(params);
    },
    onInnerColorUpdated(color) {
      var params = {
        key: "inner_corner_color",
        value: this.codeInnerColor
      };

      this.updateColor(params);
    },
    onOuterColorUpdated(color) {
      var params = {
        key: "outer_corner_color",
        value: this.codeOuterColor
      };
      this.updateColor(params);
    },
    updateColor(params) {
      if (this.getFormValid({})) {
        this.previewChanges(params);
      }
    },
    async onCopyURLToClipboard() {
      await navigator.clipboard.writeText(this.qrFullShortURL);
    },
    onSaveShortURL(event) {
      var target = document.getElementById("qr_short_url");
      if (target && target.value.length) {
        this.changeShortCode({
          id: this.getQrCurrentDesign.qr_code_id,
          short_code: target.value,
          swal: this.$swal
        });
        this.shortURLEditable = false;
      }
    },
    onCancelShortURL() {
      this.shortURLEditable = false;
    },
    passChangesToIframe(value) {
      if (this?.$refs?.templatePreviewIframe) {
        // if (this?.$refs?.templatePreviewIframe?.contentWindow?.qrChangeData) {
        //   this.$refs.templatePreviewIframe.contentWindow.qrChangeData(
        //     { ...value },
        //     null,
        //     true
        //   );
        // }

        this.$refs.templatePreviewIframe.contentWindow.postMessage(
          {
            type: "landingChangeData",
            code: { ...value },
            templateType: null,
            demo: true
          },
          "*"
        );
      }
    },
    changeShortUrlHandler() {
      this.$swal({
        title: "Important note",
        text:
          "Changes to the short URL of an already published QR Code will cause the previous version of the QR Code to be overwritten and it will no longer be scannable.",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes!",
        cancelButtonText: "No!",
        closeOnConfirm: false,
        closeOnCancel: false
      }).then(response => {
        if (response.isConfirmed) {
          this.shortURLEditable = true;
        }
      });
    },
    changeText(font, cText) {
      let fontPaths = [];
      const text = cText ?? "";

      fontPaths = font.getPaths(text, 0, 0, 100);
      const pathString = fontPaths
        .map(fontPath => {
          return fontPath.toSVG();
        })
        .join("");
      let svg = SVG();
      svg.clear();
      const bodyGroup = svg.group();
      bodyGroup.svg(pathString).attr({
        fill: this.getQrCurrentDesign.text_color
      });
      const pathLeft = bodyGroup.bbox().x;
      const pathTop = bodyGroup.bbox().y;
      const pathRight = bodyGroup.bbox().x2;
      const pathBottom = bodyGroup.bbox().y2;

      const width = pathRight - pathLeft;
      const height = pathBottom - pathTop;
      svg.attr({
        viewBox: `${pathLeft} ${pathTop} ${width} ${height}`
      });
      svg.attr({
        pathLeft: `${pathLeft}`
      });
      svg.attr({
        pathTop: `${pathTop}`
      });

      svg.size(width, height);
      let params = [
        {
          key: "svg_text",
          value: svg.node.outerHTML
        },
        {
          key: "text",
          value: text
        }
      ];
      this.previewChangesMultiple(params);
    },
    onChangeFrameText(event) {
      const text = event.target.value;
      var callBackFunction = font => {
        this.changeText(font, text);
      };
      var params = {
        fontName: this.getQrCurrentDesign.text_font,
        fontUrl: this.getQrCurrentDesign.text_font_url,
        callBackFunction: callBackFunction
      };
      this.$printqTextToPath(params);
    }
  },
  watch: {
    "getQrCurrentDesign.color": {
      handler(newColor, oldColor) {
        this.frameColor = newColor;
      },
      deep: true
    },
    "getQrCurrentDesign.foreground_color": {
      handler(newColor, oldColor) {
        this.codeColor = newColor;
      },
      deep: true
    },
    "getQrCurrentDesign.background_color": {
      handler(newColor, oldColor) {
        this.codeBgColor = newColor;
      },
      deep: true
    },
    "getQrCurrentDesign.outer_corner_color": {
      handler(newColor, oldColor) {
        this.codeOuterColor = newColor;
      },
      deep: true
    },
    "getQrCurrentDesign.inner_corner_color": {
      handler(newColor, oldColor) {
        this.codeInnerColor = newColor;
      },
      deep: true
    },
    getQrCurrentDesign: {
      handler(newDesign, oldDesign) {
        if (
          newDesign.text_font != oldDesign.text_font ||
          newDesign.text_color != oldDesign.text_color ||
          newDesign.text_font_url != oldDesign.text_font_url
        ) {
          let callBackFunction = font => {
            this.changeText(font, this.getQrCurrentDesign.text);
          };
          var params = {
            fontName: newDesign.text_font,
            fontUrl: newDesign.text_font_url,
            callBackFunction: callBackFunction
          };
          // this.$printqTextToPath(params);
        }
      },
      deep: true
    },
    getCurrentModel: {
      handler(newValue, oldValue) {
        this.passChangesToIframe(newValue);
      },
      deep: true
    }
  }
});
