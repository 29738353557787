export const createDynamicVCardFieldsWidget = (formly, t) => {
  return [
    formly.container(
      formly.header({
        index: 1,
        sectionName: "section_information",
        fold: false,
        headline: "",
        hideCollapse: true,
        icon: null
      }),
      formly.body({
        name: "section_information",
        collapseKey: "section_information",
        widget: 1,
        key: "code",
        content: [
          formly.avatarCircle({
            tooltip: t(
              "Select an image as your vCard profile picture. Upload .jpg or .png file from your computer."
            ),
            key: "avatar",
            previewWelcomeScreen: false,
            borderRadius: "50%"
          }),
          {
            className: "m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          },
          formly.rowWith2Inputs({
            label: t("Name"),
            required: true,
            firstInput: formly.inputHalf({
              key: "firstname",
              required: true,
              placeholder: t("First Name"),
              className: "flex-0",
              errorHolder: "#errorLastname",
              condition: model => !(model["firstname"] || model["lastname"]),
              validators: {
                required: function ({ messages, name, value }) {
                  if (value.trim() == "") {
                    messages.push(t("Please enter a first name"));
                    return false;
                  }

                  return true;
                }
              }
            }),
            secondInput: formly.inputHalf({
              key: "lastname",
              required: false,
              placeholder: t("Last Name"),
              className: "flex-0",
              errorHolder: "#errorLastname",
              errorMsg: t("Please enter a first name"),
              condition: model => !(model["firstname"] || model["lastname"])
            })
          }),
          formly.inputRow({
            key: "numbers_mobile",
            label: t("Numbers"),
            placeholder: t("Mobile number")
          }),
          formly.rowWith2Inputs({
            firstInput: formly.inputHalf({
              key: "numbers_phone",
              className: "flex-0",
              placeholder: t("Phone")
            }),
            secondInput: formly.inputHalf({
              key: "numbers_fax",
              className: "flex-0",
              placeholder: t("Fax")
            })
          }),
          formly.inputRow({
            key: "email",
            label: t("Email"),
            placeholder: t("your@email.com")
          }),
          formly.rowWith2Inputs({
            label: t("Company"),
            firstInput: formly.inputHalf({
              key: "company",
              className: "flex-0",
              placeholder: t("Company"),
              condition: model => !(model["firstname"] || model["lastname"])
            }),
            secondInput: formly.inputHalf({
              key: "job",
              className: "flex-0",
              placeholder: t("Your Job")
            })
          }),
          formly.autocompleteInputForm({ wrappedInAddressKey: false }),
          formly.directionsButton({
            key: "show_directions",
            initValue: true,
            placeholder: t("My vCard Plus QR Code"),
            label: t("Show Directions button"),
            hideExpression: model => !model["street"] && !model["city"]
          }),
          formly.inputRow({
            key: "website",
            label: t("Website"),
            placeholder: t("https://www.my-website.com"),
            filters: {
              makeUrl: function ({ value }) {
                if (value.trim() !== "" && !value.match(/:\/\//)) {
                  value = "http" + "://" + value;
                }
                return value;
              }
            }
          }),
          formly.textAreaRow({
            key: "bio",
            label: t("Summary"),
            placeholder: "",
            maxChar: 250
          })
        ]
      })
    )
  ];
};

export const createDynamicVCardRequiredFieldsWidget = _ => {
  return ["firstname"];
};
