import baseField from "vue-formly-bootstrap/src/fields/baseField";

import WithRender from "./QRDropDown.html";

export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  data() {
    return {
      value: "",
      dropdown: null
    };
  },
  created() {
    this.value = this.defaultValue;
  },
  mounted() {
    const targetEl = this.$refs.dropdownMenu;
    const triggerEl = this.$refs.dropdownBtn;
    const options = {
      placement: "bottom"
    };

    this.dropdown = new window.Dropdown(targetEl, triggerEl, options);
  },
  computed: {
    name() {
      return this.field.key;
    },
    defaultValue() {
      return this.field.templateOptions.defaultValue;
    },
    items() {
      return this.field.templateOptions.items;
    },
    customLabel() {
      return this.field.templateOptions.customLabel;
    },
    placeholder() {
      return this.field.templateOptions.placeholder || "";
    },
    required() {
      return this.field.templateOptions.required || false;
    }
  },
  methods: {
    onSelectItem(v) {
      this.value = v;
      const name = this.name;
      const value = this.value;
      this.$emit("update", { name, value });
      if (this.dropdown) {
        this.dropdown.hide();
      }
    }
  }
});
