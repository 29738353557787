<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
	y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
	<g>
		<g>
			<path d="M412.11,19.401L306.043,125.467L164.722,266.789H76.531L0,343.318l25.211,25.211l66.086-66.086h37.77l-46.595,46.595
			l12.606,12.605c30.799,30.801,71.995,47.762,116,47.762c44.005,0,85.201-16.962,116-47.762
			c30.799-30.799,47.762-71.995,47.762-116c0-37.947-12.624-73.8-35.848-102.703l87.885-87.885H512V19.401H412.11z" />
		</g>
	</g>
	<g>
		<g>
			<path d="M83.914,385.637c-29.49,0-53.481,23.992-53.481,53.481c0,29.49,23.992,53.481,53.481,53.481s53.481-23.992,53.481-53.481
			C137.395,409.628,113.404,385.637,83.914,385.637z" />
		</g>
	</g>
	<g>
		<g>
			<path d="M345.378,385.637c-29.49,0-53.481,23.992-53.481,53.481c0,29.49,23.992,53.481,53.481,53.481s53.481-23.992,53.481-53.481
			C398.859,409.628,374.869,385.637,345.378,385.637z" />
		</g>
	</g>
	<g>
		<g>
			<path d="M238.415,40.979c-29.49,0-53.481,23.992-53.481,53.481c0,29.49,23.992,53.481,53.481,53.481
			c29.49,0,53.481-23.992,53.481-53.481C291.897,64.97,267.905,40.979,238.415,40.979z" />
		</g>
	</g>
	<g>
		<g>
			<polygon
				points="202.041,141.278 135.954,207.365 60.144,207.365 60.144,243.019 150.721,243.019 227.252,166.489 		" />
		</g>
	</g>
</svg>