<div class="w-full">
  <div v-show="videos.length > 0">
    <div class="mb-8">
      <input type="checkbox" :value="highlightVideo" :checked="highlightVideo" @change="onHighlightVideo"
        class="w-4 h-4 text-qr_color-blue-2 hover:cursor-pointer hover:text-qr_color-blue_2 hover:bg-qr-color-blue_2 border-2 border-qr_color-blue_2 rounded-qr_checkbox_radius" />
      <label class="mx-2 text-qr_color-gray text-16">
        {{$t('Highlight the first video')}}</label>
    </div>
    <div v-for="(video, index) in videos" :key="video.id">
      <div class="flex w-full mb-16">
        <span class="mr-4 text-qr_color-gray text-16 py-1 font-semibold">{{index + 1}}.</span>
        <div
          class="relative ring ring-6 rounded-qr_input_radius cursor-pointer ring-qr_color-blue_ring mb-2 sm:mb-0 sm:w-1/3 h-44 flex items-center hover:cursor-pointer overflow-hidden min-w-[33.3%]">
          <img :src="video.videoImgUrl" alt="img" class="w-auto h-auto" />
        </div>
        <textarea
          class="ml-4 bg-qr_color-gray_3 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent"
          name="textarea" id="textarea" cols="50" rows="4" @change="(event)=>onUpdateVideoText(event, video.id)"
          :value="video.title"></textarea>
        <div class="relative float-right right-4 -top-7 text-qr_color-gray_4">
        </div>

        <div class="ml-2 w-12 p-1">
          <button type="button" class="text-qr_color-gray" @click="onRemoveVideo(video)">
            <CloseIcon class="w-4 h-4 fill-current" />
          </button>
        </div>
      </div>
    </div>

    <hr class="mb-10" />
  </div>

  <span class="text-qr_color-blue_2 font-bold mb-2">Add video:</span>

  <div class="flex flex-2 items-center">
    <input type="text" name="videoUrl"
      class="bg-qr_color-gray_3 h-12 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent"
      :placeholder="placeHolder" :value="videoUrl" @keypress="changeVideoUrl" @change="changeVideoUrl"
      v-on="inputListeners" :hasValidators="required" />
    <button
      class="ml-4 flex-shrink-0 inline-flex items-center py-2 px-4 font-medium text-center text-white bg-qr_color-blue_1 rounded-qr_input_radius"
      type="button" @click="getVideoInfo">
      {{buttonLabel}}
    </button>
  </div>
  <div v-if="errorMessage && errorMessage !==''">
    <span class="text-qr_color-error">{{errorMessage}}</span>
  </div>
</div>