import baseField from "vue-formly-bootstrap/src/fields/baseField";

import WithRender from "./QRInputColorTheming.html";

export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  data() {
    return {
      value: ""
    };
  },
  created() {
    // console.log("[QRInputColorTheming]", this.field);
  },
  computed: {
    colors() {
      return this.field.templateOptions.colors;
    },
    name() {
      return this.field.key;
    },
    keys() {
      return this.field.key.split(",");
    },
    shape() {
      return this.field.templateOptions.shape;
    },
    myValue() {
      const colors = this.keys.map(
        k => this.model[k] ?? this?.field?.templateOptions?.colors[0][k]
      );
      return colors.length ? colors.join(",") : "#455a64,#e91e63";
    }
  },
  methods: {
    getValue(c) {
      const { color1, color2 } = c;
      return `${color1},${color2}`;
    },
    onChangeValue(e) {
      const values = e.target.value.split(",");
      const keys = this.keys;

      this.$emit("update", { name: keys[0], value: values[0] });
      this.$emit("update", { name: keys[1], value: values[1] });
    }
  },
  watch: {
    model: {
      handler(newValue, oldValue) {
        if (oldValue[this.name] == newValue[this.name]) return;
        this.value = newValue[this.name];
      },
      deep: true
    }
  }
});
