import WithRender from "./QrOrderby.html";

export default WithRender({
  props: {
    value: {
      type: String,
      default: "all"
    }
  },
  data() {
    return {
      menuList: [
        { value: "UPDATED_AT", label: this.$t("qr_order_by.UPDATED_AT") },
        { value: "CREATED_AT", label: this.$t("qr_order_by.CREATED_AT") },
        { value: "TITLE", label: this.$t("qr_order_by.Name") }
      ]
    };
  },
  mounted() {
    const triggerEle = this.$refs.qrOrderbyBtn;
    const targetEle = this.$refs.qrOrderbyMenu;
    new window.Dropdown(targetEle, triggerEle, {
      placement: "bottom"
    });
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  }
});
