<div ref="optionsModal" aria-hidden="true" tabindex="-1"
  class="hidden overflow-y-auto overflow-x-hidden absolute top-0.5 right-0 left-0 z-50 p-4 pt-0 md:inset-0 h-modal md:h-full bg-gray-900 bg-opacity-50">
  <div class="relative w-full max-w-3xl h-full md:h-auto mt-0.5">
    <div class="relative bg-white rounded-b-sm shadow">
      <button type="button"
        class="absolute top-1 right-1 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
        @click="onHide">
        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"></path>
        </svg>
        <span class="sr-only">{{$t('settings_modal.close_modal')}}</span>
      </button>
      <div class="w-full pt-6">
        <div class="w-full px-6 py-4 text-gray-500">
          <h2 class="text-base font-bold text-gray-700">
            {{$t('settings_modal.campaign_info')}}
          </h2>
          <div class="w-full">
            <div class="w-full mt-8">
              <label class="block text-xs text-gray-400 mb-1">
                {{$t('settings_modal.medium')}}
              </label>
              <div class="w-full relative">
                <div class="w-full" ref="mediumBtn">
                  <div type="text"
                    class="block h-10 p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500">
                    {{inputValue.medium}}
                  </div>
                  <button type="button"
                    class="absolute top-0 right-0 p-2.5 text-sm font-medium text-gray-600 border-0 border-l border-gray-400 hover:bg-text-800 h-full">
                    <svg class="w-4 h-4" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                  </button>
                </div>
                <!-- Dropdown menu -->
                <div ref="mediumMenu" class="hidden z-10 w-full bg-white rounded divide-y divide-gray-100 shadow">
                  <ul class="text-sm text-gray-700 text-left max-h-[10rem] overflow-y-auto">
                    <li v-for="item in mediumItems">
                      <button class="text-left block py-2 px-4 w-full hover:bg-gray-100"
                        @click="onMediumClick(item.value)">{{item.label}}</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="w-full mt-8">
              <label class="block text-xs text-gray-400 mb-1">
                {{$t('settings_modal.print_run')}}
              </label>
              <input type="text"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm block w-full p-2"
                placeholder="e.g.1000" />
            </div>
            <div class="w-full mt-8 flex items-center gap-x-12">
              <div class="w-full">
                <label class="block text-xs text-gray-400 mb-1">
                  {{$t('settings_modal.campaign_start')}}
                </label>
                <div class="w-full relative">
                  <VueDatePick v-model="inputValueStart">
                  </VueDatePick>
                </div>
              </div>
              <div class="w-full">
                <label class="block text-xs text-gray-400 mb-1">
                  {{$t('settings_modal.campaign_end')}}
                </label>
                <div class="w-full relative">
                  <VueDatePick v-model="inputValueStart">
                  </VueDatePick>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full px-4 py-3 bg-gray-200 font-medium text-right">
          <button type="button" class="text-gray-500 hover:text-gray-700  text-sm px-4 py-2 mr-2" @click="onHide">
            {{$t('settings_modal.cancel')}}
          </button>
          <button type="button"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-1 focus:ring-blue-300 rounded-sm text-sm px-4 py-2 mr-2"
            @click="onSaveAction">
            {{$t('settings_modal.save')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>