import WithRender from "./PreviewCode.html";
import { mapActions, mapGetters } from "vuex";
export default WithRender({
  props: ["mode", "enabled"],
  data() {
    return {
      qrCodeMode: false
    };
  },
  computed: {
    ...mapGetters(["getWidgetMode"])
  },
  methods: {
    ...mapActions(["createSaveQrCode"]),
    onUpdateStatus(e) {
      this.$nextTick(() => {
        if (this.enabled) {
          let qrMode = this.qrCodeMode ? "qrcode" : "preview";
          let formElement = document.getElementById("formCard");
          if (formElement) {
            if (formElement.checkValidity()) {
              this.$emit("update", qrMode);
            } else {
              e.preventDefault();
              formElement.reportValidity();
              this.qrCodeMode = false;
            }
          }
        } else {
          this.qrCodeMode = false;
        }
      });
    }
  },
  watch: {
    mode: function (newMode) {
      this.qrCodeMode = newMode == "qrcode";
      if (this.qrCodeMode) {
        const edit = this.$route.params && this.$route.params.id;
        if (1) {
          this.createSaveQrCode();
        }
      }
    }
  }
});
