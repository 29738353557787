import { mapActions, mapGetters, mapMutations } from "vuex";
import { VueRecaptcha } from "vue-recaptcha";
import PrintQrLogo from "../assets/printQr.svg";

import WithRender from "./LoginPage.html";

export default WithRender({
  components: { PrintQrLogo, VueRecaptcha },
  data() {
    return {
      userEmail: "",
      userPwd: "",
      error: null,
      recaptcha: null
    };
  },
  computed: {
    recaptchaKey: function () {
      return this.$printqGlobalOptions?.recaptchaKey ?? "";
    }
  },
  methods: {
    ...mapActions(["loginUser"]),
    async handleLogin() {
      try {
        if (1 || this.recaptcha) {
          const { success, data } = await this.loginUser({
            email: this.userEmail,
            password: this.userPwd,
            recaptcha: this.recaptcha
          });
          this.$refs.recaptcha.reset();
          if (!success) {
            const { error } = data;
            this.error = error;
          } else {
            this.error = null;
            this.$router.push({ name: "home" });
          }
        } else {
          this.error = "Recaptcha is required!";
        }
      } catch (error) {
        console.log(error);
        this.error = error;
      }
    },
    goToRegister(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$router.push({ name: "register" });
    },
    verifyRecaptcha(event) {
      this.recaptcha = event;
      console.log("verifyRecaptcha", event);
    },
    expiredRecaptcha(event) {
      this.recaptcha = null;
      console.log("expiredRecaptcha", event);
    },
    renderRecaptcha(event) {
      this.recaptcha = null;
      console.log("renderRecaptcha", event);
    },
    errorRecaptcha(event) {
      this.recaptcha = null;
      console.log("errorRecaptcha", event);
    }
  }
});
