<div class="w-full qrToogle">
  <div class="mb-6 text-center" v-if="!getWidgetMode">
    <label
      for="default-toggle"
      class="inline-flex relative items-center cursor-pointer my-0 block mx-auto"
    >
      <input
        type="checkbox"
        id="default-toggle"
        class="sr-only peer"
        v-model="qrCodeMode"
        @change="onUpdateStatus"
      />
      <div
        class="w-52 h-11 bg-qr_color-gray_3 rounded-full peer peer-checked:after:translate-x-full after:content-[attr(normal-content)] peer-checked:after:content-[attr(peer-content)] after:text-white after:absolute after:top-[4px] after:left-[2px] after:leading-8 peer-checked:after:left-[14px] after:bg-qr_color-blue_1 after:rounded-full after:h-9 after:w-24 after:transition-all peer-checked:after:border-qr_color-gray_3 after:border-qr_color-gray_3 after:border"
        peer-content="QR Code"
        normal-content="Preview"
      >
        <span
          class="before:text-qr_color-blue_2 before:absolute before:right-[20px] before:top-[10px] before:content-[attr(peer-content)] before:font-bold after:font-bold after:qr_color-gray_2 after:absolute after:left-[20px] after:top-[8px] after:content-[attr(normal-content)]"
          peer-content="QR Code"
          normal-content="Preview"
        ></span>
      </div>
    </label>
  </div>
  <div class="w-full">
    <slot></slot>
  </div>
</div>
