import baseField from "vue-formly-bootstrap/src/fields/baseField";
import dayjs from "dayjs";
import VueDatePick from "vue-date-pick";

import WithRender from "./QRDateTime.html";
import ArrowRightIcon from "../icons/ArrowRightIcon";
import EyeIcon from "../icons/EyeIcon";
import EyeBlockIcon from "../icons/EyeBlockIcon";

import "vue-date-pick/dist/vueDatePick.css";
const globalTimeZones = [
  {
    key: "UTC",
    label: "UTC",
    value: ""
  },
  {
    key: "Dateline Standard Time",
    label: "(GMT-12:00) International Date Line West",
    value: "(GMT-12:00) International Date Line West"
  },
  {
    key: "Samoa Standard Time",
    label: "(GMT-11:00) Midway Island, Samoa",
    value: "(GMT-11:00) Midway Island, Samoa"
  },
  {
    key: "Hawaiian Standard Time",
    label: "(GMT-10:00) Hawaii",
    value: "(GMT-10:00) Hawaii"
  },
  {
    key: "Alaskan Standard Time",
    label: "(GMT-09:00) Alaska",
    value: "(GMT-09:00) Alaska"
  },
  {
    key: "Pacific Standard Time",
    label: "(GMT-08:00) Pacific Time (US and Canada)",
    value: "(GMT-08:00) Pacific Time (US and Canada)"
  },
  {
    key: "Mountain Standard Time",
    label: "(GMT-07:00) Mountain Time (US and Canada)",
    value: "(GMT-07:00) Mountain Time (US and Canada)"
  },
  {
    key: "Mexico Standard Time 2",
    label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
    value: "(GMT-07:00) Chihuahua, La Paz, Mazatlan"
  },
  {
    key: "U.S. Mountain Standard Time",
    label: "(GMT-07:00) Arizona",
    value: "(GMT-07:00) Arizona"
  },
  {
    key: "Central Standard Time",
    label: "(GMT-06:00) Central Time (US and Canada)",
    value: "(GMT-06:00) Central Time (US and Canada)"
  },
  {
    key: "Canada Central Standard Time",
    label: "(GMT-06:00) Saskatchewan",
    value: "(GMT-06:00) Saskatchewan"
  },
  {
    key: "Mexico Standard Time",
    label: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
    value: "(GMT-06:00) Guadalajara, Mexico City, Monterrey"
  },
  {
    key: "Central America Standard Time",
    label: "(GMT-06:00) Central America",
    value: "(GMT-06:00) Central America"
  },
  {
    key: "Eastern Standard Time",
    label: "(GMT-05:00) Eastern Time (US and Canada)",
    value: "(GMT-05:00) Eastern Time (US and Canada)"
  },
  {
    key: "U.S. Eastern Standard Time",
    label: "(GMT-05:00) Indiana (East)",
    value: "(GMT-05:00) Indiana (East)"
  },
  {
    key: "S.A. Pacific Standard Time",
    label: "(GMT-05:00) Bogota, Lima, Quito",
    value: "(GMT-05:00) Bogota, Lima, Quito"
  },
  {
    key: "Atlantic Standard Time",
    label: "(GMT-04:00) Atlantic Time (Canada)",
    value: "(GMT-04:00) Atlantic Time (Canada)"
  },
  {
    key: "S.A. Western Standard Time",
    label: "(GMT-04:00) Caracas, La Paz",
    value: "(GMT-04:00) Caracas, La Paz"
  },
  {
    key: "Pacific S.A. Standard Time",
    label: "(GMT-04:00) Santiago",
    value: "(GMT-04:00) Santiago"
  },
  {
    key: "Newfoundland and Labrador Standard Time",
    label: "(GMT-03:30) Newfoundland and Labrador",
    value: "(GMT-03:30) Newfoundland and Labrador"
  },
  {
    key: "E. South America Standard Time",
    label: "(GMT-03:00) Brasilia",
    value: "(GMT-03:00) Brasilia"
  },
  {
    key: "S.A. Eastern Standard Time",
    label: "(GMT-03:00) Buenos Aires, Georgetown",
    value: "(GMT-03:00) Buenos Aires, Georgetown"
  },
  {
    key: "Greenland Standard Time",
    label: "(GMT-03:00) Greenland",
    value: "(GMT-03:00) Greenland"
  },
  {
    key: "Mid-Atlantic Standard Time",
    label: "(GMT-02:00) Mid-Atlantic",
    value: "(GMT-02:00) Mid-Atlantic"
  },
  {
    key: "Azores Standard Time",
    label: "(GMT-01:00) Azores",
    value: "(GMT-01:00) Azores"
  },
  {
    key: "Cape Verde Standard Time",
    label: "(GMT-01:00) Cape Verde Islands",
    value: "(GMT-01:00) Cape Verde Islands"
  },
  {
    key: "GMT Standard Time",
    label: "(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London",
    value: "(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London"
  },
  {
    key: "Greenwich Standard Time",
    label: "(GMT) Casablanca, Monrovia",
    value: "(GMT) Casablanca, Monrovia"
  },
  {
    key: "Central Europe Standard Time",
    label: "(GMT+01:00) Belgrade, Bratislava, Budapest",
    value: "(GMT+01:00) Belgrade, Bratislava, Budapest"
  },
  {
    key: "Central European Standard Time",
    label: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    value: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"
  },
  {
    key: "Romance Standard Time",
    label: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
    value: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris"
  },
  {
    key: "W. Europe Standard Time",
    label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    value: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
  },
  {
    key: "W. Central Africa Standard Time",
    label: "(GMT+01:00) West Central Africa",
    value: "(GMT+01:00) West Central Africa"
  },
  {
    key: "E. Europe Standard Time",
    label: "(GMT+02:00) Bucharest",
    value: "(GMT+02:00) Bucharest"
  },
  {
    key: "Egypt Standard Time",
    label: "(GMT+02:00) Cairo",
    value: "(GMT+02:00) Cairo"
  },
  {
    key: "FLE Standard Time",
    label: "(GMT+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
    value: "(GMT+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius"
  },
  {
    key: "GTB Standard Time",
    label: "(GMT+02:00) Athens, Istanbul, Minsk",
    value: "(GMT+02:00) Athens, Istanbul, Minsk"
  },
  {
    key: "Israel Standard Time",
    label: "(GMT+02:00) Jerusalem",
    value: "(GMT+02:00) Jerusalem"
  },
  {
    key: "South Africa Standard Time",
    label: "(GMT+02:00) Harare, Pretoria",
    value: "(GMT+02:00) Harare, Pretoria"
  },
  {
    key: "Russian Standard Time",
    label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
    value: "(GMT+03:00) Moscow, St. Petersburg, Volgograd"
  },
  {
    key: "Arab Standard Time",
    label: "(GMT+03:00) Kuwait, Riyadh",
    value: "(GMT+03:00) Kuwait, Riyadh"
  },
  {
    key: "E. Africa Standard Time",
    label: "(GMT+03:00) Nairobi",
    value: "(GMT+03:00) Nairobi"
  },
  {
    key: "Arabic Standard Time",
    label: "(GMT+03:00) Baghdad",
    value: "(GMT+03:00) Baghdad"
  },
  {
    key: "Iran Standard Time",
    label: "(GMT+03:30) Tehran",
    value: "(GMT+03:30) Tehran"
  },
  {
    key: "Arabian Standard Time",
    label: "(GMT+04:00) Abu Dhabi, Muscat",
    value: "(GMT+04:00) Abu Dhabi, Muscat"
  },
  {
    key: "Caucasus Standard Time",
    label: "(GMT+04:00) Baku, Tbilisi, Yerevan",
    value: "(GMT+04:00) Baku, Tbilisi, Yerevan"
  },
  {
    key: "Transitional Islamic State of Afghanistan Standard Time",
    label: "(GMT+04:30) Kabul",
    value: "(GMT+04:30) Kabul"
  },
  {
    key: "Ekaterinburg Standard Time",
    label: "(GMT+05:00) Ekaterinburg",
    value: "(GMT+05:00) Ekaterinburg"
  },
  {
    key: "West Asia Standard Time",
    label: "(GMT+05:00) Islamabad, Karachi, Tashkent",
    value: "(GMT+05:00) Islamabad, Karachi, Tashkent"
  },
  {
    key: "India Standard Time",
    label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    value: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi"
  },
  {
    key: "Nepal Standard Time",
    label: "(GMT+05:45) Kathmandu",
    value: "(GMT+05:45) Kathmandu"
  },
  {
    key: "Central Asia Standard Time",
    label: "(GMT+06:00) Astana, Dhaka",
    value: "(GMT+06:00) Astana, Dhaka"
  },
  {
    key: "Sri Lanka Standard Time",
    label: "(GMT+06:00) Sri Jayawardenepura",
    value: "(GMT+06:00) Sri Jayawardenepura"
  },
  {
    key: "N. Central Asia Standard Time",
    label: "(GMT+06:00) Almaty, Novosibirsk",
    value: "(GMT+06:00) Almaty, Novosibirsk"
  },
  {
    key: "Myanmar Standard Time",
    label: "(GMT+06:30) Yangon Rangoon",
    value: "(GMT+06:30) Yangon Rangoon"
  },
  {
    key: "S.E. Asia Standard Time",
    label: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
    value: "(GMT+07:00) Bangkok, Hanoi, Jakarta"
  },
  {
    key: "North Asia Standard Time",
    label: "(GMT+07:00) Krasnoyarsk",
    value: "(GMT+07:00) Krasnoyarsk"
  },
  {
    key: "China Standard Time",
    label: "(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi",
    value: "(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi"
  },
  {
    key: "Singapore Standard Time",
    label: "(GMT+08:00) Kuala Lumpur, Singapore",
    value: "(GMT+08:00) Kuala Lumpur, Singapore"
  },
  {
    key: "Taipei Standard Time",
    label: "(GMT+08:00) Taipei",
    value: "(GMT+08:00) Taipei"
  },
  {
    key: "W. Australia Standard Time",
    label: "(GMT+08:00) Perth",
    value: "(GMT+08:00) Perth"
  },
  {
    key: "North Asia East Standard Time",
    label: "(GMT+08:00) Irkutsk, Ulaanbaatar",
    value: "(GMT+08:00) Irkutsk, Ulaanbaatar"
  },
  {
    key: "Korea Standard Time",
    label: "(GMT+09:00) Seoul",
    value: "(GMT+09:00) Seoul"
  },
  {
    key: "Tokyo Standard Time",
    label: "(GMT+09:00) Osaka, Sapporo, Tokyo",
    value: "(GMT+09:00) Osaka, Sapporo, Tokyo"
  },
  {
    key: "Yakutsk Standard Time",
    label: "(GMT+09:00) Yakutsk",
    value: "(GMT+09:00) Yakutsk"
  },
  {
    key: "A.U.S. Central Standard Time",
    label: "(GMT+09:30) Darwin",
    value: "(GMT+09:30) Darwin"
  },
  {
    key: "Cen. Australia Standard Time",
    label: "(GMT+09:30) Adelaide",
    value: "(GMT+09:30) Adelaide"
  },
  {
    key: "A.U.S. Eastern Standard Time",
    label: "(GMT+10:00) Canberra, Melbourne, Sydney",
    value: "(GMT+10:00) Canberra, Melbourne, Sydney"
  },
  {
    key: "E. Australia Standard Time",
    label: "(GMT+10:00) Brisbane",
    value: "(GMT+10:00) Brisbane"
  },
  {
    key: "Tasmania Standard Time",
    label: "(GMT+10:00) Hobart",
    value: "(GMT+10:00) Hobart"
  },
  {
    key: "Vladivostok Standard Time",
    label: "(GMT+10:00) Vladivostok",
    value: "(GMT+10:00) Vladivostok"
  },
  {
    key: "West Pacific Standard Time",
    label: "(GMT+10:00) Guam, Port Moresby",
    value: "(GMT+10:00) Guam, Port Moresby"
  },
  {
    key: "Central Pacific Standard Time",
    label: "(GMT+11:00) Magadan, Solomon Islands, New Caledonia",
    value: "(GMT+11:00) Magadan, Solomon Islands, New Caledonia"
  },
  {
    key: "Fiji Islands Standard Time",
    label: "(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands",
    value: "(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands"
  },
  {
    key: "New Zealand Standard Time",
    label: "(GMT+12:00) Auckland, Wellington",
    value: "(GMT+12:00) Auckland, Wellington"
  },
  {
    key: "Tonga Standard Time",
    label: "(GMT+13:00) Nuku'alofa",
    value: "(GMT+13:00) Nuku'alofa"
  }
];
export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  components: {
    VueDatePick,
    ArrowRightIcon,
    EyeIcon,
    EyeBlockIcon
  },
  mounted() {
    console.log("mounted", this.value);
  },
  computed: {
    name() {
      return this.field.key;
    },
    isAllDayEnable() {
      return this.field.templateOptions.allDay;
    },
    errorMessage() {
      return this.field.templateOptions.errorMessage;
    },
    label() {
      return this.field.templateOptions.label;
    },
    required() {
      return this.field.templateOptions.required;
    },
    startDateOnly() {
      return this.field.templateOptions.startDateOnly;
    },
    endDateOnly() {
      return this.field.templateOptions.endDateOnly;
    },
    inputValueStart: {
      get() {
        const allDay = this.value.allDay;
        const date = this.value?.startDate;
        const time = this.value?.startTime;
        if (allDay) return date;
        return `${date} ${time}`;
      },
      set(value) {
        let v = value;
        const checkDate = dayjs(v).date();
        if (!(checkDate === checkDate)) {
          v = dayjs();
        }
        let { endDate, endTime } = this.value;
        const startDate = dayjs(v).format(this.dateFormat);
        const startTime = dayjs(v).format(this.timeFormat);

        const startDay = dayjs(v).toDate();
        const endDay = dayjs(`${endDate} ${endTime}`).toDate();
        if (endDay < startDay) {
          endDate = startDate;
          endTime = startTime;
        }
        this.value = {
          ...this.value,
          startDate,
          startTime,
          endDate,
          endTime
        };
        this.handleChange();
      }
    },
    inputValueEnd: {
      get() {
        const allDay = this.value.allDay;
        const date = this.value?.endDate;
        const time = this.value?.endTime;
        if (allDay) return date;
        return `${date} ${time}`;
      },
      set(value) {
        let v = value;
        const checkDate = dayjs(v).date();
        if (!(checkDate === checkDate)) {
          v = dayjs();
        }
        let { startDate, startTime } = this.value;

        const endDate = dayjs(v).format(this.dateFormat);
        const endTime = dayjs(v).format(this.timeFormat);

        const endDay = dayjs(v).toDate();
        const startDay = dayjs(`${startDate} ${startTime}`).toDate();
        if (startDay > endDay) {
          startDate = endDate;
          startTime = endTime;
        }
        this.value = {
          ...this.value,
          startDate,
          startTime,
          endDate,
          endTime
        };
        this.handleChange();
      }
    },
    timezones() {
      return globalTimeZones;
    },
    format() {
      return `${this.dateFormat} ${this.timeFormat}`;
    },
    dateFormat() {
      return "DD MMM YYYY";
    },
    timeFormat() {
      return this.value?.timeMode === "24" ? "HH:mm" : "hh:mm A";
    }
  },
  data() {
    return {
      value: {
        startDate:
          this.model?.date?.startDate ?? dayjs().format(this.dateFormat),
        startTime:
          this.model?.date?.startTime ?? dayjs().format(this.timeFormat),
        endDate: this.model?.date?.endDate ?? dayjs().format(this.dateFormat),
        endTime: this.model?.date?.endTime ?? dayjs().format(this.timeFormat),
        allDay: this.model?.date?.allDay,
        timezone: this.model?.date?.timezone ?? "(UTC)",
        timeMode: this.model?.date?.timeMode ?? "24"
      },
      menuVisible: false
    };
  },
  methods: {
    parseDate(dateString, format) {
      return dayjs(dateString).toDate();
    },
    formatDate(dateObj, format) {
      return dayjs(dateObj).format(format);
    },

    handleChange() {
      const { name, value } = this;
      this.$emit("update", { name, value });
    },
    onUpdateOptions() {
      const {
        startDate,
        startTime,
        endDate,
        endTime,
        allDay,
        timeMode,
        timezone
      } = this.value;

      this.value = {
        ...this.value,
        startDate: dayjs(`${startDate} ${startTime}`).format(this.dateFormat),
        startTime: dayjs(`${startDate} ${startTime}`).format(this.timeFormat),
        endDate: dayjs(`${endDate} ${endTime}`).format(this.dateFormat),
        endTime: dayjs(`${endDate} ${endTime}`).format(this.timeFormat),
        allDay,
        timeMode,
        timezone
      };

      this.handleChange();
    },
    onChangeDayOption() {
      this.onUpdateOptions();
    },
    isEarlyFeature(date) {
      const { startDate, startTime } = this.value;
      return dayjs(`${startDate} ${startTime}`).toDate() > date;
    },
    onChangeVisibility() {
      this.menuVisible = !this.menuVisible;
      this.onUpdateOptions();
    }
  },
  watch: {
    model: {
      handler(newValue, oldValue) {
        if (oldValue[this.name] == newValue[this.name]) return;
        this.value = newValue[this.name];
      },
      deep: true
    }
  }
});
