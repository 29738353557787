import {
  createDynamicBusinessPageFields,
  createDynamicBusinessRequiredFields
} from "./dynamic/business";
import {
  createDynamicBusinessPageFieldsWidget,
  createDynamicBusinessRequiredFieldsWidget
} from "./dynamic/business_widget";

import {
  createDynamicCouponFields,
  createDynamicCouponRequiredFields
} from "./dynamic/coupon";
import {
  createDynamicCouponFieldsWidget,
  createDynamicCouponRequiredFieldsWidget
} from "./dynamic/coupon_widget";

import {
  createDynamicEventFields,
  createDynamicEventRequiredFields
} from "./dynamic/event";
import {
  createDynamicEventFieldsWidget,
  createDynamicEventRequiredFieldsWidget
} from "./dynamic/event_widget";

import {
  createDynamicURLFields,
  createDynamicURLRequiredFields
} from "./dynamic/url";
import {
  createDynamicURLFieldsWidget,
  createDynamicURLRequiredFieldsWidget
} from "./dynamic/url_widget";

import {
  createDynamicTextFields,
  createDynamicTextRequiredFields
} from "./dynamic/text";
import {
  createDynamicTextFieldsWidget,
  createDynamicTextRequiredFieldsWidget
} from "./dynamic/text_widget";

import {
  createDynamicVCardFields,
  createDynamicVCardRequiredFields
} from "./dynamic/vcard-plus";
import {
  createDynamicVCardFieldsWidget,
  createDynamicVCardRequiredFieldsWidget
} from "./dynamic/vcard-plus_widget";

import {
  createDynamicVideoFields,
  createDynamicVideoRequiredFields
} from "./dynamic/video";
import {
  createDynamicVideoFieldsWidget,
  createDynamicVideoRequiredFieldsWidget
} from "./dynamic/video_widget";

import {
  createDynamicImageFields,
  createDynamicImageRequiredFields
} from "./dynamic/image";
import {
  createDynamicImageFieldsWidget,
  createDynamicImageRequiredFieldsWidget
} from "./dynamic/image_widget";

import {
  createDynamicFeedbackFields,
  createDynamicFeedbackRequiredFields
} from "./dynamic/feedback";
import {
  createDynamicFeedbackFieldsWidget,
  createDynamicFeedbackRequiredFieldsWidget
} from "./dynamic/feedback_widget";

import {
  createDynamicSocialMediaFields,
  createDynamicSocialMediakRequiredFields
} from "./dynamic/social-media";
import {
  createDynamicSocialMediaFieldsWidget,
  createDynamicSocialMediaRequiredFieldsWidget
} from "./dynamic/social-media_widget";

import {
  createDynamicInstagramFields,
  createDynamicInstagramRequiredFields
} from "./dynamic/instagram";
import {
  createDynamicInstagramFieldsWidget,
  createDynamicInstagramRequiredFieldsWidget
} from "./dynamic/instagram_widget";

import {
  createDynamicFacebookFields,
  createDynamicFacebookRequiredFields
} from "./dynamic/facebook";
import {
  createDynamicFacebookFieldsWidget,
  createDynamicFacebookRequiredFieldsWidget
} from "./dynamic/facebook_widget";

import {
  createDynamicPdfFields,
  createDynamicPdfRequiredFields
} from "./dynamic/pdf";
import {
  createDynamicPdfFieldsWidget,
  createDynamicPdfRequiredFieldsWidget
} from "./dynamic/pdf_widget";

import {
  createDynamicRatingFields,
  createDynamicRatingRequiredFields
} from "./dynamic/rating";
import {
  createDynamicRatingFieldsWidget,
  createDynamicRatingRequiredFieldsWidget
} from "./dynamic/rating_widget";

import {
  createDynamicAppFields,
  createDynamicAppRequiredFields
} from "./dynamic/app";
import {
  createDynamicAppFieldsWidget,
  createDynamicAppRequiredFieldsWidget
} from "./dynamic/app_widget";

import {
  createDynamicMp3Fields,
  createDynamicMp3RequiredFields
} from "./dynamic/mp3";
import {
  createDynamicMp3FieldsWidget,
  createDynamicMp3RequiredFieldsWidget
} from "./dynamic/mp3_widget";

export const getFormFields = (type, widget = 0) => {
  switch (type) {
    case "dynamic-business":
      if (widget) {
        return createDynamicBusinessPageFieldsWidget;
      }
      return createDynamicBusinessPageFields;
    case "dynamic-coupon":
      if (widget) {
        return createDynamicCouponFieldsWidget;
      }
      return createDynamicCouponFields;
    case "dynamic-event":
      if (widget) {
        return createDynamicEventFieldsWidget;
      }
      return createDynamicEventFields;
    case "dynamic-url":
      if (widget) {
        return createDynamicURLFieldsWidget;
      }
      return createDynamicURLFields;

    case "dynamic-text":
      if (widget) {
        return createDynamicTextFieldsWidget;
      }
      return createDynamicTextFields;

    case "dynamic-vcard-plus":
      if (widget) {
        return createDynamicVCardFieldsWidget;
      }
      return createDynamicVCardFields;
    case "dynamic-video":
      if (widget) {
        return createDynamicVideoFieldsWidget;
      }
      return createDynamicVideoFields;

    case "dynamic-image":
      if (widget) {
        return createDynamicImageFieldsWidget;
      }
      return createDynamicImageFields;

    case "dynamic-feedback":
      if (widget) {
        return createDynamicFeedbackFieldsWidget;
      }
      return createDynamicFeedbackFields;

    case "dynamic-social-media":
      if (widget) {
        return createDynamicSocialMediaFieldsWidget;
      }
      return createDynamicSocialMediaFields;

    case "dynamic-instagram":
      if (widget) {
        return createDynamicInstagramFieldsWidget;
      }
      return createDynamicInstagramFields;

    case "dynamic-facebook":
      if (widget) {
        return createDynamicFacebookFieldsWidget;
      }
      return createDynamicFacebookFields;

    case "dynamic-pdf":
      if (widget) {
        return createDynamicPdfFieldsWidget;
      }
      return createDynamicPdfFields;

    case "dynamic-rating":
      if (widget) {
        return createDynamicRatingFieldsWidget;
      }
      return createDynamicRatingFields;

    case "dynamic-mp3":
      if (widget) {
        return createDynamicMp3FieldsWidget;
      }
      return createDynamicMp3Fields;

    case "dynamic-app":
      if (widget) {
        return createDynamicAppFieldsWidget;
      }
      return createDynamicAppFields;

    default:
      break;
  }
  return () => [];
};

export const getRequiredFields = (type, widget) => {
  switch (type) {
    case "dynamic-url":
      if (widget) {
        return createDynamicURLRequiredFieldsWidget;
      }
      return createDynamicURLRequiredFields;

    case "dynamic-text":
      if (widget) {
        return createDynamicTextRequiredFieldsWidget;
      }
      return createDynamicTextRequiredFields;

    case "dynamic-business":
      if (widget) {
        return createDynamicBusinessRequiredFieldsWidget;
      }
      return createDynamicBusinessRequiredFields;

    case "dynamic-coupon":
      if (widget) {
        return createDynamicCouponRequiredFieldsWidget;
      }
      return createDynamicCouponRequiredFields;

    case "dynamic-event":
      if (widget) {
        return createDynamicEventRequiredFieldsWidget;
      }
      return createDynamicEventRequiredFields;

    case "dynamic-vcard-plus":
      if (widget) {
        return createDynamicVCardRequiredFieldsWidget;
      }
      return createDynamicVCardRequiredFields;

    case "dynamic-video":
      if (widget) {
        return createDynamicVideoRequiredFieldsWidget;
      }
      return createDynamicVideoRequiredFields;

    case "dynamic-image":
      if (widget) {
        return createDynamicImageRequiredFieldsWidget;
      }
      return createDynamicImageRequiredFields;

    case "dynamic-feedback":
      if (widget) {
        return createDynamicFeedbackRequiredFieldsWidget;
      }
      return createDynamicFeedbackRequiredFields;

    case "dynamic-social-media":
      if (widget) {
        return createDynamicSocialMediaRequiredFieldsWidget;
      }
      return createDynamicSocialMediakRequiredFields;

    case "dynamic-instagram":
      if (widget) {
        return createDynamicInstagramRequiredFieldsWidget;
      }
      return createDynamicInstagramRequiredFields;

    case "dynamic-facebook":
      if (widget) {
        return createDynamicFacebookRequiredFieldsWidget;
      }
      return createDynamicFacebookRequiredFields;

    case "dynamic-pdf":
      if (widget) {
        return createDynamicPdfRequiredFieldsWidget;
      }
      return createDynamicPdfRequiredFields;

    case "dynamic-rating":
      if (widget) {
        return createDynamicRatingRequiredFieldsWidget;
      }
      return createDynamicRatingRequiredFields;

    case "dynamic-mp3":
      if (widget) {
        return createDynamicMp3RequiredFieldsWidget;
      }
      return createDynamicMp3RequiredFields;

    case "dynamic-app":
      if (widget) {
        return createDynamicAppRequiredFieldsWidget;
      }
      return createDynamicAppRequiredFields;

    default:
      break;
  }
  return () => [];
};
