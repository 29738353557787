import baseField from "vue-formly-bootstrap/src/fields/baseField";

import WithRender from "./QRInputHeadline.html";

export default WithRender({
  mixins: [baseField],
  props: ["field"],
  computed: {
    headline() {
      return this.field.templateOptions.headline;
    }
  }
});
