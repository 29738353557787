import welcome from "./img/welcome_social_media.png";

export const createDynamicSocialMediaFields = (formly, t) => {
  return [
    formly.labelTitle({
      titleIcon: "SocialMedia1",
      titleKey: "qrcode_title",
      titleTooltip: t(
        "Labeling QR Codes has been moved to this area. Names help you to stay organized and will only appear in your account and are not displayed to customers who scan your QR Codes."
      ),
      titlePlaceholder: t("My Social Media QR Code"),
      titleValue: null,
      buttonLabel: t("Got it")
    }),
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_design",
        fold: false,
        headline: t("Design & Customizations"),
        icon: "DesignIcon"
      }),
      formly.body({
        name: "section_design",
        collapseKey: "section_design",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Choose a color scheme for your page.")
          }),
          formly.colorsTemplate({
            customColors: [
              { color1: "#447fb6", color2: "#e91e63" },
              { color1: "#455a64", color2: "#e91e63" },
              { color1: "#d32f2f", color2: "#ef9a9a" },
              { color1: "#4caf50", color2: "#81c784" },
              { color1: "#795548", color2: "#ff8a65" }
            ]
          }),
          formly.colorsSwitch({ color1: "#447fb6", color2: "#e91e63" }),
          formly.avatarRectangular({
            tooltip: t(
              "Upload an image or logo from your computer. Images must be at least 640 x 360 px in .jpg or .png format."
            ),
            aspectRatio: 1.77
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 1,
        sectionName: "section_information",
        fold: false,
        headline: t("Basic Information"),
        icon: "InfoIcon"
      }),
      formly.body({
        name: "section_information",
        collapseKey: "section_information",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Fields marked with a * are required information.")
          }),
          formly.inputRowRequired({
            key: "headline",
            label: t("Headline"),
            className: "form-input-row form-row-container flex flex-col",
            placeholder: t("Connect with us on social media"),
            condition: "true",
            errorMsgTitle: t("Please provide an engaging title to your page."),
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(
                    t("Please provide an engaging title to your page.")
                  );
                  return false;
                }

                return true;
              }
            }
          }),
          formly.textAreaRow({
            key: "description",
            label: t("About us"),
            placeholder: t(
              "Follow us and get updates delivered to your favorite social media channel."
            ),
            maxChar: 120
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 2,
        sectionName: "section_media_channels",
        headline: t("Social Media Channels"),
        icon: "SocialMedia1"
      }),
      formly.body({
        name: "section_media_channels",
        collapseKey: "section_media_channels",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t(
              "Add your username or links to social media pages below. Delete a channel or use the arrows to rearrange the order of the links as they appear."
            )
          }),
          formly.socialChannels({
            key: "channels",
            required: true,
            requiredAllChannels: false,
            sortable: false,
            showLabels: false,
            withTextInputs: true,
            helpMessage: false,
            validators: {
              required: function ({ messages, value, links, link }) {
                if (value.trim() == "") {
                  messages.push(t("The URL field can not be empty"));
                  return false;
                }
                return true;
              }
            },
            textValidators: {
              required: function ({ messages, value, links, link }) {
                if (value.trim() == "") {
                  messages.push(t("The Text field can not be empty"));
                  return false;
                }

                return true;
              }
            }
          })
        ]
      })
    ),

    formly.container(
      formly.header({
        index: 3,
        sectionName: "section_welcome_screen",
        headline: "Welcome Screen",
        icon: "SmartPhoneIcon"
      }),
      formly.body({
        name: "section_welcome_screen",
        collapseKey: "section_welcome_screen",
        key: "code",
        content: [
          formly.subHeadLine({
            text: "Display your logo while your page is loading."
          }),
          formly.welcomeScreen({
            tooltip:
              "Upload an image or logo from your computer. Images must be in .jpg or .png format.",
            key: "welcome_screen",
            previewWelcomeScreen: true,
            defaultWelcomeImage: welcome
          })
        ]
      })
    ),
    formly.container_advancedOptions({
      sharingKey: "sharing",
      sharingLabel: "Add a share button to the page",
      initValue: true
    })
  ];
};

export const createDynamicSocialMediakRequiredFields = _ => {
  return ["headline"];
};
