<div
  v-if="!isHide"
  class="text-qr_color-blue_2 text-16 font-semibold inputTitle"
>
  <template v-if="label">

    <span class="py-2 mr-4">{{label}}<span v-if="required">*</span> :</span>
    <QuestionIcon
      v-if="0"
      :data-tooltip-target="'tooltip-' + label"
      class="w-4 h-4 inline-block text-gray-400 hover:text-gray-500 hover:cursor-pointer"
    />
    <div
      v-if="toolTip"
      :id="'tooltip-' + label"
      role="tooltip"
      class="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip max-w-[240px]"
    >
      {{toolTip}}
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
    <div v-if="smallLabel" class="w-full text-xs text-left text-gray-400 mt-1">
      {{smallLabel}}
    </div>
  </template>
</div>