import welcome from "./img/welcome_pdf.png";
// import avatar from "./img/coupons-avatar.jpg";
// import { remove } from "lodash";
// couponsOption

export const createDynamicPdfFields = (formly, t) => {
  return [
    formly.labelTitle({
      titleIcon: "PdfIcon",
      titleKey: "qrcode_title",
      titleTooltip: t(
        "Labeling QR Codes has been moved to this area. Names help you to stay organized and will only appear in your account and are not displayed to customers who scan your QR Codes."
      ),
      titlePlaceholder: t("My PDF QR Code"),
      titleValue: null,
      buttonLabel: t("Got it")
    }),
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_design",
        headline: t("Upload PDF"),
        icon: "UploadIcon"
      }),
      formly.body({
        name: "section_design",
        collapseKey: "section_design",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Select any PDF from your computer.")
          }),
          formly.uploadPdfFile({
            tooltip: t("Upload a pdf or logo from your computer."),
            key: "file",
            linkToPDFKey: "linkToPDF"
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_design",
        headline: t("Design & Customization"),
        icon: "DesignIcon"
      }),
      formly.body({
        name: "section_design",
        collapseKey: "section_design",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Personalize your page by selecting some colors.")
          }),
          formly.colorsTemplate({
            shape: "rect",
            customColors: [
              { color1: "#da5167", color2: "#464154" },
              { color1: "#45606f", color2: "#0288d1" },
              { color1: "#704b4b", color2: "#d72feb" },
              { color1: "#4caf50", color2: "#e04712" },
              { color1: "#e6c026", color2: "#12bce0" }
            ]
          }),
          formly.colorsSwitch({}),
          {
            className: "my-2",
            template:
              '<hr style="border-color: #eaeaea; margin: 10px 0 -10px;">'
          }
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 1,
        sectionName: "section_basic_info",
        headline: t("Basic Information"),
        icon: "InfoIcon"
      }),
      formly.body({
        name: "section_basic_info",
        collapseKey: "section_basic_info",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t(
              "Provide your business info and add some context to your PDF."
            )
          }),
          formly.inputRow({
            label: t("Company"),
            placeholder: t("Company"),
            key: "company"
          }),

          formly.inputRow({
            key: "title",
            className: "form-input-row form-row-container flex flex-col",
            label: t("Title"),
            placeholder: t("Title of PDF")
          }),
          formly.textAreaRow({
            label: t("Description"),
            placeholder: t("Description"),
            key: "description",
            maxChar: 200
          }),
          formly.inputRow({
            label: t("Website"),
            placeholder: t("www.your-website.com"),
            key: "website",
            filters: {
              makeUrl: function ({ value }) {
                if (value.trim() !== "" && !value.match(/:\/\//)) {
                  value = "http" + "://" + value;
                }
                return value;
              }
            },
            validators: {
              validUrl: function ({ messages, name, value }) {
                if (
                  value !== "" &&
                  !value.match(
                    /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                  )
                ) {
                  messages.push(t("Please enter a valid address."));
                  return false;
                }
                return true;
              }
            }
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 3,
        sectionName: "section_welcome_screen",
        headline: t("Welcome Screen"),
        icon: "SphereIcon"
      }),
      formly.body({
        name: "section_welcome_screen",
        collapseKey: "section_welcome_screen",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Display your logo while your page is loading.")
          }),
          formly.welcomeScreen({
            tooltip: t(
              "Upload an image or logo from your computer. Images must be in .jpg or .png format."
            ),
            key: "welcome_screen",
            previewWelcomeScreen: true,
            defaultWelcomeImage: welcome
          })
        ]
      })
    )
  ];
};

export const createDynamicPdfRequiredFields = _ => {
  return ["company"];
};
