import baseField from "vue-formly-bootstrap/src/fields/baseField";

import WithRender from "./QRResetAddress.html";

export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  created() {},
  computed: {
    label() {
      // return this.field.templateOptions.placeHolder;
      return "";
    }
  }
});
