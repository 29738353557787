import WithRender from "./TimeUnit.html";
import DownloadIcon from "../../icons/DownloadIcon";
import SwapIcon from "../../icons/SwapIcon";
import CogIcon from "../../icons/CogIcon";

export default WithRender({
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  components: {
    DownloadIcon,
    SwapIcon,
    CogIcon
  },
  data() {
    return {
      optionsDropdown: null
    };
  },
  mounted() {
    const optionsTrig = this.$refs.optionsBtn;
    const optionsTgt = this.$refs.optionsMenu;
    this.optionsDropdown = new window.Dropdown(optionsTgt, optionsTrig, {
      placement: "bottom"
    });
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
});
