import WithRender from "./Pagination.html";

export default WithRender({
  props: {
    pages: {
      type: Number,
      default: 1
    },
    maxPaginationSize: {
      type: Number,
      default: 5
    },
    rowsCount: {
      type: Number,
      default: 12
    },
    value: {
      type: Number,
      default: 1
    }
  },

  methods: {
    onMoveToPage(page) {
      console.log("[onMoveToPage]", page);
      this.inputValue = page;
      this.$emit("paginate", page);
    },
    onPreviousClicked() {
      if (!this.previousBtnDisabled) {
        const nextPage = this.inputValue - 1;
        this.onMoveToPage(nextPage);
      }
    },
    onNextClicked() {
      if (!this.nextBtnDisabled) {
        const nextPage = this.inputValue + 1;
        this.onMoveToPage(nextPage);
      }
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    inputRowsCount: {
      get() {
        return this.rowsCount;
      },
      set(val) {
        this.$emit("inputRowCount", val);
      }
    },
    previousBtnDisabled() {
      return this.inputValue <= 1;
    },
    nextBtnDisabled() {
      return this.inputValue >= this.pages;
    },
    pageRanges() {
      if (this.pages <= this.maxPaginationSize) return this.pages;

      const startPages = Math.min(
        Math.max(
          this.inputValue - Math.ceil((this.maxPaginationSize - 1) / 2),
          1
        ),
        this.pages - this.maxPaginationSize + 1
      );
      const res = [];
      for (let i = 0; i < this.maxPaginationSize; i++) {
        res.push(i + startPages);
      }
      return res;
    }
  }
});
