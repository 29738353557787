<li class="flex-auto relative group couponType px-1 test123">
  <div
    class="rounded-full text-white"
    :class="isSelected?'bg-qr_color-blue_1':'bg-qr_color-gray'"
  >
    <button
      class="w-full text-center flex cardType flex-col flex items-center w-full"
      type="button"
      @click="onSelectCardType($event)"
    >
      <div class="py-2 px-2">
        <h5 class="font-medium text-qr_color_gray">{{value}}</h5>
      </div>
    </button>
    <slot></slot>
  </div>
</li>
