<div class="h-auto pl-0 lg:pl-12 w-full pb-12">
  <div
    class="flex flex-col bg-qr_color-blue_3 w-full h-auto px-1 md:px-5 gap-y-3 rounded-qr_input_radius relative py-4">
    <div v-show="getLoadingQrModal || (getQRActionLoading && !getQrCurrentDesign.id)"
      class="spinnerContainer absolute w-full h-full flex items-center justify-center bg-black/[0.3] top-0 left-0 rounded-qr_input_radius z-50">
      <Spinner :visible="getLoadingQrModal || (getQRActionLoading && !getQrCurrentDesign.id)" />
    </div>
    <div class="text-center">
      <label for="default-toggle" class="inline-flex relative items-center cursor-pointer my-0 block mx-auto">
        <input type="checkbox" id="default-toggle" class="sr-only peer" v-model="live_preview" />
        <div
          class="border-2 border-qr_color-yellow_icon after:w-[120px] w-[245px] after:flex after:items-center after:justify-center h-11 bg-qr_color-gray_3 rounded-full peer peer-checked:after:translate-x-full after:content-[attr(normal-content)] peer-checked:after:content-[attr(peer-content)] after:text-white after:absolute after:top-[4px] after:left-[4px] after:leading-8 peer-checked:after:left-[0px] after:bg-qr_color-blue_1 after:rounded-full after:h-9 after:transition-all peer-checked:after:border-qr_color-gray_3 after:border-qr_color-gray_3 after:border"
          peer-content="Live Preview" normal-content="Design">
          <span
            class="before:text-qr_color-blue_2 before:absolute before:right-[20px] before:top-[10px] before:content-[attr(peer-content)] before:font-bold after:font-bold after:qr_color-gray_2 after:absolute after:left-[20px] after:top-[10px] after:content-[attr(normal-content)]"
            peer-content="Live Preview" normal-content="Design"></span>
        </div>
      </label>
    </div>
    <div class="w-full" v-if="isLivePreviewModeEnabled">
      <PreviewCode :mode="previewMode" :enabled="true">
        <div class="w-full">
          <div class="w-full">
            <div
              class="w-[320px] h-[523px] relative flex m-auto text-qr_color-blue_1 bg-qr_color-blue_1 border-[15px] border-qr_color-blue_1 rounded-qr_input_radius box-content">
              <div class="w-[320px] h-[523px]">
                <div class="ratchet overflow-hidden w-full h-full rounded-qr_input_radius">
                  <iframe class="w-full h-full" ref="templatePreviewIframe" :src="getTemplatePreviewUrl"></iframe>
                </div>
                <PhoneIcon class="absolute top-0 left-0 pointer-events-none" />
              </div>
            </div>
          </div>
        </div>
      </PreviewCode>
    </div>
    <div v-else class="w-full flex items-center justify-center relative flex flex-col gap-y-3">
      <div class="w-full flex items-center justify-center relative">
        <div class="h-[250px] w-full bg-contain bg-no-repeat bg-center flex items-center" :style="getStyleDesignBox">
          <PrintQrLogoPartial class="w-full h-full p-6" v-if="!getQrCurrentDesign.id" />
        </div>
      </div>

      <div
        class="bg-qr_color-blue_1 rounded-qr_input_radius w-full h-8 flex flex-row justify-between items-center cursor-pointer"
        @click="toggleAccordion($event,'frame_open')">
        <span class="text-white px-3 flex">{{$t('preview_qr.frame')}}</span>
        <div class="tex-white px-3">
          <ArrowUpIcon v-show="!accordion.frame_open" />
          <ArrowDownIcon v-show="accordion.frame_open" />
        </div>
      </div>
      <div class="w-full gap-y-2 flex-col flex" v-if="accordion.frame_open">
        <ul class="flex gap-x-6 my-1 justify-center">
          <li class="w-16 h-16 relative" v-for="frame in getQRFrameTemplates">
            <input class="sr-only peer" type="radio" :value="frame.id" name="qr_frame_type" :id="`qr_frame_${frame.id}`"
              :checked="(getQrCurrentDesign.frame_template_id == frame.id) || (frame.code =='default' && !getQrCurrentDesign.frame_template_id )" />
            <label
              class="flex flex-col p-2 w-full h-full ring ring-6 rounded-qr_input_radius cursor-pointer ring-qr_color-yellow_icon peer-checked:ring-qr_color-blue_2 peer-checked:ring-6"
              :for="`qr_frame_${frame.id}`" @click="previewChangesHandler($event,'frame_template_id',frame)">
              <img v-if="frame.url && frame.code!='default'" :src="frame.url" alt="frame"
                class="w-full h-full object-contain" />
              <div v-else class="m-auto">
                <div
                  class="w-8 h-8 rounded-full border-2 border-gray-700 relative before:absolute before:content-[' '] before:origin-center before:rotate-45 before:w-8 before:h-0.5 before:bg-gray-700 before:block before:top-[13px] before:left-[-2px]">
                </div>
              </div>
            </label>
          </li>
        </ul>
        <div class="flex relative flex-col gap-y-2"
          v-if="!(getQrCurrentDesign.frame_template_id ==getDefaultFrameId ||  !getQrCurrentDesign.frame_template_id) ">
          <div class="w-full relative text-gray-500 flex justify-between">
            <span class="text-qr_color-blue_2 text-16 float-left pb-2">
              {{$t('preview_qr.frame_color')}}
            </span>
            <input type="text"
              class="bg-transparent h-10 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-1 focus:ring-1 focus:ring-qr_color-yellow_icon focus:border-transparent border-qr_color-yellow_icon w-1/2"
              name="qr_frame_color" v-model="frameColor" />

            <button
              class="absolute right-0.5 top-0 h-9 inline-flex items-center px-1.5 py-1.5 rounded-qr_input-radius border-0"
              type="button" @click="preventOpenColorPicker($event,'frameColorPickerVisible')">
              <span class="w-6 h-6 border-0 rounded-qr_color_radius border-0"
                :style="`background-color: ${frameColor}`">
              </span>
            </button>
          </div>

          <div class="absolute z-40" v-if="frameColorPickerVisible">
            <QRCustomColorPicker v-model="frameColor" :swatches="[]" class="flex justify-between items-center pb-2"
              :hide-swatches="true" @change="onFrameColorUpdated" @close="frameColorPickerVisible=false" />
          </div>
          <div class="w-full relative text-gray-500 flex justify-between">
            <span class="text-qr_color-blue_2 text-16 float-left py-2">
              {{$t('preview_qr.frame_text')}}
            </span>
            <input @keydown="getFormValid($event)" type="text"
              class="bg-transparent h-10 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-1 focus:ring-1 focus:ring-qr_color-yellow_icon focus:border-transparent border-qr_color-yellow_icon w-1/2"
              name="qr_frame_text" :value="getQrCurrentDesign.text" @change="onChangeFrameText($event)" />
          </div>
        </div>
      </div>
      <div
        class="bg-qr_color-blue_2 rounded-qr_input_radius w-full h-8 flex flex-row justify-between items-center cursor-pointer"
        @click="toggleAccordion($event,'shape_open')">
        <span class="text-white px-3 flex">{{$t('preview_qr.shape_style')}}</span>
        <div class="tex-white px-3">
          <ArrowUpIcon v-show="!accordion.shape_open" />
          <ArrowDownIcon v-show="accordion.shape_open" />
        </div>
      </div>
      <div class="w-full" v-show="accordion.shape_open">
        <ul class="flex gap-x-6 my-1 justify-start">
          <li class="w-16 h-16 relative" v-for="format in getQrPatterns">
            <input class="sr-only peer" type="radio" :value="format.id" name="qr_format_type"
              :checked="getQrCurrentDesign.pattern_id==format.id" :id="`qr_format_${format.id}`" />
            <label
              class="flex flex-col p-2 w-full h-full ring ring-6 rounded-qr_input_radius cursor-pointer ring-qr_color-yellow_icon peer-checked:ring-qr_color-blue_2 peer-checked:ring-6"
              :for="`qr_format_${format.id}`" @click="previewChangesHandler($event,'pattern_id',format.id)">
              <img v-if="format.url" :src="format.url" alt="format" class="w-full h-full object-contain" />
              <div v-else class="m-auto">
                <div
                  class="w-8 h-8 rounded-full border-2 border-gray-700 relative before:absolute before:content-[' '] before:origin-center before:rotate-45 before:w-8 before:h-0.5 before:bg-gray-700 before:block before:top-[13px] before:left-[-2px]">
                </div>
              </div>
            </label>
          </li>
        </ul>
      </div>
      <div
        class="bg-qr_color-blue_1 rounded-qr_input_radius w-full h-8 flex flex-row justify-between items-center cursor-pointer"
        @click="toggleAccordion($event,'color_open')">
        <span class="text-white px-3 flex">{{$t('preview_qr.shape_color')}}</span>
        <div class="tex-white px-3">
          <ArrowUpIcon v-show="!accordion.color_open" />
          <ArrowDownIcon v-show="accordion.color_open" />
        </div>
      </div>
      <div class="w-full flex flex-col gap-y-2" v-show="accordion.color_open">
        <div class="flex relative">
          <div class="w-full relative text-gray-500 flex justify-between">
            <span class="text-qr_color-blue_2 text-16 float-left py-2">
              {{$t('preview_qr.code_color')}}:
            </span>

            <input type="text" @keydown="getFormValid($event)"
              class="bg-transparent h-10 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-1 focus:ring-1 focus:ring-qr_color-yellow_icon focus:border-transparent border-qr_color-yellow_icon w-1/2"
              name="qr_code_color" v-model="codeColor" />

            <button
              class="absolute right-0.5 top-0 h-9 inline-flex items-center px-1.5 py-1.5 rounded-qr_input-radius border-0"
              type="button" @click="preventOpenColorPicker($event,'codeColorPickerVisible')">
              <span class="w-6 h-6 border-0 rounded-qr_color_radius border-0" :style="`background-color: ${codeColor}`">
              </span>
            </button>
          </div>

          <div class="absolute z-40" v-if="codeColorPickerVisible">
            <QRCustomColorPicker v-model="codeColor" :swatches="[]" :hide-swatches="true" @change="onCodeColorUpdated"
              @close="codeColorPickerVisible=false" />
          </div>
        </div>

        <div class="flex relative">
          <div class="w-full relative text-gray-500 flex justify-between">
            <span class="text-qr_color-blue_2 text-16 float-left py-2">
              {{$t('preview_qr.background_color')}}:
            </span>

            <input type="text" @keydown="getFormValid($event)"
              class="bg-transparent h-10 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-1 focus:ring-1 focus:ring-qr_color-yellow_icon focus:border-transparent border-qr_color-yellow_icon w-1/2"
              name="qr_code_color" v-model="codeBgColor" />

            <button
              class="absolute right-0.5 top-0 h-9 inline-flex items-center px-1.5 py-1.5 rounded-qr_input-radius border-0"
              type="button" @click="preventOpenColorPicker($event,'codeBackColorPickerVisible')">
              <span class="w-6 h-6 border-0 rounded-qr_color_radius border-0"
                :style="`background-color: ${codeBgColor}`">
              </span>
            </button>
          </div>
          <div class="absolute z-40" v-if="codeBackColorPickerVisible">
            <QRCustomColorPicker v-model="codeBgColor" :swatches="[]" :hide-swatches="true"
              @change="onBackgroundColorUpdated" @close="codeBackColorPickerVisible=false" />
          </div>
        </div>
        <!-- Edge outer Color color -->
        <div class="flex relative">
          <div class="w-full relative text-gray-500 flex justify-between">
            <span class="text-qr_color-blue_2 text-16 float-left py-2">
              {{$t('preview_qr.corner_outer_color')}}:
            </span>
            <input @keydown="getFormValid($event)" type="text"
              class="bg-transparent h-10 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-1 focus:ring-1 focus:ring-qr_color-yellow_icon focus:border-transparent border-qr_color-yellow_icon w-1/2"
              name="qr_code_color" v-model="codeOuterColor" />

            <button
              class="absolute right-0.5 top-0 h-9 inline-flex items-center px-1.5 py-1.5 rounded-qr_input-radius border-0"
              type="button" @click="preventOpenColorPicker($event,'codeEdgeOuterPickerVisible')">
              <span class="w-6 h-6 border-0 rounded-qr_color_radius border-0"
                :style="`background-color: ${codeOuterColor}`">
              </span>
            </button>
          </div>

          <div class="absolute z-40" v-if="codeEdgeOuterPickerVisible">
            <QRCustomColorPicker v-model="codeOuterColor" :swatches="[]" :hide-swatches="true"
              @change="onOuterColorUpdated" @close="codeEdgeOuterPickerVisible=false" />
          </div>
        </div>

        <!-- Edge inner Color visible -->
        <div class="flex relative">
          <div class="w-full relative text-gray-500 flex justify-between">
            <span class="text-qr_color-blue_2 text-16 float-left py-2">
              {{$t('preview_qr.corner_inner_color')}}:
            </span>
            <input @keydown="getFormValid($event)" type="text"
              class="bg-transparent h-10 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-1 focus:ring-1 focus:ring-qr_color-yellow_icon focus:border-transparent border-qr_color-yellow_icon w-1/2"
              name="qr_code_color" v-model="codeInnerColor" />

            <button
              class="absolute right-0.5 top-0 h-9 inline-flex items-center px-1.5 py-1.5 rounded-qr_input-radius border-0"
              type="button" @click="preventOpenColorPicker($event,'codeEdgeInnerPickerVisible')">
              <span class="w-6 h-6 border-0 rounded-qr_color_radius border-0"
                :style="`background-color: ${codeInnerColor}`">
              </span>
            </button>
          </div>

          <div class="absolute z-40" v-if="codeEdgeInnerPickerVisible">
            <QRCustomColorPicker v-model="codeInnerColor" :swatches="[]" :hide-swatches="true"
              @change="onInnerColorUpdated" @close="codeEdgeInnerPickerVisible=false" />
          </div>
        </div>
      </div>
      <div
        class="bg-qr_color-blue_2 rounded-qr_input_radius w-full h-8 flex flex-row justify-between items-center cursor-pointer"
        @click="toggleAccordion($event,'logo_open')">
        <span class="text-white px-3 flex">{{$t('preview_qr.logo')}}</span>
        <div class="tex-white px-3">
          <ArrowUpIcon v-show="!accordion.logo_open" />
          <ArrowDownIcon v-show="accordion.logo_open" />
        </div>
      </div>
      <div class="w-full flex-col" v-show="accordion.logo_open">
        <div class="flex relative">
          <div class="w-20 h-20 absolute top-0 left-0">
            <input ref="logoFile" class="cursor-pointer absolute block opacity-0 invisible" type="file" name="logo"
              @change="onChangeLogoFile" />
            <button type="button" @click="$refs.logoFile.click()"
              class="px-8 py-2.5 overflow-hidden text-sm font-medium text-white bg-qr_color-blue_1 hover:cursor-pointer rounded-qr_input_radius items-center h-full flex flex-col items-center justify-center w-full">
              <CloudUploadIcon class="w-4 h-4 fill-current"> </CloudUploadIcon>
              <span class="ml-1 text-white text-sm font-medium text-[12px]">
                {{$t('preview_qr.upload')}}:
              </span>
            </button>
          </div>
        </div>
        <ul class="flex gap-x-6 justify-start overflow-x-auto logoContainer pb-2 pt-1 ml-20 pl-2">
          <li class="w-20 h-20 relative min-w-[80px] p-1" v-for="logo in getQrLogoTemplates">
            <div class="w-full h-full object-contain">
              <input class="sr-only peer" type="radio" :value="logo.id" name="qr_logo_type"
                :id="`qr_template_logo_${logo.id}`"
                :checked="(getQrCurrentDesign.logo_type == 'template') && (logo.id == getQrCurrentDesign.logo_template_id||( !logo.id  && getQrCurrentDesign.logo_template_id))" />
              <label
                class="flex flex-col p-2 w-full h-full ring ring-6 rounded-qr_input_radius cursor-pointer ring-qr_color-yellow_icon peer-checked:ring-qr_color-blue_2 peer-checked:ring-6"
                :for="`qr_template_logo_${logo.id}`" @click="changeColorLogoHandler($event,'template',logo.id)">
                <img :src="logo.url" v-if="logo.url" alt="logo" class="w-full h-full object-contain" />
                <div v-else class="m-auto">
                  <div
                    class="w-8 h-8 rounded-full border-2 border-gray-700 relative before:absolute before:content-[' '] before:origin-center before:rotate-45 before:w-8 before:h-0.5 before:bg-gray-700 before:block before:top-[13px] before:left-[-2px]">
                  </div>
                </div>
              </label>
            </div>
          </li>
          <li class="w-20 h-20 relative min-w-[80px] p-1" v-for="logo in getQrLogos">
            <div :key="logo.id" class="w-full h-full" v-if="logo.isLoading">
              <label
                class="flex p-2 bg-gray-50 border-1 border-gray-300 w-full h-full rounded-md items-center justify-center">
                <Spinner />
              </label>
            </div>
            <div :key="logo.id" class="w-full h-full object-contain relative" v-else>
              <input class="sr-only peer" type="radio" :value="logo.id" name="qr_logo_type" :id="`qr_logo_${logo.id}`"
                :checked="(getQrCurrentDesign.logo_type == 'defined') && (logo.id == getQrCurrentDesign.logo_id)" />
              <button type="button"
                :style="{width: '20px', height: '20px' , top: '-0.5rem' , right: '-10px' , padding: '2px'}" class="
                deleteQrLogo absolute inline-flex items-center text-sm text-normal rounded-full transition-colors
                bg-white border-1 border-qr_color-yellow_icon " @click=" (event)=> {
                event.stopPropagation()
                event.preventDefault()
                deleteQrLogo(logo.id)
                }
                ">
                <svg aria-hidden="true" class="w-full h-full" fill="currentColor" viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"></path>
                </svg>
              </button>
              <label
                class="flex flex-col p-2 w-full h-full ring ring-6 rounded-qr_input_radius cursor-pointer ring-qr_color-yellow_icon peer-checked:ring-qr_color-blue_2 peer-checked:ring-6"
                :for="`qr_logo_${logo.id}`" @click="changeColorLogoHandler($event,'defined',logo.id)">

                <img :src="logo.url" v-if="logo.url" alt="logo" class="w-full h-full object-contain" />
              </label>
            </div>
          </li>
        </ul>
      </div>
      <div class="w-full flex justify-center" v-if="getQrCurrentDesign.id">
        <button v-if="!getWidgetEditorMode" type="button"
          class="overflow-hidden text-sm font-medium text-white bg-qr_color-blue_1 hover:cursor-pointer rounded-qr_input_radius">
          <a :href="getDownloadSvgUrl" class="hover:text-white no-underline w-full h-full px-8 py-2.5 flex">
            <span class="uppercase">
              {{$t('preview_qr.download')}}
            </span>
          </a>
        </button>
        <button v-if="getWidgetEditorMode" type="button"
          class="px-8 py-2.5 overflow-hidden text-sm font-medium text-white bg-qr_color-blue_1 hover:cursor-pointer rounded-qr_input_radius"
          @click="placeQrInEditorHandler($event)">
          <span class="uppercase">
            {{$t('preview_qr.place_qr_in_editor')}}</span>
        </button>
      </div>
    </div>
  </div>
</div>