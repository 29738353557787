<div class="w-full">
  <div class="text-qr_color-gray flex text-16 font-semibold">
    {{description}}
  </div>
  <div class="mt-5">
    <ul class="w-full flex flex-wrap gap-x-10 gap-y-4">
      <li>
        <input
          type="checkbox"
          name="wifi"
          id="wifi-icon"
          v-model="value.wifi"
          @change="onChange"
          class="hidden peer"
        />
        <label
          for="wifi-icon"
          class="pb-1 inline-flex justify-between items-center text-qr_color-gray bg-white rounded-0 border-0 cursor-pointer peer-checked:text-qr_color-blue_2 peer-checked:border-qr_color-blue_2 peer-checked:border-b-2 hover:border-qr_color_gray hover:border-b-2"
        >
          <WifiIcon class="w-5 h-5 fill-current" />
        </label>
      </li>
      <li>
        <input
          type="checkbox"
          name="chair"
          id="chair-icon"
          v-model="value.chair"
          @change="onChange"
          class="hidden peer"
        />
        <label
          for="chair-icon"
          class="pb-1 inline-flex justify-between items-center text-qr_color-gray bg-white rounded-0 border-0 cursor-pointer peer-checked:text-qr_color-blue_2 peer-checked:border-qr_color-blue_2 peer-checked:border-b-2 hover:border-qr_color_gray hover:border-b-2"
        >
          <ChairIcon class="w-5 h-5 fill-current" />
        </label>
      </li>
      <li>
        <input
          type="checkbox"
          name="disabled"
          id="disabled-icon"
          v-model="value.disabled"
          @change="onChange"
          class="hidden peer"
        />
        <label
          for="disabled-icon"
          class="pb-1 inline-flex justify-between items-center text-qr_color-gray bg-white rounded-0 border-0 cursor-pointer peer-checked:text-qr_color-blue_2 peer-checked:border-qr_color-blue_2 peer-checked:border-b-2 hover:border-qr_color_gray hover:border-b-2"
        >
          <DisabledIcon class="w-5 h-5 fill-current" />
        </label>
      </li>
      <li>
        <input
          type="checkbox"
          name="toilet"
          id="toilet-icon"
          v-model="value.toilet"
          @change="onChange"
          class="hidden peer"
        />
        <label
          for="toilet-icon"
          class="pb-1 inline-flex justify-between items-center text-qr_color-gray bg-white rounded-0 border-0 cursor-pointer peer-checked:text-qr_color-blue_2 peer-checked:border-qr_color-blue_2 peer-checked:border-b-2 hover:border-qr_color_gray hover:border-b-2"
        >
          <ToiletIcon class="w-5 h-5 fill-current" />
        </label>
      </li>
      <li>
        <input
          type="checkbox"
          name="stroller"
          id="stroller-icon"
          v-model="value.stroller"
          @change="onChange"
          class="hidden peer"
        />
        <label
          for="stroller-icon"
          class="pb-1 inline-flex justify-between items-center text-qr_color-gray bg-white rounded-0 border-0 cursor-pointer peer-checked:text-qr_color-blue_2 peer-checked:border-qr_color-blue_2 peer-checked:border-b-2 hover:border-qr_color_gray hover:border-b-2"
        >
          <StrollerIcon class="w-5 h-5 fill-current" />
        </label>
      </li>
      <li>
        <input
          type="checkbox"
          name="pet"
          id="pet-icon"
          v-model="value.pet"
          @change="onChange"
          class="hidden peer"
        />
        <label
          for="pet-icon"
          class="pb-1 inline-flex justify-between items-center text-qr_color-gray bg-white rounded-0 border-0 cursor-pointer peer-checked:text-qr_color-blue_2 peer-checked:border-qr_color-blue_2 peer-checked:border-b-2 hover:border-qr_color_gray hover:border-b-2"
        >
          <PetIcon class="w-5 h-5 fill-current" />
        </label>
      </li>
      <li>
        <input
          type="checkbox"
          name="park"
          id="park-icon"
          v-model="value.parking"
          @change="onChange"
          class="hidden peer"
        />
        <label
          for="park-icon"
          class="pb-1 inline-flex justify-between items-center text-qr_color-gray bg-white rounded-0 border-0 cursor-pointer peer-checked:text-qr_color-blue_2 peer-checked:border-qr_color-blue_2 peer-checked:border-b-2 hover:border-qr_color_gray hover:border-b-2"
        >
          <ParkingIcon class="w-5 h-5 fill-current" />
        </label>
      </li>
      <li>
        <input
          type="checkbox"
          name="train"
          id="train-icon"
          v-model="value.train"
          @change="onChange"
          class="hidden peer"
        />
        <label
          for="train-icon"
          class="pb-1 inline-flex justify-between items-center text-qr_color-gray bg-white rounded-0 border-0 cursor-pointer peer-checked:text-qr_color-blue_2 peer-checked:border-qr_color-blue_2 peer-checked:border-b-2 hover:border-qr_color_gray hover:border-b-2"
        >
          <TrainIcon class="w-5 h-5 fill-current" />
        </label>
      </li>
      <li>
        <input
          type="checkbox"
          name="taxi"
          id="taxi-icon"
          v-model="value.taxi"
          @change="onChange"
          class="hidden peer"
        />
        <label
          for="taxi-icon"
          class="pb-1 inline-flex justify-between items-center text-qr_color-gray bg-white rounded-0 border-0 cursor-pointer peer-checked:text-qr_color-blue_2 peer-checked:border-qr_color-blue_2 peer-checked:border-b-2 hover:border-qr_color_gray hover:border-b-2"
        >
          <TaxiIcon class="w-5 h-5 fill-current" />
        </label>
      </li>
      <li>
        <input
          type="checkbox"
          name="bed"
          id="bed-icon"
          v-model="value.bed"
          @change="onChange"
          class="hidden peer"
        />
        <label
          for="bed-icon"
          class="pb-1 inline-flex justify-between items-center text-qr_color-gray bg-white rounded-0 border-0 cursor-pointer peer-checked:text-qr_color-blue_2 peer-checked:border-qr_color-blue_2 peer-checked:border-b-2 hover:border-qr_color_gray hover:border-b-2"
        >
          <BedIcon class="w-5 h-5 fill-current" />
        </label>
      </li>
      <li>
        <input
          type="checkbox"
          name="cafe"
          id="cafe-icon"
          v-model="value.cafe"
          @change="onChange"
          class="hidden peer"
        />
        <label
          for="cafe-icon"
          class="pb-1 inline-flex justify-between items-center text-qr_color-gray bg-white rounded-0 border-0 cursor-pointer peer-checked:text-qr_color-blue_2 peer-checked:border-qr_color-blue_2 peer-checked:border-b-2 hover:border-qr_color_gray hover:border-b-2"
        >
          <CafeIcon class="w-5 h-5 fill-current" />
        </label>
      </li>
      <li>
        <input
          type="checkbox"
          name="bar"
          id="bar-icon"
          v-model="value.bar"
          @change="onChange"
          class="hidden peer"
        />
        <label
          for="bar-icon"
          class="pb-1 inline-flex justify-between items-center text-qr_color-gray bg-white rounded-0 border-0 cursor-pointer peer-checked:text-qr_color-blue_2 peer-checked:border-qr_color-blue_2 peer-checked:border-b-2 hover:border-qr_color_gray hover:border-b-2"
        >
          <BarIcon class="w-5 h-5 fill-current" />
        </label>
      </li>
      <li>
        <input
          type="checkbox"
          name="restaurantIcon"
          id="restaurantIcon-icon"
          v-model="value.restaurant"
          @change="onChange"
          class="hidden peer"
        />
        <label
          for="restaurantIcon-icon"
          class="pb-1 inline-flex justify-between items-center text-qr_color-gray bg-white rounded-0 border-0 cursor-pointer peer-checked:text-qr_color-blue_2 peer-checked:border-qr_color-blue_2 peer-checked:border-b-2 hover:border-qr_color_gray hover:border-b-2"
        >
          <RestaurantIcon class="w-5 h-5 fill-current" />
        </label>
      </li>
    </ul>
  </div>
</div>
