<div class="border min-w-min w-min rounded-lg bg-white">
  <div class="fixed left-0 right-0 top-0 bottom-0" @click="onCloseDialog"></div>
  <div class="p-4 w-60">
    <div class="w-52 h-40" :style="{ backgroundColor: canvasColor }">
      <div class="w-full h-full" style="background-image:linear-gradient(90deg,#fff,rgba(204,154,129,0));">
        <div ref="canvas" class="w-full h-full relative cursor-pointer"
          style="background-image:linear-gradient(0deg,#000,rgba(204,154,129,0));" @mousedown="mousedownCanvas">
          <div ref="canvasCursor"
            class="h-4 w-4 border border-gray-200 rounded-full bg-white absolute -left-2 -top-2 pointer-events-none"
            style="box-shadow:2px 2px 2px 0 rgb(0 0 0 / 20%)"></div>
        </div>
      </div>
    </div>
    <div class="w-52 flex my-2">
      <div class="w-8 h-8 rounded-lg"
        style="background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAJElEQVQYV2NctWrVfwYkEBYWxojMZ6SDAmT7QGx0K1EcRBsFAADeG/3M/HteAAAAAElFTkSuQmCC') repeat">
        <div :style="{ backgroundColor: color }" class="w-full h-full rounded-lg" />
      </div>
      <div>
        <div ref="line" class="w-40 h-3 ml-4 relative cursor-pointer rounded"
          style="background-image:linear-gradient(90deg,red 0,#ff0 17%,#0f0 33%,#0ff 50%,#00f 67%,#f0f 83%,red)"
          @mousedown="mousedownLine">
          <div ref="lineCursor"
            class="h-4 w-4 border border-gray-200 rounded-full bg-white absolute -left-2 pointer-events-none"
            style="top:-2px;box-shadow:2px 2px 2px 0 rgb(0 0 0 / 20%)"></div>
        </div>
        <div ref="opacityLine" class="w-40 h-3 ml-4 mt-2 relative cursor-pointer rounded"
          style="background:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAJElEQVQYV2NctWrVfwYkEBYWxojMZ6SDAmT7QGx0K1EcRBsFAADeG/3M/HteAAAAAElFTkSuQmCC') repeat"
          @mousedown="mousedownOpacity">
          <div class="w-full h-full relative" :style="{ background: opacityLineBackground }">
            <div ref="opacityCursor"
              class="h-4 w-4 border border-gray-200 rounded-full bg-white absolute -left-2 pointer-events-none"
              style="top:-2px;box-shadow:2px 2px 2px 0 rgb(0 0 0 / 20%)"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center">
      <div v-show="inputType === 'rgba'" class="flex">
        <div>
          <p class="text-center text-gray-500 text-sm">R</p>
          <input v-model="colorLazy.r" @input="inputUpdated" @blur="blurInputR"
            class="shadow appearance-none border rounded text-center w-10 h-8 text-grey-darker" />
        </div>
        <div class="mx-1">
          <p class="text-center text-gray-500 text-sm">G</p>
          <input v-model="colorLazy.g" @input="inputUpdated" @blur="blurInputG"
            class="shadow appearance-none border rounded text-center w-10 h-8 text-grey-darker" />
        </div>
        <div class="mr-1">
          <p class="text-center text-gray-500 text-sm">B</p>
          <input v-model="colorLazy.b" @input="inputUpdated" @blur="blurInputB"
            class="shadow appearance-none border rounded text-center w-10 h-8 text-grey-darker" />
        </div>
        <div>
          <p class="text-center text-gray-500 text-sm">A</p>
          <input v-model="colorLazy.a" @input="inputUpdated" @blur="blurInputA"
            class="shadow appearance-none border rounded text-center w-10 h-8 text-grey-darker" />
        </div>
      </div>
      <div v-show="inputType === 'hexa'" class="mr-3">
        <p class="text-gray-500 text-center text-sm">HEXA</p>
        <input v-model="colorLazy.hexa" @input="hexaInputUpdated" @blur="blurInputHexa"
          class="shadow appearance-none border rounded text-center w-40 h-8 text-grey-darker" />
      </div>
      <div class="px-2 text-gray-500 cursor-pointer" @mousedown.prevent @click.stop.prevent="toggleInputs">
        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
          <path fill="currentColor"
            d="M12,18.17L8.83,15L7.42,16.41L12,21L16.59,16.41L15.17,15M12,5.83L15.17,9L16.58,7.59L12,3L7.41,7.59L8.83,9L12,5.83Z" />
        </svg>
      </div>
    </div>
    <div v-if="!hideSwatches" class="flex mt-2 flex-wrap px-1">
      <template v-for="swatch in swatchesLazy">
        <div :key="swatch" class="w-8 h-8 m-1 relative cursor-pointer rounded-full shadow-md"
          :style="{ backgroundColor: swatch }" @click="selectSwatch(swatch)">
          <div v-show="inputValue === swatch" class="h-full w-full rounded-full border-2 border-gray-200 p-0 relative">
          </div>
        </div>
      </template>
      <div class="w-8 h-8 m-1 cursor-pointer rounded-full shadow-md text-gray-600 flex items-center justify-center"
        :style="{ backgroundColor: color }" @click.stop="addRemoveCurrentSwatch">
        <svg v-if="!hasSelectedSwatch" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
          class="h-6 w-6">
          <path fill-rule="evenodd"
            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
            clip-rule="evenodd" />
        </svg>
        <svg v-else class="h-5 w-5" viewBox="0 0 24 24">
          <path fill="currentColor"
            d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
        </svg>
      </div>
    </div>
  </div>
</div>