<div class="w-full flex flex-col">
  <div class="bg-[#fbfbfb] text-qr_color-gray border-2 border-dashed border-[#d4d4d4] rounded-md float-left">
    <div class="my-8 px-6 flex justify-between s_qr:justify-center items-center">
      <p class="text-base font-semibold">
        {{$t('Drag and drop images here')}}
      </p>

      <div>
        <span type="button"
          class="sm:flex px-16 text-white bg-qr_color-blue_1 p-2 rounded-qr_input_radius cursor-pointer"
          @click="$refs.file.click()">
          {{$t('Upload')}}
        </span>
        <input ref="file" class="cursor-pointer absolute block opacity-0 pin-r pin-t" type="file" :name="name"
          @change="onChangeFileName" accept="image/*" multiple />
      </div>
    </div>
  </div>

  <div class="mt-4 mb-4">
    <input type="checkbox" :value="useGridView" :checked="useGridView" @change="onUseGridView"
      class="w-4 h-4 text-qr_color-blue-2 hover:cursor-pointer hover:text-qr_color-blue_2 hover:bg-qr-color-blue_2 border-2 border-qr_color-blue_2 rounded-qr_checkbox_radius" />
    <label class="mx-2 text-qr_color-gray text-16">
      {{$t('Show images in grid view')}}</label>
  </div>

  <div v-if="files.length > 0">
    <div v-for="(file, index) in files" :key="file.id">
      <div class="flex w-full mb-4">
        <span class="mr-4 text-qr_color-gray text-16 py-1 font-semibold">{{index + 1}}.</span>
        <div
          class="relative ring ring-6 rounded-qr_input_radius  ring-qr_color-blue_ring mb-2 sm:mb-0 sm:w-1/3 h-auto flex items-center overflow-hidden min-w-[33.3%]">
          <img v-if="file.base_path" :src="getFileUrl(file)" alt="img" class="w-full h-auto" />
          <div v-else class="absolute w-full h-full top-0 l-0 flex items-center justify-center">
            <Spinner :visible="true" />
          </div>
        </div>

        <div class="ml-4 px-2.5 py-2 block w-full">
          <textarea
            class="bg-qr_color-gray_3 w-full  text-qr_color-gray peer rounded-qr_input_radius  border-0 focus:ring-0 focus:border-transparent"
            name="textarea" id="textarea" rows="4" @change="(event)=>onUpdateImageText(event, file.id)"
            :value="file.description" :placeholder="$t('Enter Caption...')"></textarea>
          <div v-if="file && file.original_filename" class="relative float-right text-qr_color-gray">
            {{file.original_filename}}
          </div>
        </div>

        <div class="ml-2 w-12 p-1">
          <button type="button" class="text-qr_color-gray" @click="onRemoveFile(file)">
            <CloseIcon class="w-4 h-4 fill-current" />
          </button>
        </div>
      </div>
    </div>
  </div>


</div>