import WithRender from "./Error404Page.html";
import MainLayout from "../components/ui/layouts/MainLayout";

export default WithRender({
  components: {
    MainLayout
  },
  computed: {},
  methods: {}
});
