import baseField from "vue-formly-bootstrap/src/fields/baseField";
import { forEachObjIndexed, is } from "ramda";
import WithRender from "./QRAppStoreLinks.html";
import CloseIcon from "../icons/CloseIcon";
import AmazonIcon from "../icons/AmazonIcon";
import GooglePlayIcon from "../icons/GooglePlayIcon";
import AppleStoreIcon from "../icons/AppleStoreIcon";
import { makeRNDStr } from "../../../helpers/helpers";
import { mapGetters } from "vuex";

export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  components: {
    AmazonIcon,
    GooglePlayIcon,
    AppleStoreIcon,
    CloseIcon
  },
  created() {
    this.model[this.name] ?? [];
  },
  mounted() {
    this.links = this.getCurrentModel?.stores
      ? [...this.getCurrentModel.stores]
      : [];
  },
  data() {
    return {
      links: [],
      addingNew: false,
      errorMessage: {
        AppleStore: "",
        GooglePlay: "",
        Amazon: "",
        Global: ""
      }
    };
  },
  computed: {
    ...mapGetters(["getCurrentModel"]),
    name() {
      return this.field.key;
    },
    required() {
      return this.field?.templateOptions?.requiredAllStores ?? false;
    },
    hasValidators() {
      const validators = this?.field?.templateOptions?.validators ?? {};
      return Object.keys(validators).length > 0;
    },
    inputListeners: function () {
      var vm = this;
      return Object.assign({}, this.$listeners, {
        validate: event => {
          if (!this.validate(event))
            this.$emit("validate", { element: event.target, event });
        }
      });
    },
    getErrorMessage() {
      return id => this.errorMessage[id] ?? "";
    }
  },
  methods: {
    allLinksAdded() {
      return (
        this.hasLink("AppleStore") &&
        this.hasLink("GooglePlay") &&
        this.hasLink("Amazon")
      );
    },
    addButton(iconName, label) {
      this.links.push({
        type: iconName,
        label: label,
        id: makeRNDStr(10),
        value: ""
      });
    },
    onSocialUpdate(e) {
      const { value, id } = e.target;
      const socialId = id.split("-")[1];
      this.links = this.links.map(link => {
        const newValue = this.applyFilters(value);
        if (link.id == socialId) {
          return { ...link, value: newValue };
        }
        return link;
      });

      if (this.validate()) {
        this.errorMessage["Global"] = "";
      }
      this.$emit("update", {
        name: this.name,
        value: this.links
      });
    },
    onRemoveSocial(link) {
      const { id } = link;
      this.links = this.links.filter(link => link.id != id);
      this.errorMessage[link.type] = "";
      this.errorMessage["Global"] = "";
      this.$emit("update", {
        name: this.name,
        value: this.links
      });
    },
    hasLink(iconName) {
      return this.links.some(link => link.type === iconName);
    },
    applyFilters(value) {
      let newValue = value;
      const { name } = this;
      const filters = this?.field?.templateOptions?.filters ?? {};
      forEachObjIndexed((filter, key) => {
        newValue = filter({ name, value: newValue });
      }, filters);
      return newValue;
    },
    validate() {
      let isValid = true;

      const { name } = this;
      if (
        this.required &&
        !this.hasLink("AppleStore") &&
        !this.hasLink("GooglePlay") &&
        !this.hasLink("Amazon")
      ) {
        this.errorMessage["Global"] =
          this?.field?.templateOptions?.requiredMessage ??
          this.$t("At least one store is required");
        isValid = false;
      }
      const validators = this?.field?.templateOptions?.validators ?? {};

      this.links.forEach(link => {
        let valid = true;
        const messages = [];
        forEachObjIndexed((validator, key) => {
          valid =
            valid &&
            validator({
              messages,
              name,
              value: link.value,
              links: this.links
            });
        }, validators);
        this.errorMessage[link.type] = "";
        if (!valid) {
          this.errorMessage[link.type] = messages?.[0] ?? "";
        }
        isValid = isValid && valid;
      });

      return isValid;
    }
  },
  watch: {
    model: {
      handler(newValue, oldValue) {
        if (oldValue[this.name] == newValue[this.name]) return;
        this.links = newValue[this.name];
      },
      deep: true
    }
  }
});
