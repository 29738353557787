import WithRender from "./Spinner.html";

export default WithRender({
  props: {
    size: {
      type: Number || String,
      default: 12
    },
    visible: {
      type: Boolean,
      default: true
    }
  }
});
