import { querySentences, mutationSentences } from "../gql";

import Code1Img from "@/assets/images/code/code1.png";
import Code2Img from "@/assets/images/code/code2.png";
import Code3Img from "@/assets/images/code/code3.png";
import Code4Img from "@/assets/images/code/code4.png";
import Code5Img from "@/assets/images/code/code5.png";
import Code6Img from "@/assets/images/code/code6.png";

import { findIndex, propEq, remove } from "ramda";

const defaultCurrentQr = {
  title: "",
  type_id: null,
  url: null,
  id: 0,
  created_at: null,
  updated_at: null
};

const getModule = (queryAPI, mutationAPI) => {
  const state = {
    qrCodes: [],
    editorMode: true,
    qrFrameTemplates: [],
    qrUrlLandingPage: null,
    currentModel: {},
    current_qr: {
      ...defaultCurrentQr
    },
    selectedBatchItems: {},
    batchItemsCount: 0,
    curPage: 1,
    rowsCount: 12,

    count: 0,
    total: 0,

    pageCount: 0,
    loading: false,
    qr_direction: "DESC",
    filters: {
      qrStatus: "all",
      qrType: {
        "dynamic-vcard-plus": false,
        "dynamic-coupon": false,
        "dynamic-business": false,
        "dynamic-event": false,
        "dynamic-video": false,
        "dynamic-image": false,
        "dynamic-url": false,
        "dynamic-feedback": false,
        "dynamic-social-media": false,
        "dynamic-rating": false,
        "dynamic-pdf": false,
        "dynamic-facebook": false,
        "dynamic-text": false,
        "dynamic-mp3": false,
        "dynamic-instagram": false,
        "dynamic-app": false
      }
    },
    sortBy: "CREATED_AT"
  };

  const getters = {
    getQRCodesPageCount(state) {
      return state.pageCount;
    },
    getQRCodesTotal(state) {
      return state.total;
    },
    getQRCodesCount(state) {
      return state.count;
    },
    getQRActionLoading(state) {
      return state.loading;
    },
    getQRCodesRowsCount(state) {
      return state.rowsCount;
    },
    getQRCodesCurPage(state) {
      return state.curPage;
    },
    getQRCodes(state) {
      return state.qrCodes;
    },
    getQRFilters(state) {
      return state.filters;
    },
    getQrDirection(state) {
      return state.qr_direction;
    },
    getQRSortBy(state) {
      return state.sortBy;
    },
    getQRTypeFilters(state) {
      const types = state.filters.qrType;
      return Object.entries(types)
        .filter(([key, v]) => v)
        .map(([key]) => key);
    },
    getQRFrameTemplates(state) {
      return state.qrFrameTemplates;
    },
    getQrUrlLandingPage(state) {
      return state.current_qr.url;
    },
    getQrCurrentTitle(state) {
      return state.current_qr.title;
    },
    getQrCurrentTypeId(state) {
      return state.current_qr.type_id;
    },
    getCurrentQr(state) {
      return state.current_qr;
    },
    getCurrentQrId(state) {
      return state.current_qr.id;
    },
    getQRCodeFormats(state) {
      return [
        {
          key: "code1",
          image: Code1Img
        },
        {
          key: "code2",
          image: Code2Img
        },
        {
          key: "code3",
          image: Code3Img
        },
        {
          key: "code4",
          image: Code4Img
        },
        {
          key: "code5",
          image: Code5Img
        },
        {
          key: "code6",
          image: Code6Img
        }
      ];
    },
    getLogoTypes(state) {
      return [
        {
          key: "logo-none",
          image: null
        }
      ];
    },
    getBatchItems(state) {
      return state.selectedBatchItems;
    },
    getBatchItemList(state) {
      const { selectedBatchItems } = state;
      return Object.entries(selectedBatchItems)
        .map(([key, value]) => ({ key, value }))
        .filter(v => v.value);
    },
    getBatchItemCount(state) {
      return state.batchItemsCount;
    },

    getCurrentModel(state) {
      if (Object.keys(state.currentModel).length) return state.currentModel;
      return {};
    },
    getDefaultFrameId(state) {
      let id = null;
      const frame = state.qrFrameTemplates.filter(v => {
        return v.code == "default";
      });
      if (frame.length) {
        id = frame[0].id;
      }
      return id;
    }
  };

  const mutations = {
    setQRCodesPageCount(state, count) {
      state.pageCount = count;
    },
    setQRCodesCount(state, count) {
      state.count = count;
    },
    setQRCodesTotal(state, total) {
      state.total = total;
    },
    setQRCodesCurPage(state, page) {
      state.curPage = page;
    },
    setQRCodesRowsCount(state, count) {
      state.rowsCount = count;
    },
    setQRCodes(state, codes) {
      state.qrCodes = codes;
    },
    setFrameTemplates(state, qrFrameTemplates) {
      state.qrFrameTemplates = qrFrameTemplates;
    },

    setQrUrlLandingPage(state, qrUrlLandingPage) {
      state.qrUrlLandingPage = qrUrlLandingPage;
    },
    setCurrentModel(state, currentModel) {
      state.currentModel = currentModel;
    },
    setQRActionLoading(state, loading) {
      state.loading = loading;
    },
    updateCurrentModel(state, { key, value }) {
      const { currentModel } = state;
      state.currentModel = { ...currentModel, [key]: value };
    },
    setCurrentQrProps(state, payload) {
      state.current_qr = { ...state.current_qr, ...payload };
    },
    setFilters(state, filters) {
      state.filters = { ...state.filters, ...filters };
    },
    setSortBy(state, sortBy) {
      state.sortBy = sortBy;
    },
    setQrDirection(state, qr_direction) {
      state.qr_direction = qr_direction;
    },
    resetCurrentQr(state, payload) {
      state.current_qr = {
        ...defaultCurrentQr,
        ...payload
      };
    },
    resetCurrentModel(state, payload) {
      state.currentModel = {
        ...payload
      };
    },
    setQRCodeStatus(state, payload) {
      console.log("[setQRCodeStatus]", payload);
    },
    removeQrFromList(state, { id }) {
      const index = findIndex(propEq("id", id))(state.qrCodes);
      if (index != -1) {
        state.qrCodes = remove(index, 1, state.qrCodes);
      }
    },

    updateQrFromList(state, qrCode) {
      const index = findIndex(propEq("id", qrCode.id))(state.qrCodes);
      if (index != -1) {
        state.qrCodes[index]["url"] = qrCode.url;
        state.qrCodes[index]["design_id"] = qrCode.design_id;
        state.qrCodes[index]["logo_type"] = qrCode.logo_type;
        state.qrCodes[index]["qrDesign"] = qrCode.qrDesign;
        state.qrCodes[index]["qrType"] = qrCode.qrType;
        state.qrCodes[index]["short_code"] = qrCode.short_code;
        state.qrCodes[index]["shortCodeLink"] = qrCode.shortCodeLink;
      }
    },
    updateQrFromListShortCode(state, qrCode) {
      const index = findIndex(propEq("id", qrCode.id))(state.qrCodes);
      if (index != -1) {
        state.qrCodes[index]["url"] = qrCode.url;
        state.qrCodes[index]["shortCodeLink"] = qrCode.shortCodeLink;
        state.qrCodes[index]["status"] = qrCode.status;
      }
    },
    updateQRBatchItem(state, data) {
      const { key, value } = data;
      const { selectedBatchItems } = state;
      if (value) {
        selectedBatchItems[key] = true;
      } else {
        selectedBatchItems[key] = false;
      }
      state.selectedBatchItems = { ...selectedBatchItems };
    },
    clearQRBatchItem(state) {
      state.selectedBatchItems = {};
    },
    updateBatchItemCount(state) {
      const { selectedBatchItems } = state;
      const count = Object.values(selectedBatchItems).filter(v => v).length;
      state.batchItemsCount = count;
    }
  };

  const actions = {
    updateCurrentModel: function ({ commit }, payload) {
      commit("updateCurrentModel", payload);
    },
    setCurrentQrProps: function ({ commit }, payload) {
      commit("setCurrentQrProps", payload);
    },
    resetCurrentQr: function ({ commit }, payload) {
      commit("resetCurrentQr", payload);
    },
    resetCurrentModel: function ({ commit }, payload) {
      commit("resetCurrentModel", payload);
    },
    setSelectedCategory: function ({ commit }, { qrCategory }) {
      commit("setSelectedCategory", qrCategory);
    },
    updateFilter: function ({ commit, dispatch }, filters) {
      commit("setFilters", filters);
      dispatch("fetchQRCodes");
    },
    updateSortBy: function ({ commit, dispatch }, sortBy) {
      commit("setSortBy", sortBy);
      dispatch("fetchQRCodes");
    },
    updateQrDirection: function ({ commit, dispatch }, qr_direction) {
      commit("setQrDirection", qr_direction);
      dispatch("fetchQRCodes");
    },
    // graphQL info
    async fetchQRCodes({ commit, getters }) {
      try {
        commit("setLoading", true);
        const rowCount = getters.getQRCodesRowsCount;
        const page = getters.getQRCodesCurPage;

        let statusFilter = null;
        let typeFilter = null;
        const orderBy = [];
        const { qrStatus } = getters.getQRFilters;
        const qr_direction = getters.getQrDirection;
        const sortBy = getters.getQRSortBy;
        orderBy.push({ column: sortBy, order: qr_direction });

        console.log("[sortBy]", sortBy);

        if (qrStatus != "all") {
          statusFilter = {
            column: "STATUS",
            value: qrStatus == "active" ? 1 : 0
          };
        }

        typeFilter = {
          OR: getters.getQRTypeFilters.map(code => ({
            column: "CODE",
            value: code
          }))
        };

        const { paginateQrCodes } = await queryAPI({
          query: querySentences.paginateQrCodes,
          variables: {
            first: rowCount,
            page,
            where: statusFilter,
            hasQrType: typeFilter,
            orderBy: orderBy
          }
        });
        const { data, paginatorInfo } = paginateQrCodes;
        commit("setQRCodes", data);
        commit("setQRCodesPageCount", paginatorInfo.lastPage);
        commit("setQRCodesTotal", paginatorInfo.total);
        commit("setQRCodesCount", paginatorInfo.count);
      } catch (error) {
        console.log(error);
      } finally {
        commit("setLoading", false);
      }
    },
    setQrUrlLandingPage({ commit }, payload) {
      commit("setQRCodes", payload);
    },

    async createSaveQrCode({ commit, dispatch, getters }, next) {
      const curId = getters.getCurrentQrId;
      const payload = {
        qrCode: {
          ID: parseInt(curId),
          code: JSON.stringify({ ...getters.getCurrentModel }),
          title: getters.getQrCurrentTitle,
          type_id: parseInt(getters.getQrCurrentTypeId),
          save: 1
        },
        next: next
      };
      const result = await dispatch("createQrCode", payload);
      return {
        ...result,
        save: !!curId
      };
    },
    createTemporaryQrCode({ commit, dispatch, getters }) {
      const payload = {
        code: JSON.stringify({ ...this.getCurrentModel }),
        code: JSON.stringify({ ...this.getCurrentModel }),
        title: getters.getQrCurrentTitle,
        save: 1,
        current_qr_title: 1
      };
      dispatch("createQrCode", payload);
    },
    // graphQL info
    async fetchQRFrameTemplates({ commit }) {
      const { qrFrameTemplates } = await queryAPI({
        query: querySentences.qrFrameTemplates
      });
      commit("setFrameTemplates", qrFrameTemplates);
    },
    // graphQL info
    async createQrCode({ commit, dispatch, getters }, payload) {
      try {
        commit("setQRActionLoading", true);
        const { createQrCode } = await mutationAPI({
          // Query
          mutation: mutationSentences.createQr,
          // Parameters
          variables: {
            ...payload.qrCode
          }
        });
        if (createQrCode) {
          commit("setCurrentQrProps", {
            id: createQrCode.id,
            type_id: createQrCode.type_id,
            url: createQrCode.url
          });
          if (payload.next) {
            if (getters.getWidgetMode) {
              if (!parseInt(getters.getQrCurrentDesign.id)) {
                dispatch("openDesign", {
                  qrCode: createQrCode
                });
              }
            } else {
              dispatch("openDesign", {
                qrCode: createQrCode
              });
            }

            if (getters.getEditorMode) {
              dispatch("changeCurrentStep", "edit_modal");
            }
          }
        }
        return {
          payload,
          createQrCode
        };
      } catch (error) {
        console.log(error);
      } finally {
        commit("setQRActionLoading", false);
      }
    },
    async fetchQrDetails({ commit, getters }, { id, callBackFunction }) {
      const { qrCode } = await queryAPI({
        query: querySentences.qrCode,
        // Parameters
        variables: {
          ID: id
        }
      });
      if (qrCode) {
        const {
          qrType,
          meta,
          id,
          type_id,
          title,
          url,
          created_at,
          updated_at
        } = qrCode;
        commit("setCurrentQrProps", {
          id,
          type_id,
          title,
          url,
          created_at,
          updated_at
        });
        if (meta) {
          commit("setCurrentModel", JSON.parse(meta));
        }
        if (callBackFunction) {
          callBackFunction({ qrCode: qrCode });
        }
      }
    },
    async changeShortCode({ commit, dispatch }, { id, short_code, swal }) {
      const response = await mutationAPI({
        // Query
        mutation: mutationSentences.updateQrCode,
        // Parameters
        variables: {
          id,
          short_code
        }
      });
      if (response.updateQrCode.success) {
        var props = [
          {
            key: "short_code",
            value: response.updateQrCode.qrCode.short_code
          },
          {
            key: "shortCodeLink",
            value: response.updateQrCode.qrCode.shortCodeLink
          }
        ];
        dispatch("previewChangesMultiple", props);
        commit("updateQrFromListShortCode", response.updateQrCode.qrCode);
      } else {
        swal({
          text: response.updateQrCode.message,
          icon: "error"
        });
      }
    },
    async changeQRCodesAttribute({ commit, dispatch }, { id, ...payload }) {
      const response = await mutationAPI({
        // Query
        mutation: mutationSentences.updateQrCode,
        // Parameters
        variables: {
          id,
          ...payload
        }
      });
      if (response.updateQrCode.success) {
        // var props = [
        //   {
        //     key: "short_code",
        //     value: response.updateQrCode.qrCode.short_code
        //   }
        // ];
        // dispatch("previewChangesMultiple", props);
        dispatch("fetchQRCodes");
      } else {
        swal({
          text: response.updateQrCode.message,
          icon: "error"
        });
      }
    },
    async deleteQrCode({ commit, dispatch }, { id, swal }) {
      const { deleteQrCode: deleteCode } = await mutationAPI({
        // Query
        mutation: mutationSentences.deleteQrCode,
        // Parameters
        variables: {
          ID: id
        }
      });
      if (deleteCode.success) {
        commit("removeQrFromList", { id: id });
        swal({
          text: deleteCode.message,
          icon: "success"
        });
      } else {
        swal({
          text: deleteCode.message,
          icon: "error"
        });
      }
    },
    fetchDesignDetails({ commit, dispatch }, response) {
      if (response.qrCode) {
        if (!response.qrCode.qrDesign) {
          dispatch("setQrCurrentDesignToDefault");
          const props = [
            {
              key: "qr_code_id",
              value: parseInt(response.qrCode.id)
            },
            {
              key: "qr_url",
              value: response.qrCode.url
            },
            {
              key: "short_code",
              value: response.qrCode.short_code
            },
            {
              key: "shortCodeLink",
              value: response.qrCode.shortCodeLink
            }
          ];
          dispatch("previewChangesMultiple", props);
        } else {
          dispatch("setQrCurrentDesignToDefault");
          const qrDesign = response.qrCode.qrDesign;
          const props = [
            {
              key: "id",
              value: qrDesign?.id
            },
            {
              key: "frame_id",
              value: qrDesign?.frame_id
            },
            {
              key: "color",
              value: qrDesign?.frame?.color
            },
            {
              key: "frame_template_id",
              value: qrDesign?.frame_template_id
            },
            {
              key: "qr_code_id",
              value: parseInt(response.qrCode.id)
            },
            {
              key: "pattern_id",
              value: qrDesign?.pattern_id
            },
            {
              key: "background_color",
              value: qrDesign?.background_color
            },
            {
              key: "logo_type",
              value: qrDesign?.logo_type
            },
            {
              key: "logo_template_id",
              value: qrDesign?.logo_template_id
            },
            {
              key: "logo_id",
              value: qrDesign?.logo_id
            },
            {
              key: "foreground_color",
              value: qrDesign?.foreground_color
            },
            {
              key: "outer_corner_color",
              value: qrDesign?.outer_corner_color
            },
            {
              key: "inner_corner_color",
              value: qrDesign?.inner_corner_color
            },
            {
              key: "short_code",
              value: response?.qrCode?.short_code
            },
            {
              key: "shortCodeLink",
              value: response?.qrCode?.shortCodeLink
            },
            {
              key: "shortCodeLink",
              value: response?.qrCode?.shortCodeLink
            },
            {
              key: "qr_url",
              value: response?.qrCode?.url
            },
            {
              key: "text",
              value: qrDesign?.frame?.text
            },
            {
              key: "svg_text",
              value: qrDesign?.frame?.svg_text
            },
            {
              key: "text_color",
              value: qrDesign?.frame?.text_color
            },
            {
              key: "text_font",
              value: qrDesign?.frame?.text_font
            },
            {
              key: "text_font_url",
              value: qrDesign?.frame?.text_font_url
            }
          ];
          dispatch("previewChangesMultiple", props);
        }
      }
    },
    updateCurrentQrType({ commit, getters }) {
      const categories = getters.getCategories;
      const catCode = getters.getSelectedCategory;
      // console.log("[updateCurrentQrType]", categories, catCode);
      if (categories && categories.length) {
        const category = categories.find(c => c.code == catCode);
        if (category && category.id) {
          commit("setCurrentQrProps", {
            type_id: category.id
          });
        }
      }
    },
    applyQRBatchItemUpdate({ commit }, data) {
      commit("updateQRBatchItem", data);
      commit("updateBatchItemCount");
    },
    async batchQrCodesActions({ getters, commit, dispatch }, action_type) {
      const selectedItemKeys = getters.getBatchItemList.map(v => v.key);
      const rowCount = getters.getQRCodesRowsCount;
      const page = getters.getQRCodesCurPage;

      const response = await mutationAPI({
        // Query
        mutation: mutationSentences.batchQrCodesActions,
        // Parameters
        variables: {
          action_type,
          ids: selectedItemKeys
        },
        refetchQueries: () => [
          {
            query: querySentences.paginateQrCodes,
            variables: {
              first: rowCount,
              page
            }
          }
        ],
        awaitRefetchQueries: true
      });
      console.log(response);

      if (response.batchQrCodesActions.success) {
        dispatch("fetchQRCodes");
      } else {
        // swal({
        //   text: response.updateQrCode.message,
        //   icon: "error"
        // });
        console.log();
      }
      commit("clearQRBatchItem");
      commit("updateBatchItemCount");
    },

    routerActions({ commit, dispatch, getters }, payload) {
      const { page, params } = payload;
      switch (page) {
        case "homePage":
          dispatch("resetCurrentQr", {});
          dispatch("resetCurrentModel", {});
          dispatch("setSelectedCategory", "");
          dispatch("fetchQRCodes");

          break;
        case "new":
          dispatch("resetCurrentQr", {});
          dispatch("resetCurrentModel", {});
          dispatch("fetchQrCategories");
          break;
        case "newDetail":
          const { category, id } = params;
          dispatch("updateSelectedCategory", category);
          if (id) {
            dispatch("fetchQrDetails", { id });
          } else {
            const defaultModel = getters.getDefaultCategoryModel;
            commit("setCurrentModel", defaultModel);
          }
          break;
        case "management": {
          const { id } = params;
          if (id) {
            dispatch("fetchQrDetails", { id });
            dispatch("fetchAnalysisData", { id });
          } else {
            const defaultModel = getters.getDefaultCategoryModel;
            commit("setCurrentModel", defaultModel);
          }
          break;
        }
      }
    }
  };

  return {
    state,
    getters,
    mutations,
    actions
  };
};
export default getModule;
