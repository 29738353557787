<svg
  xmlns="http://www.w3.org/2000/svg"
  width="13.477"
  height="8.239"
  viewBox="0 0 13.477 8.239"
>
  <path
    id="Path_368"
    data-name="Path 368"
    d="M4307.732,2048.141l4.617,4.617,4.618-4.617"
    transform="translate(4319.088 2054.258) rotate(180)"
    fill="none"
    stroke="#fff"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="3"
  />
</svg>
