<svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 407.31 407.31"
	style="enable-background:new 0 0 407.31 407.31;" xml:space="preserve">
	<g>
		<g>
			<path d="M203.652,241.068c-33.646,0-61.019,27.371-61.019,61.016c0,33.646,27.371,61.019,61.019,61.019
			c33.645,0,61.018-27.371,61.018-61.019C264.669,268.439,237.296,241.068,203.652,241.068z M203.652,335.617
			c-18.486,0-33.531-15.045-33.531-33.533c0-18.486,15.045-33.531,33.531-33.531c18.494,0,33.531,15.045,33.531,33.531
			C237.183,320.572,222.146,335.617,203.652,335.617z" />
			<path d="M203.652,174.603c-40.578,0-78.728,15.81-107.42,44.503c-5.367,5.367-5.367,14.064,0,19.432
			c2.686,2.686,6.2,4.027,9.717,4.027c3.518,0,7.033-1.342,9.717-4.027c23.5-23.506,54.75-36.45,87.986-36.45
			c33.242,0,64.486,12.944,87.992,36.45c5.36,5.369,14.071,5.369,19.434,0c5.367-5.367,5.367-14.064,0-19.432
			C282.383,190.411,244.236,174.603,203.652,174.603z" />
			<path d="M47.832,170.696c-5.369,5.368-5.369,14.064,0,19.433c5.361,5.368,14.064,5.368,19.434,0
			c75.209-75.195,197.572-75.195,272.781,0c2.685,2.685,6.199,4.026,9.718,4.026c3.516,0,7.03-1.342,9.715-4.026
			c5.369-5.368,5.369-14.064,0-19.433C273.56,84.778,133.75,84.778,47.832,170.696z" />
			<path d="M403.285,126.898c-53.328-53.326-124.223-82.691-199.633-82.691S57.346,73.572,4.027,126.898
			c-5.369,5.368-5.369,14.065,0,19.434c5.366,5.368,14.069,5.368,19.432,0c48.135-48.134,112.123-74.639,180.193-74.639
			s132.065,26.505,180.199,74.639c2.684,2.684,6.198,4.026,9.717,4.026c3.516,0,7.031-1.343,9.717-4.026
			C408.652,140.963,408.652,132.266,403.285,126.898z" />
		</g>
	</g>
</svg>