<div>
  <button
    ref="optionsBtn"
    type="button"
    class="flex ml-auto bg-qr_color-gray_3 h-12 px-5 py-2.5 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent flex items-center w-auto capitalize"
  >
    {{inputValue || 'Options'}}
    <svg
      class="ml-2 w-4 h-4"
      :class="optionsDropdown && optionsDropdown._visible? 'rotate-180' : ''"
      aria-hidden="true"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M19 9l-7 7-7-7"
      ></path>
    </svg>
  </button>
  <div ref="optionsMenu" class="z-10 w-40 bg-white rounded shadow block hidden">
    <ul class="py-1 text-sm text-gray-700 dark:text-gray-200">
      <li class="flex items-center rounded hover:bg-qr_color-blue_3">
        <button
          type="button"
          class="p-2 w-full text-sm font-medium text-gray-900 rounded peer-checked:bg-qr_color-blue_3 text-qr_color-gray_2 cursor-pointer text-left"
          @click="inputValue='hour'"
        >
          {{$t('time_unit.hour')}}
        </button>
      </li>
      <li class="flex items-center rounded hover:bg-qr_color-blue_3">
        <button
          type="button"
          class="p-2 w-full text-sm font-medium text-gray-900 rounded peer-checked:bg-qr_color-blue_3 text-qr_color-gray_2 cursor-pointer text-left"
          @click="inputValue='day'"
        >
          {{$t('time_unit.day')}}
        </button>
      </li>
      <li class="flex items-center rounded hover:bg-qr_color-blue_3">
        <button
          type="button"
          class="p-2 w-full text-sm font-medium text-gray-900 rounded peer-checked:bg-qr_color-blue_3 text-qr_color-gray_2 cursor-pointer text-left"
          @click="inputValue='month'"
        >
          {{$t('time_unit.month')}}
        </button>
      </li>
    </ul>
  </div>
</div>
