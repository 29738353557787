import gql from "graphql-tag";

const querySentences = {
  // queries
  fetchQRTypes: gql`
    query qrTypesWithAction($action: QrTypeAction, $withAction: Boolean!) {
      qrTypesByAction(action: $action) {
        id
        name
        code
        icon
        description
        action @include(if: $withAction)
      }
    }
  `,
  fetchQrCodeImageUrl: gql`
    query QrCodeUrlById($id: ID!) {
      qrCodeUrlById(id: $id)
    }
  `,
  qrLogoTemplates: gql`
    query qrLogoTemplates {
      qrLogoTemplates {
        id
        url
      }
    }
  `,
  qrLogoTemplatesByLocale: gql`
    query qrLogoTemplatesByLocale($locale: String!) {
      qrLogoTemplatesByLocale(locale: $locale) {
        id
        url
      }
    }
  `,
  // query
  qrPatterns: gql`
    query qrPatterns {
      qrPatterns {
        id
        type
        url
      }
    }
  `, // query

  qrConfig: gql`
    query qrConfig {
      qrConfig {
        storageBaseUrl
        storageBasePath
      }
    }
  `,

  qrLogos: gql`
    query qrLogos {
      qrLogos {
        id
        url
      }
    }
  `,
  // query
  paginateQrCodes: gql`
    query PaginateQrCodes(
      $first: Int!
      $page: Int!
      $where: QueryPaginateQrCodesWhereWhereConditions
      $hasQrType: QueryPaginateQrCodesHasQrTypeWhereHasConditions
      $orderBy: [QueryPaginateQrCodesOrderByOrderByClause!]
    ) {
      paginateQrCodes(
        first: $first
        page: $page
        where: $where
        hasQrType: $hasQrType
        orderBy: $orderBy
      ) {
        data {
          id
          title
          url
          design_id
          short_code
          shortCodeLink
          uniqueScans
          totalScans
          updated_at
          created_at
          meta
          status
          qrType {
            id
            name
            code
            icon
            action
            order_number
          }
          qrDesign {
            id
            background_color
            foreground_color
            frame_id
            frame_template_id
            logo_template_id
            inner_corner_color
            outer_corner_color
            logo_id
            logo_type
            pattern_id
            rotation
            saved
            frame {
              id
              color
              text
              svg_text
              text_color
              text_font
              text_font_url
            }
          }
        }
        paginatorInfo {
          currentPage
          lastPage
          perPage
          count
          total
        }
      }
    }
  `,
  qrFrameTemplates: gql`
    query qrFrameTemplates {
      qrFrameTemplates {
        id
        url
        code
        icon_filename
        frame_filename
        text_color
        color
        text
        text_font
        text_font_url
      }
    }
  `,
  // query
  userInfo: gql`
    query me {
      me {
        id
        name
        email
        is_guest
      }
    }
  `,

  qrCode: gql`
    query qrCode($ID: ID!) {
      qrCode(id: $ID) {
        id
        title
        url
        type_id
        design_id
        short_code
        shortCodeLink
        updated_at
        created_at
        meta
        qrType {
          id
          name
          code
          icon
          action
          order_number
        }
        qrDesign {
          id
          background_color
          foreground_color
          frame_id
          frame_template_id
          logo_template_id
          inner_corner_color
          outer_corner_color
          logo_id
          logo_type
          pattern_id
          rotation
          saved
          frame {
            id
            color
            text
            svg_text
            text_color
            text_font
            text_font_url
          }
        }
      }
    }
  `,

  qrStats: gql`
    query qrStats($qrCodeId: ID, $options: QRStatInput) {
      qrStats(qr_code_id: $qrCodeId, options: $options) {
        totalScans
        uniqueScans
        platforms {
          key
          num
        }
        cities {
          key
          num
        }
        countries {
          key
          num
        }
        overtimes {
          viewed_at
          visitTime
          uniqueCounts
          totalCounts
        }
      }
    }
  `
};
const mutationSentences = {
  createDesign: gql`
    mutation createDesign(
      $ID: ID
      $logo_id: Int
      $logo_template_id: Int
      $qr_code_id: Int!
      $frame_template_id: Int
      $pattern_id: Int
      $background_color: String
      $logo_type: String
      $foreground_color: String
      $color: String
      $text_color: String
      $svg_text: String
      $text: String
      $inner_corner_color: String
      $outer_corner_color: String
    ) {
      createDesign(
        id: $ID
        logo_id: $logo_id
        logo_template_id: $logo_template_id
        qr_code_id: $qr_code_id
        pattern_id: $pattern_id
        frame_template_id: $frame_template_id
        background_color: $background_color
        logo_type: $logo_type
        foreground_color: $foreground_color
        color: $color
        text_color: $text_color
        svg_text: $svg_text
        text: $text
        inner_corner_color: $inner_corner_color
        outer_corner_color: $outer_corner_color
      ) {
        svgContent
        qrCode {
          id
          title
          url
          type_id
          design_id
          short_code
          shortCodeLink
          updated_at
          created_at
          meta
          qrType {
            id
            name
            code
            icon
            action
            order_number
          }
          qrDesign {
            id
            background_color
            foreground_color
            frame_id
            frame_template_id
            logo_template_id
            inner_corner_color
            outer_corner_color
            logo_id
            logo_type
            pattern_id
            rotation
            saved
            frame {
              id
              color
              text
              svg_text
              text_color
              text_font
              text_font_url
            }
          }
        }
      }
    }
  `,
  uploadFileBlob: gql`
    mutation uploadFileBlob($blob: String!, $name: String!) {
      uploadFileBlob(blob: $blob, name: $name) {
        id
        base_path
      }
    }
  `,
  uploadFile: gql`
    mutation uploadFile($file: Upload!) {
      uploadFile(file: $file) {
        id
        base_path
        filename
        original_filename
        width
        height
      }
    }
  `,
  uploadLogo: gql`
    mutation uploadLogo($file: Upload!) {
      uploadLogo(file: $file) {
        id
        base_path
        url
      }
    }
  `,
  deleteUploadLogo: gql`
    mutation deleteUploadLogo($ID: ID) {
      deleteUploadLogo(id: $ID) {
        id
        url
      }
    }
  `,
  updateQrCode: gql`
    mutation updateQrCode($id: ID!, $short_code: String, $status: Int) {
      updateQrCode(id: $id, short_code: $short_code, status: $status) {
        success
        message
        qrCode {
          id
          title
          url
          type_id
          shortCodeLink
          design_id
          short_code
          shortCodeLink
          updated_at
          created_at
          meta
          status
        }
      }
    }
  `,
  resetQrCode: gql`
    mutation resetQRStats($ID: ID) {
      resetQRStats(id: $ID) {
        message
      }
    }
  `,
  // mutations
  loginMutation: gql`
    mutation Login(
      $email: String!
      $password: String!
      $recaptcha: String
      $device: String!
      $oldToken: String
    ) {
      login(
        email: $email
        password: $password
        recaptcha: $recaptcha
        device: $device
        oldToken: $oldToken
      )
    }
  `,

  registerUserMutation: gql`
    mutation registerUser(
      $email: String!
      $name: String!
      $password: String!
      $confirm_password: String!
      $device: String!
      $oldToken: String
      $recaptcha: String
    ) {
      registerUser(
        email: $email
        name: $name
        password: $password
        confirm_password: $confirm_password
        device: $device
        oldToken: $oldToken
        recaptcha: $recaptcha
      )
    }
  `,

  createGuestUserMutation: gql`
    mutation CreateGuestUser($device: String!) {
      createGuestUser(device: $device)
    }
  `,
  createQr: gql`
    mutation createQrCode(
      $ID: ID
      $title: String!
      $type_id: Int
      $code: String
      $short_code: String
      $save: Int
    ) {
      createQrCode(
        id: $ID
        title: $title
        type_id: $type_id
        code: $code
        short_code: $short_code
        save: $save
      ) {
        id
        title
        url
        type_id
        design_id
        short_code
        shortCodeLink
        updated_at
        created_at
        meta
        qrType {
          id
          name
          code
          icon
          action
          order_number
        }
        qrDesign {
          id
          background_color
          foreground_color
          frame_id
          frame_template_id
          logo_template_id
          inner_corner_color
          outer_corner_color
          logo_id
          logo_type
          pattern_id
          rotation
          saved
          frame {
            id
            color
            text
            svg_text
            text_color
            text_font
            text_font_url
          }
        }
      }
    }
  `,
  batchQrCodesActions: gql`
    mutation BatchQrCodesActions($ids: [ID!], $action_type: String!) {
      batchQrCodesActions(ids: $ids, action_type: $action_type) {
        success
        message
        items {
          id
          result
        }
      }
    }
  `,

  deleteQrCode: gql`
    mutation deleteQrCode($ID: ID) {
      deleteQrCode(id: $ID) {
        success
        message
      }
    }
  `
};
export { querySentences, mutationSentences };
