<div class="relative flex flex-col items-center">
  <button type="button" class="flex px-16 text-white bg-qr_color-blue_1 p-2 rounded-qr_input_radius"
    @click="$refs.file.click()">
    <span class="m-auto"> {{$t('qr_upload.upload')}}</span>
  </button>
  <input ref="file" class="cursor-pointer absolute block opacity-0 invisible" type="file" name="vacancyImageFiles"
    @change="onChangeFileName" />
  <button v-if="!isEmptyOrDefaultImage" type="button"
    class="mt-2 inline-flex px-1 py-1.5 text-sm text-qr_color-blue_1 underline" @click="onRemoveClicked">
    {{$t('qr_upload.remove_image')}}
  </button>
</div>