import baseField from "vue-formly-bootstrap/src/fields/baseField";

import WithRender from "./QRInputSubHeadline.html";

export default WithRender({
  mixins: [baseField],
  props: ["field"],
  computed: {
    subheadline() {
      return this?.field?.templateOptions?.subheadline ?? "";
    },
    subheadlineHtml() {
      return this?.field?.templateOptions?.subheadlineHtml ?? "";
    }
  }
});
