export const createDynamicTextFieldsWidget = (formly, t) => {
  return [
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_url",
        fold: false,
        headline: "",
        hideCollapse: true,
        icon: null
      }),
      formly.body({
        name: "section_url",
        collapseKey: "section_url",
        widget: 1,
        key: "code",
        content: [
          formly.textAreaRow({
            key: "text",
            label: t("Text"),
            required: true,
            placeholder: t(
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae elit libero, a pharetra augue."
            ),
            maxChar: 300,
            filters: {},
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(t("This field can not be empty"));
                  return false;
                }

                return true;
              }
            }
          })
        ]
      })
    )
  ];
};

export const createDynamicTextRequiredFieldsWidget = _ => {
  return ["text"];
};
