import welcome from "./img/vcards-welcome.png";

export const createDynamicVCardFields = (formly, t) => {
  return [
    formly.labelTitle({
      titleIcon: "AddressIcon",
      titleKey: "qrcode_title",
      titleTooltip: t(
        "Labeling QR Codes has been moved to this area. Names help you to stay organized and will only appear in your account and are not displayed to customers who scan your QR Codes."
      ),
      titlePlaceholder: t("My vCard Plus QR Code"),
      titleValue: null,
      buttonLabel: t("Got it")
    }),
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_design",
        fold: false,
        headline: t("Design & Customize your vCard"),
        icon: "DesignIcon"
      }),
      formly.body({
        name: "section_design",
        collapseKey: "section_design",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t(
              "Personalize your vCard by selecting colors and uploading an image."
            )
          }),
          formly.colorsTemplate({
            customColors: [
              { color1: "#455a64", color2: "#e91e63" },
              { color1: "#0288d1", color2: "#64b5f6" },
              { color1: "#d32f2f", color2: "#ef9a9a" },
              { color1: "#4caf50", color2: "#81c784" },
              { color1: "#795548", color2: "#ff8a65" },
              { color1: "#42a48c", color2: "#e9b764" },
              { color1: "#f564ac", color2: "#36c17d" },
              { color1: "#ff8a65", color2: "#4b5d71" },
              { color1: "#7a1ea1", color2: "#1de9b6" },
              { color1: "#3f51b5", color2: "#ff4081" }
            ]
          }),
          formly.colorsSwitch({ color1: "#455a64", color2: "#e91e63" }),
          formly.colorGradient({
            key: "show_gradient",
            placeholder: t("My vCard Plus QR Code"),
            className: "",
            label: t("Use color gradient"),
            tooltip: t(
              "Combine your Primary color with another one to create a modern gradient effect."
            )
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 1,
        sectionName: "section_information",
        fold: false,
        headline: t("Your Information"),
        icon: "InfoIcon"
      }),
      formly.body({
        name: "section_information",
        collapseKey: "section_information",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t(
              "Fill in your contact details. Not all fields are mandatory."
            )
          }),
          formly.avatarCircle({
            tooltip: t(
              "Select an image as your vCard profile picture. Upload .jpg or .png file from your computer."
            ),
            key: "avatar",
            previewWelcomeScreen: false,
            borderRadius: "50%"
          }),
          formly.rowWith2Inputs({
            label: t("Name"),
            required: true,
            firstInput: formly.inputHalf({
              key: "firstname",
              required: true,
              placeholder: t("First Name"),
              className: "flex-0",
              errorHolder: "#errorLastname",
              condition: model => !(model["firstname"] || model["lastname"]),
              validators: {
                required: function ({ messages, name, value }) {
                  if (value.trim() == "") {
                    messages.push(t("Please enter a first name"));
                    return false;
                  }

                  return true;
                }
              }
            }),
            secondInput: formly.inputHalf({
              key: "lastname",
              required: false,
              placeholder: t("Last Name"),
              className: "flex-0",
              errorHolder: "#errorLastname",
              errorMsg: t("Please enter a first name"),
              condition: model => !(model["firstname"] || model["lastname"])
            })
          }),
          formly.inputRow({
            key: "numbers_mobile",
            label: t("Numbers"),
            placeholder: t("Mobile number")
          }),
          formly.rowWith2Inputs({
            firstInput: formly.inputHalf({
              key: "numbers_phone",
              className: "flex-0",
              placeholder: t("Phone")
            }),
            secondInput: formly.inputHalf({
              key: "numbers_fax",
              className: "flex-0",
              placeholder: t("Fax")
            })
          }),
          formly.inputRow({
            key: "email",
            label: t("Email"),
            placeholder: t("your@email.com")
          }),
          formly.rowWith2Inputs({
            label: t("Company"),
            firstInput: formly.inputHalf({
              key: "company",
              className: "flex-0",
              placeholder: t("Company"),
              condition: model => !(model["firstname"] || model["lastname"])
            }),
            secondInput: formly.inputHalf({
              key: "job",
              className: "flex-0",
              placeholder: t("Your Job")
            })
          }),
          formly.autocompleteInputForm({ wrappedInAddressKey: false }),
          formly.directionsButton({
            key: "show_directions",
            initValue: true,
            placeholder: t("My vCard Plus QR Code"),
            label: t("Show Directions button"),
            hideExpression: model => !model["street"] && !model["city"]
          }),
          formly.inputRow({
            key: "website",
            label: t("Website"),
            placeholder: t("https://www.my-website.com"),
            filters: {
              makeUrl: function ({ value }) {
                if (value.trim() !== "" && !value.match(/:\/\//)) {
                  value = "http" + "://" + value;
                }
                return value;
              }
            }
          }),
          formly.textAreaRow({
            key: "bio",
            label: t("Summary"),
            placeholder: "",
            maxChar: 250
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 2,
        sectionName: "section_social",
        fold: true,
        headline: t("Social Media"),
        icon: "EarthIcon"
      }),
      formly.body({
        name: "section_social",
        collapseKey: "section_social",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Click on the icon to add a social media channel.")
          }),
          formly.socialChannels({
            key: "channels",
            requiredAllChannels: false,
            requiredMessage: t("At least one channel is required"),
            hasLabel: false,
            hasOrder: false,
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(t("This field can not be empty"));
                  return false;
                }

                return true;
              }
            }
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 3,
        sectionName: "section_welcome_screen",
        headline: t("Welcome Screen"),
        icon: "SmartPhoneIcon"
      }),
      formly.body({
        name: "section_welcome_screen",
        collapseKey: "section_welcome_screen",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Display your logo while your page is loading.")
          }),
          formly.welcomeScreen({
            tooltip: t(
              "Upload an image or logo from your computer. Images must be in .jpg or .png format."
            ),
            key: "welcome_screen",
            previewWelcomeScreen: true,
            defaultWelcomeImage: welcome
          })
        ]
      })
    ),
    formly.container_advancedOptions({
      sharingKey: "sharing",
      sharingLabel: t("Add a share button to the page"),
      initValue: false
    })
  ];
};

export const createDynamicVCardRequiredFields = _ => {
  return ["firstname"];
};
