export const createDynamicVideoFieldsWidget = (formly, t) => {
  return [
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_url",
        fold: false,
        headline: "",
        hideCollapse: true,
        icon: null
      }),
      formly.body({
        name: "section_url",
        collapseKey: "section_url",
        widget: 1,
        key: "code",
        content: [
          formly.inputRow({
            label: t("Company"),
            key: "company",
            className: "flex-0",
            placeholder: t("Name of company, organization or channel"),
            errorHolder: "#errorLastname",
            errorMsg: t("Please provide a company.")
          }),
          formly.inputRowRequired({
            key: "headline",
            label: t("Headline"),
            className: "form-input-row form-row-container flex flex-col",
            required: true,
            placeholder: t("Add an engaging title to your page"),
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(t("This field can not be empty"));
                  return false;
                }

                return true;
              }
            }
          }),
          formly.textAreaRow({
            key: "description",
            label: t("Description"),
            placeholder: t(
              "Welcome users to your page or tell them a bit more about your video(s)."
            ),
            maxChar: 200
          }),
          {
            className: "form-row-container flex-col",
            type: "field_group",
            required: true,
            fieldGroup: [
              {
                type: "qr_input_label",
                templateOptions: {
                  label: t("Button")
                }
              },
              {
                className: "col-sm-12 col-md-8 box-input",
                key: "callToAction",
                type: "qraction_button",
                templateOptions: {
                  required: true,
                  key: "callToAction",
                  urlPlaceholder: t("http://"),
                  defaultValue: {
                    label: t("Get Yours"),
                    url: ""
                  },
                  items: [
                    t("Get Yours"),
                    t("Shop online"),
                    t("Learn More"),
                    t("Apply now")
                  ],
                  addLabel: t("Add button"),
                  removeLabel: t("Remove button"),
                  customLabel: t("Custom"),
                  errorMessages: {
                    url: t("Please enter a valid website address"),
                    required: t("This field can not be empty")
                  },
                  filters: {
                    makeUrl: function ({ value }) {
                      if (value.trim() !== "" && !value.match(/:\/\//)) {
                        value = "http" + "://" + value;
                      }
                      return value;
                    }
                  },
                  validators: {
                    required: function ({ messages, name, value }) {
                      if (value.trim() == "") {
                        messages.push(t("This field can not be empty"));
                        return false;
                      }

                      return true;
                    },
                    validUrl: function ({ messages, name, value }) {
                      if (
                        value !== "" &&
                        !value.match(
                          /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                        )
                      ) {
                        messages.push(t("Please enter a valid address."));
                        return false;
                      }
                      return true;
                    }
                  }
                }
              }
            ]
          },
          {
            className: "m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          },
          {
            type: "qryoutube_video_add",
            key: "videos",
            highlightVideoKey: "highlightVideo",
            templateOptions: {
              requiredOneVideo: true,
              requiredMessage: t("Please add at least one video."),
              buttonLabel: t("Add Video"),
              placeHolder: t("https://www.youtube.com/watch...")
            }
          },
          {
            className: "m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          }
        ]
      })
    )
  ];
};

export const createDynamicVideoRequiredFieldsWidget = _ => {
  return ["headline"];
};
