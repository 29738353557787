import WithRender from "./TypeCardItem.html";
import QRIcon from "../../base/form/QRIcon";
import { mapActions, mapGetters } from "vuex";

export default WithRender({
  props: {
    name: {
      Type: String,
      default: ""
    },
    code: {
      Type: String,
      default: ""
    },
    id: {
      Type: BigInt,
      default: 1
    },
    description: {
      Type: String,
      default: ""
    },
    icon: {
      Type: String,
      default: ""
    },
    selected: {
      Type: Boolean,
      default: false
    },
    model: {
      Type: String
    },
    value: {
      Type: String,
      default: ""
    },
    type: {
      Type: String,
      default: ""
    }
  },
  components: {
    QRIcon
  },
  computed: {
    ...mapGetters(["getEditorMode", "changeCurrentStep"]),
    isDisabled() {
      return this.getEditorMode && this.type != "dynamic-vcard-plus";
    },
    disabledClass() {
      if (this.isDisabled) {
        return "disabled";
      }
      return "";
    }
  },
  methods: {
    ...mapActions(["setSelectedCategory"]),
    onClick() {
      if (this.isDisabled) {
        return false;
      }
      this.setSelectedCategory({
        qrCategory: this.type
      });
    }
  }
});
