import WithRender from "./QRHourPicker.html";

import dayjs from "dayjs";

export default WithRender({
  props: {
    mode: {
      type: String,
      default: "24"
    },
    value: {
      type: String,
      default: "07:00"
    }
  },
  data() {
    return {
      timeList: {
        "24": [
          { value: "00:00", label: "00:00" },
          { value: "01:00", label: "01:00" },
          { value: "02:00", label: "02:00" },
          { value: "03:00", label: "03:00" },
          { value: "04:00", label: "04:00" },
          { value: "05:00", label: "05:00" },
          { value: "06:00", label: "06:00" },
          { value: "07:00", label: "07:00" },
          { value: "08:00", label: "08:00" },
          { value: "09:00", label: "09:00" },
          { value: "10:00", label: "10:00" },
          { value: "11:00", label: "11:00" },
          { value: "12:00", label: "12:00" },
          { value: "13:00", label: "13:00" },
          { value: "14:00", label: "14:00" },
          { value: "15:00", label: "15:00" },
          { value: "16:00", label: "16:00" },
          { value: "17:00", label: "17:00" },
          { value: "18:00", label: "18:00" },
          { value: "19:00", label: "19:00" },
          { value: "20:00", label: "20:00" },
          { value: "21:00", label: "21:00" },
          { value: "22:00", label: "22:00" },
          { value: "23:00", label: "23:00" }
        ],
        "12": [
          { value: "12:00 AM", label: "12:00 AM" },
          { value: "01:00 AM", label: "01:00 AM" },
          { value: "02:00 AM", label: "02:00 AM" },
          { value: "03:00 AM", label: "03:00 AM" },
          { value: "04:00 AM", label: "04:00 AM" },
          { value: "05:00 AM", label: "05:00 AM" },
          { value: "06:00 AM", label: "06:00 AM" },
          { value: "07:00 AM", label: "07:00 AM" },
          { value: "08:00 AM", label: "08:00 AM" },
          { value: "09:00 AM", label: "09:00 AM" },
          { value: "10:00 AM", label: "10:00 AM" },
          { value: "11:00 AM", label: "11:00 AM" },
          { value: "12:00 PM", label: "12:00 PM" },
          { value: "01:00 PM", label: "01:00 PM" },
          { value: "02:00 PM", label: "02:00 PM" },
          { value: "03:00 PM", label: "03:00 PM" },
          { value: "04:00 PM", label: "04:00 PM" },
          { value: "05:00 PM", label: "05:00 PM" },
          { value: "06:00 PM", label: "06:00 PM" },
          { value: "07:00 PM", label: "07:00 PM" },
          { value: "08:00 PM", label: "08:00 PM" },
          { value: "09:00 PM", label: "09:00 PM" },
          { value: "10:00 PM", label: "10:00 PM" },
          { value: "11:00 PM", label: "11:00 PM" }
        ]
      },
      dropdown: null
    };
  },
  mounted() {
    const contentTriggerEle = this.$refs.hourPickerBtn;
    const contentTargetEle = this.$refs.hourPicker;
    this.dropdown = new window.Dropdown(contentTargetEle, contentTriggerEle, {
      placement: "bottom"
    });
  },
  computed: {
    getCurrentTimes() {
      const mode = this.mode || "";
      return this.timeList[mode] || [];
    },
    isStandardFormat() {
      return this.mode == "24";
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        let value = dayjs(dayjs().format("YYYY-MM-DD ") + val);
        if (!value.isValid()) {
          value = dayjs(
            dayjs().format("YYYY-MM-DD HH:" + parseInt(Math.random() * 59))
          );
        }

        if (!this.isStandardFormat) {
          value = value.format("hh:mm A");
        } else {
          value = value.format("HH:mm");
        }

        this.$emit("input", value);
      }
    }
  },
  methods: {
    onUpdateTime(time) {
      this.inputValue = time;
      if (this.dropdown) {
        this.dropdown.hide();
      }
    },
    onBlurValue(event) {
      this.inputValue = event.target.value;
    }
  },
  watch: {
    mode: {
      handler(newValue, oldValue) {
        if (oldValue == newValue) return;
        this.inputValue = this.value;
      }
    }
  }
});
