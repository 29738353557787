import { mapActions, mapGetters, mapMutations } from "vuex";

import opentype from "opentype.js";

import WithRender from "./EditQrDesignModal.html";
import CloseIcon from "../../base/icons/CloseIcon";
import DownloadIcon from "../../base/icons/DownloadIcon";
import MoreIcon from "../../base/icons/MoreIcon";
import PrinterIcon from "../../base/icons/PrinterIcon";
import FolderUploadIcon from "../../base/icons/FolderUploadIcon";
import ArrowRightIcon from "../../base/icons/ArrowRightIcon";
import CloudUploadIcon from "../../base/icons/CloudUploadIcon";
import QuestionIcon from "../../base/icons/QuestionIcon";
import PencilIcon from "../../base/icons/PencilIcon";
import ArrowLeftIcon from "../../base/icons/ArrowLeftIcon";
import QRCustomColorPicker from "../../base/form/QRCustomColorPicker";
import { computeBackendEndpoint } from "../../../helpers/envHelpers";
import NextActionFooter from "../../ui/containers/NextActionFooter";
import Spinner from "../../base/components/Spinner";

import { SVG } from "@svgdotjs/svg.js";

import "@svgdotjs/svg.filter.js";

import _ from "lodash";
export default WithRender({
  expose: ["designModalRef"],
  components: {
    ArrowLeftIcon,
    PencilIcon,
    QuestionIcon,
    CloudUploadIcon,
    ArrowRightIcon,
    FolderUploadIcon,
    PrinterIcon,
    MoreIcon,
    DownloadIcon,
    CloseIcon,
    NextActionFooter,
    QRCustomColorPicker,
    Spinner
  },
  data() {
    return {
      designModalRef: null,
      modal: null,
      frameColor: "#000000",
      frameText: "SCAN ME",
      frameColorPickerVisible: false,
      codeColor: "#000000",
      codeBgColor: "#FFFFFF",
      codeOuterColor: "#000000",
      codeInnerColor: "#000000",
      codeColorPickerVisible: false,
      codeBackColorPickerVisible: false,
      codeEdgeOuterPickerVisible: false,
      codeEdgeInnerPickerVisible: false,
      page: "main",
      drawerOpen: false,
      fontObject: null,
      shortURLEditable: false
    };
  },
  created() {
    this.updateColor = _.debounce(this.updateColor, 100);
  },
  mounted() {
    const modalWrapperOptions = {
      placement: "center",
      backdropClasses:
        "bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
    };
    const modalWrapperRef = this.$refs.designModalRef;
    if (modalWrapperRef) {
      this.modal = new window.Modal(modalWrapperRef, modalWrapperOptions);
      if (this.getEditorMode) this.modal.show();
    }
    window.addEventListener("message", event => {
      let data = {};
      if (event.data && (typeof event.data).toLowerCase() == "string") {
        try {
          data = JSON.parse(event.data);
        } catch (e) {}
      }
      if ((typeof data).toLowerCase() === "object") {
        if (data.type && data.type == "edit_qr") {
          if (data.qr_id && data.qr_id) {
            this.fetchQrDetails({
              id: parseInt(data.qr_id)
            });
            this.changeCurrentStep("create_new_detail");
            this.setEditMode(1);
          }
          this.modal.hide();
        }
        if (data.type && data.type == "change-initial-frame") {
          this.changeCurrentStep("create_new");
          this.modal.hide();
        }
      }
    });
  },
  computed: {
    ...mapGetters([
      "getQRFrameTemplates",
      "getQrPatterns",
      "getQrLogos",
      "getQrLogoTemplates",
      "getEditorMode",
      "getEditMode",
      "getQrCurrentSvg",
      "getDefaultFrameId",
      "getLoadingQrModal",
      "getQrCurrentDesign"
    ]),
    getStyleDesignBox() {
      return {
        backgroundImage:
          "url(data:image/svg+xml;base64," + this.getQrCurrentSvg + ")"
      };
    },
    qrFullShortURL() {
      return this.getQrCurrentDesign.shortCodeLink;
    },
    getDownloadSvgUrl() {
      return computeBackendEndpoint(
        this.$printqGlobalOptions.backendBaseUrl,
        "download/downloadDesign/?id=" +
          this.getQrCurrentDesign.id +
          "&type=svg"
      );
    },
    getDownloadPngUrl() {
      return computeBackendEndpoint(
        this.$printqGlobalOptions.backendBaseUrl,
        "download/downloadDesign/?id=" +
          this.getQrCurrentDesign.id +
          "&type=png"
      );
    },
    qrShortUrlId() {
      return this.getQrCurrentDesign.short_code;
    },
    qrShortBaseUrl() {
      return this.$printqGlobalOptions.backendBaseUrl;
    },
    getDownloadJpgUrl() {
      return computeBackendEndpoint(
        this.$printqGlobalOptions.backendBaseUrl,
        "download/downloadDesign/?id=" +
          this.getQrCurrentDesign.id +
          "&type=jpg"
      );
    }
  },
  methods: {
    ...mapActions([
      "fetchQrLogos",
      "previewChanges",
      "addQrLogo",
      "addQrLoadingLogo",
      "removeQrLoadingLogo",

      "previewChangesMultiple",
      "changeCurrentStep",
      "fetchQrDetails",
      "setEditMode",
      "changeShortCode",
      "uploadLogoToServer"
    ]),
    backHandlerEditorMode() {
      if (this.getEditorMode) {
        this.fetchQrDetails({
          id: parseInt(this.getQrCurrentDesign.qr_code_id)
        });
        this.changeCurrentStep("create_new_detail");
      }
    },
    getModal() {
      return this.modal;
    },
    showDownloadOptions() {
      this.drawerOpen = true;
    },
    placeQrInEditorHandler(event) {
      var post_data = {
        url: this.getQrCurrentDesign.qr_url,
        qr_id: this.getQrCurrentDesign.qr_code_id,
        type: "qr_add",
        edit_mode: this.getEditMode,
        svg_content: this.getQrCurrentSvg
      };
      window.parent.postMessage(post_data, "*");
    },
    changeColorLogoHandler(event, type) {
      let key_logo = "logo_template_id";
      if (type == "defined") {
        key_logo = "logo_id";
      }
      this.previewChangesMultiple([
        {
          key: "logo_type",
          value: type
        },
        {
          key: key_logo,
          value: parseInt(event.target.value)
        }
      ]);
    },
    previewChangesHandler(event, key, frame) {
      if (key == "frame_template_id") {
        this.previewChangesMultiple([
          {
            key: key,
            value: parseInt(event.target.value)
          },
          {
            key: "stop",
            value: parseInt(event.target.value)
          },
          {
            key: "text_color",
            value: frame.text_color
          },
          {
            key: "text_font",
            value: frame.text_font
          },
          {
            key: "text_font_url",
            value: frame.text_font_url
          }
        ]);
        let callBackFunction = font => {
          this.changeText(font, this.getQrCurrentDesign.text);
        };
        var params = {
          fontName: this.getQrCurrentDesign.text_font,
          fontUrl: this.getQrCurrentDesign.text_font_url,
          callBackFunction: callBackFunction
        };
        this.$printqTextToPath(params);
      } else {
        this.previewChanges({
          key: key,
          value: parseInt(event.target.value)
        });
      }
    },
    onCloseDialog() {
      if (this.modal) {
        this.modal.hide();
      }
    },
    onFrameColorUpdated(color) {
      var params = {
        key: "color",
        value: this.frameColor
      };
      this.updateColor(params);
    },
    onCodeColorUpdated(color) {
      var params = {
        key: "foreground_color",
        value: this.codeColor
      };
      this.updateColor(params);
    },
    onChangeLogoFile({ target: { validity, files } }) {
      if (validity.valid && files.length > 0) {
        const file = files[0];
        const loadingId = 243;
        this.addQrLoadingLogo(loadingId);
        this.uploadLogoToServer(file)
          .then(data => {
            const { uploadLogo } = data;
            this.addQrLogo({ uploadLogo, loadingId });
          })
          .catch(e => {
            this.removeQrLoadingLogo(loadingId);
          });
      }
    },
    onBackgroundColorUpdated(color) {
      var params = {
        key: "background_color",
        value: this.codeBgColor
      };
      this.updateColor(params);
    },
    onInnerColorUpdated(color) {
      var params = {
        key: "inner_corner_color",
        value: this.codeInnerColor
      };
      this.updateColor(params);
    },
    onOuterColorUpdated(color) {
      var params = {
        key: "outer_corner_color",
        value: this.codeOuterColor
      };
      this.updateColor(params);
    },
    updateColor(params) {
      this.previewChanges(params);
    },
    async onCopyURLToClipboard() {
      await navigator.clipboard.writeText(this.qrFullShortURL);
    },
    onSaveShortURL(event) {
      var target = document.getElementById("qr_short_url");
      if (target && target.value.length) {
        this.changeShortCode({
          id: this.getQrCurrentDesign.qr_code_id,
          short_code: target.value,
          swal: this.$swal
        });
        this.shortURLEditable = false;
      }
    },
    onCancelShortURL() {
      this.shortURLEditable = false;
    },
    changeShortUrlHandler() {
      this.$swal({
        title: "Important note",
        text:
          "Changes to the short URL of an already published QR Code will cause the previous version of the QR Code to be overwritten and it will no longer be scannable.",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes!",
        cancelButtonText: "No!",
        closeOnConfirm: false,
        closeOnCancel: false
      }).then(response => {
        if (response.isConfirmed) {
          this.shortURLEditable = true;
        }
      });
    },
    changeText(font, text) {
      let fontPaths = [];
      fontPaths = font.getPaths(text, 0, 0, 100);
      const pathString = fontPaths
        .map(fontPath => {
          return fontPath.toSVG();
        })
        .join("");
      let svg = SVG();
      svg.clear();
      const bodyGroup = svg.group();
      bodyGroup.svg(pathString).attr({
        fill: this.getQrCurrentDesign.text_color
      });
      const pathLeft = bodyGroup.bbox().x;
      const pathTop = bodyGroup.bbox().y;
      const pathRight = bodyGroup.bbox().x2;
      const pathBottom = bodyGroup.bbox().y2;

      const width = pathRight - pathLeft;
      const height = pathBottom - pathTop;
      svg.attr({
        viewBox: `${pathLeft} ${pathTop} ${width} ${height}`
      });
      svg.attr({
        pathLeft: `${pathLeft}`
      });
      svg.attr({
        pathTop: `${pathTop}`
      });

      svg.size(width, height);
      let params = [
        {
          key: "svg_text",
          value: svg.node.outerHTML
        },
        {
          key: "text",
          value: text
        }
      ];
      this.previewChangesMultiple(params);
    },
    onChangeFrameText(event) {
      const text = event.target.value;
      var callBackFunction = font => {
        this.changeText(font, text);
      };
      var params = {
        fontName: this.getQrCurrentDesign.text_font,
        fontUrl: this.getQrCurrentDesign.text_font_url,
        callBackFunction: callBackFunction
      };
      this.$printqTextToPath(params);
    }
  },
  watch: {
    "getQrCurrentDesign.color": {
      handler(newColor, oldColor) {
        if (!oldColor) {
          this.frameColor = newColor;
        }
      },
      deep: true
    },
    "getQrCurrentDesign.foreground_color": {
      handler(newColor, oldColor) {
        if (!oldColor) {
          this.codeColor = newColor;
        }
      },
      deep: true
    },
    "getQrCurrentDesign.outer_corner_color": {
      handler(newColor, oldColor) {
        this.codeOuterColor = newColor;
      },
      deep: true
    },
    "getQrCurrentDesign.inner_corner_color": {
      handler(newColor, oldColor) {
        this.codeInnerColor = newColor;
      },
      deep: true
    },
    "getQrCurrentDesign.background_color": {
      handler(newColor, oldColor) {
        this.codeBgColor = newColor;
      },
      deep: true
    },
    getQrCurrentDesign: {
      handler(newDesign, oldDesign) {
        if (
          newDesign.text_font != oldDesign.text_font ||
          newDesign.text_color != oldDesign.text_color ||
          newDesign.text_font_url != oldDesign.text_font_url
        ) {
          let callBackFunction = font => {
            this.changeText(font, this.getQrCurrentDesign.text);
          };
          var params = {
            fontName: newDesign.text_font,
            fontUrl: newDesign.text_font_url,
            callBackFunction: callBackFunction
          };
          // this.$printqTextToPath(params);
        }
      },
      deep: true
    }
  }
});
