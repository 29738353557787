import { mapGetters } from "vuex";
import WithRender from "./DetailFormly.html";
import ProfileIcon from "../../../assets/icons/036-profile.svg";
import PhoneIcon from "../../../components/base/icons/PhoneIcon";
import PencilIcon from "../../../assets/icons/006-pencil.svg";
import NextActionFooter from "../../ui/containers/NextActionFooter";
import PointingLeft from "../../../assets/icons/pointing-left.svg";
import PreviewCode from "../sidebar/PreviewCode";
import { computeBackendEndpoint } from "../../../helpers/envHelpers";

import ShareIcon from "../icons/ShareIcon";

export default WithRender({
  props: ["fields", "model", "edit"],
  data() {
    const {
      backendBaseUrl,
      endpoints: { backendTemplatePreview, backendTemplate }
    } = this.$printqGlobalOptions;
    return {
      form: {},
      previewMode: "preview",
      websiteUrl: "",
      qrCodeName: "",
      type: "website",
      template_preview_endpoint: computeBackendEndpoint(
        backendBaseUrl,
        backendTemplatePreview
      ),
      template_endpoint: computeBackendEndpoint(
        backendBaseUrl,
        backendTemplate
      ),
      validation: []
    };
  },
  components: {
    ProfileIcon,
    PencilIcon,
    NextActionFooter,
    PointingLeft,
    PreviewCode,
    PhoneIcon,
    ShareIcon
  },
  computed: {
    ...mapGetters([
      "getQrUrlLandingPage",
      "getCurrentQrId",
      "getCurrentQr",
      "getEditorMode",
      "getEditMode",
      "getCurrentModel",
      "getEditorLocale"
    ]),
    getQrBoxStyle() {
      return {
        backgroundImage: "url(" + this.getQrUrlLandingPage + ")"
      };
    },
    getTemplatePreviewUrl() {
      let url = "";
      if (this.getCurrentQrId) {
        url = this.template_preview_endpoint.replace(
          "{qrCodeId}",
          this.getCurrentQrId ?? ""
        );
      } else {
        url = this.template_endpoint.replace(
          "{qrTypeId}",
          this.getCurrentQr.type_id ?? ""
        );
      }
      if (url.includes("?")) {
        url += "&lang=" + (this.getEditorLocale ?? "en");
      } else {
        url += "?lang=" + (this.getEditorLocale ?? "en");
      }
      return url;
    }
  },
  mounted() {
    const handleQrLandingEvent = e => {
      // if (this?.$refs?.templatePreviewIframe?.contentWindow?.qrChangeData) {
      //   this.$refs.templatePreviewIframe.contentWindow.qrChangeData(
      //     { ...this.getCurrentModel },
      //     null,
      //     true
      //   );
      // }
      if (this?.$refs?.templatePreviewIframe?.contentWindow) {
        this.$refs.templatePreviewIframe.contentWindow.postMessage(
          {
            type: "landingChangeData",
            code: { ...this.getCurrentModel },
            templateType: null,
            demo: true
          },
          "*"
        );
      }
    };
    // window.document.addEventListener(
    //   "qrLandingLoaded",
    //   handleQrLandingEvent,
    //   false
    // );

    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent =
      eventMethod === "attachEvent" ? "onmessage" : "message";
    eventer(messageEvent, e => {
      if (e.data === "qrLandingLoaded" || e.message === "qrLandingLoaded") {
        handleQrLandingEvent();
      }
    });
  },
  methods: {
    onPreviewModeUpdate(mode) {
      if (mode == "qrcode") {
        this.previewMode = mode;
      } else {
        this.previewMode = mode;
      }
    },
    onUpdate(v) {
      this.$emit("update", v);
    },
    onValidate(v) {
      this.validation.push(v);
    },
    onClicked(v) {
      this.$emit("click", v);
    },
    onNextClicked() {
      const submitRef = this.$refs.submitRef;
      if (submitRef) {
        submitRef.click();
      }
    },
    onSubmitForm(e) {
      e.preventDefault();
      this.validation = [];
      const validateEvent = new Event("validate");

      this.$refs.formCard.forEach(element => {
        if (element.hasAttribute("hasValidators")) {
          element.dispatchEvent(validateEvent);
        }
      });

      if (this.validation.length > 0) {
        this.validation.forEach(({ element }) => {
          if (element) {
            element.focus();
          }
        });
        return;
      }

      this.$emit("next");
    },
    onBackClicked() {
      if (this.edit) {
        this.$router.push({ name: "home" });
      } else {
        this.$router.push({ name: "new" });
      }
    }
  }
});
