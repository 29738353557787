<div>
  <MainLayout>
    <div class="container m-auto pb-24 relative">
      <div class="grid grid-flow-col gap-4 pb-4">
        <div class="font-sans text-qr_color-gray_2 font-semibold text-lg">
          <span class="mr-2">{{$t('manage.title')}}:</span>
          <span class="text-qr_color-blue_2 font-bold text-4xl inline-block">{{getTotalScans}}</span>
          <span class="inline-block">/</span>
          <span class="inline-block">{{getUniqueScans}}</span>
          <span class="inline-block"> {{$t('manage.unique')}}</span>
        </div>
      </div>
      <!-- option -->
      <div class="rounded-t-qr_input_radius mt-8 w-full border border-gray-200 p-3 flex justify-between">
        <div>
          <ManageTimeRange v-if="getCurrentUpdatedTime" :start="getCurrentUpdatedTime" v-model="dateRange"
            class="inline-block" />
          <ManageTimeUnit v-model="datetype" class="rounded-qr_input_radius ml-3 inline-block" />
        </div>
        <ManageOptions @menuClicked="onAction" />
      </div>

      <div class="rounded-t-none rounded-qr_input_radius bg-qr_color-blue_3 w-full grid grid-cols-2 p-8">
        <div class="border-0 border-r-1 border-gray-300">
          <ScanOverTimeChart :data="getOvertimes" :unit="datetype" :range="dateRange" :totalScans="getTotalScans" />
        </div>
        <div>
          <ScanByOSChart :data="getPlatformData" />
        </div>
        <div class="col-span-2 my-8 h-px bg-gray-200 border-0 relative" :class="showMore? '' : ''">
          <button type="button"
            class="absolute w-[150px] px-4 bg-qr_color-gray_3 text-sm text-qr_color-blue_1 hover:underline top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex justify-center items-center"
            @click="onShowMoreClick">
            {{showMore? $t('manage_page.more') : $t('manage_page.less')}}
            <svg class="ml-2 w-3 h-3" :class="showMore? '' : 'rotate-180'" aria-hidden="true" fill="none"
              stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
            </svg>
          </button>
        </div>
        <div class="border-0 border-r-1 border-gray-300" v-show="!showMore">
          <ScanByTopCities :data="getCitiesData" />
        </div>
        <div v-show="!showMore">
          <ScanByTopCountries :data="getCountriesData" />
        </div>
      </div>
    </div>

    <div
      class="left-0 bg-qr_color-blue_2 h-20 fixed bottom-0 w-full flex items-center justify-around md:justify-center nextArea">
      <div class="w-full flex flex-row justify-between max-w-7xl px-3">
        <div class="relative left-0 flex">
          <button type="button" class="text-white font-bold backButton" @click="onPreviousClicked">
            <ArrowLeftIcon class="w-4 h-4 fill-current inline-block">
            </ArrowLeftIcon>
            <span>{{$t('next_action_footer.back')}}</span>
          </button>
        </div>
      </div>
    </div>
    <SettingsModal v-model="settingsModal" :visibility="showModal" @hide="showModal=false" />
  </MainLayout>
</div>