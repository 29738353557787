import WithRender from "./Repeater.html";

export default WithRender({
  computed: {
    addRowText() {
      return (this.to && this.to.addRowText) || "Add Row";
    },
    repeatedModel() {
      // plain JSON representation of the model
      // this is so that we can react to changes in the actual model
      // e.g. data changes from a server, programattic updates etc
      return JSON.stringify(this.model[this.field.key]);
    }
  },
  created() {
    // add the subfields from the field definition to this component
    this.subFields = [...this.field.subForm.fields];
  },
  data() {
    return {
      formCount: 0,
      subForms: [],
      subModels: [],
      subFields: []
    };
  },
  mounted() {
    // add an initial row
    this.addRow();
  },
  methods: {
    addRow(model = {}) {
      const outModel = { ...this.field.subForm.model, ...model };
      delete outModel.isTrusted;
      // push a copy of the model definition into the list
      this.subModels.push(outModel);
      // push a blank form into the list
      this.subForms.push({});
      // increment the form count
      this.formCount++;
    },
    removeRow(idx) {
      this.formCount--;
      this.subForms.splice(idx, 1);
      this.subModels.splice(idx, 1);
    }
  },
  props: ["form", "field", "model", "to"],
  watch: {
    repeatedModel(currentModel) {
      // check if we have data coming from an external source that we don't have locally
      if (currentModel !== JSON.stringify(this.subModels)) {
        this.model[this.field.key].forEach(model => {
          this.addRow(model);
        });
      }
    },
    subModels: {
      deep: true,
      handler() {
        // push any changes to our sub models to the actual model
        this.model[this.field.key] = this.subModels;
      }
    }
  }
});
