<nav
  class="bg-white py-2.5 dark:bg-gray-900 max-w-7xl items-center m-auto px-3"
>
  <div class="container flex flex-wrap justify-between items-center mx-auto">
    <router-link to="/" class="flex items-center">
      <PrintQrLogo class="mx-auto h-12 w-auto" alt="PrintQr" />
    </router-link>
    <button
      data-collapse-toggle="navbar-default"
      type="button"
      class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      aria-controls="navbar-default"
      aria-expanded="false"
    >
      <span class="sr-only">{{$t('navbar.open_main_menu')}}</span>
      <svg
        class="w-6 h-6"
        aria-hidden="true"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>
    <div class="hidden w-full md:block md:w-auto" id="navbar-default">
      <ul
        class="flex flex-col y-3 mt-4 bg-gray-50 rounded-lg border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700"
      >
        <li class="flex items-center">
          <router-link
            to="/"
            class="block py-2 pr-4 pl-3 text-qr_color-gray rounded md:bg-transparent md:p-0 dark:text-white"
            aria-current="page"
          >
            {{$t('navbar.home')}}
          </router-link>
        </li>
        <li v-if="0">
          <router-link
            to="/"
            class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
          >
            {{$t('navbar.about')}}</router-link
          >
        </li>
        <li v-if="0">
          <router-link
            to="/"
            class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
          >
            {{$t('navbar.services')}}</router-link
          >
        </li>
        <li v-if="0">
          <router-link
            to="/profile"
            class="block py-2 pr-4 pl-3 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
          >
            {{$t('navbar.profile')}}</router-link
          >
        </li>
        <li>
          <button
            v-if="isAuthenticated"
            class="flex px-6 text-white rounded bg-qr_color-blue_1 p-2 rounded-qr_input_radius"
            @click="onLogOut"
          >
            {{$t('navbar.logout')}}
          </button>
          <router-link
            v-else
            to="/login"
            class="flex px-6 text-white rounded bg-qr_color-blue_1 p-2 rounded-qr_input_radius"
          >
            {{$t('navbar.login')}}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</nav>
