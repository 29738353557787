<div class="relative"
  :class="circleAvatar?'w-28 h-28 rounded-full  ring ring-6 cursor-pointer ring-qr_color-blue_ring  ':'w-48 h-48  mt-4 flex flex-col sm:flex-row gap-x-4  sm:items-center imgContainer'">
  <template v-if="!enableWelcomeImage">
    <div class="absolute w-full h-full top-0 l-0 flex items-center justify-center">
      <Spinner :visible="showLoading" />
    </div>
    <div class="items-center"
      :class="circleAvatar?'':' ring ring-6 cursor-pointer ring-qr_color-blue_ring  rounded-qr_input_radius  overflow-hidden'"
      v-if="imageUrl">
      <img class="w-auto h-28 object-cover bg-center bg-no-repeat bg-cover"
        :class="circleAvatar? 'rounded-full ring ring-6 cursor-pointer ring-qr_color-blue_ring  overflow-hidden':''"
        :src="imageUrl" />
    </div>
    <div v-else class="w-full h-full b">
      <div
        class="w-full h-full text-center text-qr_color-gray font-medium flex justify-center items-center hover:cursor-pointer"
        :class="circleAvatar?'':'  ring ring-6 cursor-pointer ring-qr_color-blue_ring  rounded-qr_input_radius  overflow-hidden '"
        @click="$refs.file.click()">
        <span>
          {{$t('qr_input_avatar.no_image_uploaded')}}
        </span>
      </div>
      <input ref="file" class="cursor-pointer absolute block opacity-0 invisible" type="file" name="vacancyImageFiles"
        @change="onChangeFileName" />
    </div>
  </template>
  <template v-else>
    <div
      class="relative ring ring-6 rounded-qr_input_radius cursor-pointer ring-qr_color-blue_ring mb-2 sm:mb-0 sm:w-1/3 h-44 flex items-center hover:cursor-pointer overflow-hidden min-w-[33.3%]"
      @click="$refs.file.click()" :style="{'background-color':  bgColor}">
      <div class="absolute w-full h-full top-0 l-0 flex items-center justify-center">
        <Spinner :visible="showLoading" />
      </div>
      <img class="bg-center block m-auto max-w-[50%] max-h-[50%]" :src="imageUrl" />
    </div>
    <div class="w-full h-full rounded-sm px-0 text-gray-400">
      <div v-if="0" class="w-full">
        <label class="block text-xs font-medium text-current">{{$t('qr_input_avatar.image_size')}}</label>
        <div class="w-full flex items-center text-qr_color-yellow_icon py-2 pt-1">
          <image-icon class="w-6 h-6 text-qr_color-yellow_icon mr-2"></image-icon>
          <input type="range" min="25" max="150" v-model="imageSize" @change="onChangeImageSize"
            class="w-full h-2 range-sm bg-gray-200 rounded-lg appearance-none cursor-pointer" />
          <image-icon class="w-8 h-8 text-qr_color-yellow_icon ml-2"></image-icon>
        </div>
      </div>
      <div class="w-full">
        <label class="block text-xs font-medium text-current">{{$t('qr_input_avatar.background')}}</label>
        <div class="w-full">
          <ul class="flex gap-x-3 py-2 pt-3">
            <li class="w-10 h-10 relative" v-if="0">
              <input class="sr-only peer" type="radio" value="#FFFFFF" name="qr_avatar_color_input"
                id="qr_input_color_white" :checked="true" />
              <label
                class="flex flex-col p-2 w-full h-full ring ring-6 rounded-qr_input_radius cursor-pointer ring-qr_color-blue_ring peer-checked:ring-qr_color-blue_2 peer-checked:ring-6"
                for="qr_input_color_white" @click="onSelectColor('#FFFFFF')">
                <div class="h-full w-full bg-white rounded-qr_color_radius border-1 border-qr_color-gray"></div>
              </label>
            </li>
            <li class="w-10 h-10 relative" v-if="0">
              <input class="sr-only peer" type="radio" value="#000000" name="qr_avatar_color_input"
                id="qr_input_color_black" />
              <label
                class="flex flex-col p-2 w-full h-full ring ring-6 rounded-qr_input_radius cursor-pointer ring-qr_color-blue_ring peer-checked:ring-qr_color-blue_2 peer-checked:ring-6"
                for="qr_input_color_black" @click="onSelectColor('#000000')">
                <div class="h-full w-full bg-white rounded-qr_color_radius border-1 border-qr_color-gray"></div>
              </label>
            </li>
            <li class="h-8 relative">
              <div class="flex">
                <input type="text"
                  class="bg-qr_color-gray_3 h-10 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent"
                  name="qr_avatar_color_input" v-model="bgColor" />

                <button
                  class="h-9 inline-flex items-center px-1.5 py-1.5 pt-2 rounded-qr_input_radius border-0 absolute right-0"
                  type="button" @click="toggleColorPicker">
                  <span class="w-6 h-6 border-0 rounded-qr_color_radius" :style="`background-color: ${bgColor}`">
                  </span>
                </button>
              </div>

              <div class="absolute top-34 h-full z-50" v-if="colorPickerVisible">
                <QRCustomColorPicker v-model="bgColor" @change="onChangedColor" @close="colorPickerVisible=false" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </template>

  <input ref="file" class="cursor-pointer absolute block opacity-0 pin-r pin-t" type="file" name="vacancyImageFiles"
    @change="onChangeFileName" />

  <div ref="cropperModal" tabindex="-1"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 md:inset-0 h-modal md:h-full">
    <div class="relative p-4 w-full max-w-md h-full md:h-auto">
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div class="w-full p-4">
          <div class="w-full max-h-[20rem] overflow-y-auto">
            <VueCropper ref="cropper" v-show="selectedFile" :src="selectedFile" :initialAspectRatio="aspectRatio"
              :aspectRatio="aspectRatio" alt="Source Image">
            </VueCropper>
          </div>
          <div class="mt-4">
            <div class="ml-auto flex justify-around">
              <button type="button"
                class="relative flex items-center px-10 text-white bg-qr_color-blue_1 p-2 rounded-qr_input_radius"
                @click="onSaveImage">
                {{$t('qr_input_avatar.crop')}}
              </button>
              <button type="button"
                class="relative flex items-center px-10 text-white bg-qr_color-blue_1 p-2 rounded-qr_input_radius"
                @click="onCloseModal">
                {{$t('qr_input_avatar.cancel')}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>