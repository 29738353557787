import welcome from "./img/welcome_app.png";

export const createDynamicAppFields = (formly, t) => {
  return [
    formly.labelTitle({
      titleIcon: "AppIcon",
      titleKey: "qrcode_title",
      titleTooltip: t(
        "Labeling QR Codes has been moved to this area. Names help you to stay organized and will only appear in your account and are not displayed to customers who scan your QR Codes."
      ),
      titlePlaceholder: t("My App"),
      titleValue: null,
      buttonLabel: t("Got it")
    }),
    formly.container(
      formly.header({
        index: 0,
        sectionName: "section_design",
        headline: t("Design & Customization"),
        icon: "DesignIcon"
      }),
      formly.body({
        name: "section_design",
        collapseKey: "section_design",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Personalize your page by selecting some colors.")
          }),
          formly.colorsTemplate({
            shape: "rect",
            customColors: [
              { color1: "#3c4245", color2: "#5a6368" },
              { color1: "#ff5943", color2: "#ff8665" },
              { color1: "#1e94fa", color2: "#2ddeff" },
              { color1: "#964194", color2: "#e162de" },
              { color1: "#f87c4b", color2: "#ffba71" }
            ]
          }),
          formly.colorsSwitch({}),
          {
            className: "my-2",
            template:
              '<hr style="border-color: #eaeaea; margin: 10px 0 -10px;">'
          }
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 1,
        sectionName: "section_basic_info",
        headline: t("App Information"),
        icon: "AppIcon"
      }),
      formly.body({
        name: "section_basic_info",
        collapseKey: "section_basic_info",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t(
              "Provide your App information, upload your App logo and add some context to your page."
            )
          }),
          formly.inputRowRequired({
            key: "name",
            className: "form-input-row form-row-container flex flex-col",
            label: t("App name"),
            required: true,
            placeholder: t("Name of your App"),
            condition: "true",
            errorMsgTitle: t("Please provide an App name."),
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(t("Please provide an App name."));
                  return false;
                }
                return true;
              }
            }
          }),
          formly.inputRow({
            key: "company",
            className: "form-input-row form-row-container flex flex-col",
            label: t("Developer"),
            placeholder: t("Name of App developer")
          }),
          formly.avatarRectangular({
            // tooltip:
            //   "Upload an image or logo from your computer. Images must be at least 180 x 180 px in .jpg or .png format.",
            aspectRatio: 1,
            key: "avatar",
            smallLabel: "180 x 180 px",
            label: t("App logo")
          }),
          formly.inputRow({
            key: "title",
            className: "form-input-row form-row-container flex flex-col",
            label: t("Title"),
            placeholder: t("Add a title to your page")
          }),
          formly.textAreaRow({
            label: t("Description"),
            placeholder: t("Describe your App in a few words"),
            key: "description",
            maxChar: 250
          }),
          formly.inputRow({
            key: "website",
            className: "form-input-row form-row-container flex flex-col",
            label: t("Website"),
            placeholder: t("www.appwebsite.com"),
            filters: {
              makeUrl: function ({ value }) {
                if (value.trim() !== "" && !value.match(/:\/\//)) {
                  value = "http" + "://" + value;
                }
                return value;
              }
            },
            validators: {
              validUrl: function ({ messages, name, value }) {
                if (
                  value !== "" &&
                  !value.match(
                    /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                  )
                ) {
                  messages.push(t("Please enter a valid address."));
                  return false;
                }
                return true;
              }
            }
          }),
          {
            className: "form-row-container flex-col",
            type: "field_group",
            required: true,
            fieldGroup: [
              {
                type: "qr_input_label",
                templateOptions: {
                  label: t("Button")
                }
              },
              {
                className: "col-sm-12 col-md-8 box-input",
                key: "button",
                type: "qraction_button",
                templateOptions: {
                  required: true,
                  key: "button.url",
                  defaultValue: {
                    label: t("Learn more"),
                    url: ""
                  },
                  items: [
                    t("Learn more"),
                    t("Watch the video"),
                    t("View more apps")
                  ],
                  addLabel: t("Add button"),
                  removeLabel: t("Remove button"),
                  customLabel: t("Custom"),
                  filters: {
                    makeUrl: function ({ value }) {
                      if (value.trim() !== "" && !value.match(/:\/\//)) {
                        value = "http" + "://" + value;
                      }
                      return value;
                    }
                  },
                  validators: {
                    required: function ({ messages, name, value }) {
                      if (value.trim() == "") {
                        messages.push(t("This field can not be empty"));
                        return false;
                      }

                      return true;
                    },
                    validUrl: function ({ messages, name, value }) {
                      if (
                        value !== "" &&
                        !value.match(
                          /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                        )
                      ) {
                        messages.push(t("Please enter a valid address."));
                        return false;
                      }
                      return true;
                    }
                  }
                }
              }
            ]
          }
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 2,
        sectionName: "section_app_store_links",
        headline: t("App Store Links"),
        icon: "InfoIcon"
      }),
      formly.body({
        name: "section_app_store_links",
        collapseKey: "section_app_store_links",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t(
              "Type in the links to your App in the App Stores. At least one link has to be provided."
            )
          }),
          formly.appStoreLinks({
            key: "stores",
            requiredAllStores: true,
            filters: {
              makeUrl: function ({ value }) {
                if (value.trim() !== "" && !value.match(/:\/\//)) {
                  value = "http" + "://" + value;
                }
                return value;
              }
            },
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(t("This field can not be empty"));
                  return false;
                }

                return true;
              },
              validUrl: function ({ messages, name, value }) {
                if (
                  value !== "" &&
                  !value.match(
                    /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                  )
                ) {
                  messages.push(t("Please enter a valid address."));
                  return false;
                }
                return true;
              }
            }
          })
        ]
      })
    ),
    formly.container(
      formly.header({
        index: 3,
        sectionName: "section_welcome_screen",
        headline: t("Welcome Screen"),
        icon: "SphereIcon"
      }),
      formly.body({
        name: "section_welcome_screen",
        collapseKey: "section_welcome_screen",
        key: "code",
        content: [
          formly.subHeadLine({
            text: t("Display your logo while your page is loading.")
          }),
          formly.welcomeScreen({
            tooltip: t(
              "Upload an image or logo from your computer. Images must be in .jpg or .png format."
            ),
            key: "welcome_screen",
            previewWelcomeScreen: true,
            defaultWelcomeImage: welcome
          })
        ]
      })
    )
  ];
};

export const createDynamicAppRequiredFields = _ => {
  return ["name"];
};
