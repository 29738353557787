import Vuex from "vuex";

import category from "./modules/category";
import user from "./modules/user";
import qrcode from "./modules/qrcode";
import design from "./modules/design";
import editor from "./modules/editor";
import upload from "./modules/upload";
import widget from "./modules/widget";
import root from "./modules/root";
import analysis from "./modules/analysis";
import { mutationAPI, queryAPI } from "../services/api";

export default function makeStore({ apolloClient }) {
  const $queryAPI = queryAPI(apolloClient);
  const $mutationAPI = mutationAPI(apolloClient);
  return new Vuex.Store({
    state: () => ({
      loading: false,
      loading_qr_modal: false
    }),
    mutations: {
      setLoading(state, value) {
        state.loading = value;
      },
      setLoadingQrModal(state, value) {
        state.loading_qr_modal = value;
      }
    },
    getters: {
      getLoading(state) {
        return state.loading;
      },
      getLoadingQrModal(state) {
        return state.loading_qr_modal;
      }
    },
    modules: {
      category: category($queryAPI, $mutationAPI),
      user: user($queryAPI, $mutationAPI),
      qrcode: qrcode($queryAPI, $mutationAPI),
      design: design($queryAPI, $mutationAPI),
      editor: editor($queryAPI, $mutationAPI),
      widget: widget($queryAPI, $mutationAPI),
      upload: upload($queryAPI, $mutationAPI),
      root: root($queryAPI, $mutationAPI),
      analysis: analysis($queryAPI, $mutationAPI)
    }
  });
}
