const defaultLayout = "default";
const upload_my_account = "upload_my_account";

const layouts = {
  [defaultLayout]: {
    name: "LayoutMatrix"
  }
};

export const getLayoutName = layoutType => {
  if (layouts[layoutType]) {
    return "app" + layouts[layoutType].name;
  }
  return "app" + layouts[defaultLayout].name;
};

export const getMyAccountLayoutName = layoutType => {
  return "app" + layouts[upload_my_account].name;
};
