<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
	y="0px" viewBox="0 0 512.001 512.001" xml:space="preserve">
	<g>
		<path d="M3.921,126.715c-0.225,0.334-0.419,0.68-0.622,1.022c-0.183,0.309-0.377,0.611-0.549,0.929
		c-0.188,0.352-0.348,0.714-0.517,1.071c-0.155,0.329-0.32,0.653-0.461,0.991c-0.144,0.351-0.261,0.709-0.388,1.066
		c-0.13,0.36-0.268,0.715-0.38,1.083c-0.107,0.36-0.188,0.725-0.281,1.088c-0.093,0.372-0.197,0.742-0.273,1.12
		c-0.085,0.424-0.135,0.85-0.194,1.277c-0.047,0.326-0.109,0.645-0.143,0.974c-0.15,1.53-0.15,3.07,0,4.6
		c0.034,0.329,0.095,0.649,0.143,0.974c0.059,0.427,0.109,0.853,0.194,1.277c0.076,0.38,0.18,0.748,0.273,1.12
		c0.093,0.363,0.172,0.728,0.281,1.088c0.112,0.368,0.25,0.723,0.38,1.083c0.127,0.355,0.244,0.714,0.388,1.066
		c0.141,0.338,0.306,0.663,0.461,0.991c0.169,0.358,0.329,0.72,0.517,1.071c0.171,0.318,0.365,0.619,0.549,0.929
		c0.205,0.343,0.399,0.689,0.622,1.022c0.23,0.346,0.487,0.673,0.74,1.005c0.205,0.273,0.394,0.554,0.613,0.821
		c0.489,0.594,1.002,1.168,1.547,1.711L123.18,272.456c4.544,4.546,10.501,6.817,16.457,6.817c5.956,0,11.913-2.271,16.455-6.817
		c9.089-9.087,9.089-23.824,0-32.912L79.458,162.91h409.27c12.853,0,23.273-10.42,23.273-23.273s-10.42-23.273-23.273-23.273H79.458
		l76.634-76.636c9.089-9.087,9.089-23.824,0-32.912c-9.087-9.089-23.824-9.089-32.912,0L6.821,123.177
		c-0.545,0.543-1.06,1.116-1.547,1.711c-0.22,0.267-0.41,0.548-0.613,0.821C4.41,126.041,4.152,126.369,3.921,126.715z" />
		<path d="M511.743,369.091c-0.059-0.427-0.109-0.853-0.194-1.277c-0.076-0.38-0.18-0.748-0.273-1.12
		c-0.093-0.363-0.172-0.728-0.281-1.088c-0.112-0.368-0.25-0.723-0.38-1.083c-0.127-0.355-0.244-0.714-0.388-1.064
		c-0.141-0.338-0.306-0.663-0.461-0.991c-0.169-0.358-0.329-0.72-0.517-1.071c-0.171-0.318-0.365-0.619-0.549-0.929
		c-0.205-0.343-0.399-0.689-0.622-1.022c-0.23-0.346-0.487-0.673-0.74-1.005c-0.205-0.273-0.394-0.554-0.614-0.821
		c-0.487-0.594-1.002-1.168-1.547-1.711L388.818,239.548c-9.087-9.089-23.823-9.089-32.912,0c-9.089,9.087-9.089,23.824,0,32.912
		l76.637,76.631H23.273C10.42,349.092,0,359.512,0,372.364c0,12.853,10.42,23.273,23.273,23.273h409.27l-76.636,76.636
		c-9.089,9.087-9.089,23.824,0,32.912c4.544,4.544,10.501,6.816,16.457,6.816s11.913-2.271,16.455-6.817l116.359-116.359
		c0.545-0.543,1.06-1.116,1.547-1.711c0.22-0.267,0.41-0.548,0.614-0.821c0.251-0.332,0.509-0.659,0.74-1.005
		c0.225-0.334,0.419-0.681,0.622-1.022c0.183-0.309,0.377-0.611,0.549-0.929c0.188-0.352,0.348-0.712,0.517-1.071
		c0.155-0.329,0.32-0.653,0.461-0.991c0.144-0.351,0.261-0.709,0.388-1.066c0.13-0.36,0.268-0.715,0.38-1.083
		c0.107-0.36,0.188-0.725,0.281-1.088c0.093-0.372,0.197-0.742,0.273-1.12c0.085-0.424,0.135-0.85,0.194-1.277
		c0.047-0.326,0.109-0.645,0.143-0.974c0.15-1.53,0.15-3.07,0-4.6C511.851,369.735,511.789,369.415,511.743,369.091z" />
	</g>
</svg>