import { mapActions, mapGetters, mapMutations } from "vuex";
import dayjs from "dayjs";

import WithRender from "./CodeListItem.html";
import ClockIcon from "../../base/icons/ClockIcon";
import LinkIcon from "../../base/icons/LinkIcon";
import PencilIcon from "../../base/icons/PencilIcon";
import MoreIcon from "../../base/icons/MoreIcon";
import PaintIcon from "../../base/icons/PaintIcon";
import ArrowRightIcon from "../../base/icons/ArrowRightIcon";
import DustBinIcon from "../../base/icons/DustBinIcon";
import PauseIcon from "../../base/icons/PauseIcon";
import PlayIcon from "../../base/icons/PlayIcon";
import QRIcon from "../../base/form/QRIcon";

export default WithRender({
  props: ["data"],
  mounted() {
    const dropTriggerEle = this.$refs.dropdownButton;
    const dopTargetEle = this.$refs.dropdownDots;
    this.dropdown = new window.Dropdown(dopTargetEle, dropTriggerEle, {
      placement: "left"
    });

    const contentTriggerEle = this.$refs.contentTooltipBtnRef;
    const contentTargetEle = this.$refs.contentTooltipRef;
    new window.Tooltip(contentTargetEle, contentTriggerEle, {
      placement: "bottom",
      triggerType: "hover"
    });
    const designTriggerEle = this.$refs.designTooltipBtnRef;
    const designTargetEle = this.$refs.designTooltipRef;
    new window.Tooltip(designTargetEle, designTriggerEle, {
      placement: "bottom",
      triggerType: "hover"
    });
  },
  components: {
    ClockIcon,
    LinkIcon,
    ArrowRightIcon,
    PencilIcon,
    PaintIcon,
    MoreIcon,
    DustBinIcon,
    PauseIcon,
    PlayIcon,
    QRIcon
  },
  data() {
    return {
      dropDown: null
    };
  },
  computed: {
    ...mapGetters(["getBatchItems"]),
    id() {
      return this.data.id;
    },
    designId() {
      return this.data.designId;
    },
    title() {
      return this.data.title;
    },
    qrTypeName() {
      return this.data.qrType.name;
    },
    qrTypeIcon() {
      return this.data.qrType.icon;
    },
    qrId() {
      return this.data.id;
    },
    shortCodeLink() {
      return this.data.shortCodeLink;
    },
    uniqueScans() {
      return this.data.uniqueScans;
    },
    totalScans() {
      return this.data.totalScans;
    },
    status() {
      return this.data.status;
    },
    updatedAt() {
      const { updated_at } = this.data;
      return updated_at && dayjs(updated_at).format("MMM DD, YYYY");
    },
    createdAt() {
      const { created_at } = this.data;
      return created_at && dayjs(created_at).format("MMM DD, YYYY");
    },
    link() {
      return this.data.url;
    },
    selected: {
      get() {
        return this.getBatchItems[this.id];
      },
      set(v) {
        this.applyQRBatchItemUpdate({ key: this.id, value: v });
      }
    },
    webSiteLInks() {
      const { short_code } = this.data;
      const website = "qrco.de";

      if (!short_code) {
        return {
          label: "",
          link: ""
        };
      }
      return {
        label: `${website}/${short_code}`,
        link: `https://${website}/${short_code}`
      };
    },
    realWebsiteLink() {
      const { short_code } = this.data;
      const website = "qrco.de";

      if (!short_code) {
        return {
          label: "",
          link: ""
        };
      }
      return {
        label: `${website}/${short_code}`,
        link: `https://${website}/${short_code}`
      };
    }
  },
  methods: {
    ...mapActions([
      "openDesign",
      "deleteQrCode",
      "applyQRBatchItemUpdate",
      "changeQRCodesAttribute"
    ]),
    handleClick(e) {
      if (this.dropDown) {
        this.dropDown.show();
      }
    },
    editQrCodeHandler(id) {
      const { qrType } = this.data;
      this.$router.push({
        path: `/edit/qr/${qrType.code}/${id}`
      });
    },
    async deleteQrCodeHandler(event, id) {
      try {
        const { isConfirmed } = await this.$swal({
          title: "Are you sure ?",
          text: "The Qr will be deleted!",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it !",
          cancelButtonText: "No, cancel !",
          closeOnConfirm: false,
          closeOnCancel: false
        });
        if (isConfirmed) {
          this.deleteQrCode({ id: id, swal: this.$swal });
        }
      } catch (error) {
        console.log(error);
      }
    },
    onSelectedQR() {
      this.$emit("checked", {
        id: this.id,
        value: !this.value
      });
    },
    onOpenDesign() {
      this.openDesign({
        qrCode: this.data
      });
      this.$emit("open", this.id);
    },
    async onActivateItem() {
      try {
        const response = await this.$swal({
          title: "Are you sure ?",
          text: "The Qr will be activated!",
          type: "inform",
          showCancelButton: true,
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          closeOnConfirm: false,
          closeOnCancel: false
        });
        if (response.isConfirmed) {
          this.changeQRCodesAttribute({
            id: this.id,
            status: 1
          });
        }
      } catch (error) {}
    },
    async onDeactivateItem() {
      try {
        const response = await this.$swal({
          title: "Are you sure ?",
          text: "The Qr will be deactivated!",
          type: "inform",
          showCancelButton: true,
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          closeOnConfirm: false,
          closeOnCancel: false
        });
        if (response.isConfirmed) {
          this.changeQRCodesAttribute({
            id: this.id,
            status: 0
          });
        }
      } catch (error) {}
    }
  }
});
