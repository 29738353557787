import baseField from "vue-formly-bootstrap/src/fields/baseField";

import WithRender from "./QRUploadWelcome.html";
import SwapIcon from "../icons/SwapIcon";
import Spinner from "../../base/components/Spinner";
const avatarDefaults = {
  imageSize: 100,
  bgColor: "#ffffff",
  basePath: null
};

export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  components: {
    SwapIcon,
    Spinner
  },
  created() {
    const value = this.model[this.name];
    this.basePath = value?.base_path || this.basePath;
  },
  data() {
    return {
      imageSize: avatarDefaults.imageSize,
      bgColor: avatarDefaults.bgColor,
      basePath: avatarDefaults.basePath
    };
  },
  computed: {
    name() {
      return this.field.key;
    },
    selectorId() {
      return this.field.templateOptions.selectorId;
    },
    aspectRatio() {
      return +this.field.templateOptions.aspectRatio || 0;
    },
    defaultWelcomeImage() {
      return this.field?.templateOptions?.defaultWelcomeImage || null;
    },
    defaultImage() {
      return this?.field?.templateOptions?.defaultImage || null;
    },
    enableWelcomeImage() {
      return this.field?.templateOptions?.welcomeImage || false;
    },
    isEmptyOrDefaultImage() {
      return !this.basePath;
    }
  },
  watch: {
    model: {
      handler(newValue, oldValue) {
        if (oldValue[this.name] == newValue[this.name]) return;
        const value = newValue[this.name];
        this.imageSize = value?.imageSize || avatarDefaults.imageSize;
        this.bgColor = value?.bgColor || avatarDefaults.bgColor;
        this.basePath = value?.base_path || avatarDefaults.basePath;
      },
      deep: true
    }
  },
  methods: {
    onRemoveClicked() {
      const { name, imageSize, bgColor } = this;
      this.$emit("update", {
        name,
        value: {
          imageSize,
          bgColor,
          base_path: null
        }
      });
    },
    onChangeFileName({ target: { validity, files } }) {
      const { selectorId, aspectRatio, name } = this;
      if (validity.valid && files.length > 0) {
        this.$root.$emit("avatarUpload", {
          selectorId,
          file: files[0],
          aspectRatio,
          name
        });
      }
    }
  }
});
