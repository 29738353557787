import { call, mergeDeepRight, forEachObjIndexed } from "ramda";
import opentype, { load } from "opentype.js";
import { callbackify } from "util";
const defaultOptions = {
  backendBaseUrl: "/",
  editorMode: false,
  widgetMode: false,
  recaptchaKey: "",
  youtubeKey: "",
  endpoints: {
    backendTemplatePreview: "qr-app/template/preview/{qrCodeId}",
    backendTemplate: "qr-app/template/{qrTypeId}",
    backendGraphql: "graphql"
  }
};
let loadedFont = {};

export default {
  install(Vue, options) {
    const newOptions = mergeDeepRight(defaultOptions, options);
    Vue.$printqGlobalOptions = newOptions;
    Vue.prototype.$printqGlobalOptions = newOptions;
    Vue.prototype.$printqTextToPath = ({
      fontUrl,
      fontName,
      callBackFunction
    }) => {
      if (loadedFont.hasOwnProperty(fontName)) {
        const font = loadedFont[fontName];
        callBackFunction(font);
      } else {
        opentype.load(fontUrl, (err, font) => {
          callBackFunction(font);
          loadedFont[fontName] = font;
        });
      }
    };
    Vue.prototype.$printGetColors = () => {
      let styleString = `<style> :root{`;
      const mapping = {
        main_color: "--qr_color_blue_2",
        second_color: "--qr_color_blue_1",
        icon_color: "--yellow_icon",
        icon_color: "--yellow_icon",
        input_color: "--qr_color_gray_3",
        title_color: "--qr_color_gray_2",
        subtitle_color: "--qr_color_gray",
        coupon_color: "--qr_color_blue_3",
        unselected_input: "--qr_color_blue_ring"
      };
      const theme_colors = Vue.prototype.$printqGlobalOptions.theme_colors;

      if (theme_colors) {
        forEachObjIndexed((value, key) => {
          if (mapping[key]) {
            styleString += ` ` + mapping[key] + ` : ` + value + `; `;
          }
        }, theme_colors);
      }

      styleString += `}</style>`;
      return styleString;
    };
  }
};
