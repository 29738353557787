<div class="flex items-center">
  <input
    :id="name"
    type="checkbox"
    :name="name"
    class="w-4 h-4 text-qr_color-blue-2 hover:cursor-pointer hover:text-qr_color-blue_2 hover:bg-qr-color-blue_2 border-2 border-qr_color-blue_2 rounded-qr_checkbox_radius"
    v-model="value"
    @change="onUpdateValue"
    :checked="value"
  />
  <label for="checked-checkbox" class="mx-2 text-qr_color-gray text-16"
    >{{label}}</label
  >
  <QuestionIcon
    v-if="0"
    :data-tooltip-target="'tooltip-' + label"
    class="w-4 h-4 inline-block text-gray-400 hover:text-gray-500 hover:cursor-pointer"
  />
  <div
    v-if="toolTip"
    :id="'tooltip-' + label"
    role="tooltip"
    class="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip max-w-[240px]"
  >
    {{toolTip}}
    <div class="tooltip-arrow" data-popper-arrow></div>
  </div>
</div>
