import WithRender from "./QRDatePicker.html";

import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import localizedFormat from "dayjs/plugin/localizedFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isToday);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
let handleOutsideClick;

export default WithRender({
  directives: {
    closable: {
      // https://github.com/TahaSh/vue-closable // resource
      bind(el, binding, vnode) {
        // Here's the click/touchstart handler
        // (it is registered below)
        handleOutsideClick = e => {
          e.stopPropagation();
          // Get the handler method name and the exclude array
          // from the object used in v-closable
          const { handler, exclude } = binding.value;
          // This variable indicates if the clicked element is excluded
          let clickedOnExcludedEl = false;
          if (exclude) {
            exclude.forEach(refName => {
              // We only run this code if we haven't detected
              // any excluded element yet
              if (!clickedOnExcludedEl) {
                // Get the element using the reference name
                const excludedEl = vnode.context.$refs[refName];
                // See if this excluded element
                // is the same element the user just clicked on
                clickedOnExcludedEl = excludedEl
                  ? excludedEl.contains(e.target)
                  : false;
              }
            });
          }
          // We check to see if the clicked element is not
          // the dialog element and not excluded
          if (clickedOnExcludedEl && vnode.context.autoClose) {
            vnode.context[handler]();
          }
          if (!el.contains(e.target) && !clickedOnExcludedEl) {
            // If the clicked element is outside the dialog
            // and not the button, then call the outside-click handler
            // from the same component this directive is used in
            vnode.context[handler]();
          }
        };
        // Register click/touchstart event listeners on the whole page
        document.addEventListener("click", handleOutsideClick);
        document.addEventListener("touchstart", handleOutsideClick);
      },
      unbind() {
        // If the element that has v-closable is removed, then
        // unbind click/touchstart listeners from the whole page
        document.removeEventListener("click", handleOutsideClick);
        document.removeEventListener("touchstart", handleOutsideClick);
      }
    }
  },
  props: {
    init: {
      type: Boolean,
      required: false,
      default: true
    },
    selectedDate: {
      type: String,
      required: false
    },
    startDate: {
      type: String,
      required: false
    },
    endDate: {
      type: String,
      required: false,
      default: undefined
    },
    // Next future
    disableDate: {
      type: Array,
      required: false,
      default: () => []
    },
    eventDate: {
      type: Array,
      required: false,
      default: () => []
    },
    formatDate: {
      type: String,
      required: false,
      default: "YYYY-MM-DD"
    },
    // Confused with this
    formatDisplay: {
      type: String,
      required: false,
      default: "YYYY-MM-DD"
    },
    inline: {
      type: Boolean,
      required: false,
      default: false
    },
    // Not make sure with this
    tailwindPickerValue: {
      type: String,
      required: false,
      default: ""
    },
    // Next future
    dateRange: {
      type: Boolean,
      required: false,
      default: false
    },
    // Next future
    autoClose: {
      type: Boolean,
      required: false,
      default: true
    },
    startFromMonday: {
      type: Boolean,
      required: false,
      default: false
    },
    theme: {
      type: Object,
      required: false,
      default: () => ({
        background: "#FFFFFF",
        text: "text-gray-700",
        border: "border-gray-200",
        currentColor: "text-gray-200",
        navigation: {
          background: "bg-gray-100",
          hover: "hover:bg-gray-200",
          focus: "bg-gray-200"
        },
        picker: {
          rounded: "rounded-full",
          selected: {
            background: "bg-red-500",
            border: "border-red-500",
            hover: "hover:border-red-500"
          },
          holiday: "text-red-400",
          weekend: "text-green-400",
          event: "bg-indigo-500"
        },
        event: {
          border: "border-gray-200"
        }
      })
    }
  },
  data() {
    const startDatepicker = this.startDate
      ? dayjs(this.startDate, this.formatDate)
      : dayjs();
    // Featured for my own project
    //   .add(
    //   dayjs().hour() >= 20 ? 1 : 0,
    //   'day',
    // )
    const endDatepicker = this.endDate
      ? dayjs(this.endDate, this.formatDate)
      : undefined;
    const today =
      this.selectedDate && this.startDate < this.selectedDate
        ? dayjs(this.selectedDate, this.formatDate)
        : dayjs(startDatepicker, this.formatDate);
    const months = Array.from(Array(12), (v, i) => {
      return dayjs().month(i).format("MMMM");
    });
    const years = Array.from(
      Array(
        this.endDate
          ? endDatepicker.diff(today, "year") + 1
          : today.diff(today, "year") + 36
      ),
      (v, i) => {
        return today.add(i, "year").$y;
      }
    );
    const visibleMonth = false;
    const visibleYear = false;
    const showPicker = false;
    return {
      startDatepicker,
      endDatepicker,
      today,
      visibleMonth,
      months,
      visibleYear,
      years,
      showPicker
    };
  },
  computed: {
    days() {
      const customWeekend = this.startFromMonday ? 1 : 0;
      return Array.from(Array(7), (v, i) => {
        return dayjs()
          .day(i + customWeekend)
          .format("ddd");
      });
    },
    previousPicker() {
      const customWeekend = this.startFromMonday ? 1 : 0;
      const display = [];
      const previous = this.today.date(0);
      const current = this.today.date(0);
      for (let i = 0; i <= current.day() - customWeekend; i++) {
        display.push(previous.subtract(i, "day"));
      }
      return display.sort((a, b) => a.$d - b.$d);
    },
    currentPicker() {
      const customWeekend = this.startFromMonday ? 1 : 0;
      const eventDate = this.eventDate.length > 0 ? this.eventDate : [];
      return Array.from(
        Array(this.today.daysInMonth() - customWeekend),
        (v, i) => {
          const events = this.today.date(++i);
          events.$events = eventDate.find(o => {
            return o.date === events.format(this.formatDate);
          });
          return events;
        }
      );
    },
    nextPicker() {
      const customWeekend = this.startFromMonday ? 1 : 0;
      const display = [];
      const previous = this.previousPicker.length;
      const current = this.today.daysInMonth();
      for (let i = 1; i <= 42 - (previous + current) + customWeekend; i++) {
        display.push(this.today.date(i).add(1, "month"));
      }
      return display;
    },
    enableMonth() {
      return this.visibleMonth;
    },
    enableYear() {
      return this.visibleYear;
    },
    visiblePrev() {
      if (!this.dateRange) {
        const endOf = this.today.subtract(1, "month").endOf("month");
        const diff = this.startDatepicker.diff(endOf, "day");
        return diff < this.today.daysInMonth() - this.today.$D;
      }
      return true;
    },
    visibleNext() {
      if (!this.dateRange && this.endDate) {
        const startOf = this.today.add(1, "month").startOf("month");
        return this.endDatepicker.diff(startOf, "day") > 0;
      }
      return true;
    }
  },
  watch: {
    showPicker(prev, next) {
      if (prev) {
        this.visibleMonth = next;
        this.visibleYear = next;
      }
    }
  },
  mounted() {
    if (this.init) this.emit();
  },
  methods: {
    dayjs,
    emit() {
      this.$emit("change", this.today.format(this.formatDate));
    },
    changePicker(date) {
      this.today = date;
      this.emit();
      this.showPicker = !this.showPicker;
    },
    onPrevious() {
      if (this.visiblePrev) {
        const today = this.today
          .set("month", this.today.$M === 0 ? 11 : this.today.$M - 1)
          .set("year", this.today.$M === 0 ? this.today.$y - 1 : this.today.$y);
        if (this.possibleDate(today)) {
          this.today = today;
        } else {
          this.today = this.startDatepicker;
        }
        this.emit();
      }
    },
    onNext() {
      if (this.visibleNext) {
        const today = this.today
          .set("month", (this.today.$M + 1) % 12)
          .set(
            "year",
            this.today.$M === 11 ? this.today.$y + 1 : this.today.$y
          );
        if (this.possibleDate(today)) {
          this.today = today;
        } else {
          this.today = this.endDatepicker;
        }
        this.emit();
      }
    },
    possibleStartDate(date) {
      return this.dateRange
        ? true
        : date.isSameOrAfter(this.startDatepicker, "day");
    },
    possibleEndDate(date) {
      if (this.endDate) {
        return this.dateRange
          ? true
          : date.isSameOrBefore(this.endDatepicker, "day");
      }
      return false;
    },
    possibleDate(date) {
      if (this.endDate) {
        return this.possibleStartDate(date) && this.possibleEndDate(date);
      }
      return this.possibleStartDate(date);
    },
    holidayDate(date) {
      return !!(date.$events && date.$events.holiday);
    },
    toggleMonth() {
      this.visibleMonth = !this.visibleMonth;
      if (this.visibleMonth) {
        this.visibleYear = false;
      }
    },
    toggleYear() {
      this.visibleYear = !this.visibleYear;
      if (this.visibleYear) {
        this.visibleMonth = false;
      }
    },
    setMonth(month) {
      if (this.possibleDate(this.today.set("month", month))) {
        this.today = this.today.set("month", month);
      } else {
        this.today = this.startDatepicker;
      }
      this.emit();
      this.toggleMonth();
    },
    setYear(year) {
      if (this.possibleDate(this.today.set("year", year))) {
        this.today = this.today.set("year", year);
      } else {
        this.today = this.startDatepicker;
      }
      this.emit();
      this.toggleYear();
    },
    onAway() {
      this.showPicker = false;
    },
    onFeedBack() {
      this.showPicker = true;
    }
  }
});
