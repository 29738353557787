<li class="w-1/3 text-gray-500 relative group couponType p-3">
  <div
    class="w-full bg-qr_color-blue_3 rounded-cl_input_radius rounded-qr_input_radius"
  >
    <button
      class="w-full text-center flex cardType flex-col flex items-center p-12"
      :class="disabledClass"
      type="button"
      @click="onClick"
    >
      <div class="text-qr_color-blue_2">
        <div class="w-10 iconMainContainer">
          <QRIcon :name="icon" class="w-7 fill-current iconContainer"></QRIcon>
        </div>
      </div>
      <div class="py-2">
        <h5 class="font-medium text-qr_color_gray">
          {{$t("qr_type_select." + value)}}
        </h5>
        <div v-if="0" class="text-sm">{{description}}</div>
      </div>
    </button>
    <slot></slot>
  </div>
</li>
