import { mapGetters, mapActions, mapMutations } from "vuex";

import WithRender from "./CreateNewDetail.html";
import MainLayout from "../components/ui/layouts/MainLayout";
import Sections from "../components/base/sections/Sections";
import Spinner from "../components/base/components/Spinner";

export default WithRender({
  components: {
    MainLayout,
    Sections,
    Spinner
  },
  mounted() {
    this.routerActions({
      page: "newDetail",
      params: { category: this.getCurrentCategory, id: this.getCurrentId }
    });
  },
  computed: {
    ...mapGetters(["getEditorMode", "getLoading", "getCurrentModel"]),
    getCurrentCategory() {
      return this.$route.params.type;
    },
    getCurrentId() {
      return this.$route.params.id;
    },
    isLoading() {
      if (this.getLoading) return true;
      if (this.getCurrentId && !this.getCurrentModel) return true;
      return false;
    }
  },
  methods: {
    ...mapActions(["updateSelectedCategory", "routerActions"]),
    ...mapMutations(["setCurrentQrProps"])
  }
});
