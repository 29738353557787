<div class="w-full flex flex-col">
  <div class="bg-[#fbfbfb] text-qr_color-gray border-2 border-dashed border-[#d4d4d4] rounded-md float-left">
    <div v-if="!isMp3Uploading" class="my-8 px-6 flex justify-between s_qr:justify-center items-center">
      <div>
        <p class="text-base font-semibold">
          {{$t('Drag and drop MP3 here')}}
        </p>
        <span v-if="hasMp3">{{file.original_filename}}</span>
      </div>

      <div>
        <button v-if="!hasMp3" type="button"
          class="sm:flex px-16 text-white bg-qr_color-blue_1 p-2 rounded-qr_input_radius cursor-pointer"
          @click="$refs.file.click()">
          {{$t('Upload Mp3')}}
        </button>
        <button v-if="hasMp3" type="button"
          class="sm:flex px-16 text-white bg-qr_color-blue_1 p-2 rounded-qr_input_radius cursor-pointer"
          @click="$refs.file.click()">
          {{$t('Change Mp3')}}
        </button>
        <input ref="file" class="cursor-pointer absolute block opacity-0 pin-r pin-t" type="file" :name="name"
          @change="onChangeFileName" accept=".mp3,audio/*" />
      </div>
    </div>
    <div v-if="isMp3Uploading" class="my-8 px-6 flex justify-center items-center">
      <Spinner v-if="isMp3Uploading" :visible="true" />
    </div>
  </div>

  <div v-if="hasMp3" class="mt-2">
    <input type="checkbox" :value="addDownloadOption" :checked="addDownloadOption" @change="onAddDownloadOption" />
    <label class="text-qr_color-gray text-16 pr-4 font-semibold">{{$t('Add download option')}}</label>
  </div>
</div>