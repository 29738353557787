export const createDynamicEventFieldsWidget = (formly, t) => {
  return [
    formly.container(
      formly.header({
        index: 1,
        sectionName: "section_information",
        fold: false,
        headline: "",
        hideCollapse: true,
        icon: null
      }),
      formly.body({
        name: "section_information",
        collapseKey: "section_information",
        widget: 1,
        key: "code",
        content: [
          formly.avatarRectangular({
            tooltip: t(
              "Upload an event photo from your computer. Images must be at least 640x360 px in .jpg or .png format."
            ),
            previewWelcomeScreen: false,
            key: "avatar",
            aspectRatio: 1.77
          }),
          {
            className: "m-4",
            template: '<hr style="border-color: #eaeaea; margin: -8px 0 25px;">'
          },
          formly.inputRow({
            key: "organizer",
            label: t("Organizer"),
            placeholder: t("Company or host name")
          }),
          formly.inputRowRequired({
            key: "title",
            className: "form-input-row form-row-container flex-col",
            label: t("Title"),
            placeholder: t("Event name"),
            required: true,
            condition: model => !model["title"],
            errorMsgTitle: t("Please provide an event name."),
            validators: {
              required: function ({ messages, name, value }) {
                if (value.trim() == "") {
                  messages.push(t("Please provide an event name."));
                  return false;
                }

                return true;
              }
            }
          }),
          formly.textAreaRow({
            key: "teaser",
            label: t("Summary"),
            placeholder: t(
              "Write a short summary about the purpose and type of your event."
            ),
            maxChar: 250
          }),
          {
            className: "form-row-container flex-col",
            type: "field_group",
            fieldGroup: [
              {
                type: "qr_input_label",
                templateOptions: {
                  label: t("Button")
                }
              },
              {
                className: "col-sm-12 col-md-8 box-input",
                key: "callToAction",
                type: "qraction_button",
                templateOptions: {
                  urlPlaceholder: t("http://"),
                  defaultValue: {
                    label: t("Get tickets"),
                    url: ""
                  },
                  items: [
                    t("Book Now"),
                    t("Get tickets"),
                    t("Register"),
                    t("RSVP")
                  ],
                  addLabel: t("Add button"),
                  removeLabel: t("Remove button"),
                  customLabel: t("Custom"),
                  errorMessages: {
                    url: t("Please enter a valid website address"),
                    required: t("This field can not be empty")
                  },
                  filters: {
                    makeUrl: function ({ value }) {
                      if (value.trim() !== "" && !value.match(/:\/\//)) {
                        value = "http" + "://" + value;
                      }
                      return value;
                    }
                  },
                  validators: {
                    required: function ({ messages, name, value }) {
                      if (value.trim() == "") {
                        messages.push(t("This field can not be empty"));
                        return false;
                      }

                      return true;
                    },
                    validUrl: function ({ messages, name, value }) {
                      if (
                        value !== "" &&
                        !value.match(
                          /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                        )
                      ) {
                        messages.push(t("Please enter a valid address."));
                        return false;
                      }
                      return true;
                    }
                  }
                }
              }
            ]
          },
          formly.divider(),
          formly.timeInput({
            key: "date",
            keyRequired: true,
            label: t("When"),
            labelRequired: true,
            allDay: true,
            startDateOnly: true,
            endDateOnly: true,
            errorMsg: t("Please enter an event date."),
            condition: model =>
              !model["date"]["startDate"] || !model["date"]["endDate"]
          }),
          formly.inputRow({
            key: "venue",
            label: t("Where"),
            placeholder: t("Venue name")
          }),
          formly.autocompleteAddress({
            key: "address.autocomplete",
            label: t("Address"),
            placeholder: t("Enter your address"),
            condition: model => !model["showAutocomplete"]
          }),
          formly.autocompleteInputForm({ wrappedInAddressKey: false }),
          formly.features({ key: "features" }),
          formly.textAreaRow({
            key: "about",
            label: t("About"),
            placeholder: t(
              "Provide event information on guest speakers, entertainment, dress code, ticket prices, food, etc."
            ),
            maxChar: 2500
          }),
          formly.divider(),
          formly.inputRow({
            key: "contact",
            label: t("Contact"),
            placeholder: t("Contact person for the event")
          }),
          formly.inputRow({
            key: "phone",
            label: t("Phone"),
            placeholder: t("(000) 000-0000")
          }),
          formly.inputRow({
            key: "email",
            label: t("Email"),
            placeholder: t("your@email.com")
          }),
          formly.inputRow({
            key: "website",
            label: t("Website"),
            placeholder: t("www.your-website.com"),
            filters: {
              makeUrl: function ({ value }) {
                if (value.trim() !== "" && !value.match(/:\/\//)) {
                  value = "http" + "://" + value;
                }
                return value;
              }
            },
            validators: {
              validUrl: function ({ messages, name, value }) {
                if (
                  value !== "" &&
                  !value.match(
                    /^(http|https|fb|ftp|ftps|market):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)/i
                  )
                ) {
                  messages.push(t("Please enter a valid address."));
                  return false;
                }
                return true;
              }
            }
          })
        ]
      })
    )
  ];
};

export const createDynamicEventRequiredFieldsWidget = _ => {
  return [];
};
