import baseField from "vue-formly-bootstrap/src/fields/baseField";

import WithRender from "./QRAutocompleteAddress.html";

export default WithRender({
  mixins: [baseField],
  props: ["form", "field", "model"],
  created() {
    // console.log("[QRAutocompleteAddress]", this.field);
  },
  computed: {
    label() {
      return this.field.templateOptions.placeHolder;
    }
  },
  methods: {
    onClick() {}
  }
});
