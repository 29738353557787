<div class="w-full mb-12">
  <div class="full flex items-center">
    <VueDatePick v-if="startDateOnly" v-model="inputValueStart" :use12HourClock="value.timeMode !== '24'"
      :inputAttributes="{readonly: true, class:'bg-qr_color-gray_3 h-12 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent'}"
      :format="format" :formatDate="formatDate" :parseDate="parseDate" :pickTime="!value.allDay">
    </VueDatePick>
    <ArrowRightIcon v-if="startDateOnly && endDateOnly" class="mx-4 w-4 h-4 fill-current text-gray-400">
    </ArrowRightIcon>

    <VueDatePick v-if="endDateOnly" v-model="inputValueEnd" :use12HourClock="value.timeMode !== '24'"
      :inputAttributes="{readonly: true, class:'bg-qr_color-gray_3 h-12 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent'}"
      :format="format" :formatDate="formatDate" :parseDate="parseDate" :pickTime="!value.allDay"
      :isDateDisabled="isEarlyFeature">
    </VueDatePick>
  </div>
  <div v-if="isAllDayEnable" class="w-full mt-4 flex justify-between items-center relative">
    <div class="flex items-center">
      <input type="checkbox" v-model="value.allDay" name="allDay"
        class="w-4 h-4 text-qr_color-blue-2 hover:cursor-pointer hover:text-qr_color-blue_2 hover:bg-qr-color-blue_2 border-2 border-qr_color-blue_2 rounded-qr_checkbox_radius"
        @change="onChangeDayOption" />
      <label for="default-checkbox" class="mx-2 text-qr_color-gray text-16">{{$t('qr_date_time.all_days')}}
      </label>
    </div>
    <button v-if="!value.allDay" type="button" class="px-1 text-sm text-qr_color-blue_1 underline flex items-center"
      @click="onChangeVisibility">
      <span class="mr-2 text-sm text-qr_color-blue_1 underline">{{$t('qr_date_time.time_options')}}</span>
      <EyeIcon v-if="menuVisible" class="w-3 h-3 fill-current" />
      <EyeBlockIcon v-else class="w-3 h-3 fill-current" />
    </button>
    <div v-if="menuVisible && !value.allDay" class="absolute right-0 top-8 flex items-center">
      <select
        class="bg-qr_color-gray_3 h-12 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-auto border-0 focus:ring-0 focus:border-transparent"
        v-model="value.timezone" @change="onUpdateOptions">
        <option v-for="zone in timezones" :key="zone.key" :value="zone.value">
          {{zone.label}}
        </option>
      </select>
      <select v-model="value.timeMode"
        class="bg-qr_color-gray_3 h-12 px-2.5 py-2 text-qr_color-gray peer rounded-qr_input_radius block w-32 border-0 focus:ring-0 focus:border-transparent"
        @change="onUpdateOptions">
        <option value="24">24h</option>
        <option value="12">AM/PM</option>
      </select>
    </div>
  </div>
</div>