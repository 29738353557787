<div class="flex items-center justify-between">
  <nav aria-label="Page navigation">
    <ul class="inline-flex items-center -space-x-px">
      <li>
        <button
          class="block py-2 px-3 mr-0 leading-tight bg-white rounded-r-sm border border-qr_color-gray_3 rounded-l-qr_input_radius h-12 px-5 py-2.5 text-qr_color-blue_1"
          :class="previousBtnDisabled?  'text-gray-300 cursor-not-allowed':' bg-white hover:bg-qr_color-blue_1 hover:text-white'"
          :disabled="previousBtnDisabled"
          @click="onPreviousClicked"
        >
          <span class="sr-only">{{$t('pagination.previous')}}</span>
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </li>
      <li v-for="page in pageRanges">
        <button
          :key="page"
          class="py-2 px-3 leading-tight border border-qr_color-gray_3 h-12 px-5 py-2.5"
          :class="page == inputValue? 'text-white bg-qr_color-blue_1' : 'text-qr_color-blue_1 bg-white hover:bg-qr_color-blue_1 hover:text-white'"
          @click="onMoveToPage(page)"
        >
          {{page}}
        </button>
      </li>
      <li>
        <button
          class="block py-2 px-3 mr-0 leading-tight bg-white rounded-r-sm border border-qr_color-gray_3 rounded-r-qr_input_radius h-12 px-5 py-2.5 text-qr_color-blue_1"
          :class="nextBtnDisabled? 'text-gray-300 cursor-not-allowed':' bg-white hover:bg-qr_color-blue_1 hover:text-white'"
          :disabled="nextBtnDisabled"
          @click="onNextClicked"
        >
          <span class="sr-only">{{$t('pagination.next')}}</span>
          <svg
            aria-hidden="true"
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </li>
    </ul>
  </nav>

  <div class="flex items-center ml-8">
    <label
      for="item-size"
      class="w-28 text-sm font-medium text-qr_color-gray_2 mr-2 block"
      >{{$t('pagination.number_of_rows')}}:</label
    >
    <select
      id="item-size"
      v-model="inputRowsCount"
      class="block ml-auto bg-qr_color-gray_3 h-12 px-5 py-2.5 text-qr_color-gray peer rounded-qr_input_radius block w-full border-0 focus:ring-0 focus:border-transparent w-20 flex items-center"
    >
      <option value="4">4</option>
      <option value="8">8</option>
      <option value="12">12</option>
      <option value="16">16</option>
      <option value="24">24</option>
      <option value="48">48</option>
      <option value="10000000">{{$t('pagination.all')}}</option>
    </select>
  </div>
</div>
