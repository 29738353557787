<ul class="flex flex-wrap gap-y-3 gap-x-12 py-4">
  <li v-for="(c, idx) in colors" class="w-20 h-20 w-full relative">
    <input
      class="sr-only peer"
      type="radio"
      :value="getValue(c)"
      :name="name"
      :id="`qr_input_color_theme_${idx}`"
      @change="onChangeValue"
      :checked="myValue==getValue(c)"
    />
    <label
      class="flex flex-col p-2 w-full h-full ring ring-6 rounded-qr_input_radius cursor-pointer ring-qr_color-blue_ring peer-checked:ring-qr_color-blue_2 peer-checked:ring-6"
      :for="`qr_input_color_theme_${idx}`"
    >
      <div
        class="h-full w-full rounded-qr_input_radius"
        :style="`background-color: ${c.color1}`"
      >
        <div class="w-full px-2">
          <div
            class="h-4 w-4 -mt-1 rounded-full ml-auto absolute bottom-1 right-1"
            :style="`background-color: ${c.color2}`"
          ></div>
        </div>
      </div>
    </label>
  </li>
</ul>
