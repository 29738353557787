<div>
  <main-layout v-if="!getWidgetMode">
    <div class="py-8 mx-auto max-w-screen-xl">
      <!-- header begin -->
      <div class="w-full md:flex justify-between mb-2 lg:mb-4 items-center">
        <div
          v-show="!getBatchItemCount"
          class="grow md:flex w-full justify-between"
        >
          <h2 class="font-sans text-qr_color-gray_2 font-semibold text-lg">
            <span>
              {{$t('home.activeQrCode')}}
              ({{getQRCodesCount}}/{{getQRCodesTotal}})</span
            >
          </h2>
          <div class="flex items-center gap-x-4 mr-4">
            <div>
              <QrTypeSelect class="inline-block" v-model="getQrType" />
              <QrStatus class="inline-block" v-model="getQrStatus" />
            </div>
            <div>
              <QrOrderby class="inline-block" v-model="getQrSortByKey" />
              <QrDirection class="inline-block" v-model="getDirectionByKey" />
            </div>
          </div>
        </div>
        <div
          v-show="getBatchItemCount"
          class="w-full flex divide-x-2 divide-qr_color_gray gap-x-4"
        >
          <div class="flex items-center">
            <input
              type="checkbox"
              value=""
              class="w-4 h-4 text-qr_color-blue-2 hover:cursor-pointer hover:text-qr_color-blue_2 hover:bg-qr-color-blue_2 border-2 border-qr_color-blue_2 rounded-qr_checkbox_radius"
              @click="onSelectAll"
            />
          </div>
          <div class="grow flex items-center pl-4 text-qr_color-gray_2">
            <h2 class="font-medium text-base mr-4">
              <span>{{getBatchItemCount}} {{$t('home.selected')}}</span>
            </h2>
            <QrAction
              class=""
              @delete="onDeleteItems"
              @deactivate="onDeactivateItems"
              @activate="onActivateItems"
            />
          </div>
        </div>
        <div class="flex-shrink-0 w-48">
          <router-link
            to="/new"
            class="text-white flex ml-auto text-white bg-qr_color-blue_2 px-5 py-2.5 focus:outline-none rounded-qr_input_radius justify-center items-center gap-x-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16.238"
              height="16.238"
              viewBox="0 0 16.238 16.238"
            >
              <path
                id="Icon_awesome-qrcode"
                data-name="Icon awesome-qrcode"
                d="M0,9.209H6.959V2.25H0ZM2.32,4.57H4.64V6.89H2.32ZM9.279,2.25V9.209h6.959V2.25Zm4.64,4.64H11.6V4.57h2.32ZM0,18.488H6.959V11.529H0Zm2.32-4.64H4.64v2.32H2.32Zm12.759-2.32h1.16v4.64h-3.48v-1.16H11.6v3.48H9.279V11.529h3.48v1.16h2.32Zm0,5.8h1.16v1.16h-1.16Zm-2.32,0h1.16v1.16h-1.16Z"
                transform="translate(0 -2.25)"
                fill="#fff"
              />
            </svg>

            <span class="flex"> {{$t('home.create_qr_code')}}</span>
          </router-link>
        </div>
      </div>
      <!-- header end -->

      <!-- main begin -->
      <div class="w-full">
        <div
          class="w-full min-h-[20rem] flex items-center justify-center"
          v-if="getLoading"
        >
          <Spinner :visible="getLoading" />
        </div>
        <div v-else class="w-full flex flex-wrap">
          <template v-if="getQRCodes.length > 0">
            <CardListItem
              v-for="code in getQRCodes"
              :data="code"
              @open="onOpenModal"
            />
            <div class="w-full flex justify-center mt-5">
              <Pagination
                class="w-auto"
                v-model="curPage"
                :rowsCount="rowsCount"
                :pages="pageCount"
                @paginate="onPagination"
                @inputRowCount="onUpdateRowCount"
              >
              </Pagination>
            </div>
          </template>
          <template v-else>
            <div class="w-full min-h-[20rem] flex justify-center items-center">
              <div class="text-2xl text-center text-gray-500">
                {{$t('home.no_qr_code_exist')}}
              </div>
            </div>
          </template>
        </div>
      </div>
      <!-- main end -->

      <EditQrDesignModal ref="modalRef" />
    </div>
  </main-layout>
  <widget-layout v-if="getWidgetMode"> </widget-layout>
</div>
