import WithRender from "./QRInput.html";
import { forEachObjIndexed } from "ramda";

export default WithRender({
  props: ["form", "field", "model"],
  created() {
    this.value = this.model[this.name] ?? "";
  },
  mounted() {},
  computed: {
    name() {
      return this.field.key;
    },
    required() {
      return false;
      return this.field.templateOptions.required || false;
    },
    hasValidators() {
      const validators = this?.field?.templateOptions?.validators ?? {};
      return Object.keys(validators).length > 0;
    },
    placeHolder() {
      return this.field.templateOptions.placeHolder;
    },
    className() {
      return this.field.className;
    },
    inputListeners: function () {
      var vm = this;
      // `Object.assign` merges objects together to form a new object
      return Object.assign(
        {},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          validate: event => {
            if (!this.validate())
              this.$emit("validate", { element: event.target });
          }
        }
      );
    }
  },
  data() {
    return {
      value: "",
      errorMessage: ""
    };
  },
  methods: {
    onUpdateValue(event) {
      this.value = this.applyFilters(event.target.value);
      const { name, value } = this;
      this.validate();
      this.$emit("update", { name, value });
    },
    applyFilters(value) {
      let newValue = value;
      const { name } = this;
      const filters = this?.field?.templateOptions?.filters ?? {};
      forEachObjIndexed((filter, key) => {
        newValue = filter({ name, value: newValue });
      }, filters);
      return newValue;
    },
    validate() {
      let valid = true;
      const { name } = this;
      const validators = this?.field?.templateOptions?.validators ?? {};
      const messages = [];
      forEachObjIndexed((validator, key) => {
        valid = valid && validator({ messages, name, value: this.value });
      }, validators);
      this.errorMessage = "";
      if (!valid) {
        this.errorMessage = messages?.[0] ?? "";
      }
      return valid;
    }
  },
  watch: {
    model: {
      handler(newValue, oldValue) {
        if (!newValue[this.name]) {
          this.value = "";
        }
        if (oldValue[this.name] == newValue[this.name]) return;
        this.value = newValue[this.name];
      },
      deep: true
    }
  }
});
