import WithRender from "./QRForm.html";

export default WithRender({
  props: ["form", "fields", "model"],
  methods: {
    update(data) {
      this.$emit("update", data);
    },
    validate(data) {
      this.$emit("validate", data);
    },
    click(data) {
      this.$emit("click", data);
    }
  }
});
